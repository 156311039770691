export const CALLBACK_ACTION_PARAM_KEY = 'cb-action';
export const CREATE_SUCCESS_PARAM_KEY = 'created-success';
export const EDIT_SUCCESS_PARAM_KEY = 'edited-success';
export const DELETE_SUCCESS_PARAM_KEY = 'deleted-success';
export const ADD_STAFF_SUCCESS_PARAM_KEY = 'added-staff-success';
export const ARCHIVE_SUCCESS_PARAM_KEY = 'archived-success';
export const VIEW_PARAM_KEY = 'view';
export const TAB_PARAM_KEY = 'tab';
export const ACTION_SUCCESS_PARAMS = {
  CREATE: {
    [CALLBACK_ACTION_PARAM_KEY]: CREATE_SUCCESS_PARAM_KEY,
  },
  EDIT: {
    [CALLBACK_ACTION_PARAM_KEY]: EDIT_SUCCESS_PARAM_KEY,
  },
  DELETE: {
    [CALLBACK_ACTION_PARAM_KEY]: DELETE_SUCCESS_PARAM_KEY,
  },
  ADD_STAFF: {
    [CALLBACK_ACTION_PARAM_KEY]: ADD_STAFF_SUCCESS_PARAM_KEY,
  },
  ARCHIVE: {
    [CALLBACK_ACTION_PARAM_KEY]: ARCHIVE_SUCCESS_PARAM_KEY,
  },
  VIEW: {
    [VIEW_PARAM_KEY]: 'true',
  },
};
export const isUrlAction = (action) => {
  return action === 'true';
};
export const tabParams = (val) => {
  if (!val) return {};
  return {
    [TAB_PARAM_KEY]: val,
  };
};
