import cn from 'classnames';
import Collapse from 'src/components/Collapse';
import Tag from 'src/components/Tag';
import Section from '../Section';
import classes from './Category.module.scss';

const Category = ({
  className,
  collapse,
  toggleSection,
  title,
  sections,
  toggleCategory,
  onAddItem,
  onEditSection,
  onEditItem,
  onRemoveItem,
  onRemoveSection,
  categoryCollapsed,
}) => {
  const tSections = sections || [];
  const isNotFound = !tSections.length;

  return (
    <div
      className={cn(
        classes.wrapper,
        {
          [classes.isCollapsed]: categoryCollapsed,
        },
        className
      )}
    >
      <div className={classes.header}>
        <span>{title}</span>
        <Tag
          outline
          variant={categoryCollapsed ? 'dark_gray' : 'gray'}
          radius="sm"
          onClick={toggleCategory}
          size="sm"
        >
          {categoryCollapsed ? 'Expand' : 'Collapse'} Category
        </Tag>
      </div>
      <Collapse className={classes.content} isOpen={!categoryCollapsed}>
        <div></div>
        <div>
          {tSections.map((section) => {
            const isSectionCollapsed = !!collapse?.[section.id];
            return (
              <Section
                key={section.id}
                title={section.name}
                collapse={isSectionCollapsed}
                toggle={() => {
                  toggleSection(section.id, !isSectionCollapsed);
                }}
                onAdd={() => {
                  onAddItem(section);
                }}
                onEdit={() => {
                  onEditSection(section);
                }}
                onRemove={
                  !section.is_default
                    ? () => {
                        onRemoveSection(section);
                      }
                    : undefined
                }
                onEditItem={(item) => {
                  onEditItem(section, item);
                }}
                onRemoveItem={
                  onRemoveItem
                    ? (item) => {
                        onRemoveItem(section, item);
                      }
                    : undefined
                }
                items={section.items}
              />
            );
          })}
        </div>
      </Collapse>
    </div>
  );
};

export default Category;
