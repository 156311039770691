import get from 'lodash/get';
import queryString from 'query-string';
import { ACTION_SUCCESS_PARAMS, tabParams } from 'src/helper/constants/routes';

export const getAllUrlParams = (search = '') => {
  const searchUrl = search;
  return queryString.parse(searchUrl.slice(1)) || {};
};
export const getUrlParamValueByKey = (
  search = '',
  key = '',
  isGetAll = false
) => {
  const values = getAllUrlParams(search);
  if (isGetAll) {
    return values;
  }
  return get(values, key, '');
};
const getUrlObj = (val) => {
  if (!val) return '';
  const relativePath = val;
  const baseUrl = 'https://example.com';
  const url = new URL(relativePath, baseUrl);
  return url;
};
export const getRelativePathWithParams = (val, params) => {
  if (!val) return '';
  const url = getUrlObj(val);
  Object.keys(params || {}).forEach((key) => {
    url.searchParams.set(key, params[key]);
  });
  const updatedRelativePath = url.pathname + url.search;
  return updatedRelativePath;
};
export const getRemovedParamFromUrl = (val, params) => {
  if (!val) return '';
  if (!params?.length) return val;
  const url = getUrlObj(val);
  params.forEach((key) => {
    url.searchParams.delete(key);
  });
  const updatedRelativePath = url.pathname + url.search;
  return updatedRelativePath;
};
export const decodeToCleanUrl = (val) => {
  if (!val) return '';
  return decodeURIComponent(val);
};
export const getCreateEditUrlAction = (val, isEdit) => {
  return getRelativePathWithParams(
    val,
    isEdit ? ACTION_SUCCESS_PARAMS.EDIT : ACTION_SUCCESS_PARAMS.CREATE
  );
};
export const getAddStaffUrlAction = (val) => {
  return getRelativePathWithParams(val, ACTION_SUCCESS_PARAMS.ADD_STAFF);
};
export const getArchiveUrlAction = (val) => {
  return getRelativePathWithParams(val, ACTION_SUCCESS_PARAMS.ARCHIVE);
};
export const getViewUrlAction = (val) => {
  return getRelativePathWithParams(val, ACTION_SUCCESS_PARAMS.VIEW);
};
export const getTabUrlAction = (path, tab) => {
  return getRelativePathWithParams(path, tabParams(tab));
};
