import ForgotPassword from 'src/pages/Auth/ForgotPassword';
import Login from 'src/pages/Auth/Login';
import ResetPassword from 'src/pages/Auth/ResetPassword';

const authRoutes = [
  {
    path: '/login',
    name: 'Login',
    component: Login,
    layout: 'auth',
  },
  {
    path: '/forgot-password',
    name: 'Forgot Password',
    component: ForgotPassword,
    layout: 'auth',
  },
  {
    path: '/reset-password',
    name: 'Reset Password',
    component: ResetPassword,
    layout: 'auth',
  },
];

export default authRoutes;
