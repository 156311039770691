import cn from 'classnames';
import NotificationAlert from 'react-notification-alert';
import { notifyPrimary } from 'src/utils/notification';

const ToastAlert = ({ toastRef, className }) => {
  return (
    <>
      <div className={cn('rna-wrapper primary', className)}>
        <NotificationAlert ref={toastRef} />
      </div>
    </>
  );
};

const useNotify = (notificationAlertRef) => {
  const notify = (type, title, description) => {
    notifyPrimary({
      ref: notificationAlertRef,
      type,
      title,
      description,
      settings: {
        // autoDismiss: 100000,
      },
    });
  };
  return notify;
};

export { useNotify };
export default ToastAlert;
