import cn from 'classnames';
import { useState } from 'react';
import Button from 'src/components/Button';
import Modal from 'src/components/Modal';
import classes from './Modal.module.scss';

const ConfirmModal = ({
  isOpen,
  onClose,
  onSubmit,
  title,
  children,
  submitText,
  size,
  fontSize,
  cancelText,
  type,
  className,
  hideCancel,
}) => {
  const [loading, setLoading] = useState(false);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={title}
      hideClose
      centered
      fontSize={fontSize}
      className={cn(
        classes.confirmModalWrapper,
        classes[`type_${type}`],
        className
      )}
      size={size}
    >
      <div className={classes.confirmModalContent}>{children}</div>
      <div className={classes.confirmModalFooter}>
        {!hideCancel && (
          <Button
            variant="primary"
            type="button"
            fontSize="sm"
            onClick={onClose}
            isLink
          >
            {cancelText || 'Cancel'}
          </Button>
        )}
        <Button
          variant={type === 'confirmation' ? 'primary' : 'red'}
          type="button"
          fontSize="sm"
          loading={loading}
          onClick={() => {
            if (onSubmit) {
              setLoading(true);
              onSubmit(() => {
                setLoading(false);
              });
            }
          }}
        >
          {submitText || 'Confirm'}
        </Button>
      </div>
    </Modal>
  );
};

export default ConfirmModal;
