import cn from 'classnames';
import { ReactComponent as LogoSmallSvg } from 'src/assets/icons/Logo/Logo_Small.svg';
import { ReactComponent as ArchiveSvg } from 'src/assets/icons/archive.svg';
import { ReactComponent as CalendarSvg } from 'src/assets/icons/calendar.svg';
import Avatar from 'src/components/Avatar';
import Icon from 'src/components/Icon';
import Tag from 'src/components/Tag';
import { DOCUMENT_STATUS_VALUES } from 'src/helper/constants/document';
import { getFormatDate } from 'src/utils/date';
import { getDocumentStatusByTime } from 'src/utils/document';
import classes from './UploadEditor.module.scss';

const Placeholder = () => {
  return (
    <div className={classes.placeholder}>
      <LogoSmallSvg />
    </div>
  );
};

const Document = ({ className, onView, onDelete, data, isArchived }) => {
  const statusObj = getDocumentStatusByTime(data?.expires_at);
  return (
    <div className={cn(classes.documentWrapper, className)}>
      {[
        DOCUMENT_STATUS_VALUES.EXPIRED,
        DOCUMENT_STATUS_VALUES.WARNING,
      ].includes(statusObj.type) &&
        !isArchived && (
          <Tag
            variant={
              statusObj.type === DOCUMENT_STATUS_VALUES.EXPIRED
                ? 'error'
                : 'dark_warning'
            }
            ghost
            weight="lg"
            size="sm1"
            className={classes.statusTag}
          >
            {statusObj.text}
          </Tag>
        )}
      {!isArchived && (
        <span className={classes.deleteIcon} onClick={onDelete}>
          <ArchiveSvg />
        </span>
      )}
      <div className={classes.thumbnail} onClick={onView}>
        <Placeholder />
      </div>
      <div className={classes.name}>
        <span>{data?.file_name}</span>
      </div>
      <div className={classes.info}>
        <Avatar
          name={isArchived ? data?.archived_by_name : data?.created_by_name}
          fontSize="sm"
        />
        <span className={classes.calendar}>
          <span>
            <CalendarSvg />
          </span>
          <span>
            {getFormatDate(
              isArchived ? data?.archived_at : data?.created_at,
              'MM/DD/YYYY'
            ) || '-'}
          </span>
        </span>
      </div>
    </div>
  );
};

export default Document;
