import cn from 'classnames';
import { Spinner } from 'reactstrap';
import classes from './Loading.module.scss';

const Loading = ({ className, isPage, isBottom }) => {
  return (
    <div
      className={cn(
        classes.wrapper,
        {
          [classes.isPage]: isPage,
          [classes.isBottom]: isBottom,
        },
        className
      )}
    >
      <Spinner />
    </div>
  );
};

export default Loading;
