import { ReactComponent as RegionsSvg } from 'src/assets/icons/Nav/Regions.svg';
import { ReactComponent as RegionsActiveSvg } from 'src/assets/icons/Nav/Regions_Active.svg';
import CreateLocation from 'src/pages/CreateLocation';
import DocumentDetails from 'src/pages/DocumentDetails';
import LocationDetails from 'src/pages/LocationDetails';
import Locations from 'src/pages/Locations';

const locationRoutes = [
  {
    path: '/locations',
    name: 'Locations',
    component: Locations,
    layout: 'private',
    icon: <RegionsSvg />,
    iconActive: <RegionsActiveSvg />,
  },
  {
    path: '/locations/:locationId/emergency/:emergencyId',
    name: 'Location Emergency Details',
    icon: '',
    iconActive: '',
    component: DocumentDetails,
    layout: 'private',
  },
  {
    path: '/locations/:id',
    name: 'Location Details',
    icon: '',
    iconActive: '',
    component: LocationDetails,
    layout: 'private',
    settings: {
      whiteBackground: true,
    },
  },
  {
    path: '/locations/edit/:id',
    name: 'Edit Location',
    icon: '',
    iconActive: '',
    component: CreateLocation,
    layout: 'private',
  },
  {
    path: '/locations/create-location',
    name: 'Create Location',
    icon: '',
    iconActive: '',
    component: CreateLocation,
    layout: 'private',
  },
];

export default locationRoutes;
