import cn from 'classnames';
import { ReactComponent as UserProfileSvg } from 'src/assets/icons/user-profile.svg';
import classes from './Avatar.module.scss';

const Avatar = ({ className, name, fontSize }) => {
  if (!name) return <span>-</span>;
  return (
    <span
      className={cn(
        classes.wrapper,
        classes[`fontSize_${fontSize}`],
        className
      )}
    >
      <UserProfileSvg />
      <span>{name}</span>
    </span>
  );
};

export default Avatar;
