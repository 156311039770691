import cn from 'classnames';
import React from 'react';
import { ReactComponent as TickCheckSvg } from 'src/assets/icons/tick-circle1.svg';
import { ReactComponent as TrashSvg } from 'src/assets/icons/trash.svg';
import { ReactComponent as Warning2Svg } from 'src/assets/icons/warning-2.svg';
import Button from 'src/components/Button';
import Icon from 'src/components/Icon';
import LoadingCircle from 'src/components/Loading/Circle';
import { formatFileSize } from 'src/utils/number';
import classes from './Upload.module.scss';

const FileItem = ({
  className,
  name,
  size,
  onDelete,
  onCancel,
  onRetry,
  loading,
  isSuccess,
  isError,
  displaySize,
}) => {
  return (
    <div className={cn(classes.fileItem, className)}>
      {!loading && (!!isSuccess || !!isError) && (
        <>
          {!!isSuccess && (
            <Icon
              icon={<TickCheckSvg />}
              activeIcon={<TickCheckSvg />}
              size={24}
            />
          )}
          {!!isError && (
            <Icon
              icon={<Warning2Svg />}
              activeIcon={<Warning2Svg />}
              size={24}
            />
          )}
        </>
      )}
      {loading && !isSuccess && !isError && (
        <LoadingCircle className={classes.fileItemLoading} />
      )}
      <div className={classes.fileItemContent}>
        <span>{name || ''}</span>
        {!!displaySize && <span>{formatFileSize(size)}</span>}
      </div>
      <div className={classes.fileItemIcon}>
        {loading && (
          <Button variant="primary" isLink onClick={onCancel}>
            Cancel
          </Button>
        )}
        {!loading && !!isError && (
          <Button variant="primary" isLink onClick={onRetry}>
            Retry
          </Button>
        )}
        {!loading && !isSuccess && !isError && (
          <Icon
            icon={<TrashSvg />}
            activeIcon={<TrashSvg />}
            onClick={onDelete}
            size={18}
          />
        )}
      </div>
    </div>
  );
};

export default FileItem;
