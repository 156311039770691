/* eslint-disable no-restricted-imports */
import QuillHistory from 'quill/modules/history';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Registry } from 'react-form-builder2';
import { Quill } from 'react-quill';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import MultipleAnswerQuestion from 'src/pages/Admin/Workflow/components/CustomFields/MultipleAnswerQuestion';
import App from './App';
import { MenuProvider } from './helper/providers/MenuProvider';
import { MetadataProvider } from './helper/providers/MetadataProvider';
// plugins styles from node_modules
import 'react-notification-alert/dist/animate.css';
import 'react-perfect-scrollbar/dist/css/styles.css';
import 'sweetalert2/dist/sweetalert2.min.css';
import 'select2/dist/css/select2.min.css';
import 'quill/dist/quill.core.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
// plugins styles downloaded
import './assets/vendor/nucleo/css/nucleo.css';
// core styles
import './assets/scss/argon-dashboard-pro-react.scss';
import 'react-circular-progressbar/dist/styles.css';
import 'react-quill/dist/quill.snow.css';
import 'react-datepicker/dist/react-datepicker.css';
import reportWebVitals from './reportWebVitals';
import { persistor, store } from './store';
import './index.scss';

Registry.register('MultipleAnswerQuestion', MultipleAnswerQuestion);
Quill.register('modules/history', QuillHistory);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // TODO: StrictMode will render 2 times at dev mode at initial page loaded
  // <React.StrictMode>
  <BrowserRouter>
    <Provider store={store}>
      <PersistGate persistor={persistor} loading={null}>
        <MenuProvider>
          <MetadataProvider>
            <App />
          </MetadataProvider>
        </MenuProvider>
      </PersistGate>
    </Provider>
  </BrowserRouter>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
