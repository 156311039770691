import cn from 'classnames';
import classes from './FilePreview.module.scss';

const FilePreview = ({ className, title, url }) => {
  return (
    <div className={cn(classes.wrapper, className)}>
      <div>
        {url ? (
          <iframe
            src={url}
            width="100%"
            style={{ height: 'calc(100vh - 290px)' }}
          ></iframe>
        ) : (
          <>
            <span>{title}</span>
            <span>File Preview</span>
          </>
        )}
      </div>
    </div>
  );
};

export default FilePreview;
