import Button from 'src/components/Button';
import Tag from 'src/components/Tag';
import classes from './Content.module.scss';

const TagAndCategoryRemove = ({
  onAdd,
  onRemove,
  isNeedAttention,
  documentCount,
}) => (
  <div className={classes.tagCategoryRemove}>
    {!!isNeedAttention && (
      <Tag variant="dark_warning" weight="lg">
        Needs Attention
      </Tag>
    )}
    {documentCount?.expiring_count > 0 && (
      <Tag variant="dark_warning" weight="lg">
        {documentCount.expiring_count} Item
        {documentCount.expiring_count === 1 ? '' : 's'} Expiring
      </Tag>
    )}
    {documentCount?.expired_count > 0 && (
      <Tag variant="dark_warning" weight="lg">
        {documentCount.expired_count} Item
        {documentCount.expired_count === 1 ? '' : 's'} Expired
      </Tag>
    )}
    {!!onRemove && (
      <Button
        variant="primary_fade"
        onClick={onRemove}
        size="medium"
        weight="lg"
      >
        Remove Category
      </Button>
    )}
    <Button
      variant="primary"
      onClick={onAdd}
      size="medium"
      className={classes.addNewBtn}
    >
      Add New
    </Button>
  </div>
);

export default TagAndCategoryRemove;
