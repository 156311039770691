import cn from 'classnames';
import orderBy from 'lodash/orderBy';
import Button from 'src/components/Button';
import { LOCATION_EMERGENCY_TYPE } from 'src/helper/constants/location';
import { NEW_OLD_OPTIONS } from 'src/helper/constants/option';
import useProfile from 'src/helper/hooks/useProfile';
import EmergencyContact from './Contact';
import classes from './Emergency.module.scss';
import EmergencyPlan from './Plan';

const Emergency = ({
  className,
  onAddContact,
  onEditContact,
  onDeleteContact,
  onViewPlan,
  onAddPlan,
  data,
  onPaginationChange,
  onSortChange,
  onContact,
}) => {
  const currentUser = useProfile();
  return (
    <div className={cn(classes.wrapper, className)}>
      <div className={classes.content}>
        <div className={classes.plans}>
          <EmergencyPlan
            title="Procedures"
            btnText="Add New"
            items={data?.procedures?.data || []}
            meta={data?.procedures?.meta}
            sortOptions={NEW_OLD_OPTIONS}
            onViewPlan={(obj) => {
              if (onViewPlan)
                onViewPlan(LOCATION_EMERGENCY_TYPE.PROCEDURE, obj);
            }}
            onAddPlan={() => {
              if (onAddPlan) onAddPlan(LOCATION_EMERGENCY_TYPE.PROCEDURE);
            }}
            isNotFound={!data?.procedures?.meta?.total}
            onPaginationChange={(...params) => {
              if (
                onPaginationChange &&
                (params[0] !== data?.procedures?.meta?.current_page ||
                  params[1] !== data?.procedures?.meta?.per_page)
              )
                onPaginationChange(
                  LOCATION_EMERGENCY_TYPE.PROCEDURE,
                  ...params
                );
            }}
            onSortChange={(val) => {
              if (onSortChange) {
                onSortChange(val, LOCATION_EMERGENCY_TYPE.PROCEDURE);
              }
            }}
            emptyContent={
              <>
                <span>
                  At least 1 procedure document
                  <br />
                  needs be added for compliance.
                </span>
              </>
            }
          />
          <EmergencyPlan
            title="Evacuation Plans"
            btnText="Add New"
            sortOptions={NEW_OLD_OPTIONS}
            items={data?.evacuationPlans?.data || []}
            meta={data?.evacuationPlans?.meta}
            onViewPlan={(obj) => {
              if (onViewPlan)
                onViewPlan(LOCATION_EMERGENCY_TYPE.EVACUATION_PLAN, obj);
            }}
            onAddPlan={() => {
              if (onAddPlan) onAddPlan(LOCATION_EMERGENCY_TYPE.EVACUATION_PLAN);
            }}
            isNotFound={!data?.evacuationPlans?.meta?.total}
            onPaginationChange={(...params) => {
              if (
                onPaginationChange &&
                (params[0] !== data?.evacuationPlans?.meta?.current_page ||
                  params[1] !== data?.evacuationPlans?.meta?.per_page)
              )
                onPaginationChange(
                  LOCATION_EMERGENCY_TYPE.EVACUATION_PLAN,
                  ...params
                );
            }}
            onSortChange={(val) => {
              if (onSortChange) {
                onSortChange(val, LOCATION_EMERGENCY_TYPE.EVACUATION_PLAN);
              }
            }}
            emptyContent={
              <>
                <span>
                  At least 1 evacuation plan document
                  <br />
                  needs be added for compliance.
                </span>
              </>
            }
          />
        </div>
        <div className={classes.contactsWrapper}>
          <div className={classes.header}>
            <span>Contacts</span>
            <div>
              <Button
                variant="primary"
                onClick={() => {
                  onContact({});
                }}
                fontSize="sm"
                weight="lg"
                size="medium"
                isLink
              >
                Add New Contact
              </Button>
            </div>
          </div>
          {data?.contacts?.data?.length > 0 && (
            <div className={classes.contacts}>
              {orderBy(data.contacts.data, ['favourite'], ['desc']).map(
                (contact, index) => {
                  return (
                    <EmergencyContact
                      key={index}
                      data={contact}
                      onEdit={() => {
                        onContact(contact);
                      }}
                      onDelete={() => {
                        onDeleteContact(contact);
                      }}
                      isManual={contact.created_by === currentUser.id}
                    />
                  );
                }
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Emergency;
