import cn from 'classnames';
import { useController } from 'react-hook-form';
import Select from 'src/components/FormFields/Select';
import Tag from 'src/components/Tag';
import useDynamicMenu from 'src/helper/hooks/useDynamicMenu';
import { getSelectValues } from 'src/utils/form';
import classes from './Select.module.scss';

function SelectLocationGroup({
  className,
  onChange,
  control,
  name,
  required,
  label,
}) {
  const { locationRegionGroup } = useDynamicMenu();
  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    control,
  });
  const value = field.value;
  const valueValues = getSelectValues(value || []);
  const locationOptions = (locationRegionGroup || []).flatMap(
    (obj) => obj.options
  );

  return (
    <div className={cn(classes.selectLocationGroupWrapper, className)}>
      <Select
        required={required}
        options={locationRegionGroup}
        placeholder="Select Locations"
        label={label}
        isMulti
        value={value}
        closeMenuOnSelect={false}
        // menuIsOpen
        multiLabel="Location"
        onChange={(val) => {
          onChange(val);
        }}
        locationGroup
        isClearable={false}
      />
      {value?.length > 0 && (
        <div className={classes.list}>
          {locationOptions
            .filter((option) => valueValues.includes(option.value))
            .map((option) => {
              return (
                <Tag
                  key={option.value}
                  variant="location"
                  weight="md"
                  onClose={() => {
                    onChange(
                      valueValues
                        .filter((v) => v !== option.value)
                        .map((v) =>
                          locationOptions.find((obj) => obj.value === v)
                        )
                    );
                  }}
                >
                  {option.label}
                </Tag>
              );
            })}
        </div>
      )}
    </div>
  );
}

export default SelectLocationGroup;
