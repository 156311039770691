import cn from 'classnames';
import { forwardRef } from 'react';
import { ReactComponent as InformationCircleSvg } from 'src/assets/icons/information-circle-solid.svg';
import { ReactComponent as InformationSvg } from 'src/assets/icons/information.svg';
import Icon from 'src/components/Icon';
import Tooltip from 'src/components/Tooltip';
import classes from './LabelTooltip.module.scss';

const LabelTooltip = forwardRef(({ className, tooltip }, ref) => {
  return (
    <Tooltip
      placement={tooltip?.placement || 'right'}
      tooltipText={tooltip?.text}
      hasArrow
      flex
      light
      className={cn('label-tooltip-wrapper', className)}
      fontSize="sm"
      overlayClassName={tooltip?.overlayClassName}
      id={tooltip?.id}
    >
      <Icon
        icon={
          tooltip?.icon === 'info-circle' ? (
            <InformationCircleSvg />
          ) : (
            <InformationSvg />
          )
        }
        activeIcon={
          tooltip?.icon === 'info-circle' ? (
            <InformationCircleSvg />
          ) : (
            <InformationSvg />
          )
        }
        size={tooltip?.size || 15}
      />
    </Tooltip>
  );
});

export default LabelTooltip;
