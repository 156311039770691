import { ReactComponent as IntegrationsSvg } from 'src/assets/icons/Nav/Integrations.svg';
import { ReactComponent as Integrations_ActiveSvg } from 'src/assets/icons/Nav/Integrations_Active.svg';
import { ReactComponent as KnowledgeSvg } from 'src/assets/icons/Nav/Knowledge.svg';
import { ReactComponent as Knowledge_ActiveSvg } from 'src/assets/icons/Nav/Knowledge_Active.svg';
import { ReactComponent as OrganizationSvg } from 'src/assets/icons/Nav/Organization.svg';
import { ReactComponent as Organization_ActiveSvg } from 'src/assets/icons/Nav/Organization_Active.svg';
import { ReactComponent as TasksApprovalsSvg } from 'src/assets/icons/Nav/TasksApprovals.svg';
import { ReactComponent as TasksApprovals_ActiveSvg } from 'src/assets/icons/Nav/TasksApprovals_Active.svg';
import { ReactComponent as TrainingSvg } from 'src/assets/icons/Nav/Training.svg';
import { ReactComponent as Training_ActiveSvg } from 'src/assets/icons/Nav/Training_Active.svg';
import { ReactComponent as UsersSvg } from 'src/assets/icons/Nav/Users.svg';
import { ReactComponent as Users_ActiveSvg } from 'src/assets/icons/Nav/Users_Active.svg';
import Integrations from 'src/pages/Integrations';
import KnowledgeCenter from 'src/pages/KnowledgeCenter';
import Organization from 'src/pages/Organization';
import CreateStaff from 'src/pages/Staff/Create';
import Approvals from 'src/pages/TasksApprovals/Approvals';
import Tasks from 'src/pages/TasksApprovals/Tasks';
import Training from 'src/pages/Training';
import Users from 'src/pages/Users';

const generalRoutes = [
  {
    path: '/users',
    name: 'Users',
    icon: <UsersSvg />,
    iconActive: <Users_ActiveSvg />,
    component: Users,
    layout: 'private',
  },
  {
    path: '/users/create',
    name: 'Users Create',
    icon: <UsersSvg />,
    iconActive: <Users_ActiveSvg />,
    component: CreateStaff,
    layout: 'private',
  },
  {
    path: '/tasks',
    name: 'Tasks',
    icon: <TasksApprovalsSvg />,
    iconActive: <TasksApprovals_ActiveSvg />,
    component: Tasks,
    layout: 'private',
  },
  {
    path: '/approvals',
    name: 'Approvals',
    icon: <TasksApprovalsSvg />,
    iconActive: <TasksApprovals_ActiveSvg />,
    component: Approvals,
    layout: 'private',
  },
  {
    path: '/organization',
    name: 'Organization',
    icon: <OrganizationSvg />,
    iconActive: <Organization_ActiveSvg />,
    component: Organization,
    layout: 'private',
  },
  {
    path: '/training',
    name: 'Training',
    icon: <TrainingSvg />,
    iconActive: <Training_ActiveSvg />,
    component: Training,
    layout: 'private',
  },
  {
    path: '/integrations',
    name: 'Integrations',
    icon: <IntegrationsSvg />,
    iconActive: <Integrations_ActiveSvg />,
    component: Integrations,
    layout: 'private',
  },
  {
    path: '/knowledge-center',
    name: 'KnowledgeCenter',
    icon: <KnowledgeSvg />,
    iconActive: <Knowledge_ActiveSvg />,
    component: KnowledgeCenter,
    layout: 'private',
  },
];

export default generalRoutes;
