import isEmpty from 'lodash/isEmpty';
import Button from 'src/components/Button';
import FieldsRow from 'src/components/Form/FieldsRow';
import FormDatepicker from 'src/components/FormFields/Datepicker/FormDatepicker';
import FormGroup from 'src/components/FormFields/FormGroup';
import FormInput from 'src/components/FormFields/Input/FormInput';
import FormSelect from 'src/components/FormFields/Select/FormSelect';
import PageFooter from 'src/components/Page/PageFooter';
import useSubSystemOptions from 'src/helper/hooks/useSubSystemOptions';
import { useMetadata } from 'src/helper/providers/MetadataProvider';
import CreateChemicalView from '../View';

const CreateEquipmentForm = ({
  onSubmit,
  isViewDetails,
  details,
  control,
  formState,
  isEdit,
  submitLoading,
  onCancel,
}) => {
  const { subSystemOptions } = useSubSystemOptions();
  const { userOptions } = useMetadata();

  return (
    <>
      {isViewDetails ? (
        <CreateChemicalView details={details} />
      ) : (
        <form role="form" className="s-form" onSubmit={onSubmit}>
          <div className="s-form-inner">
            <FieldsRow col={2}>
              <FormGroup>
                <FormInput
                  required
                  label="Name"
                  name="name"
                  placeholder="Enter name"
                  control={control}
                  radius="md"
                  variant="primary"
                  fontSize="sm"
                />
              </FormGroup>
              <FormGroup>
                <FormSelect
                  options={subSystemOptions}
                  required
                  label="Sub System"
                  name="sub_system_id"
                  placeholder="Select sub system"
                  control={control}
                  radius="md"
                  variant="primary"
                  fontSize="sm"
                />
              </FormGroup>
            </FieldsRow>
            <FieldsRow col={2}>
              <FormGroup>
                <FormDatepicker
                  label="Last Maintenance"
                  name="last_maintenance"
                  placeholder="Select"
                  control={control}
                  radius="md"
                  variant="primary"
                  fontSize="sm"
                  required
                />
              </FormGroup>
              <FormSelect
                options={userOptions}
                label="Maintenance By"
                name="maintenance_by_id"
                placeholder="Select"
                control={control}
                radius="md"
                variant="primary"
                fontSize="sm"
                required
              />
            </FieldsRow>
            <FieldsRow col={1}>
              <FormGroup>
                <FormInput
                  label="Description"
                  name="description"
                  placeholder="Enter description"
                  control={control}
                  radius="md"
                  variant="primary"
                  fontSize="sm"
                  type="textarea"
                />
              </FormGroup>
            </FieldsRow>
          </div>
          <PageFooter fixed>
            <Button variant="gray" type="button" onClick={onCancel}>
              Cancel
            </Button>
            <Button
              variant="primary"
              type="submit"
              disabled={!formState.isValid || isEmpty(formState.dirtyFields)}
              loading={submitLoading}
            >
              {isEdit ? 'Save Changes' : 'Add'}
            </Button>
          </PageFooter>
        </form>
      )}
    </>
  );
};

export default CreateEquipmentForm;
