import { useState } from 'react';
import { getAllCollapse, getIsCollapseAll } from './utils';

const useQuickAccessCollapse = (items) => {
  const [collapseItems, setCollapseItems] = useState({});
  const [categoriesCollapsed, setCategoriesCollapsed] = useState({});
  let isCollapseAll = getIsCollapseAll(items, categoriesCollapsed);
  const toggleAll = () => {
    const allCollapsedObj = getAllCollapse(items, isCollapseAll);
    // setCollapseItems((prev) => ({
    //   ...prev,
    //   ...allCollapsedObj.sections,
    // }));
    setCategoriesCollapsed((prev) => ({
      ...prev,
      ...allCollapsedObj.categories,
    }));
  };
  const toggleCategory = (obj) => {
    setCategoriesCollapsed((prev) => ({
      ...prev,
      [obj.id]: !prev[obj.id],
    }));
  };
  const toggleSection = (id, value) => {
    setCollapseItems((prev) => ({
      ...prev,
      [id]: value,
    }));
  };
  return {
    isCollapseAll,
    collapseItems,
    categoriesCollapsed,
    toggleAll,
    toggleCategory,
    toggleSection,
  };
};

export default useQuickAccessCollapse;
