import { RESOURCE_DATA_KEY } from 'src/helper/constants/store';
import * as locationActions from 'src/store/actions/location';
import * as regionActions from 'src/store/actions/region';
import * as resourceActions from 'src/store/actions/resource';
import { useReducerData, useStoreActions } from 'src/store/hooks';

export const useIndexData = () => {
  return {
    locationDetails: useReducerData('location', 'locationDetails.data', {}),
    locationDetailsLoading: useReducerData(
      'location',
      'locationDetails.loading',
      false
    ),
    quickAccessDetails: useReducerData(
      'resource',
      `resourceDetails.data.${RESOURCE_DATA_KEY.QUICK_ACCESS_ITEM_DETAILS}`,
      {}
    ),
    quickAccessDocuments: useReducerData(
      'resource',
      `resources.data.${RESOURCE_DATA_KEY.QUICK_ACCESS_ITEM_DOCUMENTS}`,
      {}
    ),
    quickAccessArchivedDocuments: useReducerData(
      'resource',
      `resources.data.${RESOURCE_DATA_KEY.QUICK_ACCESS_ITEM_ARCHIVED_DOCUMENTS}`,
      {}
    ),
    quickAccessDocumentCount: useReducerData(
      'resource',
      `resources.data.${RESOURCE_DATA_KEY.QUICK_ACCESS_ITEM_DOCUMENT_COUNT}.data`,
      {}
    ),
  };
};

export const useActions = () => {
  return useStoreActions({
    ...locationActions,
    ...resourceActions,
  });
};
