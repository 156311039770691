import FieldsRow from 'src/components/Form/FieldsRow';
import StatusTag from 'src/components/Tag/Status';
import Field from 'src/pages/GenericCreate/components/Field';
import { getFormatDate } from 'src/utils/date';

const CreateSubSystemView = ({ details }) => {
  return (
    <>
      <FieldsRow col={2}>
        <Field label="Name" value={details?.sub_system_name} bold />
        <Field
          label="Status"
          bold
          value={
            <span>
              <StatusTag value={details?.status} />
            </span>
          }
        />
      </FieldsRow>
      <FieldsRow col={2}>
        <Field
          label="Last Inspection"
          value={getFormatDate(details?.last_inspection, 'MMM DD, YYYY')}
          bold
        />
        <Field
          label="Documents"
          value={details?.documents_count}
          bold
          defaultValue={0}
        />
      </FieldsRow>
      <FieldsRow col={2}>
        <Field
          label="Updated at"
          value={getFormatDate(details?.updated_at, 'MMM DD, YYYY')}
          bold
        />
        <Field label="Updated by" value={details?.updated_by?.name} bold />
      </FieldsRow>
      <FieldsRow col={2}>
        <Field
          label="Last maintenance"
          value={getFormatDate(details?.last_maintenance_at, 'MMM DD, YYYY')}
          bold
        />
        <Field
          label="Maintenance by"
          value={details?.last_maintenance_by?.name}
          bold
        />
      </FieldsRow>
    </>
  );
};

export default CreateSubSystemView;
