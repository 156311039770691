import {
  buildResourceData,
  getQuickAccessHierarchyUpdatedSectionItems,
  getQuickAccessHierarchyUpdatedSections,
} from 'src/utils/resource';
import { defaultResourceObj } from '../constants';
import { handleData } from '../middlewares/handleData';
import Types from '../types/resource';

const initialState = {
  resources: {
    ...defaultResourceObj,
  },
  resourceDetails: {
    ...defaultResourceObj,
  },
};

const ResourceReducer = (state = initialState, action) => {
  const { type, payload, meta } = action;
  const key = meta?.payload?.key;

  switch (type) {
    case Types.SYNC_UPDATE_QUICK_ACCESS_SECTION: {
      return {
        ...state,
        resources: {
          ...state.resources,
          data: {
            ...state.resources.data,
            ...getQuickAccessHierarchyUpdatedSections(
              state.resources.data,
              payload
            ),
          },
        },
      };
    }
    case Types.SYNC_UPDATE_QUICK_ACCESS_SECTION_ITEM: {
      return {
        ...state,
        resources: {
          ...state.resources,
          data: {
            ...state.resources.data,
            ...getQuickAccessHierarchyUpdatedSectionItems(
              state.resources.data,
              payload
            ),
          },
        },
      };
    }
    case Types.GET_RESOURCES:
      return handleData(state, action, {
        request: (prevState) => ({
          ...prevState,
          resources: {
            ...prevState.resources,
            loading: {
              ...prevState.resources.loading,
              [key]: true,
            },
          },
        }),
        success: (prevState) => ({
          ...prevState,
          resources: {
            ...prevState.resources,
            loading: {
              ...prevState.resources.loading,
              [key]: false,
            },
            data: {
              ...prevState.resources.data,
              [key]: buildResourceData(payload, key),
            },
            error: {
              ...prevState.resources.error,
              [key]: '',
            },
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          resources: {
            ...prevState.resources,
            loading: {
              ...prevState.resources.loading,
              [key]: false,
            },
            data: {
              ...prevState.resources.data,
              [key]: {},
            },
            error: {
              ...prevState.resources.error,
              [key]: payload,
            },
          },
        }),
      });
    case Types.GET_RESOURCE_DETAILS:
      return handleData(state, action, {
        request: (prevState) => ({
          ...prevState,
          resourceDetails: {
            ...prevState.resourceDetails,
            loading: {
              ...prevState.resourceDetails.loading,
              [key]: true,
            },
          },
        }),
        success: (prevState) => ({
          ...prevState,
          resourceDetails: {
            ...prevState.resourceDetails,
            loading: {
              ...prevState.resourceDetails.loading,
              [key]: false,
            },
            data: {
              ...prevState.resourceDetails.data,
              [key]: payload.data,
            },
            error: {
              ...prevState.resourceDetails.error,
              [key]: '',
            },
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          resourceDetails: {
            ...prevState.resourceDetails,
            loading: {
              ...prevState.resourceDetails.loading,
              [key]: false,
            },
            data: {
              ...prevState.resourceDetails.data,
              [key]: {},
            },
            error: {
              ...prevState.resourceDetails.error,
              [key]: payload,
            },
          },
        }),
      });
    default:
      return state;
  }
};

export default ResourceReducer;
