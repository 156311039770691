import { yupResolver } from '@hookform/resolvers/yup';
import isEmpty from 'lodash/isEmpty';
import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import QuickAccessTypeTooltip1Png from 'src/assets/img/quick-access-type-tooltip-1.png';
import { ReactComponent as QuickAccessTypeTooltip1Svg } from 'src/assets/img/quick-access-type-tooltip-1.svg';
import QuickAccessTypeTooltip2Png from 'src/assets/img/quick-access-type-tooltip-2.png';
import { ReactComponent as QuickAccessTypeTooltip2Svg } from 'src/assets/img/quick-access-type-tooltip-2.svg';
import Button from 'src/components/Button';
import FieldsRow from 'src/components/Form/FieldsRow';
import FormCheckbox from 'src/components/FormFields/Checkbox/FormCheckbox';
import FormGroup from 'src/components/FormFields/FormGroup';
import FormInput from 'src/components/FormFields/Input/FormInput';
import FormSelect from 'src/components/FormFields/Select/FormSelect';
import Modal from 'src/components/Modal';
import { INPUT_HIGHLIGHT_ERROR_MESSAGE } from 'src/helper/constants';
import {
  RELATED_PSM_ELEMENT_OPTIONS,
  YES_NO_OPTIONS,
  YES_NO_VALUES,
} from 'src/helper/constants/option';
import { QUICK_ACCESS_TYPE_OPTIONS } from 'src/helper/constants/quickAccess';
import { useMetadata } from 'src/helper/providers/MetadataProvider';
import classes from './AddItemModal.module.scss';
import SelectElements from './SelectElements';

const formSchema = yup.object().shape({
  item_type_id: yup.mixed().required(INPUT_HIGHLIGHT_ERROR_MESSAGE),
  role_id: yup.mixed().required(INPUT_HIGHLIGHT_ERROR_MESSAGE),
  item_name: yup.string().required(INPUT_HIGHLIGHT_ERROR_MESSAGE),
});

const AddItemModal = ({ isOpen, onClose, onSubmit, editObj }) => {
  const { roleOptions } = useMetadata();
  const isEdit = !!editObj?.item?.original_id || !!editObj?.item?.id;
  const [loading, setLoading] = useState(false);
  const nameRef = useRef(null);
  const { handleSubmit, control, formState, watch, setValue } = useForm({
    resolver: yupResolver(formSchema),
    defaultValues: {
      item_type_id: editObj?.item?.type || null,
      item_name: editObj?.item?.name || '',
      section_name: editObj?.section?.name || '',
      section_id: editObj?.section?.original_id || editObj?.section?.id || '',
      item_description: editObj?.item?.description || '',
      related_psm_elements_id:
        editObj?.item?.related_psm_elements?.length > 0
          ? RELATED_PSM_ELEMENT_OPTIONS.filter((obj) =>
              editObj.item.related_psm_elements.includes(obj.value)
            )
          : [],
      role_id: editObj?.item?.role_id ? String(editObj.item.role_id) : null,
      require_manager_approval:
        editObj?.item?.requires_approval === 0
          ? YES_NO_VALUES.NO
          : YES_NO_VALUES.YES,
      can_hidden: !!editObj?.item?.can_be_hidden,
    },
  });

  useEffect(() => {
    setLoading(false);
    setTimeout(() => {
      if (nameRef && nameRef.current) {
        nameRef.current.focus();
      }
    }, 100);
  }, []);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={isEdit ? 'Edit Section Item' : 'New Section Item'}
      hideClose
      centered
      wrapClassName={classes.modalWrapper}
      radius="lg"
      size="large"
    >
      <div className={classes.description}>
        <span>
          Create sections items that will appear as card in each category tab to
          organize your documents.
        </span>
      </div>
      <form
        role="form"
        className={classes.form}
        onSubmit={handleSubmit((values) => {
          setLoading(true);
          onSubmit(
            {
              ...values,
              id: editObj?.item?.original_id || editObj?.item?.id || '',
            },
            () => {
              setLoading(false);
            }
          );
        })}
      >
        <div className={classes.formInner}>
          <FieldsRow col={3}>
            <FormGroup>
              <FormSelect
                options={QUICK_ACCESS_TYPE_OPTIONS}
                required
                label="Item Type"
                name="item_type_id"
                placeholder="Select type"
                control={control}
                radius="md"
                variant="primary"
                fontSize="sm"
                activeShadow
                labelTooltip={{
                  placement: 'right-top',
                  id: 'quick-access-type-tooltip',
                  text: (
                    <span className={classes.typeTooltipContent}>
                      Item type dictates what type of Quick Access section will
                      be visible to your staff and specific actions they can
                      take.
                      <br />
                      <br />
                      <b>File Upload:</b> For Quick Access sections that require
                      many documents living in one space. IE: Piping &
                      Instrumentation Diagrams. You can upload various files
                      that relate to that Quick Access section to ensure your
                      process safety is up to date and easily accessible.
                      <br />
                      <br />
                      <span>
                        <img
                          src={QuickAccessTypeTooltip1Png}
                          alt="tooltip-1"
                          style={{ maxWidth: '342px' }}
                        />
                      </span>
                      <br />
                      <br />
                      <b>Rich Text:</b> For Quick Access sections that require
                      written guidelines, use the rich text editor to
                      dynamically craft documents directly into Omni. These will
                      save as living documents you can export into your desired
                      format.
                      <br />
                      <br />
                      <span>
                        <img
                          src={QuickAccessTypeTooltip2Png}
                          alt="tooltip-1"
                          style={{ maxWidth: '342px' }}
                        />
                      </span>
                    </span>
                  ),
                  icon: 'info-circle',
                }}
              />
            </FormGroup>
          </FieldsRow>
          <FieldsRow col={3}>
            <FormGroup>
              <FormInput
                label="Item Name"
                name="item_name"
                placeholder="Enter item name"
                control={control}
                radius="md"
                variant="primary"
                fontSize="sm"
                required
              />
            </FormGroup>
            <FormGroup>
              <FormInput
                label="Section"
                name="section_name"
                placeholder="Section"
                control={control}
                radius="md"
                variant="primary"
                fontSize="sm"
                disabled
                labelTooltip={{
                  text: (
                    <>
                      Sections are the various tabs within each Quick Access
                      Category.
                      <br />
                      When creating a new item card, the item is automatically
                      <br />
                      assigned to the section you created it from.
                    </>
                  ),
                  icon: 'info-circle',
                }}
              />
            </FormGroup>
          </FieldsRow>
          <FieldsRow col={1}>
            <FormGroup>
              <FormInput
                label="Item Description"
                name="item_description"
                placeholder="Enter item description"
                control={control}
                radius="md"
                variant="primary"
                fontSize="sm"
                type="textarea"
                maxLength={100}
              />
            </FormGroup>
          </FieldsRow>
          <FieldsRow col={3}>
            <FormGroup>
              <SelectElements
                control={control}
                watch={watch}
                setValue={setValue}
                name="related_psm_elements_id"
              />
            </FormGroup>
            <FormGroup>
              <FormSelect
                options={roleOptions}
                required
                label="Role responsible for item"
                name="role_id"
                placeholder="Select role"
                control={control}
                radius="md"
                variant="primary"
                fontSize="sm"
                activeShadow
              />
            </FormGroup>
          </FieldsRow>
          <FieldsRow col={1}>
            <FormGroup>
              <FormSelect
                options={YES_NO_OPTIONS}
                label="Does the item require manager approval?"
                name="require_manager_approval"
                placeholder="Select"
                control={control}
                radius="md"
                variant="primary"
                fontSize="sm"
                activeShadow
                selectorWidth={100}
                labelTooltip={{
                  text: (
                    <>
                      Items marked for manager approval will be subject to
                      approval by
                      <br />
                      the role responsible’s direct manager. If a user uploads a
                      <br />
                      document to this Quick Access Item it will be pending
                      upload
                      <br />
                      until their manager approves the upload.
                    </>
                  ),
                  icon: 'info-circle',
                }}
              />
            </FormGroup>
          </FieldsRow>
          <FormGroup>
            <FormCheckbox
              name="can_hidden"
              variant="primary"
              control={control}
              label="This item can be hidden"
              fontSize="md"
              optionalText="(Optional)"
              inline
              labelTooltip={{
                text: (
                  <>
                    Selecting this option will break your form sections into
                    page
                    <br />
                    steps, meaning you can focus on one section at a time before
                    <br />
                    hitting, “Next” to advance to your next form section.
                  </>
                ),
                icon: 'info',
              }}
            />
          </FormGroup>
        </div>
        <div className={classes.formFooter}>
          <Button variant="primary" isLink type="button" onClick={onClose}>
            Cancel
          </Button>
          <Button
            variant="primary"
            type="submit"
            loading={loading}
            disabled={!formState.isValid || isEmpty(formState.dirtyFields)}
          >
            {isEdit ? 'Save Changes' : 'Create New Item'}
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default AddItemModal;
