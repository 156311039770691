import cn from 'classnames';
import React from 'react';
import { ReactComponent as LogoAuthSvg } from 'src/assets/icons/Logo/LogoAuth.svg';
import { ReactComponent as MainLogoSvg } from 'src/assets/icons/Logo/omni-horizontal.svg';
import { ReactComponent as MainLogoShortSvg } from 'src/assets/icons/Logo/omni-logo-short.svg';
import classes from './Logo.module.scss';

const Logo = ({ className, isMini, size, variant }) => {
  const isAuth = variant === 'authentication';
  return (
    <span
      className={cn(
        classes.wrapper,
        {
          [classes.isMini]: isMini,
        },
        classes[`size_${size}`],
        classes[`variant_${variant}`],
        className
      )}
    >
      {isMini ? (
        <MainLogoShortSvg className="main-logo-short-icon" />
      ) : (
        <MainLogoSvg className="main-logo-icon" />
      )}
    </span>
  );
};

export default Logo;
