import { useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import Breadcrumb from 'src/components/Breadcrumb';
import Loading from 'src/components/Loading';
import PageTitle from 'src/components/Page/PageTitle';
import PageWrapper from 'src/components/Page/PageWrapper';
import ToastAlert from 'src/components/ToastAlert';
import useOwnNavigate from 'src/helper/hooks/useOwnNavigate';
import { getViewUrlAction } from 'src/utils/routes';
import classes from './Generic.module.scss';
import GenericActionButtons from './components/ActionButtons';
import GenericContent from './components/Content';
import GenericTable from './components/Table';
import { getColumns } from './constants';

const Generic = () => {
  const notificationAlertRef = useRef(null);
  const params = useParams();
  const { onNavigate } = useOwnNavigate();
  const [checkedRows, setCheckedRows] = useState([]);
  const loading = false;
  const breadcrumbs = [
    {
      label: 'Generic',
    },
  ];
  const handleView = () => {
    onNavigate(getViewUrlAction('/generic/create?id=1'));
  };
  const handleEdit = () => {
    onNavigate('/generic/create?id=1');
  };

  const columns = getColumns({ onView: handleView, onEdit: handleEdit });
  const data = [{ key: 1, id: 1 }];

  return (
    <>
      <PageWrapper className={classes.wrapper}>
        {loading && <Loading isPage />}
        <Breadcrumb items={breadcrumbs} />
        <PageTitle subTitle={<span>Selected menu option subtitle</span>}>
          Selected menu option title
        </PageTitle>
        <GenericContent>
          <GenericActionButtons />
          <GenericTable
            onAdd={() => {
              onNavigate('/generic/create');
            }}
            columns={columns}
            title="List of all components, regions, locations, etc..."
          />
        </GenericContent>
      </PageWrapper>
      <ToastAlert toastRef={notificationAlertRef} />
    </>
  );
};

export default Generic;
