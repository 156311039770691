import cn from 'classnames';
import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { ReactComponent as ExportSvg } from 'src/assets/icons/export.svg';
import classes from './Upload.module.scss';

const Upload = ({
  className,
  placeholder,
  supportText,
  onChange,
  accept,
  isSingle,
}) => {
  const onDrop = (acceptedFiles) => {
    if (onChange) onChange(acceptedFiles);
  };
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    maxFiles: isSingle ? 1 : undefined,
  });

  return (
    <div
      {...getRootProps()}
      className={cn(classes.wrapper, getRootProps().className, className)}
    >
      <input {...getInputProps()} accept={accept} multiple={!isSingle} />
      <div className={classes.placeholder}>
        <div>
          <span>
            <ExportSvg />
          </span>
          {placeholder || (
            <>
              <span>Click to upload file or drag and drop here</span>
              <span>{supportText || 'Supported all formats'}</span>
            </>
          )}
          <span className={classes.placeholderButton}>Upload file</span>
        </div>
      </div>
    </div>
  );
};

export default Upload;
