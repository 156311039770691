import cn from 'classnames';
import cloneDeep from 'lodash/cloneDeep';
import uniq from 'lodash/uniq';
import React from 'react';
import { useController } from 'react-hook-form';
import Checkbox from 'src/components/FormFields/Checkbox';
import { MENU_ACTION_KEYS } from 'src/helper/constants/menu';
import { getMenuName } from 'src/utils/menu';
import classes from './MenuItemActions.module.scss';
import { buildNestedMenu, getActionCheckedLength } from './utils';

const Row = ({ data, parentName, onChange, value }) => {
  const id = data?.id || '0';
  const tValue = value || [];
  const handleChange = (tKey, addKeys) => {
    if (onChange) {
      const newVal = [
        ...(tValue.includes(tKey)
          ? tValue.filter((v) => v !== tKey)
          : [...tValue, tKey]),
        ...(addKeys || []),
      ];
      onChange(uniq(newVal));
    }
  };
  return (
    <tr>
      <td>
        {parentName ? `${parentName} >> ` : ''}
        {getMenuName(data)}
      </td>
      <td>
        <Checkbox
          variant="primary"
          id={`${id}_menu_item_view`}
          className={classes.checkbox}
          checked={tValue.includes(MENU_ACTION_KEYS.VIEW)}
          onChange={(e) => {
            handleChange(MENU_ACTION_KEYS.VIEW);
          }}
          disabled={tValue.length > 1}
        />
      </td>
      <td>
        <Checkbox
          variant="primary"
          id={`${id}_menu_item_edit`}
          className={classes.checkbox}
          checked={tValue.includes(MENU_ACTION_KEYS.EDIT)}
          onChange={(e) => {
            handleChange(MENU_ACTION_KEYS.EDIT, [MENU_ACTION_KEYS.VIEW]);
          }}
          disabled={tValue.length > 2}
        />
      </td>
      <td>
        <Checkbox
          variant="primary"
          id={`${id}_menu_item_add`}
          className={classes.checkbox}
          checked={tValue.includes(MENU_ACTION_KEYS.ADD)}
          onChange={(e) => {
            handleChange(MENU_ACTION_KEYS.ADD, [
              MENU_ACTION_KEYS.VIEW,
              MENU_ACTION_KEYS.EDIT,
            ]);
          }}
          disabled={tValue.length > 3}
        />
      </td>
      <td>
        <Checkbox
          variant="primary"
          id={`${id}_menu_item_delete`}
          className={classes.checkbox}
          checked={tValue.includes(MENU_ACTION_KEYS.DELETE)}
          onChange={(e) => {
            handleChange(MENU_ACTION_KEYS.DELETE, [
              MENU_ACTION_KEYS.VIEW,
              MENU_ACTION_KEYS.EDIT,
              MENU_ACTION_KEYS.ADD,
            ]);
          }}
        />
      </td>
    </tr>
  );
};

function MenuItemActions({ className, onChange, control, name, menus }) {
  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    control,
  });
  const value = field.value || [];
  const tMenus = menus || [];
  const displayMenus = buildNestedMenu(tMenus);
  const renderRows = (arr) => {
    return (arr || []).map((tData) => {
      return (
        <React.Fragment key={tData.id}>
          <Row
            data={tData}
            parentName={tData.parent_menu_item_name}
            value={value.find((obj) => obj.id == tData.id)?.actions || []}
            onChange={(tVal) => {
              const newValue = cloneDeep(value);
              const foundIndex = newValue.findIndex(
                (obj) => obj.id === tData.id
              );
              if (foundIndex >= 0) {
                newValue[foundIndex].actions = [...tVal];
              } else {
                newValue.push({
                  id: tData.id,
                  actions: [...tVal],
                });
              }
              if (onChange) onChange(newValue);
            }}
          />
          {tData.children?.length > 0 && renderRows(tData.children)}
        </React.Fragment>
      );
    });
  };

  return (
    <div className={cn(classes.wrapper, className)}>
      <table>
        <colgroup>
          <col style={{ width: 500 }} />
          <col style={{ width: 100 }} />
          <col style={{ width: 100 }} />
          <col style={{ width: 100 }} />
          <col style={{ width: 100 }} />
        </colgroup>
        <thead>
          <tr>
            <th>Menu Item</th>
            <th>
              <div className={classes.headerRow}>View</div>
            </th>
            <th>
              <div className={classes.headerRow}>Edit</div>
            </th>
            <th>
              <div className={classes.headerRow}>Add</div>
            </th>
            <th>
              <div className={classes.headerRow}>Delete</div>
            </th>
          </tr>
        </thead>
        <tbody>{renderRows(displayMenus)}</tbody>
      </table>
    </div>
  );
}

export default MenuItemActions;
