import { ReactComponent as ArchiveSvg } from 'src/assets/icons/archive.svg';
import { ReactComponent as ArrowRight2Svg } from 'src/assets/icons/arrow-right2.svg';
import Button from 'src/components/Button';
import Icon from 'src/components/Icon';
import { getMomentDate } from 'src/utils/date';
import { renderDateColumn, renderNormalColumn } from 'src/utils/table';
import classes from './HistoryModal.module.scss';

export const getColumns = ({ onView, onDelete, isQuickAccess }) => {
  return [
    {
      key: isQuickAccess ? 'file_name' : 'title',
      label: 'Name',
      render: renderNormalColumn,
      width: 217,
    },
    {
      key: 'modified_by_name',
      label: 'Updated by',
      render: (val) => val || '-',
      sortable: true,
      width: 150,
    },
    {
      key: isQuickAccess ? 'uploaded_at' : 'created_at',
      label: 'Date Added',
      render: renderDateColumn,
      sortable: true,
      width: 150,
    },
    {
      key: 'action',
      width: 50,
      maxWidth: 50,
      render: (_, obj) => {
        const today = getMomentDate('', true, true);
        const createdAt = getMomentDate(
          isQuickAccess ? obj.uploaded_at : obj.created_at,
          false,
          true
        );
        const isOver5Years = createdAt
          ? createdAt.add(5, 'years').isBefore(today)
          : false;
        return (
          <span className={classes.actions}>
            {isQuickAccess ? (
              <>
                <Button
                  variant="primary_fade"
                  onClick={() => {
                    onView(obj);
                  }}
                  size="medium"
                >
                  View
                </Button>
              </>
            ) : (
              <>
                {isOver5Years && (
                  <Icon
                    icon={<ArchiveSvg />}
                    activeIcon={<ArchiveSvg />}
                    size={20}
                    onClick={() => {
                      onDelete(obj);
                    }}
                  />
                )}
                <Icon
                  icon={<ArrowRight2Svg />}
                  activeIcon={<ArrowRight2Svg />}
                  size={20}
                  onClick={() => {
                    onView(obj);
                  }}
                />
              </>
            )}
          </span>
        );
      },
    },
  ];
};
