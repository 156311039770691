import cn from 'classnames';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import { ReactComponent as ArrowDown1Svg } from 'src/assets/icons/arrow-down1.svg';
import classes from './Score.module.scss';

const Score = ({
  className,
  score,
  title,
  color,
  activeColor,
  issuesColor,
  width = 180,
  strokeWidth = 12,
  issues,
  center,
}) => {
  const hasIssues = Number(issues) >= 0;
  return (
    <div
      className={cn(
        classes.wrapper,
        {
          [classes.hasIssues]: hasIssues,
        },
        className
      )}
      style={{ width: `${width}px`, margin: center ? '0 auto' : undefined }}
    >
      <div
        className={classes.circle}
        style={{
          height: `calc(${width / 2}px + 11px)`,
        }}
      >
        <svg style={{ position: 'absolute', width: 0, height: 0 }}>
          <defs>
            <linearGradient
              id="gradientColor"
              x1="0%"
              y1="0%"
              x2="100%"
              y2="0%"
            >
              <stop
                offset="0%"
                stopColor={activeColor || '#82EB80'}
                stopOpacity="0"
              />
              <stop
                offset="100%"
                stopColor={activeColor || '#82EB80'}
                stopOpacity="1"
              />
            </linearGradient>
          </defs>
        </svg>
        <CircularProgressbar
          value={score}
          strokeWidth={strokeWidth}
          circleRatio={0.5}
          styles={{
            ...buildStyles({
              // pathColor: activeColor || '#82EB80',
              pathColor: 'url(#gradientColor)',
              trailColor: color || '#ADBDBF',
            }),
            root: {
              transform: 'rotate(0.75turn)',
            },
          }}
        />
      </div>
      <div className={classes.info}>
        <span className={classes.title}>{title}</span>
        <span className={classes.score}>{score}</span>
      </div>
      {hasIssues && (
        <div className={classes.issues}>
          <div>
            <span style={{ background: issuesColor || '#FFDD9B' }}>
              <span>
                {issues} Issue{issues === 1 ? '' : 's'}
              </span>
              <ArrowDown1Svg />
            </span>
          </div>
        </div>
      )}
    </div>
  );
};

export default Score;
