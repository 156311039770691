import Breadcrumb from 'src/components/Breadcrumb';
import PageTitle from 'src/components/Page/PageTitle';
import PageWrapper from 'src/components/Page/PageWrapper';
import classes from './OmniFlows.module.scss';

const OmniFlows = () => {
  const breadcrumbs = [
    {
      label: 'Omni Flows',
    },
  ];

  return (
    <PageWrapper className={classes.wrapper}>
      <Breadcrumb items={breadcrumbs} />
      <PageTitle>Omni Flows</PageTitle>
    </PageWrapper>
  );
};

export default OmniFlows;
