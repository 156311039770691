import cn from 'classnames';
import { forwardRef } from 'react';
import DatePicker from 'react-datepicker';
import { FormFeedback, Label } from 'reactstrap';
import { ReactComponent as WarningSvg } from 'src/assets/icons/warning-2.svg';
import LabelTooltip from 'src/components/FormFields/LabelTooltip';
import RequiredMark from 'src/components/RequiredMark';
import { INPUT_HIGHLIGHT_ERROR_MESSAGE } from 'src/helper/constants';
import classes from './Datepicker.module.scss';

const Datepicker = forwardRef(
  (
    {
      className,
      pickerClassName,
      pickerWrapperClassName,
      portalId,
      radius,
      fontSize,
      variant,
      required,
      placeholder,
      label,
      labelTooltip,
      value,
      error,
      ...other
    },
    ref
  ) => {
    return (
      <div
        className={cn(
          classes.wrapper,
          classes[`radius_${radius}`],
          classes[`variant_${variant}`],
          classes[`fontSize_${fontSize}`],
          className
        )}
      >
        {!!label && (
          <Label className={classes.label}>
            {label}
            {required && <RequiredMark />}
            {!!labelTooltip?.text && !!labelTooltip?.icon && (
              <LabelTooltip tooltip={labelTooltip} />
            )}
          </Label>
        )}
        <DatePicker
          popperPlacement="bottom-start"
          portalId={portalId || 'root-portal'}
          container={document.body}
          {...other}
          className={cn(
            'form-control',
            {
              'is-invalid': !!error,
            },
            pickerClassName
          )}
          wrapperClassName={cn(
            'form-control',
            {
              'is-invalid': !!error,
            },
            pickerWrapperClassName
          )}
          placeholderText={placeholder}
          selected={value}
          ref={ref}
        />
        {error && error !== INPUT_HIGHLIGHT_ERROR_MESSAGE && (
          <FormFeedback className={classes.error}>
            <WarningSvg />
            <span>{error}</span>
          </FormFeedback>
        )}
      </div>
    );
  }
);

export default Datepicker;
