import orderBy from 'lodash/orderBy';
import uniqBy from 'lodash/uniqBy';
import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import Breadcrumb from 'src/components/Breadcrumb';
import Loading from 'src/components/Loading';
import ConfirmModal from 'src/components/Modal/Confirm';
import PageTitle from 'src/components/Page/PageTitle';
import PageWrapper from 'src/components/Page/PageWrapper';
import ToastAlert, { useNotify } from 'src/components/ToastAlert';
import { DOCUMENT_STATUS_VALUES } from 'src/helper/constants/document';
import { RESOURCE_DATA_KEY } from 'src/helper/constants/store';
import useOwnNavigate from 'src/helper/hooks/useOwnNavigate';
import useResourceActions from 'src/helper/hooks/useResourceActions';
import AddPlanModal from 'src/pages/LocationDetails/components/AddPlanModal';
import { LOCATION_DETAILS_TAB_KEYS } from 'src/pages/LocationDetails/constants';
import { getErrorMessageFromResponse } from 'src/utils/actions';
import { getDocumentStatusByTime } from 'src/utils/document';
import {
  getArchiveUrlAction,
  getRelativePathWithParams,
  getTabUrlAction,
} from 'src/utils/routes';
import { getErrorMessage, getSuccessMessage } from 'src/utils/toast';
import classes from './DocumentDetails.module.scss';
import FilePreview from './components/FilePreview';
import HistoryModal from './components/HistoryModal';
import Info from './components/Info';
import { useActions, useIndexData } from './selectorData';
import useFunction from './useFunction';
import { getInfoItems } from './utils';

let tempNewVersionId = 0;

const DocumentDetails = () => {
  const {
    onArchiveResource,
    onGetResourceVersions,
    onGetResourceHistory,
    onUploadNewVersionResource,
    onDeleteResource,
  } = useResourceActions();
  const notificationAlertRef = useRef(null);
  const params = useParams();
  const { onNavigate } = useOwnNavigate();
  const locationId = Number(params.locationId || 0);
  const emergencyId = Number(params.emergencyId || 0);
  const quickAccessId = Number(params.quickAccessId || 0);
  const documentId = Number(params.documentId || 0);
  const documentHistoryId = Number(params.documentHistoryId || 0);
  const isQuickAccessDocument = !!quickAccessId && !!documentId;
  const { locationDetails, detailsData } = useIndexData(
    isQuickAccessDocument,
    documentHistoryId
  );
  const isArchivedDocument =
    detailsData?.status === DOCUMENT_STATUS_VALUES.ARCHIVED;
  const isArchivedNotHistory = isArchivedDocument && !documentHistoryId;
  const [archiveId, setArchiveId] = useState(null);
  const [viewHistory, setViewHistory] = useState(false);
  const [modalPlanObj, setModalPlanObj] = useState(null);
  const [locationDetailsLoading, setLocationDetailsLoading] = useState(false);
  const [detailsLoading, reloadDetailsLoading] = useState(false);
  const [documentDetailsLoading, setDocumentDetailsLoading] = useState(false);
  const [versions, setVersions] = useState([]);
  const [uploadInformation, setUploadInformation] = useState({});
  const { getLocationDetails, getResourceDetails } = useActions();
  const statusObj = getDocumentStatusByTime(detailsData?.valid_until);
  const locationBackLink = isQuickAccessDocument
    ? `/locations/${locationId}`
    : getTabUrlAction(
        `/locations/${locationId}`,
        LOCATION_DETAILS_TAB_KEYS.EMERGENCY
      );
  const quickAccessBackLink = `/locations/${locationId}/quick-access/${quickAccessId}`;
  const breadcrumbs = [
    {
      link: `/locations`,
      label: 'Locations',
    },
    {
      link: getTabUrlAction(
        `/locations/${locationId}`,
        LOCATION_DETAILS_TAB_KEYS.EMERGENCY
      ),
      label: locationDetails?.name,
    },
    ...(isQuickAccessDocument
      ? [
          {
            link: quickAccessBackLink,
            label: detailsData?.quickAccessDetails?.name,
          },
        ]
      : []),
    {
      label: detailsData?.title,
    },
  ];
  const notify = useNotify(notificationAlertRef);
  const { onUploadNewVersion, onUploadFile } = useFunction({
    documentId,
    notify,
  });
  const resetState = () => {
    tempNewVersionId = 0;
    setArchiveId(null);
    setModalPlanObj(null);
    setViewHistory(false);
    setLocationDetailsLoading(false);
    reloadDetailsLoading(false);
  };
  const handleConfirmedArchiveVersion = async (obj, loadingCb, cb) => {
    const res = await onArchiveResource(
      RESOURCE_DATA_KEY.EMERGENCY_DOCUMENT,
      obj.id,
      {},
      'PUT'
    );
    loadingCb();
    if (res?.isSuccess) {
      cb();
      onLoadVersions();
    } else {
      notify(
        'error',
        'Error!',
        getErrorMessageFromResponse(res) || 'Archive document version failed'
      );
    }
  };
  const handleConfirmedView = (obj) => {
    if (isQuickAccessDocument) {
      onNavigate(
        `/locations/${locationId}/quick-access/${quickAccessId}/documents/${documentId}/history/${obj.id}`
      );
    } else {
      onNavigate(`/locations/${locationId}/emergency/${obj.id}`);
    }

    setViewHistory(null);
  };
  const reloadDocumentDetails = () => {
    if (documentHistoryId || documentId) {
      setDocumentDetailsLoading(true);
      getResourceDetails(
        documentHistoryId
          ? RESOURCE_DATA_KEY.QUICK_ACCESS_ITEM_DOCUMENT_HISTORY_DETAILS
          : RESOURCE_DATA_KEY.QUICK_ACCESS_ITEM_DOCUMENT_DETAILS,
        documentHistoryId ? documentHistoryId : documentId,
        () => {
          setDocumentDetailsLoading(false);
        }
      );
    }
  };
  const reloadDetails = async (cb) => {
    reloadDetailsLoading(true);
    if (isQuickAccessDocument) {
      getResourceDetails(
        RESOURCE_DATA_KEY.QUICK_ACCESS_ITEM_DETAILS,
        quickAccessId,
        () => {
          reloadDetailsLoading(false);
        }
      );
    } else {
      getResourceDetails(
        RESOURCE_DATA_KEY.EMERGENCY_DOCUMENT,
        emergencyId,
        (res) => {
          reloadDetailsLoading(false);
          if (cb) cb(res);
        }
      );
    }
  };
  const reloadLocationDetails = (cb) => {
    setLocationDetailsLoading(true);
    getLocationDetails(locationId, (res) => {
      setLocationDetailsLoading(false);
      if (cb) cb(res);
    });
  };
  const handleConfirmedArchiveFile = async (cb) => {
    let res = null;
    if (isQuickAccessDocument) {
      res = await onArchiveResource(
        RESOURCE_DATA_KEY.QUICK_ACCESS_ITEM_DOCUMENT_DETAILS,
        archiveId,
        {}
      );
    } else {
      res = await onArchiveResource(
        RESOURCE_DATA_KEY.EMERGENCY_DOCUMENT,
        archiveId,
        {},
        'PUT'
      );
    }
    if (cb) cb();
    if (res?.isSuccess) {
      onNavigate(
        getArchiveUrlAction(
          isQuickAccessDocument ? quickAccessBackLink : locationBackLink
        )
      );
    } else {
      notify(...getErrorMessage(getErrorMessageFromResponse(res)));
    }
  };
  const onLoadVersions = async (params) => {
    if (isQuickAccessDocument) {
      const res = await onGetResourceHistory(
        RESOURCE_DATA_KEY.QUICK_ACCESS_ITEM_DOCUMENTS,
        documentId,
        {}
      );
      setVersions(res?.isSuccess ? res.data : []);
    } else {
      const res = await onGetResourceVersions(
        RESOURCE_DATA_KEY.EMERGENCY_DOCUMENT,
        emergencyId,
        params
      );
      setVersions(
        res?.isSuccess
          ? res.data.filter((obj) => String(obj.id) !== String(emergencyId))
          : []
      );
    }
  };
  const handleHistorySortChanged = (obj) => {
    setVersions(orderBy(versions, [obj.field || ''], [obj.method || '']));
  };
  const handleUploadCalling = async (tFile) => {
    const tFileId = tFile.file_id;
    setUploadInformation({
      [tFileId]: {
        loading: true,
        isSuccess: false,
        isError: false,
      },
    });
    const tData = new FormData();
    tData.append('file', tFile);
    let res = null;
    if (!isQuickAccessDocument) {
      res = await onUploadNewVersionResource(
        RESOURCE_DATA_KEY.EMERGENCY_DOCUMENT,
        detailsData.parent_id,
        tData
      );
      tempNewVersionId = res?.data?.id;
    }
    setUploadInformation({
      [tFileId]: {
        loading: false,
        isSuccess: !!res?.isSuccess,
        isError: !res?.isSuccess,
      },
    });
  };
  const handleNewVersionFileChange = async (tFiles) => {
    const tFile = tFiles?.[0];
    if (tFile) {
      const tFileId = tFile.file_id;
      setUploadInformation({
        [tFileId]: {
          loading: true,
          isSuccess: false,
          isError: false,
        },
      });
      const res = await onUploadFile(tFile);
      tempNewVersionId = res?.data?.documentId;
      setUploadInformation({
        [tFileId]: {
          loading: false,
          isSuccess: false,
          isError: !res?.isSuccess,
        },
      });
    }
  };
  const handleConfirmedUploadVersion = async (values, cb) => {
    if (cb) cb();
    await handleUploadCalling(values.uploadedFiles[0]);
  };
  const handleRetryUploadVersion = async (tFile) => {
    await handleUploadCalling(tFile);
  };
  const handleArchive = () => {
    setArchiveId(isQuickAccessDocument ? documentId : emergencyId);
  };
  const handleDeleteUploadedFile = () => {
    onDeleteResource(RESOURCE_DATA_KEY.DOCUMENTS, tempNewVersionId);
    tempNewVersionId = 0;
  };

  useEffect(() => {
    reloadLocationDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (emergencyId || (documentId && quickAccessId)) {
      resetState();
      reloadDetails();
      if (!documentHistoryId) {
        onLoadVersions();
      }
      reloadDocumentDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emergencyId, quickAccessId, documentId, documentHistoryId]);

  return (
    <>
      <PageWrapper className={classes.wrapper}>
        {(locationDetailsLoading ||
          detailsLoading ||
          documentDetailsLoading) && <Loading isPage />}
        <Breadcrumb items={breadcrumbs} />
        <div className={classes.pageTitleWrapper}>
          <PageTitle>{detailsData?.title}</PageTitle>
          <Info
            onArchive={handleArchive}
            onViewHistory={() => {
              setViewHistory(true);
            }}
            onUpload={() => {
              setModalPlanObj({});
              tempNewVersionId = 0;
            }}
            statusType={
              isQuickAccessDocument
                ? DOCUMENT_STATUS_VALUES.EXPIRED
                : statusObj?.type
            }
            items={getInfoItems(detailsData, isQuickAccessDocument)}
            onBackVersion={
              documentHistoryId
                ? () => {
                    onNavigate(
                      `${quickAccessBackLink}/documents/${documentId}`
                    );
                  }
                : undefined
            }
            isArchivedNotHistory={isArchivedNotHistory}
          />
        </div>
        <FilePreview
          className={classes.filePreview}
          title={detailsData?.title}
          url={detailsData?.url}
        />
      </PageWrapper>
      {!!archiveId && (
        <ConfirmModal
          isOpen
          onClose={() => {
            setArchiveId(null);
          }}
          onSubmit={handleConfirmedArchiveFile}
          title="Archive file"
          submitText="Archive File"
          size="s_small"
          fontSize="sm"
        >
          Are you sure you want to archive this file? It will no longer be
          publicly available for audits.
        </ConfirmModal>
      )}
      {!!viewHistory && (
        <HistoryModal
          isOpen
          onClose={() => {
            setViewHistory(false);
          }}
          onConfirmedDelete={handleConfirmedArchiveVersion}
          onConfirmedView={handleConfirmedView}
          versions={versions}
          onSortChange={handleHistorySortChanged}
          isQuickAccess={isQuickAccessDocument}
        />
      )}
      {!!modalPlanObj && (
        <AddPlanModal
          isOpen
          onClose={() => {
            setModalPlanObj(null);
            setUploadInformation({});
          }}
          onSubmit={handleConfirmedUploadVersion}
          onRetry={handleRetryUploadVersion}
          obj={modalPlanObj}
          title="Upload New Version"
          description="Since you are updating the version of an existing file, you can select and upload only one file."
          isSingle
          uploadInformation={uploadInformation}
          onSuccess={async (cb) => {
            if (isQuickAccessDocument) {
              const res = await onUploadNewVersion(tempNewVersionId);
              if (cb) cb();
              if (res?.isSuccess) {
                reloadDocumentDetails();
                onLoadVersions();
                setModalPlanObj(null);
                setUploadInformation({});
                notify(...getSuccessMessage('Document Uploaded Successfully'));
              }
            } else {
              setModalPlanObj(null);
              setUploadInformation({});
              onNavigate(
                `/locations/${locationId}/emergency/${tempNewVersionId}`
              );
              notify('success', 'Success!', 'Document updated successfully');
            }
          }}
          confirmText={isQuickAccessDocument && 'Add Document'}
          onFileChange={handleNewVersionFileChange}
          uploadAfterChange={isQuickAccessDocument}
          displayUploadSize={
            isQuickAccessDocument && !uploadInformation?.[1]?.loading
          }
          onDeleteFile={handleDeleteUploadedFile}
        />
      )}
      <ToastAlert toastRef={notificationAlertRef} />
    </>
  );
};

export default DocumentDetails;
