import Upload from 'src/components/FormFields/Upload';
import Modal from 'src/components/Modal';
import classes from './UploadModal.module.scss';

const UploadModal = ({ isOpen, onClose, onSubmit }) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title=""
      // hideClose
      centered
      wrapClassName={classes.modalWrapper}
      radius="lg"
      toggle={onClose}
    >
      <Upload
        supportText="Supported formats: .doc, .xlsx, .pdf"
        accept=".doc,.docx,.xlsx,.pdf"
        onChange={(tFiles) => {
          const tFile = tFiles?.[0];
          if (tFile) {
            onSubmit(tFile);
          }
        }}
        isSingle={true}
      />
    </Modal>
  );
};

export default UploadModal;
