import sortBy from 'lodash/sortBy';
import { WEEK_DAYS } from 'src/helper/constants/date';

export const getOperatingHoursGrouped = (data) => {
  if (!data?.length) return [];
  const grouped = data.reduce((acc, entry) => {
    const key =
      entry.opening_time && entry.closing_time
        ? `${entry.opening_time}-${entry.closing_time}`
        : `is_24_hours-${entry.is_24_hours}`;
    if (!acc[key]) {
      acc[key] = {
        opening_time: entry.opening_time,
        closing_time: entry.closing_time,
        is_24_hours: entry.is_24_hours,
        days: [],
      };
    }
    acc[key].days.push(entry.day_of_week);
    return acc;
  }, {});
  const result = Object.values(grouped);
  return result;
};
export const isDaysSequence = (days) => {
  const day_weeks = WEEK_DAYS.map((v) => v.toLowerCase());
  if (!days?.length) return false;
  const indices = days.map((day) => day_weeks.indexOf(day.toLowerCase()));

  // Check if all the indices are valid and sort them
  if (indices.includes(-1)) {
    return false;
  }

  // Sort the indices
  indices.sort((a, b) => a - b);

  // Check if the sequence is continuous (i.e., no breaks in the sequence)
  for (let i = 1; i < indices.length; i++) {
    if (indices[i] !== indices[i - 1] + 1) {
      return false; // Sequence is not continuous
    }
  }

  return true;
};
export function sortDaysRandomArray(randomDays) {
  if (!randomDays?.length) return [];
  const day_weeks = WEEK_DAYS.map((v) => v.toLowerCase());
  return sortBy(randomDays, (day) => day_weeks.indexOf(day.toLowerCase()));
}
export const getIsSameDaysGrouped = (hours, hoursGrouped) => {
  let isAllSameHour = false;
  if (
    hours.length > 0 &&
    hoursGrouped?.[0]?.days?.length === hours.length &&
    isDaysSequence(hoursGrouped[0].days)
  ) {
    isAllSameHour = true;
  }
  if (hoursGrouped.every((obj) => isDaysSequence(obj.days))) {
    isAllSameHour = true;
  }
  return isAllSameHour;
};
