import cn from 'classnames';
import { STATUS_TAGS } from 'src/helper/constants/status';
import classes from './Tag.module.scss';

const StatusTag = ({ className, value, style, size, weight }) => {
  if (!value) return '-';
  const tStatus = STATUS_TAGS[value];
  return (
    <span
      className={cn(
        classes.statusTag,
        classes[`color_${tStatus?.color}`],
        classes[`size_${size}`],
        classes[`weight_${weight}`],
        className
      )}
      style={style}
    >
      <span>{tStatus?.label || value}</span>
    </span>
  );
};

export default StatusTag;
