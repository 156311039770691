import cn from 'classnames';
import { useState } from 'react';
import { ReactComponent as ArrowRight2Svg } from 'src/assets/icons/arrow-right2.svg';
import Button from 'src/components/Button';
import Select from 'src/components/FormFields/Select';
import Pagination from 'src/components/Pagination';
import Tag from 'src/components/Tag';
import { getFormatDate } from 'src/utils/date';
import { getDocumentStatusByTime } from 'src/utils/document';
import classes from './Emergency.module.scss';
import EmergencyEmptyPlan from './EmptyPlan';

const EmergencyPlan = ({
  className,
  title,
  items,
  btnText,
  onViewPlan,
  onAddPlan,
  isNotFound,
  meta,
  onPaginationChange,
  onSortChange,
  sortOptions,
  emptyContent,
}) => {
  const [val, setVal] = useState(sortOptions?.[0]);
  const page = meta?.current_page || 1;
  const size = meta?.per_page || 4;
  return (
    <div className={cn(classes.emergencyPlan, className)}>
      <div className={classes.planHeader}>
        <span>{title}</span>
        <div>
          <Select
            value={val}
            options={sortOptions}
            onChange={(v) => {
              setVal(v);
              if (onSortChange) {
                onSortChange(v);
              }
            }}
            size="sm"
          />
          <Button
            variant="primary"
            onClick={onAddPlan}
            fontSize="sm"
            size="medium"
            weight="md"
            ghost
            shadow="no"
          >
            {btnText}
          </Button>
        </div>
      </div>
      {isNotFound ? (
        <EmergencyEmptyPlan>{emptyContent}</EmergencyEmptyPlan>
      ) : (
        <div className={classes.planContent}>
          <ul>
            {items.map((item) => {
              const status = getDocumentStatusByTime(item.valid_until);
              return (
                <li
                  key={item.id}
                  className={classes.planItem}
                  onClick={() => {
                    if (onViewPlan) onViewPlan(item);
                  }}
                >
                  <span className={classes.planItemInfo}>
                    <span>{item.title}</span>
                    <span>
                      <Tag variant={status.type} weight="lg">
                        {status.text}
                      </Tag>
                    </span>
                  </span>
                  <span className={classes.planItemDate}>
                    {getFormatDate(item.created_at, 'MMM D, YYYY')}
                  </span>
                  <span className={classes.planItemArrow}>
                    <ArrowRight2Svg />
                  </span>
                </li>
              );
            })}
          </ul>
          <Pagination
            light
            data={{
              size,
              page,
              total: meta?.total || 0,
            }}
            onChange={(tPage, tSize) => {
              if (onPaginationChange && (tPage !== page || tSize !== size)) {
                onPaginationChange(tPage, tSize);
              }
            }}
            disabled={false}
            smallSizeOptions
          />
        </div>
      )}
    </div>
  );
};

export default EmergencyPlan;
