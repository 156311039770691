import cn from 'classnames';
import { ReactComponent as Edit2BoldSvg } from 'src/assets/icons/edit-2-bold.svg';
import { ReactComponent as Edit2Svg } from 'src/assets/icons/edit-2.svg';
import { ReactComponent as HeartSvg } from 'src/assets/icons/heart.svg';
import { ReactComponent as TrashBoldSvg } from 'src/assets/icons/trash-bold.svg';
import { ReactComponent as TrashSvg } from 'src/assets/icons/trash.svg';
import Icon from 'src/components/Icon';
import classes from './Emergency.module.scss';

const EmergencyContact = ({ className, onEdit, onDelete, isManual, data }) => {
  return (
    <div className={cn(classes.emergencyContact, className)}>
      <div className={classes.contactHeader}>
        <span>{data?.type || '-'}</span>
        {!!data?.favourite && (
          <Icon icon={<HeartSvg />} activeIcon={<HeartSvg />} size={18} />
        )}
        {isManual && (
          <span className={classes.contactAction}>
            <Icon
              icon={<Edit2Svg />}
              activeIcon={<Edit2BoldSvg />}
              size={18}
              onClick={onEdit}
            />
            <Icon
              icon={<TrashSvg />}
              activeIcon={<TrashBoldSvg />}
              size={18}
              onClick={onDelete}
            />
          </span>
        )}
      </div>
      <div className={classes.contactContent}>
        <div className={classes.item}>
          <span>Contact</span>
          <span>{data?.name || '-'}</span>
        </div>
        {(!!data?.phone || !!data?.website) && (
          <div className={classes.line_2}>
            {!!data?.phone && (
              <div className={classes.item}>
                <span>Phone Number</span>
                <span className={classes.moreText}>{data.phone}</span>
              </div>
            )}
            {!!data?.website && (
              <div className={classes.item}>
                <span>Website</span>
                <a
                  className={cn(classes.moreText, classes.link)}
                  href={data.website}
                  target="_blank"
                  rel="noreferrer"
                >
                  {data.website}
                </a>
              </div>
            )}
          </div>
        )}
        {!!data?.additional_details && (
          <div className={classes.item}>
            <span>Additional Details</span>
            <span>{data.additional_details}</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default EmergencyContact;
