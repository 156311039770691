import { Controller } from 'react-hook-form';
import { SELECT_CREATE_OPTION } from 'src/helper/constants';
import Select from './';

const FormSelect = ({
  name,
  control,
  onChange,
  hasCreateOption,
  isNonObj,
  ...props
}) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        return (
          <Select
            {...props}
            {...field}
            error={error?.message}
            onChange={(val) => {
              const tVal =
                hasCreateOption && props.isMulti
                  ? val
                      .filter((v) => v.value !== SELECT_CREATE_OPTION)
                      .map((obj) => (isNonObj ? obj.value : obj))
                  : isNonObj
                  ? val?.value
                  : val;
              field.onChange(tVal);
              if (onChange) {
                onChange(val);
              }
            }}
          />
        );
      }}
    />
  );
};

export default FormSelect;
