import Button from 'src/components/Button';
// import FormSelect from 'src/components/FormFields/Select/FormSelect';
// import { SELECT_CREATE_OPTION } from 'src/helper/constants';
import AddedLocation from 'src/pages/CreateRegion/components/AddedLocation';
import { getFullAddress } from 'src/utils/address';
import classes from './SelectLocation.module.scss';

const SelectLocation = ({
  control,
  selectedLocations,
  addedLocations,
  setValue,
  onCreateLocation,
  options,
  hideAdd,
  onEdit,
}) => {
  return (
    <div className={classes.wrapper}>
      <div className={classes.title}>Locations</div>
      {!hideAdd && (
        <div className={classes.selectLocations}>
          <Button variant="primary" onClick={onCreateLocation} fontSize="sm">
            Add Location
          </Button>
        </div>
      )}
      <div className={classes.addedLocations}>
        {addedLocations.length === 0 && (
          <span className={classes.noLocationMessage}>
            No location added. Please add a location.
          </span>
        )}
        {addedLocations.map((location) => {
          return (
            <AddedLocation
              key={location.id}
              label={location.name}
              description={getFullAddress(location.address || location, true)}
              onEdit={() => {
                if (onEdit) onEdit(location);
              }}
            />
          );
        })}
      </div>
    </div>
  );
};

export default SelectLocation;
