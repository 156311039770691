import { getTimeWithSuffix } from 'src/utils/date';
import { capitalize } from 'src/utils/string';

const OperatingHoursRecord = ({ dayStr, is24, from, to }) => {
  return (
    <span>
      <span
        style={{
          width: '90px',
          display: 'inline-block',
        }}
      >
        {capitalize(dayStr)}:
      </span>{' '}
      {is24 ? (
        <>
          <span>Open </span>
          <b>24 Hours</b>
        </>
      ) : (
        `${getTimeWithSuffix(from)} - ${getTimeWithSuffix(to)}`
      )}
    </span>
  );
};

export default OperatingHoursRecord;
