export const USER_STATUS_VALUES = {
  ACTIVE: 'active',
  PENDING: 'pending',
  PENDING_ARCHIVE: 'pending_archive',
};

export const USER_STATUS_OPTIONS = [
  {
    value: USER_STATUS_VALUES.ACTIVE,
    label: 'Active',
  },
  {
    value: USER_STATUS_VALUES.PENDING,
    label: 'Pending',
  },
  // {
  //   value: USER_STATUS_VALUES.PENDING_ARCHIVE,
  //   label: 'Pending Archive',
  // },
];
