import cn from 'classnames';
import classes from './Field.module.scss';

const Field = ({ className, label, value, style, bold, defaultValue }) => {
  return (
    <div
      className={cn(
        classes.wrapper,
        {
          [classes.bold]: bold,
        },
        className
      )}
    >
      <div className={classes.label}>{label}</div>
      <div className={classes.value} style={value ? style : {}}>
        {value || (defaultValue || defaultValue === 0 ? defaultValue : '-')}
      </div>
    </div>
  );
};

export default Field;
