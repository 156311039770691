import { apiGetResource, apiGetResources } from 'src/api';
import Types from '../types/resource';

export const syncUpdateQuickAccessSection = (data) => (dispatch) => {
  dispatch({
    type: Types.SYNC_UPDATE_QUICK_ACCESS_SECTION,
    payload: {
      ...data,
    },
  });
};
export const syncUpdateQuickAccessSectionItem = (data) => (dispatch) => {
  dispatch({
    type: Types.SYNC_UPDATE_QUICK_ACCESS_SECTION_ITEM,
    payload: {
      ...data,
    },
  });
};
export const getResources = (key, params, cb) => {
  return {
    isAsyncCall: true,
    payload: { key },
    type: Types.GET_RESOURCES,
    asyncCall: () => {
      return apiGetResources(key, params);
    },
    afterSuccessCall: (_dispatch, response) => {
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};
export const getResourceDetails = (key, id, cb) => {
  return {
    isAsyncCall: true,
    payload: { key },
    type: Types.GET_RESOURCE_DETAILS,
    asyncCall: () => {
      return apiGetResource(key, id, {});
    },
    afterSuccessCall: (_dispatch, response) => {
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};
