import Avatar from 'src/components/Avatar';
import Tag from 'src/components/Tag';
import { DOCUMENT_STATUS_VALUES } from 'src/helper/constants/document';
import { getFormatDate } from 'src/utils/date';
import { getDocumentStatusByTime } from 'src/utils/document';
import { capitalize } from 'src/utils/string';

export const getInfoItems = (details, isQuickAccess) => {
  const statusObj = getDocumentStatusByTime(details?.valid_until);
  const isArchived = details?.status === DOCUMENT_STATUS_VALUES.ARCHIVED;
  if (isQuickAccess) {
    return [
      {
        label: 'Uploaded By',
        value: <Avatar name={details?.created_by_name} fontSize="sm" />,
      },
      {
        label: 'Uploaded Date',
        value: getFormatDate(details?.created_at, 'MMM D, YYYY'),
      },
      {
        label: 'Expiration Date',
        value: getFormatDate(details?.expires_at, 'MMM D, YYYY'),
      },
      {
        label: 'Status',
        value: (!!statusObj || !!isQuickAccess) && (
          <span>
            <Tag
              variant={
                isQuickAccess && isArchived ? details?.status : statusObj.type
              }
              size="sm1"
              outline={isQuickAccess && isArchived}
            >
              {isQuickAccess && isArchived
                ? capitalize(details?.status)
                : statusObj.text}
            </Tag>
          </span>
        ),
      },
    ];
  }

  return [
    {
      label: 'Uploaded by',
      value: details?.uploaded_by_name,
    },
    {
      label: 'Uploaded date',
      value: getFormatDate(details?.created_at, 'MMM D, YYYY'),
    },
    {
      label: 'Status',
      value: !!statusObj && (
        <span>
          <Tag variant={statusObj.type}>{statusObj.text}</Tag>
        </span>
      ),
    },
  ];
};
