import { useEffect, useRef, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import Breadcrumb from 'src/components/Breadcrumb';
import Loading from 'src/components/Loading';
import PageWrapper from 'src/components/Page/PageWrapper';
import ToastAlert, { useNotify } from 'src/components/ToastAlert';
import {
  ARCHIVE_SUCCESS_PARAM_KEY,
  CALLBACK_ACTION_PARAM_KEY,
} from 'src/helper/constants/routes';
import { RESOURCE_DATA_KEY } from 'src/helper/constants/store';
import useOwnNavigate from 'src/helper/hooks/useOwnNavigate';
import { getAllUrlParams, getRemovedParamFromUrl } from 'src/utils/routes';
import { getSuccessMessage } from 'src/utils/toast';
import classes from './QuickAccessDetails.module.scss';
import Content from './components/Content';
import { useActions, useIndexData } from './selectorData';
import useFunction from './useFunction';

let uploadedTimeout = null;

const QuickAccessDetails = () => {
  const notificationAlertRef = useRef(null);
  const params = useParams();
  const { onNavigate, fullPathname } = useOwnNavigate();
  const location = useLocation();
  const urlParams = getAllUrlParams(location?.search);
  const locationId = Number(params.locationId || 0);
  const quickAccessId = Number(params.quickAccessId || 0);
  const [locationDetailsLoading, setLocationDetailsLoading] = useState(false);
  const [submitEditorLoading, setSubmitEditorLoading] = useState(false);
  const [isEditEditor, setIsEditEditor] = useState(false);
  const [quickAccessDetailsLoading, setQuickAccessDetailsLoading] =
    useState(false);
  const [documentsLoading, setDocumentsLoading] = useState(false);
  const [archivedDocumentsLoading, setArchivedDocumentsLoading] =
    useState(false);
  const [editorContent, setEditorContent] = useState('');
  const [uploadedResult, setUploadedResult] = useState(null);
  const {
    locationDetails,
    quickAccessDetails,
    quickAccessDocuments,
    quickAccessArchivedDocuments,
    quickAccessDocumentCount,
  } = useIndexData();
  const documentsData = quickAccessDocuments?.data || [];
  const archivedDocumentsData = quickAccessArchivedDocuments?.data || [];
  const firstDocument = documentsData?.[0];
  const quickAccessName = quickAccessDetails.name;
  const type = quickAccessDetails.type;
  const { getLocationDetails, getResourceDetails, getResources } = useActions();
  const notify = useNotify(notificationAlertRef);
  const locationDetailsUrl = `/locations/${locationId}`;
  const breadcrumbs = [
    {
      link: `/locations`,
      label: 'Locations',
    },
    {
      link: locationDetailsUrl,
      label: locationDetails?.name,
    },
    {
      label: quickAccessName,
    },
  ];
  const resetState = () => {};
  const handleBack = () => {
    onNavigate(locationDetailsUrl);
  };
  const reloadLocationDetails = (cb) => {
    setLocationDetailsLoading(true);
    getLocationDetails(locationId, (res) => {
      setLocationDetailsLoading(false);
      if (cb) cb(res);
    });
  };
  const reloadQuickAccessDetails = (tId) => {
    setQuickAccessDetailsLoading(true);
    getResourceDetails(RESOURCE_DATA_KEY.QUICK_ACCESS_ITEM_DETAILS, tId, () => {
      setQuickAccessDetailsLoading(false);
    });
  };
  const reloadQuickAccessDocumentCounts = (tId) => {
    getResources(
      RESOURCE_DATA_KEY.QUICK_ACCESS_ITEM_DOCUMENT_COUNT,
      {
        entity_type: 'location',
        entity_id: locationId,
        item_id: quickAccessId,
      },
      () => {}
    );
  };
  const reloadQuickAccessDocuments = (tPage, tId, settings) => {
    const isSilent = settings?.silent;
    if (!isSilent) {
      setDocumentsLoading(true);
    }
    getResources(
      RESOURCE_DATA_KEY.QUICK_ACCESS_ITEM_DOCUMENTS,
      {
        entity_type: 'location',
        item_id: tId,
        entity_id: locationId,
        page: tPage,
        per_page: 99,
      },
      (res) => {
        setDocumentsLoading(false);
        if (settings?.cb) {
          settings.cb(res);
        }
        setEditorContent(res?.data?.[0]?.content || '');
      }
    );
  };
  const reloadQuickAccessArchivedDocuments = (tPage, tId) => {
    setArchivedDocumentsLoading(true);
    getResources(
      RESOURCE_DATA_KEY.QUICK_ACCESS_ITEM_ARCHIVED_DOCUMENTS,
      {
        entity_type: 'location',
        item_id: tId,
        entity_id: locationId,
        page: tPage,
        per_page: 99,
      },
      (res) => {
        setArchivedDocumentsLoading(false);
      }
    );
  };
  const { onSubmitEditor, onSubmitDocument, onArchiveDocument, onRemoveItem } =
    useFunction({
      locationId,
      quickAccessId,
      reloadDocuments: reloadQuickAccessDocuments,
      reloadArchivedDocuments: reloadQuickAccessArchivedDocuments,
      notify,
    });
  const handleSubmitEditor = async (existingDocument) => {
    setSubmitEditorLoading(true);
    onSubmitEditor(existingDocument?.id, editorContent, () => {
      setSubmitEditorLoading(false);
      setIsEditEditor(false);
    });
  };
  const handleUploadDocument = async (tFile) => {
    if (uploadedTimeout) {
      clearTimeout(uploadedTimeout);
      uploadedTimeout = null;
    }
    setUploadedResult({
      name: tFile.name,
      size: tFile.size,
      loading: true,
    });
    const res = await onSubmitDocument(tFile);
    if (res?.isSuccess) {
      setUploadedResult({
        name: tFile.name,
        size: tFile.size,
      });
      uploadedTimeout = setTimeout(() => {
        setUploadedResult(null);
      }, 5000);
    }
  };
  const handleDeleteUploadedDocument = () => {
    setUploadedResult(null);
  };
  const handleViewDocument = (obj) => {
    onNavigate(
      `/locations/${locationId}/quick-access/${quickAccessId}/documents/${obj.id}`
    );
  };
  const handleDeleteDocument = async (obj, loadingCb, cb) => {
    const res = await onArchiveDocument(obj.id);
    if (loadingCb) loadingCb();
    if (res?.isSuccess && cb) cb();
  };
  const handleDeleteCategory = async (loadingCb, cb) => {
    await onRemoveItem();
    if (loadingCb) loadingCb();
  };

  useEffect(() => {
    reloadLocationDetails();
    if (urlParams?.[CALLBACK_ACTION_PARAM_KEY] === ARCHIVE_SUCCESS_PARAM_KEY) {
      notify(...getSuccessMessage('Document Archived Successfully'));
      onNavigate(
        getRemovedParamFromUrl(fullPathname, [CALLBACK_ACTION_PARAM_KEY])
      );
    }
    return () => {
      if (uploadedTimeout) {
        clearTimeout(uploadedTimeout);
        uploadedTimeout = null;
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (quickAccessId) {
      resetState();
      reloadQuickAccessDetails(quickAccessId);
      reloadQuickAccessDocuments(1, quickAccessId);
      reloadQuickAccessArchivedDocuments(1, quickAccessId);
      reloadQuickAccessDocumentCounts(quickAccessId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quickAccessId]);

  return (
    <>
      <PageWrapper className={classes.wrapper}>
        {(locationDetailsLoading || quickAccessDetailsLoading) && (
          <Loading isPage />
        )}
        <Breadcrumb items={breadcrumbs} />
        <Content
          details={{
            ...quickAccessDetails,
            name: quickAccessName,
          }}
          organization="Location"
          type={type}
          onBack={handleBack}
          editorContent={editorContent}
          onEditorChange={(v) => {
            setEditorContent(v);
          }}
          onSubmitEditor={handleSubmitEditor}
          submitEditorLoading={submitEditorLoading}
          archivedDocuments={archivedDocumentsData}
          documents={documentsData}
          firstDocument={firstDocument}
          isEditEditor={isEditEditor}
          setIsEditEditor={setIsEditEditor}
          onUploadDocument={handleUploadDocument}
          uploadedResult={uploadedResult}
          onDeleteUploadedDocument={handleDeleteUploadedDocument}
          onViewDocument={handleViewDocument}
          onDeleteDocument={handleDeleteDocument}
          onDeleteCategory={handleDeleteCategory}
          onSwitchTab={(val) => {
            if (val === 'archived') {
              reloadQuickAccessArchivedDocuments(1, quickAccessId);
            } else {
              reloadQuickAccessDocuments(1, quickAccessId);
            }
          }}
          documentCount={quickAccessDocumentCount}
        />
      </PageWrapper>
      <ToastAlert toastRef={notificationAlertRef} />
    </>
  );
};

export default QuickAccessDetails;
