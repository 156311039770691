import { useEffect, useRef, useState } from 'react';
import Breadcrumb from 'src/components/Breadcrumb';
import ConfirmModal from 'src/components/Modal/Confirm';
import PageTitle from 'src/components/Page/PageTitle';
import PageWrapper from 'src/components/Page/PageWrapper';
import ToastAlert from 'src/components/ToastAlert';
import { DEFAULT_SORT_METHOD } from 'src/helper/constants/sort';
import { RESOURCE_DATA_KEY } from 'src/helper/constants/store';
import useOwnNavigate from 'src/helper/hooks/useOwnNavigate';
import useResourceActions from 'src/helper/hooks/useResourceActions';
import GenericContent from 'src/pages/Generic/components/Content';
import GenericTable from 'src/pages/Generic/components/Table';
import { notifyPrimary } from 'src/utils/notification';
import { getViewUrlAction } from 'src/utils/routes';
import classes from './Companies.module.scss';
import { getColumns } from './constants';
import { useActions, useIndexData } from './selectorData';

const DEFAULT_SORT_KEY = 'name';
let cacheSearch = '';
let cachePage = 1;
let cacheSize = 10;
let cacheSortField = DEFAULT_SORT_KEY;
let cacheSortMethod = DEFAULT_SORT_METHOD;

const AdminCompanies = () => {
  const { onDeleteResource } = useResourceActions();
  const { getResources } = useActions();
  const notificationAlertRef = useRef(null);
  const { onNavigate } = useOwnNavigate();
  const [loading, setLoading] = useState(false);
  const [deleteId, setDeleteId] = useState('');
  const { companiesData } = useIndexData();
  const companies = companiesData?.data || [];
  const breadcrumbs = [
    {
      label: 'Companies',
    },
  ];
  const handleView = (record) => {
    onNavigate(getViewUrlAction(`/admin/companies/create?id=${record?.id}`));
  };
  const handleEdit = (record) => {
    onNavigate(`/admin/companies/create?id=${record?.id}`);
  };
  const handleDelete = (record) => {
    setDeleteId(record?.id);
  };
  const notify = (type, title, description) => {
    notifyPrimary({ ref: notificationAlertRef, type, title, description });
  };
  const resetState = () => {
    setLoading(false);
    setDeleteId('');
    cacheSearch = '';
    cachePage = 1;
    cacheSize = 10;
    cacheSortField = DEFAULT_SORT_KEY;
    cacheSortMethod = DEFAULT_SORT_METHOD;
  };

  const columns = getColumns({
    onView: handleView,
    onEdit: handleEdit,
    onDelete: handleDelete,
  });
  const reloadData = (tSearch, tPage, tSize, tSortField, tSortMethod) => {
    setLoading(true);
    getResources(
      RESOURCE_DATA_KEY.COMPANIES,
      {
        page: tPage,
        per_page: tSize,
        search: tSearch,
        sort_by: tSortField,
        sort_order: tSortMethod,
      },
      () => {
        setLoading(false);
      }
    );
  };

  useEffect(() => {
    resetState();
    reloadData(
      cacheSearch,
      cachePage,
      cacheSize,
      cacheSortField,
      cacheSortMethod
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <PageWrapper className={classes.wrapper}>
        <Breadcrumb items={breadcrumbs} />
        <PageTitle
          subTitle={
            <span>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer took a galley of
              type and scrambled it to make a type specimen book.
            </span>
          }
        >
          Companies
        </PageTitle>
        <GenericContent>
          <GenericTable
            onAdd={() => {
              onNavigate('/admin/companies/create');
            }}
            columns={columns}
            title="List of companies"
            data={companies}
            total={companiesData.meta?.total || 0}
            onPaginationChange={(tPage, tSize) => {
              cachePage = tPage;
              cacheSize = tSize;
              reloadData(
                cacheSearch,
                cachePage,
                cacheSize,
                cacheSortField,
                cacheSortMethod
              );
            }}
            onSearchChange={(val) => {
              cacheSearch = val;
              cachePage = 1;
              reloadData(
                cacheSearch,
                cachePage,
                cacheSize,
                cacheSortField,
                cacheSortMethod
              );
            }}
            onSortChange={(obj) => {
              cacheSortField = obj.field;
              cacheSortMethod = obj.method;
              cachePage = 1;
              reloadData(
                cacheSearch,
                cachePage,
                cacheSize,
                cacheSortField,
                cacheSortMethod
              );
            }}
            loading={loading}
          />
        </GenericContent>
      </PageWrapper>
      <ToastAlert toastRef={notificationAlertRef} />
      {!!deleteId && (
        <ConfirmModal
          isOpen
          onClose={() => {
            setDeleteId('');
          }}
          onSubmit={async (cb) => {
            const res = await onDeleteResource(
              RESOURCE_DATA_KEY.COMPANIES,
              deleteId
            );
            if (cb) cb();
            if (res.isSuccess) {
              setDeleteId('');
              reloadData(
                cacheSearch,
                cachePage,
                cacheSize,
                cacheSortField,
                cacheSortMethod
              );
            } else {
              notify('error', 'Error!', 'Delete Company failed!');
            }
          }}
          title="Delete Company"
          submitText="Delete"
        >
          Do you want to delete this company?
        </ConfirmModal>
      )}
    </>
  );
};

export default AdminCompanies;
