import { yupResolver } from '@hookform/resolvers/yup';
import uniq from 'lodash/uniq';
import { useContext, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';
import Breadcrumb from 'src/components/Breadcrumb';
import Button from 'src/components/Button';
import FormGroup from 'src/components/FormFields/FormGroup';
import FormInput from 'src/components/FormFields/Input/FormInput';
import FormSelect from 'src/components/FormFields/Select/FormSelect';
import Loading from 'src/components/Loading';
import PDFViewer from 'src/components/PDFViewer';
import ToastAlert from 'src/components/ToastAlert';
import { INPUT_HIGHLIGHT_ERROR_MESSAGE } from 'src/helper/constants';
// import { DOCUMENT_ICON_OPTIONS } from 'src/helper/constants/menu';
import usePartOptions from 'src/helper/hooks/usePartOptions';
import useSubSystemOptions from 'src/helper/hooks/useSubSystemOptions';
import useCurrentPath from 'src/helper/hooks/userCurrentPath';
import { MenuContext } from 'src/helper/providers/MenuProvider';
import Field from 'src/pages/GenericCreate/components/Field';
import GenericCreateFooter from 'src/pages/GenericCreate/components/Footer';
import GenericCreateTitle from 'src/pages/GenericCreate/components/Title';
import GenericCreateWrapper from 'src/pages/GenericCreate/components/Wrapper';
import { useReducerData } from 'src/store/hooks';
import { getMenusByItemType } from 'src/utils/menu';
import { notifyPrimary } from 'src/utils/notification';
import { getAllUrlParams } from 'src/utils/routes';
import classes from './DocumentCreate.module.scss';
import { useActions } from './selectorData';

const formSchema = yup.object().shape({
  description: yup.string().nullable(),
});

const DocumentCreate = () => {
  const location = useLocation();
  const urlParams = getAllUrlParams(location?.search);
  const currentPath = useCurrentPath();
  const navigate = useNavigate();
  const genericId = Number(urlParams.id || 0);
  const notificationAlertRef = useRef(null);
  const [createRegionLoading, setCreateRegionLoading] = useState(false);
  const [fileTitle, setFileTitle] = useState('');
  const [versions, setVersions] = useState([]);
  const [documentData, setDocumentData] = useState(
    location.state?.documentData || {}
  );
  const { postDocument, getDocument } = useActions();
  // const { documentData } = location.state || {}; // Safely access state
  const { availableMenus, selectedLocationIds } = useContext(MenuContext);
  const menusData = useReducerData('auth', 'login.data.menus_data', []);
  const { subSystemOptions } = useSubSystemOptions();
  const { partOptions } = usePartOptions();

  const isEdit = !!location.state;
  const isView = document.location.href.indexOf('/repository/view') > -1;

  const folders = getMenusByItemType(menusData, 'repository folder');
  const workflows = [
    { id: 0, item_name: 'none', url: '' },
    ...getMenusByItemType(menusData, 'workflow'),
  ];
  const getTitle = () => {
    if (isView) return 'View Details';
    return isEdit ? 'Edit Document' : 'Create Document';
  };
  const getSubTitle = () => {
    if (isView) return 'View Details sub title';
    return isEdit ? 'Edit View sub title' : 'Create sub title';
  };
  const getBreadcrumbText = () => {
    if (isView) return 'View Document Details';
    return isEdit ? 'Edit Document' : 'Create Document';
  };
  const breadcrumbs = [
    {
      link: '/repository',
      label: 'Documents',
    },
    {
      label: getBreadcrumbText(),
    },
  ];
  const { handleSubmit, control, watch, setValue, formState, reset } = useForm({
    resolver: yupResolver(formSchema),
    defaultValues: { folder: location.state?.folder, ...documentData?.form },
  });
  const wSelectedSubSystems = watch('subsystem');

  const notify = (type, title, description) => {
    notifyPrimary({ ref: notificationAlertRef, type, title, description });
  };

  const onSubmit = async (values) => {
    const payload = {
      publicUrl: values.publicUrl,
      folder: values.folder?.value,
      locationIds: (values.locationIds || []).map((l) => l.value),
      parts: (values.parts || []).map((p) => p.value),
      subsystem: (values.subsystem || []).map((s) => s.value),
      workflow: values.workflow?.value,
      fileTitle,
      form: values,
    };
    const postData = async () => {
      const res = await postDocument(payload);
      if (res.isSuccess && res.result) {
        setFileTitle(res.result.fileName);
      } else {
        setFileTitle('Error parsing file');
      }
    };

    // Call the async function
    await postData();
    navigate(
      '/repository' +
        (location.state?.folder ? '/' + location.state?.folder : '')
    );
  };

  const analizeFile = async (e) => {
    const payload = {
      publicUrl: e.target.value,
      analize: true,
    };
    const postData = async () => {
      const res = await postDocument(payload);
      if (res) {
        setFileTitle(res.result);
        setValue('title', res.result);
      }
    };

    // Call the async function
    await postData();
    // navigate('/repository');
  };

  useEffect(() => {
    const fetchData = async () => {
      const res = await getDocument({
        selectedLocationIds,
        _id: documentData._id,
      });
      setVersions(res.versions);
      setDocumentData(res.live);
      // setGroups(res.result.groups);
      // setParts(res.result.parts);
      // setSubsystems(res.result.subsystems);
    };
    // Call the async function
    if (documentData?._id) fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLocationIds, JSON.stringify(documentData)]);
  const scopeFilteredMenus = [];
  const getLocationMenus = (location) => {
    const menus = [];
    (location?.roles || []).forEach((role) => {
      if (role.menu_items) {
        role.menu_items.forEach((menu) => {
          const foundIndex = menus.findIndex((obj) => obj.id === menu.id);
          if (foundIndex >= 0) {
            menus[foundIndex].actions = uniq([
              ...menus[foundIndex].actions,
              ...menu.actions,
            ]);
          } else {
            menus.push(menu);
          }
        });
      }
    });
    return menus;
  };

  menusData.forEach((company) => {
    company.regions.forEach((region) => {
      region.locations.forEach((location) => {
        // TODO: check for pemissions
        // const menus = getLocationMenus(location);
        scopeFilteredMenus.push({
          value: location.id,
          label: `${company.name} ➡ ${region.name} ➡ ${location.name}`,
        });
      });
    });
  });
  return (
    <>
      {/* {JSON.stringify(scopeFilteredMenus)} */}
      <GenericCreateWrapper>
        {false && <Loading isPage />}
        <Breadcrumb items={breadcrumbs} />
        <GenericCreateTitle subTitle={getSubTitle()}>
          <span>{getTitle()}</span>
          {/* <Button variant="primary" type="button" onClick={() => { }}>
            Button Goes Here
          </Button> */}
        </GenericCreateTitle>
        {fileTitle && <b>{fileTitle}</b>}
        <form
          role="form"
          className={classes.form}
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className={classes.moreFieldsWrapper}>
            {isView ? (
              <>
                {versions?.length > 0 && (
                  <div style={{ maxHeight: '30vh', overflow: 'auto' }}>
                    Versions:
                    <br />
                    {/* {JSON.stringify(versions)} */}
                    {versions.map((v, i) => (
                      <div
                        key={i}
                        style={{
                          backgroundColor:
                            v._id == documentData._id ? '#ddd' : 'transparent',
                          cursor: 'pointer',
                        }}
                        onClick={() => setDocumentData(v)}
                      >
                        status: {v.status} <br /> created: {v.createdAt} <br />{' '}
                        live at:{' '}
                        {v.form.locationIds.map((l) => l.label).join(', ')}{' '}
                        <br /> was live at:{' '}
                        {v.originalLocations
                          .map(
                            (l) =>
                              scopeFilteredMenus.find((f) => f.value == l)
                                ?.label
                          )
                          .join(', ')}
                        <hr />
                      </div>
                    ))}
                  </div>
                )}
                <Field label="Status" value={documentData?.status} />
                <Field label="Created At" value={documentData?.createdAt} />
                {/* <Field label="Document Title" value="Title" /> */}
                {/* <Field label="Document Icon" value="Icon" /> */}
              </>
            ) : (
              <>
                <FormGroup>
                  <FormInput
                    label="Import Document from Google Drive"
                    name="publicUrl"
                    placeholder="Enter public URL"
                    control={control}
                    radius="md"
                    variant="primary"
                    fontSize="sm"
                    onBlur={analizeFile}
                  />
                </FormGroup>{' '}
                {/* Or
                <FormGroup>
                  <FormInput
                    label="Upload Document"
                    name="title"
                    placeholder="Enter title"
                    control={control}
                    radius="md"
                    variant="primary"
                    fontSize="sm"
                    type="file"
                  />
                </FormGroup>*/}
                <FormGroup>
                  <FormInput
                    label="Document Title"
                    name="title"
                    placeholder="Enter title if not automatically parsed from the uploaded file"
                    control={control}
                    radius="md"
                    variant="primary"
                    fontSize="sm"
                  />
                </FormGroup>
                <h2>Select parent folder for this document</h2>
                <FormGroup>
                  <FormSelect
                    options={folders.map((g) => {
                      return {
                        value: g.item_name,
                        label: g.item_name,
                      };
                    })}
                    label="Select folder"
                    name="folder"
                    placeholder="Select Folder"
                    control={control}
                    radius="md"
                    variant="primary"
                    fontSize="sm"
                    autoFocus
                  />
                </FormGroup>
                <FormGroup>
                  <FormSelect
                    options={workflows.map((w) => {
                      return {
                        value: w.url.replace('/executions/', ''),
                        label: w.item_name,
                      };
                    })}
                    label="Select workflow"
                    name="workflow"
                    placeholder="Select Workflow"
                    control={control}
                    radius="md"
                    variant="primary"
                    fontSize="sm"
                    autoFocus
                  />
                </FormGroup>
                <h2>Select the locations for this document</h2>
                <FormGroup>
                  <FormSelect
                    options={scopeFilteredMenus}
                    label="Select scope"
                    name="locationIds"
                    placeholder="Select Locations"
                    control={control}
                    radius="md"
                    variant="primary"
                    fontSize="sm"
                    autoFocus
                    isMulti
                    multiLabel="locations selected"
                  />
                </FormGroup>
                {/* <FormGroup>
                  <FormInput
                    label="Description"
                    name="description"
                    placeholder="Enter description"
                    control={control}
                    type="textarea"
                    radius="md"
                    variant="primary"
                    fontSize="sm"
                  />
                </FormGroup> */}
                <h2>Select the elements related to this document</h2>
                <FormGroup>
                  <FormGroup>
                    <FormSelect
                      options={subSystemOptions}
                      label="Sub System"
                      name="subsystem"
                      placeholder="Select Sub Systems"
                      control={control}
                      radius="md"
                      variant="primary"
                      fontSize="sm"
                      autoFocus
                      isMulti
                      multiLabel="sub systems selected"
                    />
                  </FormGroup>
                  <FormSelect
                    options={partOptions.filter(
                      (p) =>
                        !wSelectedSubSystems?.length ||
                        wSelectedSubSystems
                          .map((s) => s.value)
                          .indexOf(p.subSystemId) !== -1
                    )}
                    label="Parts"
                    name="parts"
                    placeholder="Select Parts"
                    control={control}
                    radius="md"
                    variant="primary"
                    fontSize="sm"
                    autoFocus
                    isMulti
                    multiLabel="parts selected"
                  />
                </FormGroup>
              </>
            )}
          </div>
          {!isView && (
            <GenericCreateFooter>
              <Button
                variant="primary"
                isLink
                type="button"
                onClick={() => {
                  navigate(
                    '/repository' +
                      (location.state?.folder
                        ? '/' + location.state?.folder
                        : '')
                  );
                }}
              >
                Cancel
              </Button>
              <Button
                variant="primary"
                type="submit"
                // disabled={!formState.isValid}
                loading={createRegionLoading}
              >
                {isEdit ? 'Save Changes' : 'Create'}
              </Button>
            </GenericCreateFooter>
          )}
          {documentData && isView && (
            <PDFViewer documentId={documentData._id} slug={documentData.slug} />
          )}
        </form>
      </GenericCreateWrapper>
      <ToastAlert toastRef={notificationAlertRef} />
    </>
  );
};

export default DocumentCreate;
