import Button from 'src/components/Button';
import useOwnNavigate from 'src/helper/hooks/useOwnNavigate';
import classes from './NotFound.module.scss';

const NotFound = () => {
  const { onNavigate } = useOwnNavigate();
  return (
    <div className={classes.wrapper}>
      <div>
        <span>404 Not Found</span>
        <Button
          variant="primary"
          onClick={() => {
            onNavigate('/dashboard');
          }}
        >
          Return to Dashboard
        </Button>
      </div>
    </div>
  );
};

export default NotFound;
