import cn from 'classnames';
import { useEffect, useState } from 'react';
import Button from 'src/components/Button';
import Modal from 'src/components/Modal';
import ConfirmModal from 'src/components/Modal/Confirm';
import GenericTable from 'src/pages/Generic/components/Table';
import classes from './HistoryModal.module.scss';
import { getColumns } from './utils';

const HistoryModal = ({
  isOpen,
  onClose,
  onConfirmedDelete,
  onConfirmedView,
  versions,
  onSortChange,
  isQuickAccess,
}) => {
  const [deleteObj, setDeleteObj] = useState(null);
  const columns = getColumns({
    onView: (obj) => {
      onConfirmedView(obj);
    },
    onDelete: (obj) => {
      setDeleteObj(obj);
    },
    isQuickAccess,
  });
  useEffect(() => {}, []);

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        title={'Manage Document Versions'}
        hideClose
        centered
        wrapClassName={classes.modalWrapper}
        radius="lg"
        size="xl"
      >
        <div className={classes.content}>
          <div className={classes.description}>
            <span>
              Older versions of this file will have the option to be archived
              after 5 years.
              <br />
              All versions will be visible here unless manually archived.
            </span>
          </div>
          <GenericTable
            unbox
            hideHeader
            columns={columns}
            title="List of roles"
            data={versions}
            total={100}
            hoverable
            hideCheckboxes
            onRowClick={
              isQuickAccess
                ? undefined
                : (obj) => {
                    onConfirmedView(obj);
                  }
            }
            hidePagination
            onSortChange={onSortChange}
            loading={false}
            className={cn({
              [classes.quickAccessTable]: !!isQuickAccess,
            })}
          />
        </div>
        <div className={classes.formFooter}>
          <Button variant="primary" onClick={onClose} type="button">
            Close
          </Button>
        </div>
      </Modal>
      {!!deleteObj && (
        <ConfirmModal
          isOpen
          onClose={() => {
            setDeleteObj(null);
          }}
          onSubmit={async (cb) => {
            onConfirmedDelete(deleteObj, cb, () => {
              setDeleteObj(null);
            });
          }}
          title="Archive file"
          submitText="Archive File"
          size="sm"
        >
          Are you sure you want to archive this document [document name goes
          here]? It will no longer be publicly available for reference or
          audits.
          <br />
          <br />
          Please confirm this action below.
        </ConfirmModal>
      )}
    </>
  );
};

export default HistoryModal;
