import cn from 'classnames';
import range from 'lodash/range';
import {
  PaginationItem,
  PaginationLink,
  Pagination as RSPagination,
} from 'reactstrap';
import Select from 'src/components/FormFields/Select';
import {
  TABLE_PAGINATION_SIZE,
  TABLE_PAGINATION_SIZE_SMALL,
} from 'src/helper/constants/pagination';
import classes from './Pagination.module.scss';
import { calculatePagination } from './utils';

const Pagination = ({
  className,
  data,
  onChange,
  disabled,
  light,
  smallSizeOptions,
}) => {
  const tTotal = data?.total || 1;
  const tSize = data?.size || 10;
  const tPage = data?.page || 1;
  const has1PageMore = tTotal / tSize > parseInt(tTotal / tSize);
  const pages = parseInt(tTotal / tSize) + (has1PageMore ? 1 : 0);
  const visiblePages = calculatePagination(tPage, pages, 5);
  const sizeOptions = smallSizeOptions
    ? TABLE_PAGINATION_SIZE_SMALL
    : TABLE_PAGINATION_SIZE;
  const handleChange = (tPage, tSize) => {
    if (onChange && !disabled) onChange(tPage, tSize);
  };
  return (
    <div
      className={cn(
        classes.wrapper,
        {
          [classes.isLight]: light,
        },
        className
      )}
    >
      <div className={classes.sizeChanger}>
        <Select
          className={classes.selectWrapper}
          options={sizeOptions}
          isDisabled={disabled}
          placeholder="View 10 items"
          value={sizeOptions.find((obj) => obj.value === data?.size) || null}
          isPagination
          onChange={(val) => {
            handleChange(1, val?.value);
          }}
        />
      </div>
      <RSPagination
        className="pagination justify-content-end mb-0"
        listClassName="justify-content-end mb-0"
      >
        <PaginationItem
          className={cn({
            disabled: tPage === 1,
          })}
        >
          <PaginationLink
            href="#pablo"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              handleChange(tPage - 1, tSize);
            }}
            tabIndex="-1"
          >
            <i className="fas fa-angle-left" />
            <span className="sr-only">Previous</span>
          </PaginationLink>
        </PaginationItem>
        {visiblePages[0] !== 1 && (
          <>
            <PaginationItem className={cn({})}>
              <PaginationLink
                href="#"
                onClick={(e) => {
                  handleChange(1, tSize);
                }}
              >
                1
              </PaginationLink>
            </PaginationItem>
            {visiblePages[0] !== 2 && (
              <PaginationItem className={cn({})}>
                <PaginationLink
                  href="#"
                  className={classes.semiNext}
                  onClick={(e) => {
                    handleChange(tPage - 2, tSize);
                  }}
                >
                  ...
                </PaginationLink>
              </PaginationItem>
            )}
          </>
        )}
        {visiblePages.map((p) => {
          return (
            <PaginationItem
              key={p}
              className={cn({
                active: data?.page === p,
              })}
            >
              <PaginationLink
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  handleChange(p, tSize);
                }}
              >
                {p}
              </PaginationLink>
            </PaginationItem>
          );
        })}
        {visiblePages[visiblePages.length - 1] !== pages && (
          <>
            {visiblePages[visiblePages.length - 2] !== pages - 1 && (
              <PaginationItem className={cn({})}>
                <PaginationLink
                  href="#"
                  onClick={(e) => {
                    handleChange(tPage + 2, tSize);
                  }}
                  className={classes.semiNext}
                >
                  ...
                </PaginationLink>
              </PaginationItem>
            )}
            <PaginationItem className={cn({})}>
              <PaginationLink
                href="#"
                onClick={(e) => {
                  handleChange(pages, tSize);
                }}
              >
                {pages}
              </PaginationLink>
            </PaginationItem>
          </>
        )}
        <PaginationItem
          className={cn({
            disabled: tPage === pages,
          })}
        >
          <PaginationLink
            href="#"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              handleChange(tPage + 1, tSize);
            }}
          >
            <i className="fas fa-angle-right" />
            <span className="sr-only">Next</span>
          </PaginationLink>
        </PaginationItem>
      </RSPagination>
    </div>
  );
};

export default Pagination;
