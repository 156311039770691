/* eslint-disable max-lines */

// source: https://ehs.ucr.edu/media/2031/download
export const hazardMap = {
  T: 'Toxic',
  F: 'Flammable',
  R: 'Reactive',
  C: 'Corrosive',
};

export const chemicals = [
  { haz: 'T', name: '(2,4,5-Trichlorophenoxy) Acetic Acid', cas: '93-76-5' },
  { haz: 'T', name: '(2,4-Dichlorophenoxy) Acetic Acid', cas: '94-75-7' },
  { haz: 'T', name: "[1,1'-Biphenyl]-4,4'-diamine", cas: '92-87-5' },
  {
    haz: 'T',
    name: "[1,1'-Biphenyl]-4,4'-diamine, 3,3'-dichloro-",
    cas: '91-94-1',
  },
  {
    haz: 'T',
    name: "[1,1'-Biphenyl]-4,4'-diamine, 3,3'-dimethoxy-",
    cas: '119-90-4',
  },
  {
    haz: 'T',
    name: "[1,1'-Biphenyl]-4,4'-diamine, 3,3'-dimethyl-",
    cas: '119-93-7',
  },
  {
    haz: 'T',
    name: '1-(2-Chloroethyl)-3-(4-methylcyclohexyl)-1-nitrosourea',
    cas: '13909-09-6',
  },
  {
    haz: 'T',
    name: '1-(2-Chloroethyl)-3-cyclohexyl-1-nitrosourea',
    cas: '13010-47-4',
  },
  { haz: 'T', name: '1-(o-Chlorophenyl)thiourea', cas: '5344-82-1' },
  { haz: 'T', name: '1,1,1,2-Tetrachloroethane', cas: '630-20-6' },
  { haz: 'T', name: '1,1,1-Trichloro-2, -bis(p-methoxyphenyl)ethane', cas: '' },
  {
    haz: 'T',
    name: '1,1,1-Trichloro-2,2-bis(p-chlorophenyl)ethane',
    cas: '50-29-3',
  },
  { haz: 'T,F', name: '1,1,2,2-Tetrachloroethane', cas: '79-34-5' },
  { haz: 'T', name: '1,1,2-Trichloroethane', cas: '79-00-5' },
  {
    haz: '',
    name: '1,1a,2,2,3,3a,4,5,5,5a,5b,6-Dodecachlorooctahydro-1,3,4-metheno 1H-cyclobuta (cd) pentalene, Dechlorane',
    cas: '',
  },
  {
    haz: '',
    name: '1,1a,3,3a,4,5,5,5a,5b,6-Decachloro--octahydro-1,2,4-metheno-2H- cyclobuta (cd) pentalen-2-one, Chlorecone',
    cas: '',
  },
  { haz: 'T', name: '1,1-Dichloroethane', cas: '75-34-3' },
  { haz: 'T', name: '1,1-Dichloroethylene', cas: '75-35-4' },
  { haz: 'T', name: '1,1-Dimethylhydrazine', cas: '57-14-7' },
  {
    haz: '',
    name: '1,2,3,4,10,10-Hexachloro-6,7-epoxy-1,4,4,4a,5,6,7,8,8a-octahydro- 1,4-endo-endo-5,8-dimethanonaph-thalene',
    cas: '',
  },
  { haz: 'T', name: '1,2,3-Propanetriol, trinitrate', cas: '55-63-0' },
  { haz: 'T', name: '1,2,3-Trichlorpropane', cas: '96-18-4' },
  {
    haz: '',
    name: '1,2,4,5,6,7,8,8-Octachloro-4,7-methano-3a,4,7,7a-tetra- hydro- indane',
    cas: '',
  },
  { haz: 'T', name: '1,2,4,5-Tetrachlorobenzene', cas: '95-94-3' },
  { haz: 'T', name: '1,2:3,4-Diepoxybutane', cas: '464-53-5' },
  {
    haz: 'T',
    name: '1,2-Benzenedicarboxylic acid, bis(2-ethylhexyl) ester',
    cas: '117-81-7',
  },
  {
    haz: 'T',
    name: '1,2-Benzenedicarboxylic acid, dibutyl ester',
    cas: '84-74-2',
  },
  {
    haz: 'T',
    name: '1,2-Benzenedicarboxylic acid, diethyl ester',
    cas: '84-66-2',
  },
  {
    haz: 'T',
    name: '1,2-Benzenedicarboxylic acid, dimethyl ester',
    cas: '131-11-3',
  },
  {
    haz: 'T',
    name: '1,2-Benzenedicarboxylic acid, dioctyl ester',
    cas: '117-84-0',
  },
  {
    haz: 'T',
    name: '1,2-Benzenediol, 4-[1-hydroxy-2-(methylamino)ethyl]-,',
    cas: '51-43-4',
  },
  {
    haz: 'T',
    name: '1,2-Benzisothiazol-3(2H)-one, 1,1-dioxide, & salts',
    cas: '181-07-2',
  },
  { haz: 'T', name: '1,2-Dibromo-3-chloropropane', cas: '96-12-8' },
  { haz: 'T', name: '1,2-Dibromoethane', cas: '106-93-4' },
  { haz: 'T,F', name: '1,2-Dichloroethane', cas: '107-06-2' },
  { haz: 'T,F', name: '1,2-Dichloroethylene', cas: '156-60-5' },
  { haz: 'T,F', name: '1,2-Dichloropropane', cas: '78-87-5' },
  { haz: 'T,F', name: '1,2-Diethylhydrazine', cas: '1615-80-1' },
  { haz: 'T,F', name: '1,2-Dimethylhydrazine', cas: '540-73-8' },
  { haz: 'T', name: '1,2-Diphenylhydrazine', cas: '122-66-7' },
  { haz: 'T', name: '1,2-Epoxybutane', cas: '106-88-7' },
  { haz: 'T', name: '1,2-Epoxypropane', cas: '75-56-9' },
  {
    haz: 'T',
    name: "1,2-Ethanediamine, N,N-dimethyl-N'-2-pyridinyl-N'-(2thienylmethyl)-",
    cas: '91-80-5',
  },
  { haz: 'T', name: '1,2-Oxathiolane, 2,2-dioxide', cas: '1120-71-4' },
  { haz: 'T', name: '1,2-Propylenimine', cas: '75-55-8' },
  {
    haz: 'T',
    name: '1,3,4,5,6,7,8,8-Octachloro-1,3,3a,4,7,7a-hexahydro-4,7-',
    cas: '',
  },
  {
    haz: 'T',
    name: '1,1a,3,3a,4,5,5,5a,5b,6-decachlorooctahydro-',
    cas: '143-50-0',
  },
  { haz: 'T,F', name: '1,3,5-Trinitrobenzene', cas: '99-35-4' },
  { haz: 'T', name: '1,3,5-Trioxane, 2,4,6-trimethyl-', cas: '123-63-7' },
  { haz: 'T', name: '1,3-Benzenediol', cas: '108-46-3' },
  { haz: 'T', name: '1,3-Benzodioxol-4-ol, 2,2-dimethyl-,', cas: '22961-82-6' },
  {
    haz: 'T',
    name: '1,3-Benzodioxol-4-ol, 2,2-dimethyl-,methyl carbamate.',
    cas: '22781-23-3',
  },
  { haz: 'T', name: '1,3-Benzodioxole, 5-(1-propenyl)-', cas: '120-58-1' },
  { haz: 'T', name: '1,3-Benzodioxole, 5-(2-propenyl)-', cas: '94-59-7' },
  { haz: 'T', name: '1,3-Benzodioxole, 5-propyl-', cas: '94-58-6' },
  { haz: 'T', name: '1,3-Butadiene [1910.1051]', cas: '106-99-0' },
  { haz: 'T', name: '1,3-Butadiene, 1,1,2,3,4,4-hexachloro-', cas: '87-68-3' },
  {
    haz: 'T',
    name: '1,3-Cyclopentadiene, 1,2,3,4,5,5-hexachloro-',
    cas: '77-47-4',
  },
  { haz: 'T', name: '1,3-Dichloropropene', cas: '542-75-6' },
  { haz: 'T', name: '1,3-Dichloropropene (technical grade)', cas: '542-75-6' },
  { haz: 'T', name: 'carbonyl]oxime.', cas: '26419-73-8' },
  { haz: 'T', name: '1,3-Isobenzofurandione', cas: '85-44-9' },
  { haz: 'T', name: '1,3-Pentadiene', cas: '504-60-9' },
  { haz: 'T', name: '1,3-Propane Sultone', cas: '1120-71-4' },
  {
    haz: '',
    name: '1,4,5,8-Dimethanonaphthalene, 1,2,3,4,10,10-hexa- chloro- 1,4,4a,5,8,8a,-hexahydro-,(1alpha,4alpha,4abeta,5alpha,8alpha,abeta)-',
    cas: '309-00-2',
  },
  {
    haz: '',
    name: '1,4,5,8-Dimethanonaphthalene, 1,2,3,4,10,10-hexa- chloro- 1,4,4a,5,8,8a-hexahydro-,(1alpha,4alpha,4abeta,5beta,8beta,8aeta)-',
    cas: '465-73-6',
  },
  {
    haz: 'T',
    name: '1,4,5,8-Tetraamino-9,10-anthracenedione',
    cas: '2475-45-8',
  },
  { haz: 'T', name: '1,4-Benzoquinone', cas: '' },
  { haz: 'T', name: '1,4-Butanediol Dimethylsulfonate', cas: '55-98-1' },
  { haz: 'T', name: '1,4-Dichloro-2-butene', cas: '764-41-0' },
  { haz: 'T', name: '1,4-Dichloro-2-butene', cas: '764-41-0' },
  { haz: 'T', name: '1,4-Dichlorobenzene', cas: '106-46-7' },
  { haz: 'T,F', name: '1,4-Dichlorobenzene', cas: '106-46-7' },
  { haz: 'T', name: '1,4-Diethyleneoxide', cas: '23-91-1' },
  { haz: 'T,F', name: '1,4-Dioxane', cas: '123-91-1' },
  { haz: 'T', name: '1,4-Dioxane-d8', cas: '17647-74-4' },
  { haz: 'T', name: '1,4-Naphthalenedione', cas: '130-15-4' },
  { haz: 'T', name: '1,4-Naphthoquinone', cas: '130-15-4' },
  { haz: 'T', name: '1,6-Dinitropyrene', cas: '42397-64-8' },
  { haz: 'T', name: '1,8-Dihydroxyanthraquinone', cas: '117-10-2' },
  { haz: 'T', name: '1,8-Dinitropyrene', cas: '42397-65-9' },
  {
    haz: 'T',
    name: '1-[(5-Nitrofurfurylidene)amino]-2-imidazolidinone',
    cas: '555-84-0',
  },
  { haz: 'T', name: '1-Acetyl-2-thiourea', cas: '591-08-2' },
  { haz: 'T', name: '1-Amino-2,4-dibromoanthraquinone', cas: '81-49-2' },
  { haz: 'T', name: '1-Amino-2-methylanthraquinone', cas: '82-28-0' },
  { haz: 'T', name: '1-Butanamine, N-butyl-N-nitroso-', cas: '924-16-3' },
  { haz: 'T,F', name: '1-Butanol', cas: '71-36-3' },
  { haz: 'T', name: '1-Chloro-2,3-epoxy-propane', cas: '106-89-8' },
  { haz: 'T', name: '1-Chloro-2-methylpropene', cas: '513-37-1' },
  { haz: 'T', name: '1-Chloro-4-nitrobenzene', cas: '100-00-5' },
  { haz: 'T', name: '1H-1,2,4-Triazol-3-amine', cas: '61-82-5' },
  { haz: 'T', name: '1-Methylbutadiene', cas: '504-60-9' },
  { haz: 'T', name: '1-NA', cas: '' },
  { haz: 'T', name: '1-Naphthalenamine', cas: '134-32-7' },
  { haz: 'T', name: '1-Naphthalenol, methylcarbamate.', cas: '63-25-2' },
  { haz: 'T', name: '1-Nitropyrene', cas: '5522-43-0' },
  { haz: 'T', name: '1-Propanamine', cas: '107-10-8' },
  { haz: 'T', name: '1-Propanamine, N-nitroso-N-propyl-', cas: '621-64-7' },
  { haz: 'T', name: '1-Propanamine, N-propyl- (I)', cas: '142-84-7' },
  {
    haz: 'T',
    name: '1-Propanol, 2,3-dibromo-, phosphate (3:1)',
    cas: '126-72-7',
  },
  { haz: 'T,F', name: '1-Propanol, 2-methyl-', cas: '78-83-1' },
  { haz: 'T', name: '1-Propene, 1,1,2,3,3,3-hexachloro-', cas: '1888-71-7' },
  { haz: 'T', name: '1-Propene, 1,3-dichloro-', cas: '542-75-6' },
  { haz: 'T', name: '2-NA', cas: '' },
  {
    haz: 'T',
    name: '2-(2-Formylhydrazino)-4-(5-nitro-2-furyl)thiazole',
    cas: '3570-75-0',
  },
  { haz: 'T', name: '2-(Diethoxyphosphinylimino)-1,3-dithio-lane', cas: '' },
  { haz: 'T', name: "2,2'-Bioxirane", cas: '1464-53-5' },
  { haz: 'T', name: '2,2-bis(Bromomethyl)-1,3-propandiol', cas: '3296-90-0' },
  { haz: 'T', name: '2,2-bis(Bromomethyl)propane-1,3-diol', cas: '3296-90-0' },
  { haz: 'T', name: "2,2'-Dichlorodiethylsulfide", cas: '505-60-2' },
  { haz: 'T,F', name: '2,3,4,6-Tetrachlorophenol', cas: '58-90-2' },
  { haz: 'T', name: '2,3,7,8-Tetrachlorodibenzo-p-dioxin', cas: '1746-01-6' },
  { haz: 'T', name: '2,3-Dibromo-1-propanol', cas: '96-13-9' },
  { haz: 'T', name: '2,3-Dibromopropan-1-ol', cas: '96-13-9' },
  {
    haz: 'T',
    name: '2,3-Dihydro-2,2-dimethyl-7-benzofuranylmethylcarbamate',
    cas: '',
  },
  {
    haz: 'T',
    name: '2,4-(1H,3H)-Pyrimidinedione, 5-[bis(2-chloroethyl)amino]-',
    cas: '66-75-1',
  },
  { haz: 'T', name: '2,4,5-T', cas: '93-76-5' },
  { haz: 'T', name: '2,4,5-Trichlorophenol', cas: '95-95-4' },
  { haz: 'T', name: '2,4,5-Trichlorophenoxyacetic acid', cas: '' },
  { haz: 'T', name: '2,4,5-Trichlorophenoxypropionic acid', cas: '' },
  {
    haz: 'T',
    name: '2,4,5-Trimethylaniline (and its strong acid salts)',
    cas: '137-17-7',
  },
  { haz: 'T', name: '2,4,6-Trichlorophenol', cas: '88-06-2' },
  { haz: 'T,F', name: '2,4-/2,6-Dinitrotoluene (mixture)', cas: '0-66-0' },
  { haz: 'T', name: '2,4-D, salts & esters', cas: '194-75-7' },
  { haz: 'T', name: '2,4-Diaminoanisole', cas: '615-05-4' },
  { haz: 'T', name: '2,4-Diaminoanisole Sulfate', cas: '39156-41-7' },
  { haz: 'T', name: '2,4-Diaminotoluene', cas: '95-80-7' },
  { haz: 'T', name: '2,4-Dichlorophenol', cas: '120-83-2' },
  { haz: 'T', name: '2,4-Dichlorophenoxyacetic acid', cas: '' },
  {
    haz: 'T',
    name: '2,4-Dichlorophenyl-p-nitrophenyl Ether',
    cas: '1836-75-5',
  },
  { haz: 'T', name: '2,4-Dimethylphenol', cas: '105-67-9' },
  { haz: 'T', name: '2,4-Dinitro-6-sec-butylphenol', cas: '' },
  { haz: 'T', name: '2,4-Dinitrophenol', cas: '51-28-5' },
  { haz: 'T', name: '2,4-Dinitrotoluene', cas: '121-14-2' },
  { haz: 'T', name: '2,5-Cyclohexadiene-1,4-dione', cas: '106-51-4' },
  { haz: 'T', name: '2,5-Furandione', cas: '108-31-6' },
  { haz: 'T', name: '2,6-Dichlorophenol', cas: '87-65-0' },
  { haz: 'T', name: '2,6-Dimethylaniline', cas: '87-62-7' },
  { haz: 'T,F', name: '2,6-Dinitrotoluene', cas: '606-20-2' },
  { haz: 'T', name: '2,6-Xylidine', cas: '87-62-7' },
  {
    haz: 'T',
    name: '2,7:3,6-Dimethanonaphth[2,3-b]oxirene,3,4,5,6,9,9-hexachloro1a,2,2a,3,6,6a,7,7a-octahydro-,alpha,7beta, 7aalpha)-b]oxirene, 3,4,5,6,9,9-hexachloro-1a,2,2a,3,6,6a,7,7a-octahydro,(1aalpha,2beta,2abeta,3alpha,6alpha,6abeta,7beta, 7aalpha)-, &',
    cas: '72-20-8',
  },
  {
    haz: 'T',
    name: "2,7-Naphthalenedisulfonic acid, 3,3'-[(3,3'-dimethyl[1,1'-biphenyl]4,4'-diyl)bis(azo)bis[5-amino-4-hydroxy]-,tetrasodium salt",
    cas: '72-57-1',
  },
  { haz: 'T', name: '254. *Diborane', cas: '' },
  { haz: 'T', name: '2-AAF', cas: '' },
  { haz: 'T', name: '2-Acetylaminofluorene', cas: '53-96-3' },
  {
    haz: 'T',
    name: '2-Amino-5-(5-nitro-2-furyl)-1,3,4-thiadiazole',
    cas: '712-68-5',
  },
  { haz: 'T', name: '2-Aminoanthraquinone', cas: '117-79-3' },
  { haz: 'T', name: '2-Aminofluorene', cas: '153-78-6' },
  { haz: 'T', name: '2-Aminonaphthalene', cas: '91-59-8' },
  { haz: 'T', name: '2-Aminopyridine', cas: '' },
  { haz: 'T', name: '2-Butanone', cas: '78-93-3' },
  {
    haz: '',
    name: '2-Butanone, 3,3-dimethyl-1(methylthio)-, O- [methylamino)carbonyl] oxime',
    cas: '39196-18-4',
  },
  { haz: 'T', name: '2-Butanone, peroxide', cas: '1338-23-4' },
  { haz: 'T', name: '2-Butenal', cas: '4170-30-3' },
  { haz: 'T', name: '2-Butene, 1,4-dichloro-', cas: '764-41-0' },
  {
    haz: 'T',
    name: '2-Butenoic acid, 2-methyl-, 7-[[2,3-dihydroxy-2-(1-methoxyethyl)-3- methyl-1-oxobutoxy]methyl]-2,3,5,7a-tetrahydro-1H-pyrrolizin-1-yl ester,[1S-[1alpha(Z),7(2S*,3R*),7aalpha]]-',
    cas: '303-34-4',
  },
  { haz: 'T', name: '2-Carbomethoxy-1-methylvinyldimethylphosphate', cas: '' },
  {
    haz: 'T',
    name: '2-Chloro-1-(2,4-dichlorophenyl) vinyl diethyl phosphate',
    cas: '',
  },
  {
    haz: 'T',
    name: '2-Chloro-2-diethyl--carbamoyl-1-methylvinyl dimethyl phosphate',
    cas: '',
  },
  { haz: 'T', name: '2-Chloro-4,5-dimethylphenylmethyl-carbamate', cas: '' },
  { haz: 'T', name: '2-Chloro-4-dimethylamino-6-methyl-pyrimidine', cas: '' },
  { haz: 'T,F', name: '2-Chloroethyl vinyl ether', cas: '110-75-8' },
  { haz: 'T', name: '2-Chlorophenol', cas: '95-57-8' },
  { haz: 'T', name: '2-Cyclohexyl-4,6-dinitrophenol', cas: '131-89-5' },
  { haz: 'T', name: '2-Furancarboxaldehyde', cas: '98-01-1' },
  {
    haz: 'T',
    name: '2H-1,3,2-Oxazaphosphorin-2-amine,N,N-bis(2- chloroethyl)tetrahydro-, 2-oxide',
    cas: '50-18-0',
  },
  {
    haz: '',
    name: '2H-1-Benzopyran-2-one, 4-hydroxy-3-(3-oxo-1-phenyl-butyl)-, & salts, when present at concentrations of 0.3% or less',
    cas: '',
  },
  { haz: 'T', name: '', cas: '81-81-2' },
  { haz: 'T', name: '2-Imidazolidinethione', cas: '96-45-7' },
  { haz: 'T', name: '2-Methyl-1,3-butadiene', cas: '78-79-5' },
  { haz: 'T', name: '2-Methyl-1-nitroanthraquinone', cas: '129-15-7' },
  {
    haz: '',
    name: '2-Methyl-2(methylthio)propionaldehyde-O-(methylcarbamoyloxime',
    cas: '',
  },
  { haz: 'T', name: '2-Methylaziridine', cas: '75-55-8' },
  { haz: 'T', name: '2-Methyllactonitrile', cas: '75-86-5' },
  { haz: 'T', name: '2-Naphthalenamine', cas: '91-59-8' },
  { haz: 'T', name: '2-Naphthylamine', cas: '91-59-8' },
  { haz: 'T', name: '2-Nitroanisole', cas: '91-23-6' },
  { haz: 'T', name: '2-Nitrofluorene', cas: '607-57-8' },
  { haz: 'T', name: '2-Nitropropane', cas: '79-46-9' },
  { haz: 'T', name: '2-Picoline', cas: '109-06-8' },
  { haz: 'T,F', name: '2-Propanone', cas: '67-64-1' },
  { haz: 'T', name: '2-Propanone, 1-bromo-', cas: '598-31-2' },
  { haz: 'T', name: '2-Propen-1-ol', cas: '107-18-6' },
  { haz: 'T', name: '2-Propenal', cas: '107-02-8' },
  { haz: 'T', name: '2-Propenamide', cas: '79-06-1' },
  { haz: 'T', name: '2-Propenenitrile', cas: '107-13-1' },
  { haz: 'T', name: '2-Propenenitrile, 2-methyl-', cas: '126-98-7' },
  { haz: 'T', name: '2-Propenoic acid', cas: '79-10-7' },
  {
    haz: 'T',
    name: '2-Propenoic acid, 2-methyl-, ethyl ester',
    cas: '97-63-2',
  },
  {
    haz: 'T',
    name: '2-Propenoic acid, 2-methyl-, methyl ester',
    cas: '80-62-6',
  },
  { haz: 'T', name: '2-Propenoic acid, ethyl ester', cas: '140-88-5' },
  { haz: 'T', name: '2-Propyn-1-ol', cas: '107-19-7' },
  {
    haz: 'T',
    name: '2-tert-Butyl-5-methyl-4,6-dinitro-phenyl acetate',
    cas: '',
  },
  { haz: 'T', name: '3(2H)-Isoxazolone, 5-(aminomethyl)-', cas: '2763-96-4' },
  {
    haz: 'T',
    name: '3-(N-Nitrosomethylamino)propionitrile',
    cas: '60153-49-3',
  },
  {
    haz: 'T',
    name: "3,3'-Dichloro-4,4'-diaminodiphenyl Ether",
    cas: '28434-86-8',
  },
  { haz: 'T', name: '3,3-Dichlorobenzidine and salts', cas: '91-94-1' },
  { haz: 'T', name: "3,3'-Dichlorobenzidine Dihydrochloride", cas: '612-83-9' },
  { haz: 'T', name: "3,3'-Dimethoxybenzidine", cas: '119-90-4' },
  {
    haz: 'T',
    name: "3,3'-Dimethoxybenzidine Dihydrochloride",
    cas: '20325-40-0',
  },
  { haz: 'T', name: "3,3'-Dimethylbenzidine", cas: '119-93-7' },
  { haz: 'T', name: "3,3'-Dimethylbenzidine Dihydrochloride", cas: '612-82-8' },
  { haz: 'T', name: '3,6-Pyridazinedione, 1,2-dihydro-', cas: '123-33-1' },
  { haz: 'T', name: '3,7-Dinitrofluoranthene', cas: '105735-71-5' },
  { haz: 'T', name: '3,9-Dinitrofluoranthene', cas: '22506-53-2' },
  {
    haz: 'T',
    name: '3-[1-(2-Furanyl)-3-oxobutyl]1-4-hydroxy-2H-1-benzopyran-2-one',
    cas: '',
  },
  { haz: 'T', name: '3-Amino-1,2,4-triazole', cas: '61-82-5' },
  {
    haz: 'T',
    name: '3-Amino-9-ethylcarbazole Hydrochloride',
    cas: '6109-97-3',
  },
  { haz: 'T', name: '3-Bromo-1-propyne', cas: '' },
  { haz: 'T', name: '3-Chloro-2-methylpropene', cas: '563-47-3' },
  { haz: 'T', name: '3-Chlorophenol', cas: '108-43-0' },
  { haz: 'T', name: '3-Chloropropionitrile', cas: '542-76-7' },
  { haz: 'T', name: '3-Isopropylphenyl N-methylcarbamate.', cas: '64-00-6' },
  { haz: 'T', name: '3-Methylcholanthrene', cas: '56-49-5' },
  {
    haz: 'T',
    name: '4(1H)-Pyrimidinone, 2,3-dihydro-6-methyl-2-thioxo-',
    cas: '56-04-2',
  },
  {
    haz: 'T',
    name: '4-(Dimethylamino)-3,5-dimethylphenyl methylcarbamate',
    cas: '',
  },
  {
    haz: 'T',
    name: '4-(N-Nitrosomethylamino)-1-(3-pyridyl)-1-butanone',
    cas: '64091-91-4',
  },
  { haz: 'T', name: "4,4'-Diaminodiphenyl Ether", cas: '101-80-4' },
  { haz: 'T', name: "4,4'-Methylene bis(2-Chloroaniline)", cas: '101-14-4' },
  { haz: 'T', name: "4,4'-Methylene bis(2-Methylaniline)", cas: '838-88-0' },
  {
    haz: 'T',
    name: "4,4'-Methylene bis(N,N-dimethyl) Benzenamine",
    cas: '101-61-1',
  },
  { haz: 'T', name: "4,4'-Methylenebis(2-chloroaniline)", cas: '101-14-4' },
  { haz: 'T', name: "4,4'-Methylenedianiline [1910.1050]", cas: '101-77-9' },
  {
    haz: 'T',
    name: "4,4'-Methylenedianiline Dihydrochloride",
    cas: '13552-44-8',
  },
  { haz: 'T', name: "4,4'-Oxydianiline", cas: '101-80-4' },
  { haz: 'T', name: "4,4'-Thiodianiline", cas: '139-65-1' },
  { haz: 'T', name: '4,6-Dinitro-o-cresol, & salts', cas: '534-52-1' },
  {
    haz: 'T',
    name: '4,7-Methano-1H-indene, 1,2,4,5,6,7,8,8-octachloro-2,3,3a,4,7,7ahexahydro-',
    cas: '57-74-9',
  },
  {
    haz: 'T',
    name: '4,7-Methano-1H-indene, 1,4,5,6,7,8,8-heptachloro-3a,4,7,7a- tetrahydro-',
    cas: '76-44-8',
  },
  { haz: 'T', name: '460.Mercuric salicylate', cas: '' },
  { haz: 'T', name: '4-ADP', cas: '' },
  { haz: 'T', name: '4-Amino-2-nitrophenol', cas: '119-34-6' },
  { haz: 'T', name: '4-Aminobiphenyl', cas: '92-67-1' },
  { haz: 'T', name: '4-Aminopyridine', cas: '504-24-5' },
  { haz: 'T', name: '4-Bromophenyl phenyl ether', cas: '101-55-3' },
  {
    haz: 'T',
    name: '4-Chloro-2-methylbenzenamine (and its strong acid salts)',
    cas: '95-69-2',
  },
  {
    haz: 'T',
    name: '4-Chloro-2-methylbenzenamine Hydrochloride',
    cas: '3165-93-3',
  },
  { haz: 'T', name: '4-Chloroaniline', cas: '106-47-8' },
  { haz: 'T', name: '4-Chloro-o-phenylenediamine', cas: '95-83-0' },
  {
    haz: 'T',
    name: '4-Chloro-o-toluidine (and its strong acid salts)',
    cas: '95-69-2',
  },
  { haz: 'T', name: '4-Chloro-o-toluidine, hydrochloride', cas: '3165-93-3' },
  { haz: 'T', name: '4-Chlorophenol', cas: '106-48-9' },
  { haz: 'T', name: '4-Dimethylaminoazobenzene', cas: '60-11-7' },
  {
    haz: '',
    name: '4-Hydroxy-3-(1,2,3,4-tetrahydro-1-naphthalenyl)-2H-1-benzopyran- 2-one',
    cas: '',
  },
  { haz: 'T', name: '4-Methyl-2-pentanone', cas: '108-10-1' },
  { haz: 'T', name: '4-Nitrobiphenyl', cas: '92-93-3' },
  { haz: 'T', name: '4-Nitrodiphenyl', cas: '92-93-3' },
  { haz: 'T', name: '4-Nitropyrene', cas: '57835-92-4' },
  { haz: 'T', name: '4-Pyridinamine', cas: '504-24-5' },
  { haz: 'T', name: '4-Vinyl Cyclohexene', cas: '100-40-3' },
  { haz: 'T', name: '4-Vinyl-1-cyclohexene Diepoxide', cas: '106-87-6' },
  { haz: 'T', name: '5-(Aminomethyl)-3-isoxazolol', cas: '2763-96-4' },
  {
    haz: 'T',
    name: '5-(Morpholinomethyl)-3-[(5-nitrofurfurylidene)amino]-2- oxazolidinone',
    cas: '139-91-3',
  },
  {
    haz: 'T',
    name: '5-(Morpholinomethyl)-3-[(5-nitrofurfurylidene)amino]-2- oxazolidinone',
    cas: '3795-88-8',
  },
  {
    haz: 'T',
    name: '5,12-Naphthacenedione, 8-acetyl-10-[(3-amino-2,3,6-trideoxy)- alpha-L-lyxo-hexopyranosyl)oxy]-7,8,9,10-tetrahydro-6,8,11- trihydroxy-1-methoxy-, (8S-cis)-',
    cas: '20830-81-3',
  },
  { haz: 'T', name: '5-Azacytidine', cas: '320-67-2' },
  {
    haz: 'T',
    name: '5-Chloro-o-toluidine (and its strong acids)',
    cas: '0-65-0',
  },
  { haz: 'T', name: '5-Methoxypsoralen', cas: '484-20-8' },
  { haz: 'T', name: '5-Methoxypsoralen plus UV A radiation', cas: '484-20-8' },
  { haz: 'T', name: '5-Methylchrysene {PAH}', cas: '3697-24-3' },
  { haz: 'T', name: '5-Nitroacenaphthene', cas: '602-87-9' },
  { haz: 'T', name: '5-Nitro-o-anisidine', cas: '99-59-2' },
  { haz: 'T', name: '5-Nitro-o-toluidine', cas: '99-55-8' },
  {
    haz: 'T',
    name: '6,9-Methano-2,4,3-benzodioxathiepin, 6,7,8,9,10,10- hexachloro1,5,5a,6,9,9a-hexahydro-, 3-oxide',
    cas: '115-29-7',
  },
  { haz: 'T', name: '6-Nitrochrysene', cas: '7496-02-8' },
  { haz: 'T', name: '7,12-Dimethylbenz(a)anthracene', cas: '57-97-6' },
  {
    haz: 'T',
    name: '7-Benzofuranol, 2,3-dihydro-2,2-dimethyl-',
    cas: '1563-38-8',
  },
  { haz: 'T', name: '7H-Dibenzo[c,g]carbazole {PAH}', cas: '194-59-2' },
  {
    haz: 'T',
    name: '7-Oxabicyclo[2.2.1]heptane-2,3-dicarboxylic acid',
    cas: '145-73-3',
  },
  { haz: 'T', name: '8-Methoxypsoralen plus UV A radiation', cas: '298-81-7' },
  { haz: 'T', name: 'A2213', cas: '30558-43-1' },
  {
    haz: 'T',
    name: 'A-alpha-C(2-Amino-9H-pyrido[2,3-b]indole)',
    cas: '26148-68-5',
  },
  { haz: 'T', name: 'Acetaldehyde', cas: '75-07-0' },
  { haz: 'T', name: 'Acetaldehyde Methylformylhydrazone', cas: '16568-02-8' },
  { haz: 'T', name: 'Acetaldehyde, chloro-', cas: '107-20-0' },
  { haz: 'T', name: 'Acetaldehyde, trichloro-', cas: '75-87-6' },
  { haz: 'T', name: 'Acetamide', cas: '60-35-5' },
  { haz: 'T', name: 'Acetamide, 2-fluoro-', cas: '640-19-7' },
  { haz: 'T', name: 'Acetamide, N-(4-ethoxyphenyl)-', cas: '62-44-2' },
  { haz: 'T', name: 'Acetamide, N-(aminothioxomethyl)-', cas: '591-08-2' },
  { haz: 'T', name: 'Acetamide, N-9H-fluoren-2-yl-', cas: '53-96-3' },
  { haz: 'T', name: 'Acetic acid ethyl ester', cas: '141-78-6' },
  { haz: 'T', name: 'Acetic acid, (2,4,5-trichlorophenoxy)-', cas: '93-76-5' },
  {
    haz: 'T',
    name: 'Acetic acid, (2,4-dichlorophenoxy)-,salts and esters',
    cas: '194-75-7',
  },
  { haz: 'T', name: 'Acetic acid, fluoro-, sodium salt', cas: '62-74-8' },
  { haz: 'T', name: 'Acetic acid, lead(2+) salt', cas: '301-04-2' },
  { haz: 'T', name: 'Acetic acid, thallium(1+) salt', cas: '563-68-8' },
  { haz: 'T', name: 'Acetochlor', cas: '34256-82-1' },
  { haz: 'T,F', name: 'Acetone', cas: '67-64-1' },
  { haz: 'T,F', name: 'Acetonitrile', cas: '75-05-8' },
  { haz: 'T', name: 'Acetophenone', cas: '98-86-2' },
  { haz: 'T,R', name: 'Acetyl chloride', cas: '75-36-5' },
  { haz: 'T', name: 'Acifluorfen', cas: '62476-59-9' },
  { haz: 'T', name: 'Acrolein', cas: '107-02-8' },
  { haz: 'T', name: 'Acrylamide', cas: '79-06-1' },
  { haz: 'T', name: 'Acrylic acid', cas: '79-10-7' },
  { haz: 'T', name: 'Acrylonitrile', cas: '107-13-1' },
  { haz: 'T', name: 'ACTIDIONE', cas: '' },
  { haz: 'T', name: 'Actinolite [asbestiform]', cas: '77536-66-4' },
  { haz: 'T', name: 'Actinomycin D', cas: '50-76-0' },
  { haz: 'T', name: 'Adiponitrile', cas: '' },
  { haz: 'T', name: 'Adriamycin', cas: '23214-92-8' },
  {
    haz: 'T',
    name: 'AF-2[2-(2-Furyl)-3-(5-nitro-2-furyl)acrylamide]',
    cas: '3688-53-7',
  },
  { haz: 'T', name: 'AFL 1082', cas: '' },
  { haz: 'T', name: 'Aflatoxin M1', cas: '6795-23-9' },
  { haz: 'T', name: 'Aflatoxins', cas: '1402-68-2' },
  { haz: 'T', name: 'AGALLOL', cas: '' },
  { haz: 'T', name: 'Alachlor', cas: '15972-60-8' },
  { haz: 'T', name: 'Aldicarb', cas: '116-06-3' },
  { haz: 'T', name: 'Aldicarb sulfone', cas: '1646-88-4' },
  { haz: 'T', name: 'Aldrin', cas: '309-00-2' },
  { haz: 'T', name: 'Alkyl aluminum chloride', cas: '' },
  { haz: 'T', name: 'Alkyl aluminum compounds', cas: '' },
  { haz: 'T,F', name: 'Allyl alcohol', cas: '107-18-6' },
  { haz: 'T', name: 'Allyl trichlorosilane', cas: '' },
  { haz: 'T', name: 'alpha,alpha-Dimethylphenethylamine', cas: '122-09-8' },
  {
    haz: 'T',
    name: 'alpha-Chlorinated Toluenes and Benzoyl Chloride (combineexposures)',
    cas: '0-51-0',
  },
  { haz: 'T', name: 'alpha-Chloroacetophenone', cas: '' },
  { haz: 'T', name: 'alpha-Hexachlorocyclohexane', cas: '319-84-6' },
  { haz: 'T', name: 'alpha-Naphthylamine', cas: '134-32-7' },
  { haz: 'T', name: 'alpha-Naphthylthiourea', cas: '86-88-4' },
  { haz: 'T', name: 'Aluminum chloride (anhydrous)', cas: '' },
  { haz: 'T', name: 'Aluminum diethyl monochloride', cas: '' },
  { haz: 'T', name: 'Aluminum phosphide', cas: '20859-73-8' },
  { haz: 'T', name: 'Aluminum Production', cas: '0-58-0' },
  { haz: 'T', name: 'Amitrole', cas: '61-82-5' },
  { haz: 'T', name: 'Ammonium arsenate', cas: '' },
  { haz: 'T', name: 'Ammonium bifluoride', cas: '' },
  {
    haz: 'T',
    name: 'Ammonium Chromate, as Cr6+ [water soluble]',
    cas: '7788-98-9',
  },
  {
    haz: 'T',
    name: 'Ammonium Dichromate, as Cr6+ [water soluble]',
    cas: '7789-09-5',
  },
  {
    haz: 'T',
    name: 'Ammonium Molybdate, as Mo [water soluble]',
    cas: '13106-76-8',
  },
  { haz: 'T', name: 'Ammonium picrate', cas: '131-74-8' },
  { haz: 'T', name: 'Ammonium vanadate', cas: '7803-55-6' },
  { haz: 'T', name: 'Amosite', cas: '12172-73-5' },
  { haz: 'T', name: 'Amsacrine', cas: '51264-14-3' },
  { haz: 'T', name: 'Amyl trichlorosilane (and isomers)', cas: '' },
  { haz: 'T', name: 'Androgenic (anabolic) steroids', cas: '0-01-0' },
  { haz: 'T', name: 'Aniline', cas: '62-53-3' },
  { haz: 'T', name: 'Aniline Hydrochloride', cas: '142-04-1' },
  { haz: 'T', name: 'ANIMERT V-101', cas: '' },
  { haz: 'T', name: 'Anthophyllite [asbestiform]', cas: '77536-67-5' },
  { haz: 'T', name: 'Antimony and/or antimony compounds', cas: '' },
  { haz: 'T', name: 'Antimony pentachloride', cas: '' },
  { haz: 'T', name: 'Antimony pentafluoride', cas: '' },
  { haz: 'T', name: 'Antimony Trioxide', cas: '1309-64-4' },
  { haz: 'T', name: 'Aqualin', cas: '' },
  { haz: 'T', name: 'Aramite', cas: '140-57-8' },
  { haz: 'T', name: 'ARETAN', cas: '' },
  { haz: 'T', name: 'Argentate(1-), bis(cyano-C)-,potassium', cas: '506-61-6' },
  { haz: 'T', name: 'Aroclor  1254 {PCBs}', cas: '11097-69-1' },
  { haz: 'T', name: 'Aroclor  1260 {PCBs}', cas: '11096-82-5' },
  { haz: 'T', name: 'Arsenic acid', cas: '7778-39-4' },
  { haz: 'T', name: 'Arsenic Acid Hemihydrate', cas: '7774-41-6' },
  { haz: 'T', name: 'Arsenic and/or arsenic compounds', cas: '' },
  { haz: 'T', name: 'Arsenic bromide', cas: '' },
  { haz: 'T', name: 'Arsenic chloride', cas: '' },
  { haz: 'T', name: 'Arsenic compounds', cas: '' },
  { haz: 'T', name: 'Arsenic Disulfide', cas: '1303-32-8' },
  { haz: 'T', name: 'Arsenic iodide', cas: '' },
  { haz: 'T', name: 'Arsenic oxide', cas: '1327-53-3' },
  { haz: 'T', name: 'Arsenic oxide', cas: '1303-28-2' },
  { haz: 'T', name: 'Arsenic pentaselenide', cas: '' },
  { haz: 'T', name: 'Arsenic Pentoxide', cas: '1303-28-2' },
  { haz: 'T', name: 'Arsenic sulfide', cas: '' },
  { haz: 'T', name: 'Arsenic Tribromide', cas: '7784-33-0' },
  { haz: 'T', name: 'Arsenic Trichloride', cas: '7784-34-1' },
  { haz: 'T', name: 'Arsenic Trifluoride', cas: '7784-35-2' },
  { haz: 'T', name: 'Arsenic Triiodide', cas: '7784-45-4' },
  { haz: 'T', name: 'Arsenic Trioxide', cas: '1327-53-3' },
  { haz: 'T', name: 'Arsenic Triselenide', cas: '1303-36-2' },
  { haz: 'T', name: 'Arsenic Trisulfide', cas: '1303-33-9' },
  {
    haz: 'T',
    name: 'Arsenic, Inorganic [1910.1018] - [see specific compound]',
    cas: '7440-38-2',
  },
  { haz: 'T', name: 'Arsenical Dip', cas: '8024-75-9' },
  { haz: 'T', name: 'Arsenious Acid', cas: '14060-38-9' },
  { haz: 'T', name: 'Arsenious acid and salts', cas: '' },
  { haz: 'T', name: 'Arsenious oxide', cas: '' },
  { haz: 'T', name: 'Arsine, diethyl', cas: '692-42-2' },
  { haz: 'T', name: 'Arsines', cas: '' },
  { haz: 'T', name: 'Arsinic acid, dimethyl-', cas: '75-60-5' },
  { haz: 'T', name: 'Arsonous dichloride, phenyl-', cas: '696-28-6' },
  {
    haz: 'T',
    name: 'Art Glass, Glass Containers, and Pressed Ware (manufacture of)',
    cas: '0-63-0',
  },
  { haz: 'T', name: 'Asbestos', cas: '' },
  { haz: 'T', name: 'Askarel', cas: '' },
  { haz: 'T', name: 'Asphalt (Petroleum) Fumes', cas: '8052-42-4' },
  { haz: 'T', name: 'Attapulgite  (long fibers, > 5 µm)', cas: '12174-11-7' },
  { haz: 'T', name: 'Auramine', cas: '492-80-8' },
  { haz: 'T', name: 'Azacitidine', cas: '320-67-2' },
  { haz: 'T', name: 'Azaserine', cas: '115-02-6' },
  { haz: 'T', name: 'Azathioprine', cas: '446-86-6' },
  { haz: 'T', name: 'Aziridine', cas: '151-56-4' },
  { haz: 'T', name: 'Aziridine, 2-methyl-', cas: '75-55-8' },
  {
    haz: 'T',
    name: "Azirino[2',3':3,4]pyrrolo[1,2-a]indole-4,7-dione, 6-amino-8[[(aminocarbonyl)oxy]methyl]-1,1a,2,8,8a,8b-hexahydro-8a- methoxy-5- methyl-, [1aS-(1aalpha, 8beta,8aalpha,8balpha)]-",
    cas: '50-07-7',
  },
  { haz: 'T', name: 'Azobenzene', cas: '103-33-3' },
  { haz: 'T', name: 'AZODRIN, 3-Hydroxy-N-cis-crotonamide', cas: '' },
  { haz: 'T', name: 'AZT', cas: '30516-87-1' },
  { haz: 'T', name: 'BANOL', cas: '' },
  { haz: 'T', name: 'Barban.', cas: '101-27-9' },
  {
    haz: 'T',
    name: 'Barium and/or barium compounds (excluding barite)',
    cas: '7440-39-3',
  },
  { haz: 'T', name: 'Barium Chromate, as Cr6+', cas: '10294-40-3' },
  { haz: 'T', name: 'Barium Chromate, as Cr6+', cas: '12000-34-9' },
  { haz: 'T', name: 'Barium Chromate, as Cr6+', cas: '12231-18-4' },
  { haz: 'T,R', name: 'Barium cyanide', cas: '542-62-1' },
  { haz: 'T', name: 'Basic Bismuth Dichromate, as Cr6+', cas: '37235-82-8' },
  { haz: 'T', name: 'Basic Copper (II) Chromate, as Cr6+', cas: '1308-09-4' },
  { haz: 'T', name: 'Basic Lead Carbonate Sulfate', cas: '1319-48-8' },
  { haz: 'T', name: 'Basic Lead Chromate, as Cr6+', cas: '1344-38-3' },
  { haz: 'T', name: 'Basic Lead Chromate, as Cr6+', cas: '18454-12-1' },
  { haz: 'T', name: 'Basic Lead Chromate, as Cr6+', cas: '54692-53-4' },
  { haz: 'T', name: 'BAYER 25141', cas: '' },
  { haz: 'T', name: 'BAYER 25634', cas: '' },
  { haz: 'T', name: 'BCME', cas: '' },
  { haz: 'T', name: 'BCNU', cas: '154-93-8' },
  { haz: 'T', name: 'Bendiocarb phenol.', cas: '22961-82-6' },
  { haz: 'T', name: 'Bendiocarb.', cas: '22781-23-3' },
  { haz: 'T', name: 'Benomyl.', cas: '17804-35-2' },
  { haz: 'T', name: 'Benz[a]anthracene', cas: '56-55-3' },
  { haz: 'T', name: 'Benz[a]anthracene {PAH}', cas: '56-55-3' },
  { haz: 'T', name: 'Benz[a]anthracene, 7,12-dimethyl-', cas: '57-97-6' },
  { haz: 'T', name: 'Benz[c]acridine', cas: '225-51-4' },
  {
    haz: 'T',
    name: 'Benz[j]aceanthrylene, 1,2-dihydro-3- methyl-',
    cas: '56-49-5',
  },
  { haz: 'T', name: 'Benzal Chloride', cas: '98-87-3' },
  {
    haz: 'T',
    name: 'Benzamide, 3,5-dichloro-N-(1,1dimethyl-2-propynyl)--',
    cas: '23950-58-5',
  },
  { haz: 'T', name: 'Benzenamine', cas: '62-53-3' },
  { haz: 'T', name: 'Benzenamine, 2-methyl-', cas: '95-53-4' },
  { haz: 'T', name: 'Benzenamine, 2-methyl-, hydrochloride', cas: '636-21-5' },
  { haz: 'T', name: 'Benzenamine, 2-methyl-5-nitro-', cas: '99-55-8' },
  {
    haz: 'T',
    name: "Benzenamine, 4,4'-carbonimidoylbis[N,N-dimethyl-",
    cas: '492-80-8',
  },
  {
    haz: 'T',
    name: "Benzenamine, 4,4'-methylenebis[2-chloro",
    cas: '101-14-4',
  },
  { haz: 'T', name: 'Benzenamine, 4-chloro-', cas: '106-47-8' },
  {
    haz: 'T',
    name: 'Benzenamine, 4-chloro-2-methyl-, hydrochloride',
    cas: '3165-93-3',
  },
  { haz: 'T', name: 'Benzenamine, 4-methyl-', cas: '106-49-0' },
  { haz: 'T', name: 'Benzenamine, 4-nitro-', cas: '100-01-6' },
  {
    haz: 'T',
    name: 'Benzenamine, N,N-dimethyl-4- (phenylazo)-',
    cas: '60-11-7',
  },
  { haz: 'T,F', name: 'Benzene', cas: '71-43-2' },
  {
    haz: '',
    name: 'Benzene hexachloride, BHC; 1,2,3,4,5,6-Hexachloro--cyclohexane',
    cas: '',
  },

  { haz: 'T,F', name: 'Benzene, (1-methylethyl)-', cas: '98-82-8' },
  { haz: 'T', name: 'Benzene, (chloromethyl)-', cas: '100-44-7' },
  { haz: 'T', name: 'Benzene, (dichloromethyl)-', cas: '98-87-3' },
  { haz: 'T', name: 'Benzene, (trichloromethyl)-', cas: '98-07-7' },
  {
    haz: 'T',
    name: "Benzene, 1,1'-(2,2,2-trichloroethylidene)bis[4- methoxy-",
    cas: '72-43-5',
  },
  {
    haz: 'T',
    name: "Benzene, 1,1'-(2,2,2-trichloroethylidene)bis[4-chloro-",
    cas: '50-29-3',
  },
  {
    haz: 'T',
    name: "Benzene, 1,1'-(2,2-dichloroethylidene)bis[4-chloro-",
    cas: '72-54-8',
  },
  { haz: 'T', name: 'Benzene, 1,2,4,5-tetrachloro-', cas: '95-94-3' },
  { haz: 'T', name: 'Benzene, 1,2-dichloro-', cas: '95-50-1' },
  { haz: 'T,F', name: 'Benzene, 1,3,5-trinitro-', cas: '99-35-4' },
  { haz: 'T', name: 'Benzene, 1,3-dichloro-', cas: '541-73-1' },
  { haz: 'T', name: 'Benzene, 1,3-diisocyanatomethyl-', cas: '26471-62-5' },
  { haz: 'T', name: 'Benzene, 1,4-dichloro-', cas: '106-46-7' },
  { haz: 'T', name: 'Benzene, 1-bromo-4-phenoxy-', cas: '101-55-3' },
  { haz: 'T', name: 'Benzene, 1-methyl-2,4-dinitro-', cas: '121-14-2' },
  { haz: 'T', name: 'Benzene, 2-methyl-1,3-dinitro-', cas: '606-20-2' },
  { haz: 'T', name: 'Benzene, chloro-', cas: '108-90-7' },
  { haz: 'T,F', name: 'Benzene, dimethyl-', cas: '1330-20-7' },
  { haz: 'T', name: 'Benzene, hexachloro-', cas: '118-74-1' },
  { haz: 'T', name: 'Benzene, hexahydro-', cas: '110-82-7' },
  { haz: 'T', name: 'Benzene, methyl-', cas: '108-88-3' },
  { haz: 'T', name: 'Benzene, nitro-', cas: '98-95-3' },
  { haz: 'T', name: 'Benzene, pentachloro-', cas: '608-93-5' },
  { haz: 'T', name: 'Benzene, pentachloronitro-', cas: '82-68-8' },
  { haz: 'T', name: 'Benzene-1,3,5-d3 {C6H3D3}', cas: '1684-47-5' },
  {
    haz: 'T',
    name: 'Benzeneacetic acid, 4-chloro-alpha-(4-chlorophenyl)-alpha- hydroxy-, ethyl ester',
    cas: '510-15-6',
  },
  {
    haz: 'T',
    name: 'Benzenebutanoic acid, 4-[bis(2-chloroethyl)amino]-',
    cas: '305-03-3',
  },
  { haz: 'T', name: 'Benzene-d {C6H5D1}', cas: '1120-89-4' },
  { haz: 'T', name: 'Benzene-d6 {C6D6}', cas: '1076-43-3' },
  { haz: 'T', name: 'Benzenediamine, ar-methyl-', cas: '25376-45-8' },
  {
    haz: 'T',
    name: 'Benzeneethanamine, alpha,alpha- dimethyl-',
    cas: '122-09-8',
  },
  { haz: 'T', name: 'Benzenephosphorous dichloride', cas: '' },
  { haz: 'T', name: 'Benzenesulfonic acid chloride', cas: '98-09-9' },
  { haz: 'T, R', name: 'Benzenesulfonyl chloride', cas: '98-09-9' },
  { haz: 'T', name: 'Benzenethiol', cas: '108-98-5' },
  { haz: 'T', name: 'Benzidine and salts', cas: '92-87-5' },
  { haz: 'T', name: 'Benzidine-based Dyes', cas: '0-46-0' },
  { haz: 'T', name: 'Benzo[a]pyrene', cas: '50-32-8' },
  { haz: 'T', name: 'Benzo[b]fluoranthene {PAH}', cas: '205-99-2' },
  { haz: 'T', name: 'Benzo[j]fluoranthene {PAH}', cas: '205-82-3' },
  { haz: 'T', name: 'Benzo[k]fluoranthene {PAH}', cas: '207-08-9' },
  { haz: 'T', name: 'Benzo[rst]pentaphene', cas: '189-55-9' },
  { haz: 'T', name: 'Benzofuran', cas: '271-89-6' },
  {
    haz: '',
    name: 'Benzoic acid, 2-hydroxy-, compd. With (3aS-cis)-1,2,3,3a,8,8a- hexahydro-1,3a,8-trimethylpyrrolo[2,3-b]indol-5-yl methylcarbamate ester (1:1).',
    cas: '57-64-7',
  },
  { haz: 'T', name: 'Benzotrichloride', cas: '98-07-7' },
  { haz: 'T', name: 'Benzotrifluoride', cas: '' },
  { haz: 'T', name: 'Benzoyl Chloride', cas: '98-88-4' },
  { haz: 'T,R', name: 'Benzyl Chloride', cas: '100-44-7' },
  { haz: 'T', name: 'Benzyl chlorocarbonate', cas: '' },
  { haz: 'T', name: 'Benzyl chloroformate', cas: '' },
  { haz: 'T', name: 'Benzyl Violet 4B', cas: '1694-09-3' },
  { haz: 'T', name: 'Bertrandite', cas: '12161-82-9' },
  { haz: 'T', name: 'Beryl Ore', cas: '1302-52-9' },
  {
    haz: 'T',
    name: 'Beryllium & compounds, as Be - [see specific compound]',
    cas: '7440-41-7',
  },
  { haz: 'T', name: 'Beryllium Acetate', cas: '543-81-7' },
  { haz: 'T', name: 'Beryllium Acetate, Basic', cas: '1332-52-1' },
  { haz: 'T', name: 'Beryllium Acetate, Basic', cas: '19049-40-2' },
  { haz: 'T', name: 'Beryllium Acetylacetonate', cas: '10210-64-7' },
  { haz: 'T', name: 'Beryllium Aluminum Silicate', cas: '1302-52-9' },
  { haz: 'T,R', name: 'Beryllium Borohydride', cas: '17440-85-6' },
  { haz: 'T', name: 'Beryllium Bromide', cas: '7787-46-4' },
  { haz: 'T', name: 'Beryllium Carbide', cas: '506-66-1' },
  { haz: 'T,C', name: 'Beryllium Carbonate', cas: '13106-47-3' },
  { haz: 'T,C', name: 'Beryllium Carbonate', cas: '66104-24-3' },
  { haz: 'T', name: 'Beryllium Carbonate Basic', cas: '1319-43-3' },
  { haz: 'T', name: 'Beryllium Chloride', cas: '7787-47-5' },
  { haz: 'T', name: 'Beryllium copper', cas: '' },
  { haz: 'T', name: 'Beryllium Fluoride', cas: '7787-49-7' },
  { haz: 'T', name: 'Beryllium Formate', cas: '1111-71-3' },
  { haz: 'T', name: 'Beryllium Hydride', cas: '7787-52-2' },
  { haz: 'T,C', name: 'Beryllium Hydroxide', cas: '13327-32-7' },
  { haz: 'T', name: 'Beryllium Iodide', cas: '7787-53-3' },
  { haz: 'T', name: 'Beryllium Nitrate', cas: '13597-99-4' },
  { haz: 'T', name: 'Beryllium Nitrate Tetrahydrate', cas: '13510-48-0' },
  { haz: 'T', name: 'Beryllium Nitrate Trihydrate', cas: '7787-55-5' },
  { haz: 'T', name: 'Beryllium Nitride', cas: '1304-54-7' },
  { haz: 'T', name: 'Beryllium Oxide', cas: '1304-56-9' },
  { haz: 'T', name: 'Beryllium Perchlorate', cas: '13597-95-0' },
  { haz: 'T', name: 'Beryllium Phosphate', cas: '13598-15-7' },
  { haz: 'T', name: 'Beryllium Phosphate', cas: '13598-26-0' },
  { haz: 'T', name: 'Beryllium Phosphate', cas: '35089-00-0' },
  { haz: 'T', name: 'Beryllium Potassium Fluoride', cas: '7787-50-0' },
  { haz: 'T', name: 'Beryllium Potassium Sulfate', cas: '53684-48-3' },
  { haz: 'T, R', name: 'Beryllium powder', cas: '7440-41-7' },
  { haz: 'T', name: 'Beryllium Selenate', cas: '10039-31-3' },
  { haz: 'T', name: 'Beryllium Silicate', cas: '13598-00-0' },
  { haz: 'T', name: 'Beryllium Silicate', cas: '15191-85-2' },
  { haz: 'T', name: 'Beryllium Silicate', cas: '58500-38-2' },
  { haz: 'T', name: 'Beryllium Silicate Hydrate', cas: '12161-82-9' },
  { haz: 'T', name: 'Beryllium Sodium Fluoride', cas: '13871-27-7' },
  { haz: 'T', name: 'Beryllium Sulfate', cas: '13510-49-1' },
  { haz: 'T', name: 'Beryllium Sulfate Tetrahydrate', cas: '7787-56-6' },
  { haz: 'T', name: 'Beryllium Zinc Silicate, as Be', cas: '39413-47-3' },
  {
    haz: 'T',
    name: 'Beryllium-Aluminum Alloy, as Be fume or dust',
    cas: '12770-50-2',
  },
  {
    haz: 'T',
    name: 'Beryllium-Copper Alloy, as Be fume or dust',
    cas: '11133-98-5',
  },
  {
    haz: 'T',
    name: 'Beryllium-Copper-Cobalt Alloy, as Be fume or dust',
    cas: '55158-44-6',
  },
  {
    haz: 'T',
    name: 'Beryllium-Nickel Alloy, as Be fume or dust [also see Ni]',
    cas: '37227-61-5',
  },
  { haz: 'T', name: 'beta-Butyrolactone', cas: '3068-88-0' },
  { haz: 'T', name: 'beta-Chloronaphthalene', cas: '91-58-7' },
  { haz: 'T', name: 'beta-Chloroprene', cas: '126-99-8' },
  { haz: 'T', name: 'beta-Chlorovinyldichloroarsine', cas: '' },
  { haz: 'T', name: 'beta-Hexachlorocyclohexane', cas: '319-85-7' },
  { haz: 'T', name: 'beta-Hydroxypropionitrile', cas: '' },
  { haz: 'T', name: 'beta-Naphthylamine', cas: '91-59-8' },
  { haz: 'T', name: 'beta-Propiolactone', cas: '57-57-8' },
  { haz: 'T', name: 'Betel quid with tobacco', cas: '0-79-0' },
  { haz: 'T', name: 'BHA', cas: '25013-16-5' },
  {
    haz: '',
    name: 'BIDRIN, Dicrotophos, 3-(Dimethylamino)-1-methyl-3-oxo-1- propenyldimethyl phosphate',
    cas: '',
  },

  { haz: 'T', name: 'Biphenyl', cas: '' },
  { haz: 'T', name: 'bis (Chloromethyl) ether', cas: '' },
  { haz: 'T', name: 'bis (Methylmercuric) sulfate', cas: '' },
  { haz: 'T', name: 'bis(2,3-Epoxypropyl) ether', cas: '' },
  {
    haz: 'T',
    name: 'bis(2-Chloro-1-methylethyl) Ether (technical grade)',
    cas: '108-60-1',
  },
  { haz: 'T', name: 'bis(2-Chloroethyl) Ether', cas: '111-44-4' },
  { haz: 'T', name: 'bis(2-Ethylhexyl) Phthalate', cas: '117-81-7' },
  { haz: 'T', name: 'bis(Chloroethyl) Nitrosourea', cas: '154-93-8' },
  { haz: 'T', name: 'bis(Chloromethyl) Ether', cas: '542-88-1' },
  { haz: 'T', name: 'bis(Dimethylamino) Benzophenone', cas: '90-94-8' },
  {
    haz: 'T',
    name: 'Bitumen (extracts of steam-refined and air-refined)',
    cas: '8052-42-4',
  },
  { haz: 'T', name: 'BLACAFUM', cas: '' },
  { haz: 'T', name: 'Bleomycins', cas: '11056-06-7' },
  { haz: 'T', name: 'BOMYL', cas: '' },
  { haz: 'T', name: 'Boranes', cas: '' },
  { haz: 'T', name: 'Bordeaux arsenites', cas: '' },
  { haz: 'T', name: 'Boron trichloride', cas: '' },
  { haz: 'T', name: 'Boron trifluoride', cas: '' },
  { haz: 'T', name: 'BPL', cas: '57-57-8' },
  { haz: 'T', name: 'Bracken Fern', cas: '0-04-0' },
  { haz: 'T', name: 'Bromine', cas: '' },
  { haz: 'T', name: 'Bromine pentafluoride', cas: '' },
  { haz: 'T', name: 'Bromine trifluoride', cas: '' },
  { haz: 'T', name: 'Bromoacetone', cas: '598-31-2' },
  { haz: 'T', name: 'Bromodichloromethane', cas: '75-27-4' },
  { haz: 'T', name: 'Bromoethane', cas: '74-96-4' },
  { haz: 'T', name: 'Bromoform', cas: '75-25-2' },
  { haz: 'T', name: 'Bromomethane', cas: '' },
  { haz: 'T', name: 'Brucine', cas: '357-57-3' },
  { haz: 'T', name: 'Brucine, Dimethoxystrychnine', cas: '' },
  { haz: 'T', name: 'Busulfan', cas: '55-98-1' },
  { haz: 'T', name: 'Butylated Hydroxyanisole', cas: '25013-16-5' },
  {
    haz: 'T',
    name: 'Butylphenoxyisopropyl Chloroethyl Sulfite',
    cas: '140-57-8',
  },
  { haz: 'T', name: 'C.I. Acid Red 114', cas: '6459-94-5' },
  { haz: 'T', name: 'C.I. Basic Red 9 Monohydrochloride', cas: '569-61-9' },
  { haz: 'T', name: 'C.I. Direct Blue 14', cas: '72-57-1' },
  { haz: 'T', name: 'C.I. Direct Blue 15', cas: '2429-74-5' },
  { haz: 'T', name: 'C.I. Direct Blue 218', cas: '28407-37-6' },
  { haz: 'T', name: 'C.I. Disperse Orange 11', cas: '82-28-0' },
  { haz: 'T', name: 'C.I. Pigment Black 13', cas: '1307-96-6' },
  { haz: 'T', name: 'C.I. Pigment Orange 21, as Cr6+', cas: '1344-38-3' },
  { haz: 'T', name: 'C.I. Pigment Orange 21, as Cr6+', cas: '54692-53-4' },
  { haz: 'T', name: 'C.I. Pigment Red 104, as Cr6+', cas: '8005-36-5' },
  { haz: 'T', name: 'C.I. Pigment Red 104, as Cr6+', cas: '12213-61-5' },
  { haz: 'T', name: 'C.I. Pigment Red 104, as Cr6+', cas: '12656-85-8' },
  { haz: 'T', name: 'C.I. Pigment Red 104, as Cr6+', cas: '12709-98-7' },
  { haz: 'T', name: 'C.I. Pigment Red 104, as Cr6+', cas: '64523-06-4' },
  { haz: 'T', name: 'C.I. Pigment Yellow 34, as Cr6+', cas: '7758-97-6' },
  { haz: 'T', name: 'C.I. Pigment Yellow 36, as Cr6+', cas: '1308-13-0' },
  { haz: 'T', name: 'C.I. Pigment Yellow 36, as Cr6+', cas: '1328-67-2' },
  { haz: 'T', name: 'C.I. Pigment Yellow 36, as Cr6+', cas: '13530-65-9' },
  { haz: 'T', name: 'C.I. Pigment Yellow 36, as Cr6+', cas: '14675-41-3' },
  { haz: 'T', name: 'C.I. Pigment Yellow 36, as Cr6+', cas: '37300-23-5' },
  { haz: 'T', name: 'C.I. Pigment Yellow 36, as Cr6+', cas: '57486-12-1' },
  { haz: 'T', name: 'C.I. Pigment Yellow 45, as Cr6+', cas: '10294-52-7' },
  { haz: 'T', name: 'C.I. Solvent Orange 2', cas: '2646-17-5' },
  { haz: 'T', name: 'C.I. Solvent Yellow 14', cas: '842-07-9' },
  { haz: 'T', name: 'Cacodylic Acid', cas: '75-60-5' },
  { haz: 'T', name: 'Cacodylic acid', cas: '75-60-5' },
  { haz: 'T', name: 'Cadmium Acetate', cas: '543-90-8' },
  { haz: 'T', name: 'Cadmium and/or cadmium compounds', cas: '7440-43-9' },
  { haz: 'T', name: 'Cadmium Bromide', cas: '7789-42-6' },
  { haz: 'T', name: 'Cadmium Carbonate', cas: '513-78-0' },
  { haz: 'T', name: 'Cadmium Chloride', cas: '10108-64-2' },
  { haz: 'T,R', name: 'Cadmium Cyanide', cas: '542-83-6' },
  { haz: 'T', name: 'Cadmium Fluoborate', cas: '14486-19-2' },
  { haz: 'T', name: 'Cadmium Fluoride', cas: '7790-79-6' },
  { haz: 'T.C', name: 'Cadmium Hydroxide', cas: '21041-95-2' },
  { haz: 'T', name: 'Cadmium Iodide', cas: '7790-80-9' },
  { haz: 'T', name: 'Cadmium Nitrate', cas: '10325-94-7' },
  { haz: 'T', name: 'Cadmium Oxide', cas: '1306-19-0' },
  { haz: 'T', name: 'Cadmium Potassium Cyanide', cas: '14402-75-6' },
  { haz: 'T', name: 'Cadmium Selenate', cas: '13814-62-5' },
  { haz: 'T', name: 'Cadmium Selenide', cas: '1306-24-7' },
  { haz: 'T', name: 'Cadmium Stearate', cas: '2223-93-0' },
  { haz: 'T', name: 'Cadmium Sulfate', cas: '10124-36-4' },
  { haz: 'T, R', name: 'Cadmium Sulfide', cas: '1306-23-6' },
  { haz: 'T', name: 'Cadmium Telluride', cas: '1306-25-8' },
  { haz: 'T', name: 'Cadmium Tungstate (VI)', cas: '7790-85-4' },
  {
    haz: 'T',
    name: 'Cadmium-Copper Alloy, cadmium nonbase',
    cas: '12685-29-9',
  },
  {
    haz: 'T',
    name: 'Cadmium-Copper Alloy, copper alloy, base, Cu>99.60%',
    cas: '132295-57-9',
  },
  {
    haz: 'T',
    name: 'Cadmium-Copper Alloy, copper alloy, base, Cu>99.75%',
    cas: '132295-56-8',
  },
  { haz: 'T', name: 'Cadmium-Copper Alloy, copper base', cas: '37364-06-0' },
  { haz: 'T', name: 'Caffeic Acid', cas: '331-39-5' },
  { haz: 'T, R', name: 'Calcium', cas: '' },
  { haz: 'T', name: 'Calcium Arsenate', cas: '7778-44-1' },
  { haz: 'T', name: 'Calcium Arsenate', cas: '10103-62-5' },
  { haz: 'T', name: 'Calcium Arsenite, 1:1', cas: '52740-16-6' },
  { haz: 'T', name: 'Calcium Arsenite, 2:1', cas: '15194-98-6' },
  { haz: 'T', name: 'Calcium Arsenite, 2:3', cas: '27152-57-4' },
  { haz: 'T,R', name: 'Calcium carbide', cas: '' },
  { haz: 'T', name: 'Calcium chromate', cas: '13765-19-0' },
  { haz: 'T', name: 'Calcium Chromate, as Cr6+', cas: '13765-19-0' },
  { haz: 'T,R', name: 'Calcium cyanide', cas: '592-01-8' },
  { haz: 'T,R', name: 'Calcium hydride', cas: '' },
  { haz: 'T,R', name: 'Calcium hypochlorite', cas: '' },
  {
    haz: 'T',
    name: 'Calcium Molybdate, as Mo [water soluble]',
    cas: '7789-82-4',
  },
  { haz: 'T', name: 'Calcium oxychloride (dry)', cas: '' },
  { haz: 'T', name: 'Calcium phosphide', cas: '' },
  { haz: 'T', name: 'Captafol', cas: '2425-06-1' },
  { haz: 'T', name: 'Captan', cas: '133-06-2' },
  {
    haz: 'T',
    name: 'Carbamic acid, (3-chlorophenyl)-, 4-chloro-2-butynyl ester.',
    cas: '101-27-9',
  },
  {
    haz: '',
    name: 'Carbamic acid, [(dibutylamino)- thio]methyl-, 2,3-dihydro-2,2dimethyl- 7-benzofuranyl ester.',
    cas: '55285-14-8',
  },
  {
    haz: 'T',
    name: 'Carbamic acid, [1,2-phenylenebis(iminocarbonothioyl)]bis-dimethyl ester',
    cas: '23564-05-8',
  },
  {
    haz: 'T',
    name: 'Carbamic acid, [1-[(butylamino)carbonyl]-1H-benzimidazol-2-yl]-, methyl ester.',
    cas: '17804-35-2',
  },
  {
    haz: 'T',
    name: 'Carbamic acid, 1H-benzimidazol-2-yl, methyl ester',
    cas: '10605-21-7',
  },
  {
    haz: '',
    name: 'Carbamic acid, dimethyl-, 1-[(dimethyl- amino)carbonyl]- 5-methyl1H- pyrazol-3-yl ester.',
    cas: '644-64-4',
  },
  {
    haz: '',
    name: 'Carbamic acid, dimethyl-, 3-methyl-1-(1-methylethyl)-1H- pyrazol5-yl ester.',
    cas: '119-38-0',
  },
  { haz: 'T', name: 'Carbamic Acid, Ethyl Ester', cas: '51-79-6' },
  { haz: 'T', name: 'Carbamic acid, ethyl ester', cas: '51-79-6' },
  {
    haz: 'T',
    name: 'Carbamic acid, methyl-, 3-methylphenyl ester.',
    cas: '1129-41-5',
  },
  {
    haz: 'T',
    name: 'Carbamic acid, methylnitroso-, ethyl ester',
    cas: '615-53-2',
  },
  {
    haz: 'T',
    name: 'Carbamic acid, phenyl-, 1-methylethyl ester',
    cas: '122-42-9',
  },
  { haz: 'T', name: 'Carbamic chloride, dimethyl-', cas: '79-44-7' },
  {
    haz: 'T',
    name: 'Carbamodithioic acid, 1,2-ethanediylbis-,salts & esters',
    cas: '111-54-6',
  },
  {
    haz: 'T',
    name: 'Carbamothioic acid, bis(1-methylethyl)-, S-(2,3,3-trichloro-2propenyl) ester',
    cas: '2303-17-5',
  },
  {
    haz: 'T',
    name: 'Carbamothioic acid, bis(1-methylethyl)-, S-(2,3-dichloro-2propenyl) ester',
    cas: '2303-16-4',
  },
  {
    haz: 'T',
    name: 'Carbamothioic acid, dipropyl-, S-(phenylmethyl) ester.',
    cas: '52888-80-9',
  },
  { haz: 'T', name: 'Carbanolate', cas: '' },
  { haz: 'T', name: 'Carbaryl.', cas: '63-25-2' },
  { haz: 'T', name: 'Carbazole', cas: '86-74-8' },
  { haz: 'T', name: 'Carbendazim.', cas: '10605-21-7' },
  { haz: 'T', name: 'Carbofuran phenol.', cas: '1563-38-8' },
  { haz: 'T', name: 'Carbofuran.', cas: '1563-66-2' },
  { haz: 'T,F', name: 'Carbon Black', cas: '1333-86-4' },
  {
    haz: 'T',
    name: 'Carbon Black extracts (benzene solvent) {PAH}',
    cas: '0-05-0',
  },
  { haz: 'T', name: 'Carbon disulfide', cas: '75-15-0' },
  { haz: 'T', name: 'Carbon oxyfluoride', cas: '353-50-4' },
  { haz: 'T', name: 'Carbon Tetrachloride', cas: '56-23-5' },
  { haz: 'T', name: 'Carbonic acid, dithallium(1+) salt', cas: '6533-73-9' },
  { haz: 'T', name: 'Carbonic dichloride', cas: '75-44-5' },
  { haz: 'T', name: 'Carbonic difluoride', cas: '353-50-4' },
  { haz: 'T', name: 'Carbonochloridic acid, methyl ester', cas: '79-22-1' },
  { haz: 'T', name: 'Carbophenothion', cas: '' },
  { haz: 'T', name: 'Carbosulfan', cas: '55285-14-8' },
  { haz: 'T', name: 'Carmustine', cas: '154-93-8' },
  { haz: 'T', name: 'CASTRIX', cas: '' },
  { haz: 'T', name: 'Catechol', cas: '120-80-9' },
  { haz: 'T', name: 'CCNU', cas: '13010-47-4' },
  { haz: 'T', name: 'Ceramic Fiber', cas: '409-21-2' },
  { haz: 'T', name: 'Ceresan Liquid', cas: '' },
  { haz: 'T', name: 'CEREWET', cas: '' },
  {
    haz: 'T',
    name: 'Cesium Chromate, as Cr6+ [water soluble]',
    cas: '13454-78-9',
  },
  { haz: 'T', name: 'CHEMFLORM', cas: '' },
  { haz: 'T', name: 'Chloral', cas: '75-87-6' },
  { haz: 'T', name: 'Chlorambucil', cas: '305-03-3' },
  { haz: 'T', name: 'Chloramphenicol', cas: '56-75-7' },
  { haz: 'T', name: 'Chlordane', cas: '57-74-9' },
  { haz: 'T', name: 'Chlordane (technical grade)', cas: '12789-03-6' },
  { haz: 'T', name: 'Chlordecone', cas: '143-50-0' },
  { haz: 'T', name: 'Chlordimeform', cas: '6164-98-3' },
  { haz: 'T', name: 'Chlorendic Acid', cas: '115-28-6' },
  { haz: 'T', name: 'Chlorextol', cas: '' },
  { haz: 'T', name: 'Chlorfenvinphos', cas: '' },
  { haz: 'T', name: 'Chlorinated Camphene', cas: '8001-35-2' },
  {
    haz: 'T',
    name: 'Chlorinated Paraffins (avg. C12, 60% Chlorine)',
    cas: '63449-39-8',
  },
  {
    haz: 'T',
    name: 'Chlorinated Paraffins (avg. C12, 60% Chlorine)',
    cas: '108171-26-2',
  },
  { haz: 'T', name: 'Chlorine', cas: '' },
  { haz: 'T', name: 'Chlorine dioxide', cas: '' },
  { haz: 'T', name: 'Chlorine pentafluoride', cas: '' },
  { haz: 'T', name: 'Chlorine trifluoride', cas: '' },
  { haz: 'T', name: 'Chlornaphazine', cas: '494-03-1' },
  { haz: 'T', name: 'Chloroacetaldehyde', cas: '107-20-0' },
  { haz: 'T', name: 'Chloroacetyl chloride', cas: '' },
  { haz: 'T', name: 'Chlorobenzene', cas: '108-90-7' },
  { haz: 'T', name: 'Chlorobenzilate', cas: '510-15-6' },
  { haz: 'T', name: 'Chlorochromic anhydride', cas: '' },
  { haz: 'T', name: 'Chlorodiphenyl (42% chlorine) {PCBs}', cas: '53469-21-9' },
  { haz: 'T', name: 'Chlorodiphenyl (54% chlorine) {PCBs}', cas: '11097-69-1' },
  { haz: 'T', name: 'Chloroethane', cas: '75-00-3' },
  { haz: 'T', name: 'Chloroethylene [1910.1017]', cas: '75-01-4' },
  { haz: 'T', name: 'Chloroform', cas: '67-66-3' },
  { haz: 'T', name: 'Chloroform-d {CDCl3}', cas: '865-49-6' },
  { haz: 'T,F', name: 'Chloromethyl Methyl Ether', cas: '107-30-2' },
  { haz: 'T', name: 'Chlorophenoxy Herbicides', cas: '0-06-0' },
  { haz: 'T', name: 'Chloropicrin', cas: '' },
  { haz: 'T', name: 'Chlorosulfonic acid', cas: '' },
  { haz: 'T', name: 'Chlorothalonil', cas: '1897-45-6' },
  { haz: 'T', name: 'Chlorotrianisene', cas: '569-57-3' },
  { haz: 'T', name: 'Chlorozotocin', cas: '54749-90-5' },
  { haz: 'T', name: 'Chrome Red, as Cr6+', cas: '18454-12-1' },
  {
    haz: 'T',
    name: 'Chromic Acetate, as Cr6+ [water-soluble]',
    cas: '1066-30-4',
  },
  { haz: 'T', name: 'Chromic Acid, as Cr6+ [water soluble]', cas: '1333-82-0' },
  {
    haz: 'T',
    name: 'Chromic Acid, as Cr6+ [water soluble]',
    cas: '12324-05-9',
  },
  {
    haz: 'T',
    name: 'Chromic Acid, as Cr6+ [water soluble]',
    cas: '12324-08-2',
  },
  { haz: 'T', name: 'Chromic acid, calcium salt', cas: '13765-19-0' },
  {
    haz: 'T',
    name: 'Chromic Chromate, as Cr6+ [water soluble]',
    cas: '24613-89-6',
  },
  { haz: 'T', name: 'Chromium', cas: '7440-47-3' },
  { haz: 'T', name: 'Chromium [VI] Chloride', cas: '14986-48-2' },
  {
    haz: 'T',
    name: 'Chromium and/or chromium (III) compounds',
    cas: '18540-29-9',
  },
  {
    haz: 'T',
    name: 'Chromium Carbonate, as Cr6+ [water-soluble]',
    cas: '29689-14-3',
  },
  { haz: 'T', name: 'Chromium Carbonyl, as Cr6+', cas: '13007-92-6' },
  { haz: 'T', name: 'Chromium Carbonyl, as Cr6+', cas: '13930-94-4' },
  { haz: 'T', name: 'Chromium Hexachloride, as Cr6+', cas: '14986-48-2' },
  {
    haz: 'T',
    name: 'Chromium Oxide, as Cr6+ [water soluble]',
    cas: '1333-82-0',
  },
  {
    haz: 'T',
    name: 'Chromium Oxide, as Cr6+ [water soluble]',
    cas: '12324-05-9',
  },
  {
    haz: 'T',
    name: 'Chromium Oxide, as Cr6+ [water soluble]',
    cas: '12324-08-2',
  },
  {
    haz: 'T',
    name: 'Chromium Phosphate, as Cr6+ [water-soluble]',
    cas: '7789-04-0',
  },
  {
    haz: 'T',
    name: 'Chromium Trioxide, as Cr6+ [water soluble]',
    cas: '1333-82-0',
  },
  {
    haz: 'T',
    name: 'Chromium Trioxide, as Cr6+ [water soluble]',
    cas: '12324-05-9',
  },
  {
    haz: 'T',
    name: 'Chromium Trioxide, as Cr6+ [water soluble]',
    cas: '12324-08-2',
  },
  { haz: 'T', name: 'Chromyl chloride', cas: '' },
  {
    haz: 'T',
    name: 'Chromyl Chloride, as Cr6+ [water soluble]',
    cas: '14977-61-8',
  },
  { haz: 'T', name: 'Chrysazin', cas: '117-10-2' },
  { haz: 'T', name: 'Chrysene', cas: '218-01-9' },
  { haz: 'T', name: 'Chrysotile', cas: '12001-29-5' },
  { haz: 'T', name: 'Ciclosporin', cas: '59865-13-3' },
  { haz: 'T', name: 'Ciclosporin', cas: '79217-60-0' },
  { haz: 'T', name: 'Cidofovir', cas: '113852-37-2' },
  { haz: 'T', name: 'Cinnamyl Anthranilate', cas: '87-29-6' },
  { haz: 'T', name: 'Cisplatin', cas: '15663-27-1' },
  { haz: 'T', name: 'Citrus Red No.2', cas: '6358-53-8' },
  { haz: 'T', name: 'Clofibrate', cas: '637-07-0' },
  { haz: 'T', name: 'CMME', cas: '' },
  {
    haz: 'T',
    name: 'Coal Tar Pitch Volatiles (as benzene solubles)',
    cas: '65996-93-2',
  },
  { haz: 'T', name: 'Coal Tars', cas: '8007-45-2' },
  {
    haz: 'T',
    name: 'Coal Tars & Extracts, and high-temp. coal tars',
    cas: '65996-89-6',
  },
  { haz: 'T', name: 'Cobalt (II) Acetate', cas: '71-48-7' },
  { haz: 'T', name: 'Cobalt (II) Acetate Tetrahydrate', cas: '6147-53-1' },
  { haz: 'T', name: 'Cobalt (II) Arsenate, as As3+', cas: '7785-24-2' },
  { haz: 'T', name: 'Cobalt (II) Bromide', cas: '7789-43-7' },
  { haz: 'T', name: 'Cobalt (II) Carbonate', cas: '513-79-1' },
  {
    haz: 'T',
    name: 'Cobalt (II) Carbonate Hydroxide (1:1)',
    cas: '12069-68-0',
  },
  {
    haz: 'T',
    name: 'Cobalt (II) Carbonate Hydroxide (2:3)',
    cas: '12602-23-2',
  },
  {
    haz: 'T',
    name: 'Cobalt (II) Carbonate Hydroxide (2:3) Monohydrate',
    cas: '51839-24-8',
  },
  { haz: 'T', name: 'Cobalt (II) Chloride', cas: '7646-79-9' },
  { haz: 'T', name: 'Cobalt (II) Chloride Hexahydrate', cas: '7791-13-1' },
  { haz: 'T', name: 'Cobalt (II) Chromate (III)', cas: '13455-25-9' },
  { haz: 'T,R', name: 'Cobalt (II) Cyanide', cas: '542-84-7' },
  { haz: 'T,C', name: 'Cobalt (II) Fluoride', cas: '10026-17-2' },
  { haz: 'T', name: 'Cobalt (II) Formate', cas: '544-18-3' },
  { haz: 'T,C', name: 'Cobalt (II) Hydroxide', cas: '21041-93-0' },
  { haz: 'T', name: 'Cobalt (II) Iodide', cas: '15238-00-3' },
  { haz: 'T', name: 'Cobalt (II) Molybdenum (VI) Oxide', cas: '13762-14-6' },
  { haz: 'T', name: 'Cobalt (II) Naphthenate', cas: '61789-51-3' },
  { haz: 'T', name: 'Cobalt (II) Nitrate', cas: '10141-05-6' },
  { haz: 'T', name: 'Cobalt (II) Nitrate Hexahydrate', cas: '10026-22-9' },
  { haz: 'T', name: 'Cobalt (II) Oxalate', cas: '814-89-1' },
  { haz: 'T', name: 'Cobalt (II) Oxide', cas: '1307-96-6' },
  { haz: 'T', name: 'Cobalt (II) Phosphate', cas: '13455-36-2' },
  { haz: 'T', name: 'Cobalt (II) Potassium Sulfate', cas: '13596-22-0' },
  { haz: 'T', name: 'Cobalt (II) Sulfate', cas: '10124-43-3' },
  { haz: 'T', name: 'Cobalt (II) Sulfide', cas: '1317-42-6' },
  { haz: 'T', name: 'Cobalt (II) Thiocyanate', cas: '3017-60-5' },
  { haz: 'T', name: 'Cobalt (II, III) Oxide', cas: '1308-06-1' },
  { haz: 'T', name: 'Cobalt (III) Acetate', cas: '917-69-1' },
  { haz: 'T', name: 'Cobalt (III) Fluoride', cas: '10026-18-3' },
  { haz: 'T,C', name: 'Cobalt (III) Hydroxide', cas: '1307-86-4' },
  { haz: 'T', name: 'Cobalt (III) Oxide', cas: '1308-04-9' },
  { haz: 'T', name: 'Cobalt (III) Oxide Monohydrate', cas: '12016-80-7' },
  { haz: 'T', name: 'Cobalt (III) Potassium Nitrite', cas: '13782-01-9' },
  { haz: 'T', name: 'Cobalt and/or cobalt compounds', cas: '' },
  { haz: 'T', name: 'Cobalt Carbonyl, as Co', cas: '10210-68-1' },
  { haz: 'T', name: 'Cobalt Chromium Alloy, as Cr6+', cas: '11114-92-4' },
  { haz: 'T', name: 'Cobalt Hydrocarbonyl, as Co', cas: '16842-03-8' },
  {
    haz: 'T',
    name: 'Cobalt metal powder & Co compounds, as Co - [see specific compound]',
    cas: '7440-48-4',
  },
  { haz: 'T', name: 'Cobalt Monoxide', cas: '1307-96-6' },
  { haz: 'T', name: 'Cobalt Sulfate Heptahydrate', cas: '10026-24-1' },
  { haz: 'T', name: 'Coffee (urinary bladder only)', cas: '0-47-0' },
  { haz: 'T', name: 'Coke Oven Emissions {PAH}', cas: '0-07-0' },
  { haz: 'T', name: 'Compound 1080', cas: '' },
  { haz: 'T', name: 'Compound 1836', cas: '' },
  { haz: 'T', name: 'Compound 4072', cas: '' },
  { haz: 'T', name: 'Copper (II) Acetoarsenite', cas: '12002-03-8' },
  {
    haz: 'T',
    name: 'Copper (II) Dichromate, as Cr6+ [water soluble]',
    cas: '0-08-0',
  },
  { haz: 'T', name: 'Copper acetoarsenite', cas: '' },
  { haz: 'T', name: 'Copper and/or copper compounds', cas: '' },
  { haz: 'T', name: 'Copper arsenate', cas: '' },
  { haz: 'T', name: 'Copper arsenite', cas: '' },
  { haz: 'T', name: 'Copper Chromate Oxide, as Cr6+', cas: '1308-09-4' },
  { haz: 'T', name: 'Copper Chromate Oxide, as Cr6+', cas: '18906-50-8' },
  { haz: 'T', name: 'Copper Chromate, as Cr6+', cas: '13548-42-0' },
  { haz: 'T,R', name: 'Copper cyanide', cas: '544-92-3' },
  { haz: 'T', name: 'Coroxon', cas: '' },
  { haz: 'T', name: 'Coumafuryl', cas: '' },
  { haz: 'T', name: 'Coumatetralyl', cas: '' },
  { haz: 'T', name: 'Creosote', cas: '' },
  { haz: 'T', name: 'Creosotes (coal)', cas: '8001-58-9' },
  { haz: 'T', name: 'Creosotes (wood)', cas: '8021-39-4' },
  { haz: 'T', name: 'Cresol (Cresylic acid)', cas: '1319-77-3' },
  { haz: 'T', name: 'Crimidine', cas: '' },
  {
    haz: 'T',
    name: 'Cristobalite {Silica (respirable) - Crystalline}',
    cas: '14464-46-1',
  },
  { haz: 'T', name: 'Crocidolite', cas: '12001-28-4' },
  { haz: 'T', name: 'Crotonaldehyde', cas: '4170-30-3' },
  { haz: 'T', name: 'Cumene', cas: '98-82-8' },
  { haz: 'T', name: 'Cupferron', cas: '135-20-6' },
  { haz: 'T', name: 'Cupric Acetoarsenite', cas: '12002-03-8' },
  { haz: 'T', name: 'Cupric arsenate', cas: '' },
  { haz: 'T', name: 'Cupric Arsenite', cas: '10290-12-7' },
  { haz: 'T', name: 'Cupric Chromate, as Cr6+', cas: '13548-42-0' },
  {
    haz: 'T, R',
    name: 'Cyanides (soluble cyanide salts), not otherwise specified',
    cas: '',
  },
  { haz: 'T', name: 'Cyanogen', cas: '460-19-5' },
  { haz: 'T', name: 'Cyanogen bromide (CN)Br', cas: '506-68-3' },
  { haz: 'T', name: 'Cyanogen chloride', cas: '506-77-4' },
  { haz: 'T', name: 'Cyanophenphos', cas: '' },
  { haz: 'T', name: 'Cycasin', cas: '14901-08-7' },
  { haz: 'T', name: 'Cyclohexane', cas: '110-82-7' },
  {
    haz: 'T',
    name: 'Cyclohexane, 1,2,3,4,5,6-hexachloro,(1alpha,2alpha,3beta,4alpha,5alpha,6beta)-',
    cas: '58-89-9',
  },
  { haz: 'T', name: 'Cyclohexanone', cas: '108-94-1' },
  { haz: 'T', name: 'Cyclohexenyltrichlorosilane', cas: '' },
  { haz: 'T', name: 'Cycloheximide', cas: '' },
  { haz: 'T', name: 'Cyclohexyltrichlorosilane', cas: '' },
  { haz: 'T', name: 'Cyclophosphamide (anhydrous)', cas: '50-18-0' },
  { haz: 'T', name: 'Cyclophosphamide (hydrated)', cas: '6055-19-2' },
  { haz: 'T', name: 'Cyclosporin', cas: '79217-60-0' },
  { haz: 'T', name: 'Cyclosporin A', cas: '59865-13-3' },
  { haz: 'T', name: 'CYOLAN', cas: '' },
  { haz: 'T', name: 'Cytembena', cas: '21739-91-3' },
  { haz: 'T', name: 'D&C Orange No. 17', cas: '3468-63-1' },
  { haz: 'T', name: 'D&C Red No. 19', cas: '81-88-9' },
  { haz: 'T', name: 'D&C Red No. 8', cas: '2092-56-0' },
  { haz: 'T', name: 'D&C Red No. 9', cas: '5160-02-1' },
  { haz: 'T', name: 'Dacarbazine', cas: '4342-03-4' },
  { haz: 'T', name: 'Daminozide', cas: '1596-84-5' },
  { haz: 'T', name: 'Dantron', cas: '117-10-2' },
  { haz: 'T', name: 'DASANIT', cas: '' },
  { haz: 'T', name: 'Daunomycin', cas: '20830-81-3' },
  { haz: 'T', name: 'DBCP', cas: '' },
  { haz: 'T', name: 'DBCP [1910.1044]', cas: '96-12-8' },
  { haz: 'T', name: 'DCB', cas: '91-94-1' },
  { haz: 'T', name: 'DDD', cas: '72-54-8' },
  { haz: 'T', name: 'DDE', cas: '72-55-9' },
  { haz: 'T', name: 'DDT', cas: '50-29-3' },
  { haz: 'T', name: 'DDVP', cas: '62-73-7' },
  { haz: 'T', name: 'DEAC', cas: '' },
  { haz: 'T', name: 'Decaborane', cas: '' },
  { haz: 'T', name: 'Decabromobiphenyl {PBBs}', cas: '13654-09-6' },
  { haz: 'T', name: 'DEHP', cas: '117-81-7' },
  { haz: 'T', name: 'DELNAV', cas: '' },
  { haz: 'T', name: 'Demeton', cas: '' },
  { haz: 'T', name: 'Demeton-S-methyl sulfone', cas: '' },
  { haz: 'T', name: 'DEN', cas: '55-18-5' },
  { haz: 'T', name: 'DES', cas: '56-53-1' },
  {
    haz: 'T',
    name: 'D-Glucose, 2-deoxy-2-[[(methylnitrosoamino)-carbonyl]amino]-',
    cas: '18883-66-4',
  },
  { haz: 'T', name: 'DGRE', cas: '101-90-6' },
  { haz: 'T', name: 'di(2-Ethylhexyl) Phthalate', cas: '117-81-7' },
  { haz: 'T', name: 'Diallate', cas: '2303-16-4' },
  { haz: 'T', name: 'Diaminotoluene (mixed)', cas: '0-74-0' },
  {
    haz: 'T',
    name: 'Diammonium Molybdate, as Mo [water soluble]',
    cas: '27546-07-2',
  },
  { haz: 'T', name: 'Diazomethane', cas: '334-88-3' },
  { haz: 'T', name: 'Dibenz[a,h]acridine {PAH}', cas: '226-36-8' },
  { haz: 'T', name: 'Dibenz[a,h]anthracene {PAH}', cas: '53-70-3' },
  { haz: 'T', name: 'Dibenz[a,j]acridine {PAH}', cas: '224-42-0' },
  { haz: 'T', name: 'Dibenzo[a,e]pyrene {PAH}', cas: '192-65-4' },
  { haz: 'T', name: 'Dibenzo[a,h]pyrene {PAH}', cas: '189-64-0' },
  { haz: 'T', name: 'Dibenzo[a,i]pyrene {PAH}', cas: '189-55-9' },
  { haz: 'T', name: 'Dibenzo[a,l]pyrene {PAH}', cas: '191-30-0' },
  { haz: 'T', name: 'Diboron Hexahydride', cas: '' },
  { haz: 'T', name: 'Dibutyl phthalate', cas: '84-74-2' },
  { haz: 'T', name: 'Dichloroacetic Acid', cas: '79-43-6' },
  { haz: 'T', name: 'Dichlorodifluoromethane', cas: '75-71-8' },
  { haz: 'T', name: 'Dichlorodimethylsilane', cas: '' },
  { haz: 'T', name: 'Dichlorodiphenyldichloroethane', cas: '72-54-8' },
  { haz: 'T', name: 'Dichlorodiphenyldichloroethylene', cas: '72-55-9' },
  { haz: 'T', name: 'Dichlorodiphenyltrichloroethane', cas: '50-29-3' },
  { haz: 'T,F', name: 'Dichloroethyl ether', cas: '111-44-4' },
  { haz: 'T', name: 'Dichloroethylarsine', cas: '' },
  { haz: 'T', name: 'Dichloroisopropyl ether', cas: '108-60-1' },
  { haz: 'T', name: 'Dichloromethane [1910.1052]', cas: '75-09-2' },
  {
    haz: 'T',
    name: 'Dichloromethane-d2 {CD2Cl2} [1910.1052]',
    cas: '1665-00-5',
  },
  { haz: 'T', name: 'Dichloromethoxy ethane', cas: '111-91-1' },
  { haz: 'T,F', name: 'Dichloromethyl ether', cas: '542-88-1' },
  { haz: 'T', name: 'Dichlorophenylarsine', cas: '696-28-6' },
  { haz: 'T', name: 'Dichlorvos', cas: '62-73-7' },
  { haz: 'T', name: 'Dicobalt Octacarbonyl, as Co', cas: '10210-68-1' },
  { haz: 'T', name: 'Dieldrin', cas: '60-57-1' },
  { haz: 'T', name: 'Dienestrol', cas: '84-17-3' },
  { haz: 'T', name: 'Diepoxybutane', cas: '1464-53-5' },
  {
    haz: 'T',
    name: 'Diesel Engine Emissions {particulate, <1 µm}',
    cas: '0-29-0',
  },
  { haz: 'T', name: 'Diesel Fuel #4', cas: '0-18-0' },
  { haz: 'T', name: 'Diesel Fuel, Marine', cas: '0-18-0' },
  { haz: 'T', name: 'Diethyl chlorovinyl phosphate', cas: '' },
  { haz: 'T', name: 'Diethyl phthalate', cas: '84-66-2' },
  { haz: 'T', name: 'Diethylaluminum chloride', cas: '' },
  { haz: 'T', name: 'Diethylarsine', cas: '692-42-2' },
  { haz: 'T,R', name: 'Diethyldichlorosilane', cas: '' },
  { haz: 'T', name: 'Diethylene glycol, dicarbamate.', cas: '5952-26-1' },
  { haz: 'T', name: 'Diethylhexyl phthalate', cas: '117-81-7' },
  { haz: 'T', name: 'Diethylnitrosamine', cas: '55-18-5' },
  { haz: 'T', name: 'Diethyl-p-nitrophenyl phosphate', cas: '311-45-5' },
  { haz: 'T', name: 'Diethylstilbestrol', cas: '56-53-1' },
  { haz: 'T', name: 'Diethylsulfate', cas: '64-67-5' },
  { haz: 'T', name: 'Diethylzinc', cas: '' },
  { haz: 'T', name: 'Difluorophosphoric acid', cas: '' },
  { haz: 'T', name: 'Diglycidyl Resorcinol Ether', cas: '101-90-6' },
  { haz: 'T', name: 'Dihydrosafrole', cas: '94-58-6' },
  { haz: 'T', name: 'Diisopropylfluorophosphate (DFP)', cas: '55-91-4' },
  { haz: 'T', name: 'Diisopropylsulfate', cas: '2973-10-6' },
  { haz: 'T', name: 'DIMECRON', cas: '' },
  { haz: 'T', name: 'Dimefox', cas: '' },
  { haz: 'T', name: 'Dimethoate', cas: '60-51-5' },
  { haz: 'T', name: 'Dimethoxystrychnine', cas: '' },
  {
    haz: 'T',
    name: 'Dimethyl 3-hydroxyglutaconate dimethylphosphate',
    cas: '',
  },
  { haz: 'T', name: 'Dimethyl dichlorovinylphosphate', cas: '' },
  { haz: 'T', name: 'Dimethyl nitrosoamine', cas: '' },
  { haz: 'T', name: 'Dimethyl phthalate', cas: '131-11-3' },
  { haz: 'T', name: 'Dimethyl sulfate', cas: '77-78-1' },
  { haz: 'T,R', name: 'Dimethyl sulfide', cas: '' },
  { haz: 'T', name: 'Dimethylamine', cas: '124-40-3' },
  { haz: 'T', name: 'Dimethylaminoazobenzene', cas: '' },
  { haz: 'T', name: 'Dimethylarsinic acid', cas: '' },
  { haz: 'T', name: 'Dimethylbenzylhydroperoxide', cas: '80-15-9' },
  { haz: 'T', name: 'Dimethylcarbamoyl Chloride', cas: '79-44-7' },
  { haz: 'T', name: 'Dimethyldichlorosilane', cas: '' },
  { haz: 'T', name: 'Dimethylsulfate', cas: '77-78-1' },
  { haz: 'T', name: 'Dimethylvinyl Chloride', cas: '513-37-1' },
  { haz: 'T', name: 'Dimetilan.', cas: '644-64-4' },
  { haz: 'T', name: 'Dinitrobenzene (ortho, meta, para)', cas: '' },
  { haz: 'T', name: 'Di-n-octyl phthalate', cas: '117-84-0' },
  { haz: 'T', name: 'DINOSEB', cas: '88-85-7' },
  { haz: 'T', name: 'di-n-Propyl Isocinchomeronate', cas: '136-45-8' },
  { haz: 'T', name: 'Di-n-propylnitrosamine', cas: '621-64-7' },
  { haz: 'T', name: 'Dioxathion', cas: '' },
  { haz: 'T', name: 'Dioxin (2,3,7,8-TCDD)', cas: '' },
  { haz: 'T', name: 'Diphenyl', cas: '' },
  { haz: 'T', name: 'Diphenylamine chloroarsine', cas: '' },
  { haz: 'T', name: 'Diphenyldichlorosilane', cas: '' },
  { haz: 'T', name: 'Diphenylhydantoin', cas: '57-41-0' },
  { haz: 'T', name: 'Diphenylhydantoin (sodium salt)', cas: '630-93-3' },
  { haz: 'T', name: 'Diphosphoramide, octamethyl-', cas: '152-16-9' },
  { haz: 'T', name: 'Diphosphoric acid, tetraethyl ester', cas: '107-49-3' },
  { haz: 'T', name: 'Dipropylamine', cas: '142-84-7' },
  { haz: 'T', name: 'Direct Black 38 (technical grade)', cas: '1937-37-7' },
  { haz: 'T', name: 'Direct Black GX', cas: '1937-37-7' },
  { haz: 'T', name: 'Direct Blue 6 (technical grade)', cas: '2602-46-2' },
  { haz: 'T', name: 'Direct Brown 95 (technical grade)', cas: '16071-86-6' },
  { haz: 'T', name: 'di-sec-Octylphthalate', cas: '117-81-7' },
  { haz: 'T', name: 'Disodium Arsenate', cas: '7778-43-0' },
  { haz: 'T', name: 'Disodium Arsenate Heptahydrate', cas: '10048-95-0' },
  { haz: 'T', name: 'Disodium Hydrogen Arsenate', cas: '10048-95-0' },
  { haz: 'T', name: 'Disperse Blue 1', cas: '2475-45-8' },
  { haz: 'T', name: 'Disulfoton', cas: '298-04-4' },
  { haz: 'T', name: 'Disulfuryl chloride', cas: '' },
  { haz: 'T', name: 'Dithiobiuret', cas: '541-53-7' },
  { haz: 'T', name: 'DITHIONE', cas: '' },
  { haz: 'T', name: 'DMN', cas: '62-75-9' },
  { haz: 'T', name: 'DNPC', cas: '' },
  { haz: 'T', name: 'Dodecyltrichlorosilane', cas: '' },
  { haz: 'T', name: "Donovan's Solution, as As3+", cas: '8012-54-2' },
  { haz: 'T', name: 'DOWCO-139', cas: '' },
  { haz: 'T', name: 'DOWICIDE I', cas: '' },
  { haz: 'T', name: 'Doxorubicin Hydrochloride', cas: '23214-92-8' },
  { haz: 'T', name: 'Doxorubicin Hydrochloride', cas: '25316-40-9' },
  { haz: 'T', name: 'Dyes that metabolize to benzidine', cas: '0-77-0' },
  { haz: 'T', name: 'DYFONATE', cas: '' },
  { haz: 'T', name: 'E Dinitrophenol(2,3-;2,4-;2,6-isomers)', cas: '' },
  { haz: 'T', name: 'EDB', cas: '106-93-4' },
  { haz: 'T', name: 'Endosulfan', cas: '115-29-7' },
  { haz: 'T', name: 'Endothall', cas: '145-73-3' },
  { haz: 'T', name: 'Endothion', cas: '' },
  { haz: 'T', name: 'Endrin', cas: '72-20-8' },
  { haz: 'T', name: 'ENU', cas: '759-73-9' },
  { haz: 'T', name: 'Epichlorohydrin', cas: '106-89-8' },
  { haz: 'T', name: 'Epinephrine', cas: '51-43-4' },
  { haz: 'T', name: 'Epoxystyrene', cas: '96-09-3' },
  { haz: 'T', name: 'Erionite', cas: '12510-42-8' },
  { haz: 'T', name: 'Erionite', cas: '66733-21-9' },
  { haz: 'T', name: 'Estradiol-17B', cas: '50-28-2' },
  { haz: 'T', name: 'Estragole', cas: '140-67-0' },
  { haz: 'T', name: 'Estrogens, Conjugated (Indirect)', cas: '0-10-0' },
  { haz: 'T', name: 'Estrogens, Nonsteroidal and Steroidal', cas: '0-11-0' },
  { haz: 'T', name: 'Estrone', cas: '53-16-7' },
  { haz: 'T', name: 'Estropipate', cas: '7280-37-7' },
  { haz: 'T', name: 'Ethanal', cas: '75-07-0' },
  { haz: 'T', name: 'Ethanamine, N,N-diethyl-', cas: '121-44-8' },
  { haz: 'T', name: 'Ethanamine, N-ethyl-N-nitroso-', cas: '55-18-5' },
  { haz: 'T', name: 'Ethane, 1,1,1,2-tetrachloro-', cas: '630-20-6' },
  { haz: 'T', name: 'Ethane, 1,1,1-trichloro-', cas: '71-55-6' },
  { haz: 'T', name: 'Ethane, 1,1,2,2-tetrachloro-', cas: '79-34-5' },
  { haz: 'T', name: 'Ethane, 1,1,2-trichloro-', cas: '79-00-5' },
  {
    haz: 'T',
    name: "Ethane, 1,1'-[methylenebis(oxy)]bis[2-chloro-",
    cas: '111-91-1',
  },
  { haz: 'T', name: 'Ethane, 1,1-dichloro-', cas: '75-34-3' },
  { haz: 'T', name: "Ethane, 1,1'-oxybis-", cas: '60-29-7' },
  { haz: 'T', name: "Ethane, 1,1'-oxybis[2-chloro-", cas: '111-44-4' },
  { haz: 'T', name: 'Ethane, 1,2-dibromo-', cas: '06-93-4' },
  { haz: 'T', name: 'Ethane, 1,2-dichloro-', cas: '107-06-2' },
  { haz: 'T', name: 'Ethane, hexachloro-', cas: '67-72-1' },
  { haz: 'T', name: 'Ethane, pentachloro-', cas: '76-01-7' },
  { haz: 'T', name: 'Ethanedinitrile', cas: '460-19-5' },
  { haz: 'T', name: 'Ethanethioamide', cas: '62-55-5' },
  { haz: 'T', name: 'Ethanethiol', cas: '' },
  {
    haz: 'T',
    name: 'Ethanimidothioc acid, 2-(dimethylamino)-N[[(methylamino)carbonyl]oxy]-2-oxo-, methyl ester.',
    cas: '23135-22-0',
  },
  {
    haz: 'T',
    name: 'Ethanimidothioic acid, 2-(dimethylamino)-N-hydroxy-2-oxo-,methyl ester.',
    cas: '30558-43-1',
  },
  {
    haz: 'T',
    name: "Ethanimidothioic acid, N,N'-[thiobis[(methylimino)carbonyloxy]]bis-dimethyl ester",
    cas: '59669-26-0',
  },
  {
    haz: 'T',
    name: 'Ethanimidothioic acid,N-[[(methylamino)carbonyl]oxy]-,methyl ester',
    cas: '16752-77-5',
  },
  { haz: 'T', name: "Ethanol, 2,2'-(nitrosoimino)bis-", cas: '1116-54-7' },
  { haz: 'T', name: "Ethanol, 2,2'-oxybis-, dicarbamate.", cas: '5952-26-1' },
  { haz: 'T', name: 'Ethanol, 2-ethoxy-', cas: '110-80-5' },
  { haz: 'T', name: 'Ethanone, 1-phenyl-', cas: '98-86-2' },
  { haz: 'T', name: 'Ethene, (2-chloroethoxy)-', cas: '110-75-8' },
  { haz: 'T', name: 'Ethene, 1,1-dichloro-', cas: '75-35-4' },
  { haz: 'T', name: 'Ethene, 1,2-dichloro-, (E)-', cas: '156-60-5' },
  { haz: 'T', name: 'Ethene, chloro-', cas: '75-01-4' },
  { haz: 'T', name: 'Ethene, tetrachloro-', cas: '127-18-4' },
  { haz: 'T', name: 'Ethene, trichloro-', cas: '79-01-6' },
  { haz: 'T', name: 'Ethinylestradiol', cas: '57-63-6' },
  { haz: 'T', name: 'Ethion', cas: '' },
  { haz: 'T', name: 'Ethoprop', cas: '13194-48-4' },
  { haz: 'T,F', name: 'Ethyl acetate', cas: '141-78-6' },
  { haz: 'T', name: 'Ethyl Acrylate', cas: '140-88-5' },
  { haz: 'T', name: 'Ethyl Carbamate', cas: '51-79-6' },
  { haz: 'T', name: 'Ethyl Chloride', cas: '75-00-3' },
  { haz: 'T', name: 'Ethyl Chlorocarbonate', cas: '' },
  { haz: 'T', name: 'Ethyl chloroformate', cas: '' },
  { haz: 'T,R', name: 'Ethyl cyanide', cas: '107-12-0' },
  { haz: 'T,F', name: 'Ethyl ether', cas: '60-29-7' },
  { haz: 'T,F', name: 'Ethyl mercaptan', cas: '' },
  { haz: 'T', name: 'Ethyl methacrylate', cas: '97-63-2' },
  { haz: 'T', name: 'Ethyl Methanesulfonate', cas: '62-50-0' },
  { haz: 'T', name: "Ethyl-4,4'-dichlorobenzilate", cas: '510-15-6' },
  { haz: 'T,F', name: 'Ethylbenzene', cas: '100-41-4' },
  { haz: 'T', name: 'Ethyldichloroarsine', cas: '' },
  { haz: 'T,R', name: 'Ethyldichlorosilane', cas: '' },
  { haz: 'T', name: 'Ethylene cyanohydrin', cas: '' },
  { haz: 'T', name: 'Ethylene Dibromide', cas: '106-93-4' },
  { haz: 'T', name: 'Ethylene Dichloride', cas: '107-06-2' },
  { haz: 'T', name: 'Ethylene glycol monoethyl ether', cas: '110-80-5' },
  { haz: 'T', name: 'Ethylene oxide', cas: '75-21-8' },
  { haz: 'T', name: 'Ethylene Thiourea', cas: '96-45-7' },
  {
    haz: 'T',
    name: 'Ethylenebisdithiocarbamic acid, salts & esters',
    cas: '111-54-6',
  },
  { haz: 'T', name: 'Ethyleneimine', cas: '151-56-4' },
  { haz: 'T', name: 'Ethylidene dichloride', cas: '75-34-3' },
  { haz: 'T', name: 'Ethylphenyldichlorosilane', cas: '' },
  { haz: 'T,R', name: 'Ethyltrichlorosilane', cas: '' },
  { haz: 'T', name: 'Etoposide', cas: '33419-42-0' },
  {
    haz: '',
    name: 'exo-3-Chloro-endo-6-cyano-2-norbornanone-O- (methylcarbamoyl) oxime',
    cas: '',
  },
  { haz: 'T', name: 'EXOTHION', cas: '' },
  { haz: 'T', name: 'FAC', cas: '' },
  { haz: 'T', name: 'Famphur', cas: '52-85-7' },
  { haz: 'T', name: 'Fenoxycarb', cas: '72490-01-8' },
  { haz: 'T', name: 'Fensulfothion', cas: '' },
  { haz: 'T', name: 'Ferric arsenate', cas: '' },
  { haz: 'T', name: 'Ferric Chromate, as Cr6+', cas: '10294-52-7' },
  { haz: 'T', name: 'Ferrous arsenate', cas: '' },
  { haz: 'T', name: 'Firemaster BP-6 {PBBs}', cas: '59536-65-1' },
  { haz: 'T', name: 'Firemaster FF-1 {PBBs}', cas: '67774-32-7' },
  { haz: 'T', name: 'Fluoboric acid', cas: '' },
  { haz: 'T', name: 'Fluoranthene', cas: '206-44-0' },
  { haz: 'T', name: 'Fluorine', cas: '7782-41-4' },
  { haz: 'T', name: 'Fluoroacetamide', cas: '640-19-7' },
  { haz: 'T', name: 'Fluoroacetanilide', cas: '' },
  { haz: 'T', name: 'Fluoroacetic acid, sodium salt', cas: '62-74-8' },
  { haz: 'T', name: 'Fluoroboric Acid', cas: '' },
  { haz: 'T', name: 'Fluorsulfonic Acid', cas: '' },
  { haz: 'T', name: 'Folpet', cas: '133-07-3' },
  { haz: 'T', name: 'Fonofos', cas: '' },
  { haz: 'T', name: 'Formaldehyde', cas: '50-00-0' },
  { haz: 'T', name: 'Formetanate hydrochloride.', cas: '23422-53-9' },
  { haz: 'T', name: 'Formic acid', cas: '64-18-6' },
  { haz: 'T', name: 'Formparanate.', cas: '17702-57-7' },
  { haz: 'T', name: 'FOSTION', cas: '' },
  { haz: 'T', name: "Fowler's Solution, as As3+", cas: '1327-53-3' },
  { haz: 'T', name: 'Fuel Oil, Residual (Heavy)', cas: '68476-33-5' },
  { haz: 'T', name: 'Fulminic acid, mercury(2+) salt', cas: '628-86-4' },
  { haz: 'T', name: 'FUMARIN', cas: '' },
  { haz: 'T', name: 'Fumazone', cas: '' },
  { haz: 'T', name: 'Fuming Sulfuric Acid', cas: '' },
  { haz: 'T', name: 'FURADAN', cas: '' },
  { haz: 'T', name: 'Furan', cas: '110-00-9' },
  { haz: 'T,F', name: 'Furan, tetrahydro-', cas: '109-99-9' },
  { haz: 'T', name: 'Furathiazole', cas: '531-82-8' },
  { haz: 'T', name: 'Furazolidone', cas: '67-45-8' },
  { haz: 'T', name: 'Furfural', cas: '98-01-1' },
  { haz: 'T', name: 'Furfuran', cas: '110-00-9' },
  { haz: 'T', name: 'Furmecyclox', cas: '60568-05-0' },
  { haz: 'T', name: 'Furniture and Cabinet Making', cas: '0-60-0' },
  { haz: 'T', name: 'Fusarin C', cas: '79748-81-5' },
  { haz: 'T', name: 'Gallium Arsenide', cas: '1303-00-0' },
  { haz: 'T', name: 'Gamma Radiation', cas: '0-80-0' },
  { haz: 'T', name: 'gamma-Hexachlorocyclohexane', cas: '58-89-9' },
  { haz: 'T', name: 'Ganciclovir Sodium', cas: '82410-32-0' },
  { haz: 'T,F', name: 'Gasoline', cas: '8006-61-9' },
  {
    haz: '',
    name: 'Gasoline Engine Exhaust (condensates/extracts)',
    cas: '0-48-0',
  },
  { haz: 'T,F', name: 'Gasoline, Unleaded (wholly vaporized)', cas: '0-73-0' },
  { haz: 'T', name: 'GB', cas: '' },
  { haz: 'T', name: 'Gemfibrozil', cas: '25812-30-0' },
  { haz: 'T', name: 'Glasswool', cas: '0-14-0' },
  {
    haz: 'T',
    name: 'Glucopyranose, 2-deoxy-2-(3-methyl-3-nitrosoureido)-, D-',
    cas: '18883-66-4',
  },
  {
    haz: 'T',
    name: "Glu-P-1(2-Amino-6-methyldipyrido[1,2-a:3',2'-d]imidazole)",
    cas: '67730-11-4',
  },
  {
    haz: 'T',
    name: "Glu-P-2(2-Aminodipyrido[1,2-a:3',2'-d]imidazole)",
    cas: '67730-10-3',
  },
  { haz: 'T', name: 'Glycidaldehyde', cas: '765-34-4' },
  { haz: 'T', name: 'Glycidol', cas: '556-52-5' },
  { haz: 'T', name: 'Glycidylaldehyde', cas: '765-34-4' },
  { haz: 'T', name: 'Griseofulvin', cas: '126-07-8' },
  { haz: 'T', name: "Guanidine, N-methyl-N'-nitro-N-nitroso-", cas: '70-25-7' },
  { haz: 'T', name: 'Guthion', cas: '' },
  { haz: 'T', name: 'Gyromitrin', cas: '16568-02-8' },
  { haz: 'T', name: 'Hanane', cas: '' },
  { haz: 'T', name: 'HC Blue No.1', cas: '2784-94-3' },
  { haz: 'T', name: 'Heptachlor', cas: '76-44-8' },
  { haz: 'T', name: 'Heptachlor Epoxide', cas: '1024-57-3' },
  { haz: 'T', name: 'Hexabromobiphenyl {PBBs}', cas: '67774-32-7' },
  { haz: 'T', name: 'Hexachlorobenzene', cas: '118-74-1' },
  { haz: 'T', name: 'Hexachlorobutadiene', cas: '87-68-3' },
  {
    haz: 'T',
    name: 'Hexachlorocyclohexane (technical grade)',
    cas: '608-73-1',
  },
  { haz: 'T', name: 'Hexachlorocyclopentadiene', cas: '77-47-4' },
  { haz: 'T', name: 'Hexachlorodibenzodioxin', cas: '34465-46-8' },
  { haz: 'T', name: 'Hexachloroethane', cas: '67-72-1' },
  { haz: 'T', name: 'Hexachlorophene', cas: '70-30-4' },
  { haz: 'T', name: 'Hexachloropropene', cas: '1888-71-7' },
  { haz: 'T', name: 'Hexadecyltrichlorosilane', cas: '' },
  { haz: 'T', name: 'Hexaethyl tetraphosphate', cas: '757-58-4' },
  { haz: 'T', name: 'Hexamethylphosphoramide', cas: '680-31-9' },
  { haz: 'T', name: 'Hexyltrichlorosilane', cas: '' },
  { haz: 'T,R', name: 'Hydrazine', cas: '302-01-2' },
  { haz: 'T', name: 'Hydrazine Sulfate', cas: '10034-93-2' },
  { haz: 'T', name: 'Hydrazine, 1,1-dimethyl-', cas: '57-14-7' },
  { haz: 'T', name: 'Hydrazine, 1,2-diethyl-', cas: '1615-80-1' },
  { haz: 'T', name: 'Hydrazine, 1,2-dimethyl-', cas: '540-73-8' },
  { haz: 'T', name: 'Hydrazine, 1,2-diphenyl-', cas: '122-66-7' },
  { haz: 'T', name: 'Hydrazine, Diamine', cas: '' },
  { haz: 'T', name: 'Hydrazine, methyl-', cas: '60-34-4' },
  { haz: 'T', name: 'Hydrazinecarbothioamide', cas: '79-19-6' },
  { haz: 'T', name: 'Hydrazobenzene', cas: '122-66-7' },
  { haz: 'T', name: 'Hydriodic acid', cas: '' },
  { haz: 'T,C', name: 'Hydrobromic acid', cas: '' },
  { haz: 'T,C', name: 'Hydrochloric acid', cas: '' },
  { haz: 'T,C', name: 'Hydrocyanic acid', cas: '74-90-8' },
  { haz: 'T,C', name: 'Hydrofluoric acid', cas: '7664-39-3' },
  { haz: 'T,C', name: 'Hydrogen Bromide', cas: '' },
  { haz: 'T,C', name: 'Hydrogen Chloride', cas: '' },
  { haz: 'T,R', name: 'Hydrogen cyanide', cas: '74-90-8' },
  { haz: 'T,C', name: 'Hydrogen fluoride', cas: '7664-39-3' },
  { haz: 'T', name: 'Hydrogen Iodide', cas: '' },
  { haz: 'T', name: 'Hydrogen phosphide', cas: '7803-51-2' },
  { haz: 'T', name: 'Hydrogen selenide', cas: '' },
  { haz: 'T,R', name: 'Hydrogen sulfide', cas: '7783-06-4' },
  { haz: 'T', name: 'Hydroperoxide, 1-methyl-1-phenylethyl-', cas: '80-15-9' },
  { haz: 'T', name: 'Hypochlorite compounds', cas: '' },
  { haz: 'T', name: 'Indeno[1,2,3-cd]pyrene', cas: '193-39-5' },
  { haz: 'T', name: 'Indeno[1,2,3-cd]pyrene {PAH}', cas: '193-39-5' },
  { haz: 'T', name: 'Indium Phosphide', cas: '22398-80-7' },
  { haz: 'T', name: 'Inerteen', cas: '' },
  {
    haz: 'T',
    name: 'Inorganic Arsenic [1910.1018] - [see specific compound]',
    cas: '7440-38-2',
  },
  { haz: 'T', name: 'Iprodione', cas: '36734-19-7' },
  {
    haz: 'T',
    name: 'IQ(2-Amino-3-methylimidazo[4,5-f]quinoline)',
    cas: '76180-96-6',
  },
  { haz: 'T', name: 'Iron (III) Chromate, as Cr6+', cas: '10294-52-7' },
  {
    haz: 'T',
    name: 'Iron (III) Dichromate, as Cr6+ [water soluble]',
    cas: '10294-53-8',
  },
  { haz: 'T', name: 'Iron and Steel Founding', cas: '0-61-0' },
  { haz: 'T', name: 'Iron Arsenate', cas: '' },
  { haz: 'T', name: 'Iron Dextran Complex', cas: '9004-66-4' },
  { haz: 'T', name: 'Isobenzan', cas: '' },
  { haz: 'T,C', name: 'Isobutyl alcohol', cas: '78-83-1' },
  { haz: 'T', name: 'Isobutyl Nitrite', cas: '542-56-3' },
  { haz: 'T', name: 'Isodrin', cas: '465-73-6' },
  { haz: 'T', name: 'Isolan.', cas: '119-38-0' },
  { haz: 'T', name: 'Isopentadiene', cas: '78-79-5' },
  { haz: 'T', name: 'Isoprene', cas: '78-79-5' },
  {
    haz: 'T',
    name: 'Isopropyl Alcohol Manufacture (strong-acid process)',
    cas: '0-16-0',
  },
  { haz: 'T', name: 'Isosafrole', cas: '120-58-1' },
  { haz: 'T', name: 'Isoxaflutole', cas: '141112-29-0' },
  { haz: 'T', name: 'Kanechlor  500 {PCBs}', cas: '37317-41-2' },
  { haz: 'T', name: 'Kepone', cas: '143-50-0' },
  { haz: 'T', name: 'Lactofen', cas: '77501-63-4' },
  { haz: 'T', name: 'LAH', cas: '' },
  { haz: 'T', name: 'LANNATE', cas: '' },
  { haz: 'T', name: 'Lasiocarpine', cas: '303-34-4' },
  { haz: 'T', name: 'Lead Acetate', cas: '301-04-2' },
  { haz: 'T', name: 'Lead and/or lead compounds', cas: '7439-92-1' },
  { haz: 'T', name: 'Lead Antimonate (V)', cas: '13510-89-9' },
  { haz: 'T', name: 'Lead arsenate', cas: '' },
  { haz: 'T', name: 'Lead Arsenate, as As3+', cas: '3687-31-8' },
  { haz: 'T', name: 'Lead Arsenate, as As3+', cas: '7645-25-2' },
  { haz: 'T', name: 'Lead Arsenate, as As3+', cas: '7784-40-9' },
  { haz: 'T', name: 'Lead Arsenate, as As3+', cas: '10102-48-4' },
  { haz: 'T', name: 'Lead Arsenite, as As3+', cas: '10031-13-7' },
  { haz: 'T', name: 'Lead Azide', cas: '13424-46-9' },
  { haz: 'T', name: 'Lead Borate', cas: '10214-39-8' },
  { haz: 'T', name: 'Lead Bromate', cas: '34018-28-5' },
  { haz: 'T', name: 'Lead Bromide', cas: '10031-22-8' },
  { haz: 'T', name: 'Lead Butyrate', cas: '819-73-8' },
  { haz: 'T', name: 'Lead Chlorate', cas: '10294-47-0' },
  { haz: 'T', name: 'Lead Chloride', cas: '7758-95-4' },
  { haz: 'T', name: 'Lead Chromate Oxide, as Cr6+', cas: '18454-12-1' },
  { haz: 'T', name: 'Lead Chromate, as Cr6+', cas: '7758-97-6' },
  { haz: 'T', name: 'Lead Chromate, as Cr6+', cas: '8049-64-7' },
  { haz: 'T, R', name: 'Lead cyanide', cas: '' },
  { haz: 'T', name: 'Lead Dioxide', cas: '1309-60-0' },
  { haz: 'T', name: 'Lead Fluoride', cas: '7783-46-2' },
  { haz: 'T', name: 'Lead Formate', cas: '811-54-1' },
  { haz: 'T', name: 'Lead Hexafluorosilicate', cas: '25808-74-6' },
  { haz: 'T,C', name: 'Lead Hydroxide', cas: '1311-11-1' },
  { haz: 'T', name: 'Lead Hypophosphite', cas: '10294-58-3' },
  { haz: 'T', name: 'Lead Iodide', cas: '10101-63-0' },
  { haz: 'T', name: 'Lead Molybdate (VI)', cas: '10190-55-3' },
  { haz: 'T', name: 'Lead Monoxide', cas: '1317-36-8' },
  { haz: 'T', name: 'Lead Nitrate', cas: '10099-74-8' },
  { haz: 'T', name: 'Lead Orthoarsenate', cas: '' },
  { haz: 'T', name: 'Lead Phosphate', cas: '7446-27-7' },
  { haz: 'T', name: 'Lead Selenate', cas: '7446-15-3' },
  { haz: 'T', name: 'Lead Selenite', cas: '7488-51-9' },
  { haz: 'T', name: 'Lead Sesquioxide', cas: '1314-27-8' },
  { haz: 'T', name: 'Lead Sodium Thiosulfate', cas: '10101-94-7' },
  { haz: 'T', name: 'Lead Subacetate', cas: '1335-32-6' },
  { haz: 'T', name: 'Lead Sulfate', cas: '7446-14-2' },
  { haz: 'T', name: 'Lead Sulfide', cas: '1314-87-0' },
  { haz: 'T', name: 'Lead Telluride', cas: '1314-91-6' },
  { haz: 'T', name: 'Lead Tetrafluoride', cas: '7783-59-7' },
  { haz: 'T', name: 'Lead Tetraoxide', cas: '1314-41-6' },
  { haz: 'T', name: 'Lead Thiocyanate', cas: '592-87-0' },
  { haz: 'T', name: 'Lead Tungstate (VI)', cas: '7759-01-5' },
  { haz: 'T', name: 'Lead Vanadate (V)', cas: '10099-79-3' },
  { haz: 'T', name: 'Lead, bis(acetato-O)tetrahydroxytri-', cas: '1335-32-6' },
  { haz: 'T', name: 'Leadhillite', cas: '1319-48-8' },
  { haz: 'T', name: 'Lewisite', cas: '' },
  { haz: 'T', name: 'Lindane', cas: '58-89-9' },
  { haz: 'T', name: 'Lithium', cas: '' },
  { haz: 'T,R', name: 'Lithium aluminum hydride', cas: '' },
  { haz: 'T', name: 'Lithium amide', cas: '' },
  {
    haz: 'T',
    name: 'Lithium Bichromate Dihydrate, as Cr6+ [water soluble]',
    cas: '0-17-0',
  },
  {
    haz: 'T',
    name: 'Lithium Chromate, as Cr6+ [water soluble]',
    cas: '7789-01-7',
  },
  {
    haz: 'T',
    name: 'Lithium Chromate, as Cr6+ [water soluble]',
    cas: '14307-35-8',
  },
  {
    haz: 'T',
    name: 'Lithium Dichromate, as Cr6+ [water soluble]',
    cas: '13843-81-7',
  },
  { haz: 'T', name: 'Lithium ferrosilicon', cas: '' },
  { haz: 'T,R,C', name: 'Lithium hydride', cas: '' },
  { haz: 'T,C', name: 'Lithium hypochlorite', cas: '' },
  {
    haz: 'T',
    name: 'Lithium Molybdate, as Mo [water soluble]',
    cas: '13568-40-6',
  },
  { haz: 'T', name: 'Lomustine', cas: '13010-47-4' },
  {
    haz: '',
    name: 'London purple, Mixture of arsenic trioxide, aniline, lime, anferrous oxide',
    cas: '',
  },
  {
    haz: 'T',
    name: 'L-Phenylalanine, 4-[bis(2-chloroethyl)amino]-',
    cas: '148-82-3',
  },
  { haz: 'T', name: 'L-Serine, diazoacetate (ester)', cas: '115-02-6' },
  { haz: 'T', name: 'Lynestrenol', cas: '52-76-6' },
  { haz: 'T', name: 'Magenta (containing C.I. Basic Red 9)', cas: '632-99-5' },
  { haz: 'T', name: 'Magnesium', cas: '' },
  { haz: 'T', name: 'Magnesium Arsenate', cas: '10103-50-1' },
  { haz: 'T', name: 'Magnesium arsenate', cas: '' },
  { haz: 'T', name: 'Magnesium arsenite', cas: '' },
  {
    haz: 'T',
    name: 'Magnesium Chromate, as Cr6+ [water soluble]',
    cas: '13423-61-5',
  },
  { haz: 'T', name: 'Maleic anhydride', cas: '108-31-6' },
  { haz: 'T', name: 'Maleic anhydride', cas: '' },
  { haz: 'T', name: 'Maleic hydrazide', cas: '123-33-1' },
  { haz: 'T', name: 'Malononitrile', cas: '109-77-3' },
  { haz: 'T', name: 'Mancozeb', cas: '8018-01-7' },
  { haz: 'T', name: 'Maneb', cas: '12427-38-2' },
  { haz: 'T', name: 'Manganese arsenate', cas: '' },
  { haz: 'T', name: 'Manganese dimethyldithiocarbamate.', cas: '15339-36-3' },
  {
    haz: 'T',
    name: "Manganese,bis(dimethylcarbamodithioato-S,S')-,",
    cas: '15339-36-3',
  },
  { haz: 'T', name: 'Manganous arsenate', cas: '' },
  { haz: 'T', name: 'Marine Diesel Fuel', cas: '0-18-0' },
  { haz: 'T', name: 'MARLATE', cas: '' },
  { haz: 'T', name: 'm-Arsenic Acid', cas: '10102-53-1' },
  { haz: 'T', name: 'MBOCA', cas: '101-14-4' },
  { haz: 'T', name: 'm-Cresol', cas: '108-39-4' },
  { haz: 'T', name: 'm-Cumenyl methylcarbamate.', cas: '64-00-6' },
  { haz: 'T', name: 'm-Dichlorobenzene', cas: '541-73-1' },
  {
    haz: 'T',
    name: 'MeA-alpha-C(2-Amino-3-methyl-9H-pyrido[2,3-b]indole)',
    cas: '68006-83-7',
  },
  { haz: 'T', name: 'MECARBAM', cas: '' },
  { haz: 'T', name: 'MeCCNU', cas: '13909-09-6' },
  { haz: 'T', name: 'Mechlorethamine', cas: '51-75-2' },
  { haz: 'T', name: 'Mechlorethamine Hydrochloride', cas: '55-86-7' },
  { haz: 'T', name: 'Medinoterb acetate', cas: '' },
  { haz: 'T', name: 'Medroxyprogesterone Acetate', cas: '71-58-9' },
  {
    haz: 'T',
    name: 'MeIQ (2-Amino-3,4-dimethylimidazo[4,5-f]quinoline)',
    cas: '77094-11-2',
  },
  {
    haz: 'T',
    name: 'MeIQx (2-Amino-3,8-dimethylimidazo[4,5-f]quinoxaline)',
    cas: '77500-04-0',
  },
  { haz: 'T', name: 'Melphalan', cas: '148-82-3' },
  { haz: 'T', name: 'Mercuric chloride', cas: '' },
  { haz: 'T', name: 'Mercuric Chromate, as Cr6+', cas: '13444-75-2' },
  { haz: 'T,R', name: 'Mercuric cyanide', cas: '' },
  { haz: 'T', name: 'Mercuric Dichromate, as Cr6+', cas: '7789-10-8' },
  { haz: 'T', name: 'Mercury (II) Chromate, as Cr6+', cas: '13444-75-2' },
  { haz: 'T', name: 'Mercury (II) Dichromate, as Cr6+', cas: '7789-10-8' },
  { haz: 'T', name: 'Mercury and/or mercury compounds', cas: '7439-97-6' },
  { haz: 'T', name: 'Mercury Chloride', cas: '' },
  { haz: 'T,R', name: 'Mercury Cyanide', cas: '' },
  { haz: 'T', name: 'Mercury fulminate (R,T)', cas: '628-86-4' },
  { haz: 'T', name: 'Mercury, (acetato-O)phenyl-', cas: '62-38-4' },
  { haz: 'T', name: 'Merphalan', cas: '531-76-0' },
  { haz: 'T', name: 'Mestranol', cas: '72-33-3' },
  {
    haz: 'T',
    name: 'meta-Isopropylphenyl-N-methylcarbamate, Ac 5,727',
    cas: '',
  },
  { haz: 'T', name: 'METAISOSYSTOX-SULFON', cas: '' },
  { haz: 'T', name: 'Methacrylonitrile', cas: '126-98-7' },
  { haz: 'T', name: 'Metham Sodium', cas: '137-42-8' },
  { haz: 'T', name: 'Methanamine, N-methyl-', cas: '124-40-3' },
  { haz: 'T', name: 'Methanamine, N-methyl-N-nitroso-', cas: '62-75-9' },
  { haz: 'T', name: 'Methane Dichloride [1910.1052]', cas: '75-09-2' },
  { haz: 'T', name: 'Methane, bromo-', cas: '74-83-9' },
  { haz: 'T', name: 'Methane, chloro-', cas: '74-87-3' },
  { haz: 'T', name: 'Methane, chloromethoxy-', cas: '107-30-2' },
  { haz: 'T', name: 'Methane, dibromo-', cas: '74-95-3' },
  { haz: 'T', name: 'Methane, dichloro-', cas: '75-09-2' },
  { haz: 'T', name: 'Methane, dichlorodifluoro-', cas: '75-71-8' },
  { haz: 'T', name: 'Methane, iodo-', cas: '74-88-4' },
  { haz: 'T', name: 'Methane, isocyanato-', cas: '624-83-9' },
  { haz: 'T', name: 'Methane, oxybis[chloro-', cas: '542-88-1' },
  { haz: 'T', name: 'Methane, tetrachloro-', cas: '56-23-5' },
  { haz: 'T', name: 'Methane, tetranitro- (R)', cas: '509-14-8' },
  { haz: 'T', name: 'Methane, tribromo-', cas: '75-25-2' },
  { haz: 'T', name: 'Methane, trichloro-', cas: '67-66-3' },
  { haz: 'T', name: 'Methane, trichlorofluoro-', cas: '75-69-4' },
  {
    haz: 'T',
    name: 'Methane-d2 Dichloride {CD2Cl2} [1910.1052]',
    cas: '1665-00-5',
  },
  { haz: 'T', name: 'Methanesulfonic acid, ethyl ester', cas: '62-50-0' },
  { haz: 'T', name: 'Methanethiol', cas: '74-93-1' },
  { haz: 'T', name: 'Methanethiol, trichloro-', cas: '75-70-7' },
  {
    haz: 'T',
    name: "Methanimidamide, N,N-dimethyl-N'-[2-methyl-4[[(methylamino)carbonyl]oxy]phenyl]-",
    cas: '17702-57-7',
  },
  {
    haz: 'T',
    name: "Methanimidamide, N,N-dimethyl-N'-[3-[[(methylamino)carbonyl]oxy]phenyl]-, monohydrochloride.",
    cas: '23422-53-9',
  },
  { haz: 'T,F', name: 'Methanol', cas: '67-56-1' },
  { haz: 'T', name: 'Methapyrilene', cas: '91-80-5' },
  { haz: 'T', name: 'Methiocarb.', cas: '2032-65-7' },
  { haz: 'T', name: 'Methomyl', cas: '16752-77-5' },
  { haz: 'T', name: 'Methoxsalen', cas: '298-81-7' },
  { haz: 'T', name: 'Methoxsalen plus UV A radiation', cas: '298-81-7' },
  { haz: 'T', name: 'Methoxychlor', cas: '72-43-5' },
  { haz: 'T', name: 'Methoxyethylmercuric chloride', cas: '' },
  { haz: 'T', name: 'Methyl alcohol', cas: '67-56-1' },
  { haz: 'T', name: 'Methyl bromide', cas: '74-83-9' },
  { haz: 'T', name: 'Methyl Carbamate', cas: '598-55-0' },
  { haz: 'T', name: 'Methyl chloride', cas: '74-87-3' },
  { haz: 'T', name: 'Methyl chlorocarbonate', cas: '79-22-1' },
  { haz: 'T', name: 'Methyl chloroform', cas: '71-55-6' },
  { haz: 'T', name: 'Methyl chloroformate', cas: '' },
  { haz: 'T,F', name: 'Methyl chloromethyl ether', cas: '' },
  { haz: 'T,F', name: 'Methyl ethyl ketone (MEK)', cas: '78-93-3' },
  { haz: 'T,F', name: 'Methyl ethyl ketone peroxide', cas: '1338-23-4' },
  { haz: 'T,F', name: 'Methyl Hydrazine (and its salts)', cas: '60-34-4' },
  { haz: 'T', name: 'Methyl Iodide', cas: '74-88-4' },
  { haz: 'T,F', name: 'Methyl isobutyl ketone', cas: '108-10-1' },
  { haz: 'T,F', name: 'Methyl isocyanate', cas: '624-83-9' },
  { haz: 'T', name: 'Methyl methacrylate', cas: '80-62-6' },
  { haz: 'T', name: 'Methyl Methanesulfonate', cas: '66-27-3' },
  { haz: 'T', name: 'Methyl parathion', cas: '298-00-0' },
  { haz: 'T', name: 'Methyl Sulfate', cas: '' },
  { haz: 'T,R', name: 'Methyl Sulfide', cas: '' },
  { haz: 'T', name: 'Methyl Yellow', cas: '' },
  { haz: 'T', name: 'Methylaluminum sesquibromide', cas: '' },
  { haz: 'T', name: 'Methylaluminum sesquichloride', cas: '' },
  { haz: 'T', name: 'Methylazoxymethanol', cas: '590-96-5' },
  { haz: 'T', name: 'Methylazoxymethanol Acetate', cas: '592-62-1' },
  { haz: 'T', name: 'Methyl-CCNU', cas: '13909-09-6' },
  { haz: 'T', name: 'Methylchloro Methyl Ether', cas: '107-30-2' },
  { haz: 'T', name: 'Methyldichloroarsine', cas: '' },
  { haz: 'T', name: 'Methyldichlorosilane', cas: '' },
  { haz: 'T', name: 'Methylene bromide', cas: '74-95-3' },
  { haz: 'T,F', name: 'Methylene chloride', cas: '75-09-2' },
  { haz: 'T,F', name: 'Methylene Chloride [1910.1052]', cas: '75-09-2' },
  {
    haz: 'T',
    name: 'Methylene-d2 Chloride {CD2Cl2} [1910.1052]',
    cas: '1665-00-5',
  },
  { haz: 'T', name: 'Methyleugenol', cas: '93-15-2' },
  { haz: 'T', name: 'Methylmagnesium bromide', cas: '' },
  { haz: 'T', name: 'Methylmagnesium chloride', cas: '' },
  { haz: 'T', name: 'Methylmagnesium iodide', cas: '' },
  { haz: 'T', name: 'Methylmercury Chloride', cas: '115-09-3' },
  { haz: 'T', name: 'Methylmercury compounds', cas: '0-53-0' },
  { haz: 'T', name: 'Methylmercury Dicyandiamide', cas: '502-39-6' },
  { haz: 'T', name: 'Methyl-o-anisidine', cas: '120-71-8' },
  { haz: 'T', name: 'Methylthiouracil', cas: '56-04-2' },
  { haz: 'T,R', name: 'Methyltrichlorosilane', cas: '' },
  { haz: 'T', name: 'Metiram', cas: '9006-42-2' },
  { haz: 'T', name: 'Metolcarb.', cas: '1129-41-5' },
  { haz: 'T', name: 'Metronidazole', cas: '443-48-1' },
  { haz: 'T', name: 'Mevinphos', cas: '' },
  { haz: 'T', name: 'Mexacarbate.', cas: '315-8-4' },
  { haz: 'T', name: 'MGK Repellant 326', cas: '136-45-8' },
  { haz: 'T', name: "Michler's Base", cas: '101-61-1' },
  { haz: 'T', name: "Michler's Ketone", cas: '90-94-8' },
  {
    haz: 'T',
    name: 'Mineral Oil Mist (mildly refined; containing 15 PAHs listed by the NTP)',
    cas: '0-28-0',
  },
  {
    haz: 'T',
    name: 'Mineral Oils (untreated and mildly-treated)',
    cas: '8002-05-9',
  },
  { haz: 'T', name: 'MINTACOL', cas: '' },
  { haz: 'T', name: 'Mirex', cas: '2385-85-5' },
  { haz: 'T', name: 'Mitomycin C', cas: '50-07-7' },
  { haz: 'T', name: 'Mitoxantrone', cas: '65271-80-9' },
  { haz: 'T', name: 'MMH', cas: '' },
  { haz: 'T', name: 'MNNG', cas: '70-25-7' },
  { haz: 'T', name: 'MNNG', cas: '70-25-7' },
  { haz: 'T', name: 'MOCA', cas: '101-14-4' },
  { haz: 'T', name: 'MOCAP', cas: '' },
  {
    haz: 'T',
    name: 'Molybdenum and/or molybdenum compounds',
    cas: '1313-27-5',
  },
  {
    haz: 'T',
    name: 'Molybdenum Dichloride Dioxide, as Mo [water soluble]',
    cas: '13637-68-8',
  },
  {
    haz: 'T',
    name: 'Molybdenum Hexafluoride, as Mo [water soluble]',
    cas: '7783-77-9',
  },
  { haz: 'T', name: 'Molybdenum Orange, as Cr6+', cas: '8005-36-5' },
  { haz: 'T', name: 'Molybdenum Orange, as Cr6+', cas: '12213-61-5' },
  { haz: 'T', name: 'Molybdenum Orange, as Cr6+', cas: '12656-85-8' },
  { haz: 'T', name: 'Molybdenum Orange, as Cr6+', cas: '12709-98-7' },
  { haz: 'T', name: 'Molybdenum Orange, as Cr6+', cas: '64523-06-4' },
  {
    haz: 'T',
    name: 'Molybdenum Oxytetrachloride, as Mo [water soluble]',
    cas: '13814-75-0',
  },
  {
    haz: 'T',
    name: 'Molybdenum Trioxide, as Mo [water soluble]',
    cas: '1313-27-5',
  },
  {
    haz: 'T',
    name: 'Molybdenum Trisulfide, as Mo [water soluble]',
    cas: '12033-29-3',
  },
  { haz: 'T', name: 'compound]', cas: '7439-98-7' },
  {
    haz: 'T',
    name: 'Molybdic Acid Anhydride, as Mo [water soluble]',
    cas: '1313-27-5',
  },
  {
    haz: 'T',
    name: 'Molybdic Acid, 85%; Hexammonium Salt, as Mo [water soluble]',
    cas: '12027-67-7',
  },
  {
    haz: 'T',
    name: 'Molybdic Acid, Dilithium Salt, as Mo [water soluble]',
    cas: '13568-40-6',
  },
  { haz: 'T', name: 'Monochlorodimethyl Ether', cas: '107-30-2' },
  { haz: 'T', name: 'Monocrotaline', cas: '315-22-0' },
  { haz: 'T', name: 'Monomethyl Hydrazine', cas: '' },
  { haz: 'T', name: 'Muriatic Acid', cas: '' },
  { haz: 'T', name: 'Mustard Gas', cas: '505-60-2' },
  {
    haz: 'T',
    name: 'MX(3-chloro-4-dichloromethyl-5-hydroxy-2(5H)-furanone)',
    cas: '77439-76-0',
  },
  { haz: 'T', name: 'Myleran', cas: '55-98-1' },
  {
    haz: 'T',
    name: 'N, N-Diethyldithiocarbamic Acid 2-Chloroallyl Ester',
    cas: '95-06-7',
  },
  { haz: 'T', name: 'N,N-bis(2-Chloroethyl)-2-naphthylamine', cas: '494-03-1' },
  { haz: 'T', name: "N,N'-Diacetylbenzidine", cas: '613-35-4' },
  { haz: 'T', name: "N,N'-Diethylhydrazine", cas: '1615-80-1' },
  { haz: 'T', name: 'N,N-Dimethylnitrosoamine', cas: '62-75-9' },
  {
    haz: 'T',
    name: 'N-[4-(5-Nitro-2-furyl)-2-thiazolyl]acetamide',
    cas: '531-82-8',
  },
  { haz: 'T', name: 'Nafenopin', cas: '3771-19-5' },
  { haz: 'T', name: 'Nalidixic Acid', cas: '389-08-2' },
  {
    haz: 'T',
    name: "Naphthalenamine, N,N'-bis(2-chloroethyl)-",
    cas: '494-03-1',
  },
  { haz: 'T', name: 'Naphthalene', cas: '91-20-3' },
  { haz: 'T', name: 'Naphthalene, 2-chloro-', cas: '91-58-7' },
  { haz: 'T,F', name: 'n-Butyl alcohol', cas: '71-36-3' },
  { haz: 'T,R', name: 'n-Butyllithium (and isomers)', cas: '' },
  { haz: 'T,R', name: 'n-Butyltrichlorosilane', cas: '' },
  { haz: 'T', name: 'N-Carboxymethyl-N-nitrosourea', cas: '60391-92-6' },
  { haz: 'T', name: 'NDEA', cas: '55-18-5' },
  { haz: 'T', name: 'Nemagon', cas: '' },
  {
    haz: 'T',
    name: 'Neodymium Chromate Heptahydrate, as Cr6+',
    cas: '16569-87-2',
  },
  { haz: 'T', name: 'Neodymium Chromate, as Cr6+', cas: '16565-95-0' },
  { haz: 'T', name: 'N-Ethyl-N-nitrosourea', cas: '759-73-9' },
  { haz: 'T', name: 'Neutrons', cas: '0-81-0' },
  { haz: 'T', name: 'NIA 10,242', cas: '' },
  { haz: 'T', name: 'NIALATE', cas: '' },
  {
    haz: 'T',
    name: 'Nickel (II) Nitrate Hexahydrate, as Ni [water soluble]',
    cas: '13478-00-7',
  },
  { haz: 'T', name: 'Nickel Acetate [water soluble]', cas: '373-02-4' },
  {
    haz: 'T',
    name: 'Nickel Acetylacetonate [water soluble]',
    cas: '3264-82-2',
  },
  { haz: 'T', name: 'Nickel and/or nickel compounds', cas: '' },
  { haz: 'T', name: 'Nickel arsenate, Nickelous arsenate', cas: '' },
  { haz: 'T', name: 'Nickel Bromide [water soluble]', cas: '13462-88-9' },
  { haz: 'T', name: 'Nickel Carbonate', cas: '3333-67-3' },
  { haz: 'T,C', name: 'Nickel Carbonate Hydroxide', cas: '12607-70-4' },
  { haz: 'T,R', name: 'Nickel Carbonyl', cas: '13463-39-3' },
  { haz: 'T,C', name: 'Nickel Chloride [water soluble]', cas: '7718-54-9' },
  { haz: 'T,R', name: 'Nickel Cyanide', cas: '557-19-7' },
  { haz: 'T', name: 'Nickel Dimethylglyoxime', cas: '13478-93-8' },
  { haz: 'T', name: 'Nickel Fluoride [water soluble]', cas: '10028-18-9' },
  { haz: 'T', name: 'Nickel Formate [water soluble]', cas: '3349-06-2' },
  { haz: 'T,C', name: 'Nickel Hydroxide', cas: '12054-48-7' },
  { haz: 'T,C', name: 'Nickel Hydroxide', cas: '12125-56-3' },
  { haz: 'T,C', name: 'Nickel Hydroxide [water soluble]', cas: '11113-74-9' },
  { haz: 'T', name: 'Nickel Iodide [water soluble]', cas: '13462-90-3' },
  {
    haz: 'T,R',
    name: 'Nickel metal powder & Ni alloys/compounds, as Ni - [see specific compound]',
    cas: '7440-02-0',
  },
  { haz: 'T', name: 'Nickel Monoxide', cas: '1313-99-1' },
  { haz: 'T', name: 'Nickel Nitrate [water soluble]', cas: '13138-45-9' },
  { haz: 'T', name: 'Nickel Oxalate', cas: '547-67-1' },
  { haz: 'T', name: 'Nickel Oxide', cas: '1313-99-1' },
  {
    haz: 'T',
    name: 'Nickel Perchlorate Hexahydrate [water soluble]',
    cas: '13520-61-1',
  },
  { haz: 'T', name: 'Nickel Phosphate', cas: '10381-36-9' },
  {
    haz: 'T',
    name: 'Nickel Refinery Dust (from the pyrometallurgical process)',
    cas: '0-67-0',
  },
  { haz: 'T', name: 'Nickel Sesquioxide', cas: '1314-06-3' },
  { haz: 'T', name: 'Nickel Subsulfide', cas: '12035-72-2' },
  { haz: 'T', name: 'Nickel Sulfamate', cas: '13770-89-3' },
  { haz: 'T', name: 'Nickel Sulfate [water soluble]', cas: '7786-81-4' },
  {
    haz: 'T',
    name: 'Nickel-Beryllium Alloy, as Ni fume or dust [also see Be]',
    cas: '37227-61-5',
  },
  { haz: 'T', name: 'Nickelocene', cas: '1271-28-9' },
  { haz: 'T', name: 'Nicotine, & salts', cas: '54-11-5' },
  { haz: 'T', name: 'Niridazole', cas: '61-57-4' },
  { haz: 'T,C', name: 'Nitric acid, thallium(1+) salt', cas: '10102-45-1' },
  { haz: 'T', name: 'Nitric oxide', cas: '10102-43-9' },
  { haz: 'T', name: 'Nitrilotriacetic Acid (and its salts)', cas: '139-13-9' },
  {
    haz: 'T',
    name: 'Nitrilotriacetic Acid , Sodium Salt (unspecified)',
    cas: '10042-84-9',
  },
  { haz: 'T', name: 'Nitrilotriacetic Acid, Disodium Salt', cas: '15467-20-6' },
  {
    haz: 'T',
    name: 'Nitrilotriacetic Acid, Disodium Salt, Hydrate',
    cas: '23255-03-0',
  },
  {
    haz: 'T',
    name: 'Nitrilotriacetic Acid, Monosodium Salt',
    cas: '18994-66-6',
  },
  { haz: 'T', name: 'Nitrilotriacetic Acid, Trisodium Salt', cas: '5064-31-3' },
  {
    haz: 'T',
    name: 'Nitrilotriacetic Acid, Trisodium Salt, Hydrate',
    cas: '18662-53-8',
  },
  { haz: 'T,F', name: 'Nitrobenzene', cas: '98-95-3' },
  { haz: 'T', name: 'Nitrobenzol', cas: '' },
  { haz: 'T', name: 'Nitrofen (technical grade)', cas: '1836-75-5' },
  { haz: 'T', name: 'Nitrofurazone', cas: '59-87-0' },
  { haz: 'T', name: 'Nitrogen dioxide', cas: '10102-44-0' },
  { haz: 'T', name: 'Nitrogen Mustard', cas: '51-75-2' },
  { haz: 'T', name: 'Nitrogen Mustard Hydrochloride', cas: '55-86-7' },
  { haz: 'T', name: 'Nitrogen Mustard N-oxide', cas: '126-85-2' },
  { haz: 'T', name: 'Nitrogen Mustard N-oxide Hydrochloride', cas: '302-70-5' },
  { haz: 'T', name: 'Nitrogen oxide NO', cas: '10102-43-9' },
  { haz: 'T', name: 'Nitroglycerine', cas: '55-63-0' },
  { haz: 'T,F', name: 'Nitromethane', cas: '75-52-5' },
  { haz: 'T,F', name: 'Nitrophenol (ortho, meta, para)', cas: '' },
  { haz: 'T', name: 'N-Methyl-bis(2-chloroethyl) Amine', cas: '51-75-2' },
  { haz: 'T', name: "N-Methyl-N'-nitro-N-nitrosoguanidine", cas: '70-25-7' },
  { haz: 'T', name: 'N-Methyl-N-nitrosourea', cas: '684-93-5' },
  { haz: 'T', name: 'N-Methyl-N-nitrosourethane', cas: '615-53-2' },
  { haz: 'T', name: 'N-Methylolacrylamide', cas: '924-42-5' },
  { haz: 'T', name: 'N-Nitrosodiethanolamine', cas: '1116-54-7' },
  { haz: 'T', name: 'N-Nitrosodiethylamine', cas: '55-18-5' },
  { haz: 'T', name: 'N-Nitrosodimethylamine', cas: '62-75-9' },
  { haz: 'T', name: 'N-Nitrosodi-n-butylamine', cas: '924-16-3' },
  { haz: 'T', name: 'N-Nitrosodi-n-propylamine', cas: '621-64-7' },
  { haz: 'T', name: 'N-Nitrosodiphenylamine', cas: '86-30-6' },
  { haz: 'T', name: 'N-Nitrosomethylethylamine', cas: '10595-95-6' },
  { haz: 'T', name: 'N-Nitrosomethylvinylamine', cas: '4549-40-0' },
  { haz: 'T', name: 'N-Nitrosomorpholine', cas: '59-89-2' },
  {
    haz: 'T',
    name: 'N-Nitroso-n-butyl-N-(3-carboxypropyl)amine',
    cas: '38252-74-3',
  },
  {
    haz: 'T',
    name: 'N-Nitroso-n-butyl-N-(4-hydroxybutyl)amine',
    cas: '3817-11-6',
  },
  { haz: 'T', name: 'N-Nitroso-N-ethylurea', cas: '759-73-9' },
  { haz: 'T', name: 'N-Nitroso-N-methylurea', cas: '684-93-5' },
  { haz: 'T', name: 'N-Nitroso-N-methylurethane', cas: '615-53-2' },
  { haz: 'T', name: "N'-Nitrosonornicotine", cas: '16543-55-8' },
  { haz: 'T', name: 'N-Nitrosopiperidine', cas: '100-75-4' },
  { haz: 'T', name: 'N-Nitrosopyrrolidine', cas: '930-55-2' },
  { haz: 'T', name: 'N-Nitrososarcosine', cas: '13256-22-9' },
  { haz: 'T', name: 'NNK', cas: '64091-91-4' },
  { haz: 'T', name: 'application of)', cas: '0-62-0' },
  { haz: 'T', name: 'Nonyltrichlorosilane', cas: '' },
  { haz: 'T', name: 'Norethindrone', cas: '68-22-4' },
  { haz: 'T', name: 'Norethisterone', cas: '68-22-4' },
  { haz: 'T', name: 'Norethynodrel', cas: '68-23-5' },
  { haz: 'T', name: 'n-Propylamine', cas: '107-10-8' },
  { haz: 'T', name: 'n-Propyltrichlorosilane', cas: '' },
  {
    haz: 'T',
    name: "O,O,O',O'-Tetraethyl-S,S-methylenediphos-phorodithioate",
    cas: '',
  },
  {
    haz: 'T',
    name: 'O,O-Diethyl O-pyrazinyl phosphorothioate',
    cas: '297-97-2',
  },
  {
    haz: '',
    name: 'O,O-Diethyl S-(N-ethoxycarbonyl N-methylcarba-moyl-methylphosphorodithioate',
    cas: '',
  },
  { haz: 'T', name: 'O,O-Diethyl S-methyl dithiophosphate', cas: '3288-58-2' },
  {
    haz: 'T',
    name: 'O,O-Diethyl-0-[4-(methyl--sulfinyl)phenyl]phosphorothioate',
    cas: '',
  },
  { haz: 'T', name: 'O,O-Diethyl-O-para-nitrophenylphosphate', cas: '' },
  {
    haz: 'T',
    name: 'O,O-Diethyl-S-(isopropylthiomethyl) phosphorodithioate',
    cas: '',
  },
  {
    haz: 'T',
    name: 'O,O-Diethyl-S-carboethoxy--ethyl phosphorodithioate',
    cas: '',
  },
  {
    haz: 'T',
    name: 'O,O-Dimethyl-O-para-nitrophenyl—phosphorothioate',
    cas: '',
  },
  {
    haz: '',
    name: 'O,O-Dimethyl-S-4-oxo-1,2,3-benzotriazin-3(4H)-ylmethyphosphorodithioate',
    cas: '',
  },
  { haz: 'T', name: 'O,O-Tetramethylthiuram monosulfide', cas: '' },
  { haz: 'T', name: 'o-Aminoazotoluene', cas: '97-56-3' },
  { haz: 'T', name: 'o-Anisidine', cas: '90-04-0' },
  { haz: 'T', name: 'o-Anisidine', cas: '29191-52-4' },
  { haz: 'T', name: 'o-Anisidine Hydrochloride', cas: '134-29-2' },
  { haz: 'T', name: 'o-Arsenic Acid', cas: '7778-39-4' },
  { haz: 'T', name: 'o-Chlorophenol', cas: '95-57-8' },
  { haz: 'T', name: 'Ochratoxin A', cas: '303-47-9' },
  { haz: 'T', name: 'OCMB', cas: '' },
  { haz: 'T', name: 'o-Cresol', cas: '95-48-7' },
  { haz: 'T', name: 'Octabromobiphenyl {PBBs}', cas: '61288-13-9' },
  { haz: 'T', name: 'Octadecyltrichlorosilane', cas: '' },
  { haz: 'T', name: 'Octamethylpyrophosphoramide', cas: '152-16-9' },
  { haz: 'T', name: 'Octyltrichlorosilane', cas: '' },
  { haz: 'T', name: 'o-Dianisidine Based Dyes', cas: '119-90-4' },
  { haz: 'T', name: 'o-Dianisidine Dihydrochloride', cas: '20325-40-0' },
  { haz: 'T', name: 'o-Dichlorobenzene', cas: '95-50-1' },
  { haz: 'T', name: 'Oestrogens, Nonsteroidal and Steroidal', cas: '0-11-0' },
  {
    haz: 'T',
    name: 'O-Ethyl O-para-nitrophenyl phenylphosphonothioate',
    cas: '',
  },
  { haz: 'T', name: 'O-Ethyl-S,S-dipropyl phosphorodithioate', cas: '' },
  { haz: 'T', name: 'O-Ethyl-S-phenylethyl phosphonodithioate', cas: '' },
  {
    haz: 'T',
    name: 'Oil Mist, Mineral (mildly refined; containing 15 PAHs listed by thNTP)',
    cas: '0-28-0',
  },
  { haz: 'T', name: 'Oil Orange SS', cas: '2646-17-5' },
  { haz: 'T', name: 'O-Isopropyl methyl phosphoryl fluoride', cas: '' },
  { haz: 'T', name: 'Oleum', cas: '' },
  { haz: 'T', name: 'OMPA', cas: '' },
  { haz: 'T', name: 'o-Nitroanisole', cas: '91-23-6' },
  { haz: 'T,F', name: 'o-Nitrotoluene', cas: '88-72-2' },
  {
    haz: 'T',
    name: 'O-para-Cyanophenyl-O-ethyl phenyl phosphonothioate',
    cas: '',
  },
  { haz: 'T', name: 'o-Phenylenediamine (and its salts)', cas: '95-54-5' },
  { haz: 'T', name: 'o-Phenylphenate, Sodium', cas: '132-27-4' },
  { haz: 'T', name: 'o-Phenylphenol', cas: '90-43-7' },
  {
    haz: '',
    name: 'ortho,ortho-Diethyl-ortho-(3-chloro-4-methylcoumarin-7-ylphosphate',
    cas: '',
  },
  { haz: 'T', name: 'ortho-Chlorobenzylidene malonitrile', cas: '' },
  { haz: 'T', name: 'Orthozenol', cas: '' },
  { haz: 'T', name: 'Osmium tetroxide', cas: '20816-12-0' },
  { haz: 'T', name: 'o-Tolidine', cas: '119-93-7' },
  { haz: 'T', name: 'o-Toluidine', cas: '95-53-4' },
  { haz: 'T', name: 'o-Toluidine Hydrochloride', cas: '636-21-5' },
  { haz: 'T', name: 'Oxadiazon', cas: '19666-30-9' },
  { haz: 'T', name: 'Oxamyl.', cas: '23135-22-0' },
  { haz: 'T', name: 'Oxazepam', cas: '604-75-1' },
  { haz: 'T', name: 'Oxirane', cas: '75-21-8' },
  { haz: 'T', name: 'Oxirane, (chloromethyl)-', cas: '106-89-8' },
  { haz: 'T', name: 'Oxiranecarboxyaldehyde', cas: '765-34-4' },
  { haz: 'T', name: 'Oxygen difluoride', cas: '' },
  { haz: 'T', name: 'Oxymetholone', cas: '434-07-1' },
  { haz: 'T', name: 'Oxythioquinox', cas: '2439-01-2' },
  { haz: 'T', name: "p,p'-DDT", cas: '50-29-3' },
  { haz: 'T', name: 'p-a,a,a-Tetrachlorotoluene', cas: '5216-25-1' },
  { haz: 'T', name: 'PAH {5-Methylchrysene}', cas: '3697-24-3' },
  { haz: 'T', name: 'PAH {7H-Dibenzo[c,g]carbazole}', cas: '194-59-2' },
  { haz: 'T', name: 'PAH {Benz[a]anthracene}', cas: '56-55-3' },
  { haz: 'T', name: 'PAH {Benzo[a]pyrene}', cas: '50-32-8' },
  { haz: 'T', name: 'PAH {Benzo[b]fluoranthene}', cas: '205-99-2' },
  { haz: 'T', name: 'PAH {Benzo[j]fluoranthene}', cas: '205-82-3' },
  { haz: 'T', name: 'PAH {Benzo[k]fluoranthene}', cas: '207-08-9' },
  { haz: 'T', name: 'PAH {Dibenz[a,h]acridine}', cas: '226-36-8' },
  { haz: 'T', name: 'PAH {Dibenz[a,h]anthracene}', cas: '53-70-3' },
  { haz: 'T', name: 'PAH {Dibenz[a,j]acridine}', cas: '224-42-0' },
  { haz: 'T', name: 'PAH {Dibenzo[a,e]pyrene}', cas: '192-65-4' },
  { haz: 'T', name: 'PAH {Dibenzo[a,h]pyrene}', cas: '189-64-0' },
  { haz: 'T', name: 'PAH {Dibenzo[a,i]pyrene}', cas: '189-55-9' },
  { haz: 'T', name: 'PAH {Dibenzo[a,l]pyrene}', cas: '191-30-0' },
  { haz: 'T', name: 'PAH {Indeno[1,2,3-cd]pyrene}', cas: '193-39-5' },
  {
    haz: 'T',
    name: 'PAH {Polycyclic Aromatic Hydrocarbon(s); see 15 specific chemicals}',
    cas: '0-50-0',
  },
  { haz: 'T', name: 'Painter (occ. exposure as a)', cas: '0-45-0' },
  { haz: 'T', name: 'Palygorskite (long fibers, > 5 µm)', cas: '12174-11-7' },
  { haz: 'T', name: 'p-Aminoazobenzene', cas: '60-09-3' },
  {
    haz: 'T',
    name: 'Panfuran S (containing dihydroxymethylfuratrizine)',
    cas: '794-93-4',
  },
  {
    haz: '',
    name: "para-(5-Amino-3-phenyl-1H-1,2,4-triazol-1-yl)-N,N,N',N'- tetramethylphosphonic diamide",
    cas: '',
  },
  { haz: 'T', name: 'Paraformaldehyde', cas: '30525-89-4' },
  { haz: 'T', name: 'Paraldehyde', cas: '123-63-7' },
  { haz: 'T', name: 'Para-oxon', cas: '' },
  { haz: 'T', name: 'Parathion', cas: '56-38-2' },
  { haz: 'T', name: 'Paris green', cas: '' },
  {
    haz: 'T',
    name: 'Particulate Polycyclic Aromatic Hydrocarbons [PPAH]',
    cas: '65996-93-2',
  },
  { haz: 'T', name: 'PBBs {Polybrominated Biphenyls}', cas: '67774-32-7' },
  { haz: 'T', name: 'p-Benzoquinone', cas: '106-51-4' },
  { haz: 'T', name: 'PCBs {Polychorinated Biphenyls}', cas: '1336-36-3' },
  { haz: 'T', name: 'p-Chloroaniline', cas: '106-47-8' },
  { haz: 'T', name: 'p-Chloroaniline Hydrochloride', cas: '20265-96-7' },
  { haz: 'T', name: 'p-Chloro-m-cresol', cas: '59-50-7' },
  {
    haz: 'T',
    name: 'p-Chloro-o-toluidine (and its strong acid salts)',
    cas: '95-69-2',
  },
  { haz: 'T', name: 'p-Chloro-o-toluidine Hydrochloride', cas: '3165-93-3' },
  { haz: 'T', name: 'p-Cresidine', cas: '120-71-8' },
  { haz: 'T', name: 'p-Cresol', cas: '106-44-5' },
  { haz: 'T', name: 'p-Dichlorobenzene', cas: '106-46-7' },
  { haz: 'T', name: 'p-Dimethylaminoazobenzene', cas: '60-11-7' },
  { haz: 'T', name: 'PENSAL', cas: '' },
  { haz: 'T', name: 'Pentaborane', cas: '' },
  { haz: 'T', name: 'Pentachlorobenzene', cas: '608-93-5' },
  { haz: 'T', name: 'Pentachloroethane', cas: '76-01-7' },
  { haz: 'T', name: 'Pentachloronitrobenzene (PCNB)', cas: '82-68-8' },
  { haz: 'T,F', name: 'Pentachlorophenol', cas: '87-86-5' },
  { haz: 'T', name: 'Pentanol, 4-methyl-', cas: '108-10-1' },
  { haz: 'T', name: 'Pentrachlorophenol', cas: '87-86-5' },
  { haz: 'T', name: 'Perchloroethylene', cas: '127-18-4' },
  { haz: 'T', name: 'Perchloromethyl mercaptan', cas: '' },
  { haz: 'T', name: 'Pextox 14', cas: '' },
  { haz: 'T', name: 'Phenacetin', cas: '62-44-2' },
  {
    haz: 'T',
    name: 'Phenacetin contained in analgesic mixtures',
    cas: '0-20-0',
  },
  { haz: 'T', name: 'Phenarsazine Chloride', cas: '' },
  { haz: 'T', name: 'Phenazopyridine', cas: '94-78-0' },
  { haz: 'T', name: 'Phenazopyridine Hydrochloride', cas: '136-40-3' },
  { haz: 'T', name: 'Phenesterin', cas: '3546-10-9' },
  { haz: 'T', name: 'Phenobarbital', cas: '50-06-6' },
  { haz: 'T', name: 'Phenol', cas: '108-95-2' },
  {
    haz: 'T',
    name: 'Phenol, (3,5-dimethyl-4-(methylthio)-,methylcarbamate',
    cas: '2032-65-7',
  },
  { haz: 'T', name: 'Phenol, 2-(1-methylethoxy)-,', cas: '114-26-1' },
  { haz: 'T', name: 'Phenol, 2-(1-methylpropyl)-4,6-dinitro-', cas: '88-85-7' },
  {
    haz: 'T',
    name: "Phenol, 2,2'-methylenebis[3,4,6-trichloro-",
    cas: '70-30-4',
  },
  { haz: 'T', name: 'Phenol, 2,3,4,6-tetrachloro-', cas: '58-90-2' },
  { haz: 'T', name: 'Phenol, 2,4,5-trichloro-', cas: '95-95-4' },
  { haz: 'T', name: 'Phenol, 2,4,6-trichloro-', cas: '88-06-2' },
  {
    haz: 'T',
    name: 'Phenol, 2,4,6-trinitro-, ammonium salt ®',
    cas: '131-74-8',
  },
  { haz: 'T', name: 'Phenol, 2,4-dichloro-', cas: '120-83-2' },
  { haz: 'T', name: 'Phenol, 2,4-dimethyl-', cas: '105-67-9' },
  { haz: 'T', name: 'Phenol, 2,4-dinitro-', cas: '51-28-5' },
  { haz: 'T', name: 'Phenol, 2,6-dichloro-', cas: '87-65-0' },
  { haz: 'T', name: 'Phenol, 2-chloro-', cas: '95-57-8' },
  { haz: 'T', name: 'Phenol, 2-cyclohexyl-4,6-dinitro-', cas: '131-89-5' },
  { haz: 'T', name: 'Phenol, 2-methyl-4,6-dinitro-, & salts', cas: '534-52-1' },
  {
    haz: 'T',
    name: 'Phenol, 3-(1-methylethyl)-, methylcarbamate.',
    cas: '64-00-6',
  },
  {
    haz: 'T',
    name: 'Phenol, 3-methyl-5-(1-methylethyl)-,methyl carbamate.',
    cas: '2631-37-0',
  },
  {
    haz: 'T',
    name: 'Phenol, 4-(dimethylamino)-3,5-dimethyl-, methylcarbamate (ester).',
    cas: '315-18-4',
  },
  {
    haz: 'T',
    name: "Phenol, 4,4'-(1,2-diethyl-1,2-ethenediyl)bis-, (E)-",
    cas: '56-53-1',
  },
  { haz: 'T', name: 'Phenol, 4-chloro-3-methyl-', cas: '59-50-7' },
  { haz: 'T', name: 'Phenol, 4-nitro-', cas: '100-02-7' },
  { haz: 'T', name: 'Phenol, methyl-', cas: '1319-77-3' },
  { haz: 'T,F', name: 'Phenol, pentachloro-', cas: '87-86-5' },
  { haz: 'T', name: 'Phenolphthalein', cas: '77-09-8' },
  { haz: 'T', name: 'Phenoxybenzamine', cas: '59-96-1' },
  { haz: 'T', name: 'Phenoxybenzamine Hydrochloride', cas: '63-92-3' },
  { haz: 'T', name: 'Phenyl chloromethyl ketone', cas: '' },
  { haz: 'T', name: 'Phenyl Glycidyl Ether', cas: '122-60-1' },
  { haz: 'T', name: 'Phenylbenzene', cas: '' },
  { haz: 'T', name: 'Phenyldichloroarsine', cas: '' },
  { haz: 'T', name: 'Phenylethylene', cas: '100-42-5' },
  { haz: 'T', name: 'Phenylhydrazine (and its salts)', cas: '100-63-0' },
  { haz: 'T', name: 'Phenylmercury acetate', cas: '62-38-4' },
  { haz: 'T', name: 'Phenylphenol', cas: '' },
  { haz: 'T', name: 'Phenylthiourea', cas: '103-85-5' },
  { haz: 'T', name: 'Phenyltrichorosilane', cas: '' },
  { haz: 'T', name: 'Phenytoin', cas: '57-41-0' },
  { haz: 'T', name: 'Phenytoin (sodium salt)', cas: '630-93-3' },
  {
    haz: 'T',
    name: 'PhIP (2-Amino-1-methyl-6-phenylimidazo[4,5-b]pyridine)',
    cas: '105650-23-5',
  },
  { haz: 'T', name: 'Phorate', cas: '298-02-2' },
  { haz: 'T', name: 'PHOSDRIN', cas: '' },
  { haz: 'T', name: 'Phosfolan', cas: '' },
  { haz: 'T', name: 'Phosgene', cas: '75-44-5' },
  { haz: 'T', name: 'Phoshporyl Bromide', cas: '' },
  { haz: 'T', name: 'Phosphamidon', cas: '' },
  { haz: 'T', name: 'Phosphine', cas: '7803-51-2' },
  {
    haz: 'T',
    name: 'Phosphomolybdic Acid Hydrate, as Mo [water soluble]',
    cas: '51429-74-4',
  },
  {
    haz: 'T',
    name: 'Phosphomolybdic Acid, as Mo [water soluble]',
    cas: '11104-88-4',
  },
  {
    haz: 'T',
    name: 'Phosphomolybdic Acid, as Mo [water soluble]',
    cas: '12026-57-2',
  },
  {
    haz: 'T',
    name: 'Phosphoric acid, diethyl 4-nitrophenylester',
    cas: '311-45-5',
  },
  { haz: 'T', name: 'Phosphoric acid, lead(2+) salt (2:3)', cas: '7446-27-7' },
  { haz: 'T', name: 'Phosphoric Chloride', cas: '' },
  { haz: 'T,R,C', name: 'Phosphoric Sulfide', cas: '' },
  {
    haz: 'T',
    name: 'Phosphorodithioic acid, O,O-diethyl S-[2-(ethylthio)ethyl] ester',
    cas: '298-04-4',
  },
  {
    haz: 'T',
    name: 'Phosphorodithioic acid, O,O-diethyl S-[2-(ethylthio)methyl] ester',
    cas: '298-02-2',
  },
  {
    haz: 'T',
    name: 'Phosphorodithioic acid, O,O-diethyl S-methyl ester',
    cas: '3288-58-2',
  },
  { haz: 'T', name: 'oxoethyl] ester', cas: '60-51-5' },
  {
    haz: 'T',
    name: 'Phosphorofluoridic acid, bis(1-methylethyl) ester',
    cas: '55-91-4',
  },
  {
    haz: 'T',
    name: 'Phosphorothioic acid, O,O,-dimethyl O(4-nitrophenyl) ester',
    cas: '298-00-0',
  },
  {
    haz: 'T',
    name: 'Phosphorothioic acid, O,O-diethyl O-(4-nitrophenyl) ester',
    cas: '56-38-2',
  },
  {
    haz: 'T',
    name: 'Phosphorothioic acid, O,O-diethyl O-pyrazinyl ester',
    cas: '297-97-2',
  },
  {
    haz: 'T',
    name: 'Phosphorothioic acid,O-[4-[(dimethylamino)sulfonyl]phenyl] O,O- dimethyl ester',
    cas: '52-85-7',
  },
  { haz: 'T,R,C', name: 'Phosphorus (as 32P, as phosphate)', cas: '0-82-0' },
  { haz: 'T,R,C', name: 'Phosphorus (white or yellow)', cas: '' },
  { haz: 'T,R,C', name: 'Phosphorus oxybromide', cas: '' },
  { haz: 'T,R,C', name: 'Phosphorus oxychloride', cas: '' },
  { haz: 'T,R,C', name: 'Phosphorus pentachloride', cas: '' },
  { haz: 'T,R,C', name: 'Phosphorus pentasulfide', cas: '' },
  { haz: 'T,R,C', name: 'Phosphorus sesquisulfide', cas: '' },
  { haz: 'T,R,C', name: 'Phosphorus sulfide', cas: '1314-80-3' },
  { haz: 'T,R,C', name: 'Phosphorus tribromide', cas: '' },
  { haz: 'T,R,C', name: 'Phosphorus trichloride', cas: '' },
  { haz: 'T,R,C', name: 'Phosphoryl Chloride', cas: '' },
  { haz: 'T', name: 'PHOSTOXIN', cas: '' },
  { haz: 'T', name: 'Phthalic anhydride', cas: '85-44-9' },
  { haz: 'T', name: 'Physostigmine salicylate.', cas: '57-64-7' },
  { haz: 'T', name: 'Physostigmine.', cas: '57-47-6' },
  { haz: 'T', name: 'Piperazine Estrone Sulfate', cas: '7280-37-7' },
  { haz: 'T', name: 'Piperidine, 1-nitroso-', cas: '100-75-4' },
  { haz: 'T', name: 'Platinum compounds', cas: '' },
  { haz: 'T', name: 'Plumbane, tetraethyl-', cas: '78-00-2' },
  {
    haz: 'T',
    name: 'Plutonium (as 239Pu, and its decay products [may contain otheisotopes], as aerosols)',
    cas: '0-83-0',
  },
  { haz: 'T,F', name: 'p-Nitroaniline', cas: '100-01-6' },
  { haz: 'T,F', name: 'p-Nitrophenol', cas: '100-02-7' },
  { haz: 'T', name: 'p-Nitrosodiphenylamine', cas: '156-10-5' },
  { haz: 'T', name: 'Polybrominated Biphenyls {PBBs}', cas: '59536-65-1' },
  { haz: 'T', name: 'Polybrominated Biphenyls {PBBs}', cas: '67774-32-7' },
  { haz: 'T', name: 'Polychlorinated Biphenyls {PCBs}', cas: '1336-36-3' },
  { haz: 'T', name: 'Polychlorinated Dibenzofurans', cas: '0-69-0' },
  { haz: 'T', name: 'Polychlorinated Dibenzo-p-dioxins', cas: '0-68-0' },
  { haz: 'T', name: 'Polychlorocamphene', cas: '' },
  {
    haz: 'T',
    name: 'Polychlorophenols (and their sodium salts) (mixed exposure)',
    cas: '0-54-0',
  },
  {
    haz: 'T',
    name: 'Polycyclic Aromatic Hydrocarbon(s) {PAH; see 15 specifichemicals}',
    cas: '0-50-0',
  },
  { haz: 'T', name: 'Polygeenan', cas: '53973-98-1' },
  { haz: 'T', name: 'Ponceau 3R', cas: '3564-09-8' },
  { haz: 'T', name: 'Ponceau MX', cas: '3761-53-3' },
  { haz: 'T,R,C', name: 'Potassium', cas: '' },
  { haz: 'T', name: 'Potassium Acide Fluoride', cas: '' },
  { haz: 'T', name: 'Potassium Arsenate', cas: '7784-41-0' },
  { haz: 'T', name: 'Potassium arsenate', cas: '' },
  { haz: 'T', name: 'Potassium Arsenite', cas: '13464-35-2' },
  { haz: 'T', name: 'Potassium arsenite', cas: '' },
  { haz: 'T,R,C', name: 'Potassium bifluoride', cas: '' },
  { haz: 'T', name: 'Potassium Bromate', cas: '7758-01-2' },
  {
    haz: 'T',
    name: 'Potassium Chromate, as Cr6+ [water soluble]',
    cas: '7789-00-6',
  },
  { haz: 'T,R,C', name: 'Potassium cyanide K(CN)', cas: '151-50-8' },
  {
    haz: 'T',
    name: 'Potassium Dichromate , as Cr6+ [water soluble]',
    cas: '7778-50-9',
  },
  { haz: 'T,R,C', name: 'Potassium hydride', cas: '' },
  {
    haz: 'T',
    name: 'Potassium Molybdate, as Mo [water soluble]',
    cas: '13446-49-6',
  },
  { haz: 'T,R,C', name: 'Potassium silver cyanide', cas: '506-61-6' },
  { haz: 'T', name: 'Primidone', cas: '125-33-7' },
  { haz: 'T', name: 'Procarbazine', cas: '671-16-9' },
  { haz: 'T', name: 'Procarbazine Hydrochloride', cas: '366-70-1' },
  { haz: 'T', name: 'Procymidone', cas: '32809-16-8' },
  { haz: 'T', name: 'Progesterone (Indirect)', cas: '57-83-0' },
  { haz: 'T', name: 'Progestins', cas: '0-21-0' },
  { haz: 'T', name: 'Promecarb', cas: '2631-37-0' },
  { haz: 'T', name: 'Pronamide', cas: '23950-58-5' },
  { haz: 'T', name: 'Propachlor', cas: '1918-16-7' },
  {
    haz: 'T',
    name: 'Propanal, 2-methyl-2-(methyl-sulfonyl)-, O- [(methylamino)carbonyl] oxime.',
    cas: '1646-88-4',
  },
  {
    haz: 'T',
    name: 'Propanal, 2-methyl-2-(methylthio)-,O[(methylamino)carbonyl]oxime',
    cas: '116-06-3',
  },
  { haz: 'T', name: 'Propane, 1,2-dibromo-3-chloro-', cas: '96-12-8' },
  { haz: 'T', name: 'Propane, 1,2-dichloro-', cas: '78-87-5' },
  { haz: 'T', name: "Propane, 2,2'-oxybis[2-chloro-", cas: '108-60-1' },
  { haz: 'T', name: 'Propane, 2-nitro-', cas: '79-46-9' },
  { haz: 'T', name: 'Propanedinitrile', cas: '109-77-3' },
  { haz: 'T', name: 'Propanenitrile', cas: '107-12-0' },
  { haz: 'T', name: 'Propanenitrile, 2-hydroxy-2-methyl-', cas: '75-86-5' },
  { haz: 'T', name: 'Propanenitrile, 3-chloro-', cas: '542-76-7' },
  {
    haz: 'T',
    name: 'Propanoic acid, 2-(2,4,5-trichlorophenoxy)-',
    cas: '93-72-1',
  },
  { haz: 'T', name: 'Propargite', cas: '2312-35-8' },
  { haz: 'T', name: 'Propargyl alcohol', cas: '107-19-7' },
  { haz: 'T', name: 'Propargyl bromide', cas: '' },
  { haz: 'T', name: 'Propham.', cas: '122-42-9' },
  { haz: 'T', name: 'Propoxur.', cas: '114-26-1' },
  { haz: 'T', name: 'Propylene dichloride', cas: '78-87-5' },
  { haz: 'T', name: 'Propylene Oxide', cas: '75-56-9' },
  { haz: 'T', name: 'Propyleneimine', cas: '75-55-8' },
  { haz: 'T', name: 'Propylthiouracil', cas: '51-52-5' },
  { haz: 'T', name: 'p-Rosaniline', cas: '569-61-9' },
  { haz: 'T', name: 'Prosulfocarb.', cas: '52888-80-9' },
  { haz: 'T', name: 'Prothoate', cas: '' },
  { haz: 'T', name: 'p-Toluidine', cas: '106-49-0' },
  { haz: 'T', name: 'Pyranol', cas: '' },
  { haz: 'T, F', name: 'Pyridine', cas: '110-86-1' },
  { haz: 'T,F', name: 'Pyridine, 2-methyl-', cas: '109-06-8' },
  {
    haz: 'T,F',
    name: 'Pyridine, 3-(1-methyl-2-pyrrolidinyl)-, (S)-, & salts',
    cas: '54-11-5',
  },
  { haz: 'T', name: 'Pyrosulfuryl chloride', cas: '' },
  { haz: 'T', name: 'Pyrrolidine, 1-nitroso-', cas: '930-55-2' },
  {
    haz: 'T',
    name: 'Pyrrolo[2,3-b]indol-5-ol,1,2,3,3a,8,8a-hexahydro-1,3a,8- trimethyl-, methylcarbamate (ester), (3aS-cis)-.',
    cas: '57-47-6',
  },
  {
    haz: 'T',
    name: 'Quartz {Silica (respirable) - Crystalline}',
    cas: '14808-60-7',
  },
  { haz: 'T', name: 'Quinoline (and its strong acid salts)', cas: '0-70-0' },
  { haz: 'T', name: 'Quinone', cas: '' },
  { haz: 'T', name: 'RACUMIN 57', cas: '' },
  {
    haz: 'T',
    name: 'Radium (as 224Ra, and its decay products)',
    cas: '0-87-0',
  },
  {
    haz: 'T',
    name: 'Radium (as 226Ra, and its decay products)',
    cas: '0-88-0',
  },
  {
    haz: 'T',
    name: 'Radium (as 228Ra, and its decay products)',
    cas: '0-89-0',
  },
  { haz: 'T', name: 'Radon (as 222Rn, and its decay products)', cas: '0-90-0' },
  { haz: 'T', name: 'RATOX', cas: '' },
  { haz: 'T', name: 'Refractory Ceramic Fiber', cas: '409-21-2' },
  { haz: 'T', name: 'Reserpine', cas: '50-55-5' },
  { haz: 'T', name: 'Residual (Heavy) Fuel Oil', cas: '68476-33-5' },
  { haz: 'T', name: 'Resorcinol', cas: '108-46-3' },
  { haz: 'T', name: 'Rockwool', cas: '0-23-0' },
  {
    haz: 'T',
    name: 'Rubidium Chromate, as Cr6+ [water soluble]',
    cas: '13446-72-5',
  },
  {
    haz: 'T',
    name: 'Rubidium Dichromate, as Cr6+ [water soluble]',
    cas: '13446-73-6',
  },
  {
    haz: 'T',
    name: 'S,S-1,4-dioxane-2,3-diyl bis(O,O-diethyl phosphorodithioate)',
    cas: '',
  },
  {
    haz: '',
    name: 'S-[(5-Methoxy-2-oxo-1,3,4-thia-diazo13(2H)-yl)methyl] -O,O- dimethyl phosphorodithioate',
    cas: '',
  },
  {
    haz: '',
    name: 'S-[(5-Methoxy-4-oxo-4H-pyran-2-yl)-methyl] O,O-dimethyl phosphorothioate',
    cas: '',
  },
  {
    haz: 'T',
    name: 'S[[(4-Chlorophenyl)thio]methyl] 0,0-diethyl phosphorodithioate',
    cas: '',
  },
  {
    haz: 'T',
    name: 'S-[2-(ethyl-sulfonyl)ethyl] O,O-dimethyl phosphorothioate',
    cas: '',
  },
  { haz: 'T', name: 'Saccharin, & salts', cas: '81-07-2' },
  { haz: 'T', name: 'Saccharin, Sodium Salt', cas: '128-44-9' },
  { haz: 'T', name: 'Safrole', cas: '94-59-7' },
  { haz: 'T', name: 'Salicylated mercury', cas: '' },
  { haz: 'T', name: 'Salicylazosulfapyridine', cas: '599-79-1' },
  { haz: 'T', name: 'Samarium Chromate Dihydrate, as Cr6+', cas: '58569-17-8' },
  {
    haz: 'T',
    name: 'Samarium Chromate Heptahydrate, as Cr6+',
    cas: '58477-24-0',
  },
  { haz: 'T', name: 'Samarium Chromate, as Cr6+', cas: '16565-96-1' },
  { haz: 'T', name: 'Schradan', cas: '' },
  { haz: 'T,C', name: 'Selenious acid', cas: '7783-00-8' },
  { haz: 'T,C', name: 'Selenious acid and salts', cas: '' },
  {
    haz: 'T,C',
    name: 'Selenious acid, dithallium(1+) salt',
    cas: '12039-52-0',
  },
  { haz: 'T', name: 'Selenium and/or selenium compounds', cas: '7782-49-2' },
  { haz: 'T', name: 'Selenium dioxide', cas: '7783-00-8' },
  { haz: 'T', name: 'Selenium fluoride', cas: '' },
  { haz: 'T,R,C', name: 'Selenium Sulfide', cas: '7446-34-6' },
  { haz: 'T,R,C', name: 'Selenium sulfide', cas: '7488-56-4' },
  { haz: 'T', name: 'Selenourea', cas: '630-10-4' },
  { haz: 'T,C', name: 'Selenous acid', cas: '' },
  { haz: 'T', name: 'Semustine', cas: '13909-09-6' },
  { haz: 'T', name: 'Shale Oils', cas: '68308-34-9' },
  {
    haz: 'T',
    name: 'Silica (respirable) - Crystalline {Cristobalite}',
    cas: '14464-46-1',
  },
  {
    haz: 'T',
    name: 'Silica (respirable) - Crystalline {Quartz}',
    cas: '14808-60-7',
  },
  {
    haz: 'T',
    name: 'Silica (respirable) - Crystalline {Tridymite}',
    cas: '15468-32-3',
  },
  {
    haz: 'T',
    name: 'Silica (respirable) - Crystalline {Tripoli}',
    cas: '1317-95-9',
  },
  { haz: 'T', name: 'Silicochloroform', cas: '' },
  {
    haz: 'T',
    name: 'Silicon Carbide (fibrous forms, including whiskers)',
    cas: '409-21-2',
  },
  { haz: 'T', name: 'Silicon Chloride', cas: '' },
  {
    haz: 'T',
    name: 'Silicon Dioxide - [see specific crystalline silica form]',
    cas: '7631-86-9',
  },
  { haz: 'T,R,C', name: 'Silicon tetrachloride', cas: '' },
  { haz: 'T', name: 'Silver', cas: '7440-22-4' },
  { haz: 'T', name: 'Silver acetylide', cas: '' },
  { haz: 'T', name: 'Silver and/or silver compounds', cas: '' },
  { haz: 'T', name: 'Silver Chromate, as Cr6+', cas: '7784-01-2' },
  { haz: 'T,R', name: 'Silver cyanide Ag(CN)', cas: '506-64-9' },
  { haz: 'T', name: 'Silver Dichromate, as Cr6+', cas: '7784-02-3' },
  { haz: 'T', name: 'Silvex (2,4,5-TP)', cas: '93-72-1' },
  { haz: 'T', name: 'SINOX', cas: '' },
  { haz: 'T', name: 'Slagwool', cas: '0-24-0' },
  {
    haz: 'T',
    name: 'S-Methyl-N-((methyl-carbamoyl)oxy) thioacetimidate',
    cas: '',
  },
  { haz: 'T', name: 'Sodamide', cas: '' },
  { haz: 'T', name: 'Sodium', cas: '' },
  { haz: 'T', name: 'Sodium Acid Fluoride', cas: '' },
  { haz: 'T', name: 'Sodium aluminum hydride', cas: '' },
  { haz: 'T', name: 'Sodium amide', cas: '' },
  { haz: 'T', name: 'Sodium Arsenate', cas: '7631-89-2' },
  { haz: 'T', name: 'Sodium Arsenate', cas: '15120-17-9' },
  { haz: 'T', name: 'Sodium Arsenite', cas: '7784-46-5' },
  { haz: 'T', name: 'Sodium azide', cas: '26628-22-8' },
  { haz: 'T', name: 'Sodium bifluoride', cas: '' },
  { haz: 'T', name: 'Sodium cacodylate', cas: '' },
  {
    haz: 'T',
    name: 'Sodium Chromate, as Cr6+ [water soluble]',
    cas: '7775-11-3',
  },
  { haz: 'T,R', name: 'Sodium cyanide Na(CN)', cas: '143-33-9' },
  {
    haz: 'T',
    name: 'Sodium Dichromate, as Cr6+ [water soluble]',
    cas: '10588-01-9',
  },
  {
    haz: 'T',
    name: 'Sodium Dichromate, as Cr6+ [water soluble]',
    cas: '12018-32-5',
  },
  { haz: 'T', name: 'Sodium dimethylarsenate', cas: '' },
  { haz: 'T', name: 'Sodium Equilin Sulfate', cas: '16680-47-0' },
  { haz: 'T', name: 'Sodium Estrone Sulfate', cas: '438-67-5' },
  { haz: 'T', name: 'Sodium hydride', cas: '' },
  { haz: 'T,R', name: 'Sodium hypochlorite', cas: '' },
  { haz: 'T', name: 'Sodium methoxide', cas: '' },
  { haz: 'T', name: 'Sodium methylate', cas: '' },
  {
    haz: 'T',
    name: 'Sodium Molybdate Dihydrate, as Mo [water soluble]',
    cas: '10102-40-6',
  },
  {
    haz: 'T',
    name: 'Sodium Molybdate, as Mo [water soluble]',
    cas: '7631-95-0',
  },
  { haz: 'T', name: 'Sodium o-Phenylphenate', cas: '132-27-4' },
  { haz: 'T,R', name: 'Sodium peroxide', cas: '' },
  { haz: 'T', name: 'Sodium potassium alloy', cas: '' },
  { haz: 'T', name: 'Sodium selenate', cas: '' },
  { haz: 'T', name: 'Solar Radiation, as UV radiation', cas: '0-30-0' },
  { haz: 'T', name: 'Soot extracts (containing PAHs)', cas: '0-39-0' },
  { haz: 'T', name: 'Soots {PAH}', cas: '0-25-0' },
  {
    haz: 'T',
    name: 'S-para-Chlorophenyl-2,4,5-trichlorophenyl sulfide',
    cas: '',
  },
  { haz: 'T', name: 'Spironolactone', cas: '52-01-7' },
  {
    haz: 'T',
    name: 'Stannic Chromate, as Cr6+ [water soluble]',
    cas: '38455-77-5',
  },
  { haz: 'T', name: 'Stanozolol', cas: '10418-03-8' },
  { haz: 'T', name: 'Sterigmatocystin', cas: '10048-13-2' },
  { haz: 'T', name: 'Streptozotocin', cas: '18883-66-4' },
  { haz: 'T', name: 'Streptozotocin', cas: '18883-66-4' },
  { haz: 'T', name: 'Strontium arsenate', cas: '' },
  { haz: 'T', name: 'Strontium Chromate, as Cr6+', cas: '7789-06-2' },
  { haz: 'T', name: 'Strontium Chromate, as Cr6+', cas: '54322-60-0' },
  { haz: 'T', name: 'Strychnidin-10-one, & salts', cas: '57-24-9' },
  { haz: 'T', name: 'Strychnidin-10-one, 2,3-dimethoxy-', cas: '357-57-3' },
  { haz: 'T', name: 'Strychnine, & salts', cas: '57-24-9' },
  { haz: 'T', name: 'Styrene Epoxide', cas: '96-09-3' },
  { haz: 'T', name: 'Styrene Oxide', cas: '96-09-3' },
  { haz: 'T', name: 'Styrene, Monomer', cas: '100-42-5' },
  { haz: 'T', name: 'Styrene-7,8-oxide', cas: '96-09-3' },
  { haz: 'T', name: 'Sulfallate', cas: '95-06-7' },
  { haz: 'T,C', name: 'Sulfonyl Chloride', cas: '' },
  { haz: 'T,C', name: 'Sulfonyl Fluoride', cas: '' },
  { haz: 'T', name: 'Sulfotepp', cas: '' },
  { haz: 'T', name: 'Sulfur chloride', cas: '' },
  { haz: 'T', name: 'Sulfur Monochcloride', cas: '' },
  { haz: 'T', name: 'Sulfur mustard', cas: '' },
  { haz: 'T,C', name: 'Sulfur oxychloride', cas: '' },
  { haz: 'T,C', name: 'Sulfur pentafluoride', cas: '' },
  { haz: 'T', name: 'Sulfur phosphide', cas: '1314-80-3' },
  {
    haz: 'T,C',
    name: 'Sulfuric Acid Mist (occ. exposure to strong inorganic acid mists)',
    cas: '0-35-0',
  },
  { haz: 'T,C', name: 'Sulfuric acid, dimethyl ester', cas: '77-78-1' },
  { haz: 'T,C', name: 'Sulfuric acid, dithallium(1+) salt', cas: '7446-18-6' },
  { haz: 'T', name: 'Sulfuryl chloride', cas: '' },
  { haz: 'T', name: 'Sulfuryl fluoride', cas: '' },
  {
    haz: 'T',
    name: 'Sunlamps and sunbeds, use of [as UV radiation]',
    cas: '0-34-0',
  },
  { haz: 'T', name: 'SUPRACIDE', cas: '' },
  { haz: 'T', name: 'SURECIDE', cas: '' },
  {
    haz: 'T',
    name: 'Synthetic Vitreous Fibers (see glasswool, rockwool, slagwool)',
    cas: '0-44-0',
  },
  { haz: 'T', name: 'SYSTOX', cas: '' },
  { haz: 'T', name: 'Talc (containing asbestos fibers)', cas: '0-75-0' },
  { haz: 'T', name: 'Tamoxifen', cas: '10540-29-1' },
  { haz: 'T', name: 'Tars', cas: '0-78-0' },
  { haz: 'T', name: 'TCDD', cas: '1746-01-6' },
  { haz: 'T', name: 'TDI', cas: '584-84-9' },
  { haz: 'T', name: 'TEDP', cas: '' },
  { haz: 'T', name: 'Tellurium hexafluoride', cas: '' },
  { haz: 'T', name: 'TELODRIN', cas: '' },
  { haz: 'T', name: 'TEMIK', cas: '' },
  { haz: 'T', name: 'Teniposide', cas: '29767-20-2' },
  { haz: 'T', name: 'TEPA', cas: '' },
  { haz: 'T', name: 'Terrazole', cas: '2593-15-9' },
  { haz: 'T', name: 'Testosterone (and its esters)', cas: '58-22-0' },
  { haz: 'T', name: 'Tetrachloroethylene', cas: '127-18-4' },
  { haz: 'T', name: 'Tetrachloromethane', cas: '56-23-5' },
  { haz: 'T', name: 'Tetracobalt Dodecacarbonyl, as Co', cas: '17786-31-1' },
  { haz: 'T', name: 'Tetraethyl lead', cas: '78-00-2' },
  { haz: 'T', name: 'Tetraethyl pyrophosphate', cas: '107-49-3' },
  { haz: 'T', name: 'Tetraethyldithiopyrophosphate', cas: '3689-24-5' },
  { haz: 'T', name: 'Tetrafluoroethylene', cas: '116-14-3' },
  { haz: 'T', name: 'Tetrahydrofuran', cas: '109-99-9' },
  { haz: 'T', name: 'Tetramethyl succinonitrile', cas: '' },
  { haz: 'T', name: 'Tetramethylphosphorodiamidic fluoride', cas: '' },
  { haz: 'T', name: 'Tetranitromethane', cas: '509-14-8' },
  { haz: 'T', name: 'Tetraphosphoric acid, hexaethyl ester', cas: '757-58-4' },
  { haz: 'T', name: 'Tetraphosphorus Trisulfide', cas: '' },
  { haz: 'T', name: 'Tetrasul', cas: '' },
  { haz: 'T', name: 'Thallic oxide', cas: '1314-32-5' },
  { haz: 'T', name: 'Thallium and/or thallium compounds', cas: '' },
  { haz: 'T', name: 'Thallium chloride Tlcl', cas: '7791-12-0' },
  { haz: 'T', name: 'Thallium Chromate, as Cr6+', cas: '13473-75-1' },
  { haz: 'T', name: 'Thallium Chromate, as Cr6+', cas: '15190-21-3' },
  { haz: 'T', name: 'Thallium Chromate, as Cr6+', cas: '22534-09-4' },
  { haz: 'T', name: 'Thallium Dichromate, as Cr6+', cas: '13453-35-5' },
  { haz: 'T', name: 'Thallium Sulfate', cas: '' },
  { haz: 'T', name: 'Thallium(I) acetate', cas: '563-68-8' },
  { haz: 'T', name: 'Thallium(I) carbonate', cas: '6533-73-9' },
  { haz: 'T', name: 'Thallium(I) chloride', cas: '7791-12-0' },
  { haz: 'T', name: 'Thallium(I) nitrate', cas: '10102-45-1' },
  { haz: 'T', name: 'Thallium(I) selenite', cas: '12039-52-0' },
  { haz: 'T', name: 'Thallium(I) sulfate', cas: '7446-18-6' },
  { haz: 'T', name: 'Thallous sulfate', cas: '' },
  { haz: 'T', name: 'Thioacetamide', cas: '62-55-5' },
  { haz: 'T', name: 'Thiocarbonylchloride', cas: '' },
  { haz: 'T', name: 'Thiodicarb', cas: '59669-26-0' },
  {
    haz: 'T',
    name: 'Thiodiphosphoric acid, tetraethylester',
    cas: '3689-24-5',
  },
  { haz: 'T', name: 'Thiofanox', cas: '39196-18-4' },
  { haz: 'T', name: 'Thioimidodicarbonic diamide', cas: '541-53-7' },
  { haz: 'T', name: 'Thiomethanol', cas: '74-93-1' },
  { haz: 'T', name: 'Thionazin', cas: '' },
  { haz: 'T,R,C', name: 'Thionyl chloride', cas: '' },
  {
    haz: 'T',
    name: 'Thioperoxydicarbonic diamide[(tetramethyl-',
    cas: '137-26-8',
  },
  { haz: 'T', name: 'Thiophanate-methyl.', cas: '23564-05-8' },
  { haz: 'T', name: 'Thiophenol', cas: '108-98-5' },
  { haz: 'T', name: 'Thiophosgene', cas: '' },
  { haz: 'T', name: 'Thiophosphoryl chloride', cas: '' },
  { haz: 'T', name: 'Thiosemicarbazide', cas: '79-19-6' },
  { haz: 'T', name: 'Thiotepa', cas: '52-24-4' },
  { haz: 'T', name: 'Thiouracil', cas: '141-90-2' },
  { haz: 'T', name: 'Thiourea', cas: '62-56-6' },
  { haz: 'T', name: 'Thiourea, (2-chlorophenyl)-', cas: '5344-82-1' },
  { haz: 'T', name: 'Thiourea, 1-naphthalenyl-', cas: '86-88-4' },
  { haz: 'T', name: 'Thiourea, phenyl-', cas: '103-85-5' },
  { haz: 'T', name: 'Thiram', cas: '137-26-8' },
  {
    haz: 'T',
    name: 'Thorium (as 232Th, and its decay products)',
    cas: '0-91-0',
  },
  { haz: 'T', name: 'Thorium Dioxide', cas: '1314-20-1' },
  {
    haz: 'T',
    name: 'Tin (IV) Chromate, as Cr6+ [water soluble]',
    cas: '38455-77-5',
  },
  { haz: 'T', name: 'Tirpate.', cas: '26419-73-8' },
  { haz: 'T', name: 'Titanic Chloride', cas: '' },
  { haz: 'T', name: 'Titanium tetrachloride', cas: '' },
  { haz: 'T', name: 'Tobacco Products, Smokeless', cas: '0-26-0' },
  { haz: 'T', name: 'Tobacco Smoke', cas: '0-27-0' },
  { haz: 'T,F', name: 'Toluene', cas: '108-88-3' },
  { haz: 'T', name: 'Toluene Diisocyanate', cas: '26471-62-5' },
  { haz: 'T', name: 'Toluene-2,4-diamine', cas: '95-80-7' },
  { haz: 'T', name: 'Toluene-2,4-diisocyanate', cas: '584-84-9' },
  { haz: 'T', name: 'Toluene-2,6-diisocyanate', cas: '91-08-7' },
  { haz: 'T', name: 'Toluenediamine', cas: '25376-45-8' },
  { haz: 'T', name: 'Toxaphene', cas: '8001-35-2' },
  { haz: 'T', name: 'TRANID', cas: '' },
  {
    haz: 'T',
    name: 'trans-2-[(Dimethylamino)methylimino]-5-[2-(5-nitro-2-furyl)vinyl]- 1,3,4-oxadiazole',
    cas: '25962-77-0',
  },
  {
    haz: 'T',
    name: 'trans-2-[(Dimethylamino)methylimino]-5-[2-(5-nitro-2-furyl)vinyl]- 1,3,4-oxadiazole',
    cas: '55738-54-0',
  },
  { haz: 'T', name: 'Tremolite [asbestiform]', cas: '14567-73-8' },
  { haz: 'T', name: 'Treosulfan', cas: '299-75-2' },
  { haz: 'T', name: 'Treosulphan', cas: '299-75-2' },
  { haz: 'T', name: 'Triallate.', cas: '2303-17-5' },
  { haz: 'T', name: 'Triamiphos', cas: '' },
  { haz: 'T', name: 'Triaziquone', cas: '68-76-8' },
  { haz: 'T', name: 'Trichlormethine', cas: '817-09-4' },
  { haz: 'T', name: 'Trichloroborane', cas: '' },
  { haz: 'T,F', name: 'Trichloroethylene', cas: '79-01-6' },
  { haz: 'T,F', name: 'Trichloromethane', cas: '67-66-3' },
  { haz: 'T', name: 'Trichloromethanethiol', cas: '75-70-7' },
  { haz: 'T', name: 'Trichloromethylsulfenylchloride', cas: '' },
  { haz: 'T', name: 'Trichloromonofluoromethane', cas: '75-69-4' },
  { haz: 'T,F', name: 'Trichloronitromethane', cas: '' },
  { haz: 'T,R', name: 'Trichlorosilane', cas: '' },
  {
    haz: 'T',
    name: 'Tridymite {Silica (respirable) - Crystalline}',
    cas: '15468-32-3',
  },
  { haz: 'T,R,C', name: 'Triethylamine.', cas: '121-44-8' },
  { haz: 'T', name: 'Triethylenephospho-ramide', cas: '' },
  { haz: 'T', name: 'Trifluoromethylbenzene', cas: '' },
  { haz: 'T', name: 'Trimethyl Phosphate', cas: '512-56-1' },
  { haz: 'T', name: 'Trimustine Hydrochloride', cas: '817-09-4' },
  { haz: 'T', name: 'Triphenyltin Hydroxide', cas: '76-87-9' },
  {
    haz: 'T',
    name: 'Tripoli {Silica (respirable) - Crystalline}',
    cas: '1317-95-9',
  },
  { haz: 'T', name: 'tris(1-Aziridinyl) phosphine oxide', cas: '' },
  { haz: 'T', name: 'tris(1-Aziridinyl)phosphine Sulfide', cas: '52-24-4' },
  { haz: 'T', name: 'tris(2,3-Dibromopropyl) Phosphate', cas: '126-72-7' },
  { haz: 'T', name: 'tris(2-Chloroethyl) Phosphate', cas: '115-96-8' },
  { haz: 'T', name: 'tris(Aziridinyl)-p-benzoquinone', cas: '68-76-8' },
  { haz: 'T', name: 'Trisodium Arsenate Heptahydrate', cas: '64070-83-3' },
  { haz: 'T', name: 'TRITHION', cas: '' },
  {
    haz: 'T',
    name: 'Trp-P-1(3-Amino-1,4-dimethyl-5H-pyrido[4,3-b]indole)',
    cas: '62450-06-0',
  },
  { haz: 'T', name: 'Trp-P-1(Tryptophan-P-1)', cas: '62450-06-0' },
  {
    haz: 'T',
    name: 'Trp-P-2(3-Amino-1-methyl-5H-pyrido[4,3-b]indole)',
    cas: '62450-07-1',
  },
  { haz: 'T', name: 'Trp-P-2(Tryptophan-P-2)', cas: '62450-07-1' },
  { haz: 'T', name: 'Trypan blue', cas: '72-57-1' },
  { haz: 'T', name: 'Trypan Blue (commercial grade)', cas: '72-57-1' },
  { haz: 'T', name: 'UDMH', cas: '57-14-7' },
  { haz: 'T', name: 'ULTRACIDE', cas: '' },
  {
    haz: 'T',
    name: 'Ultraviolet A Radiation {UV A @ 315-400 nm}',
    cas: '0-31-0',
  },
  {
    haz: 'T',
    name: 'Ultraviolet B Radiation {UV B @ 280-315 nm}',
    cas: '0-32-0',
  },
  {
    haz: 'T',
    name: 'Ultraviolet C Radiation {UV C @ 100-280 nm}',
    cas: '0-33-0',
  },
  { haz: 'T', name: 'Unleaded Gasoline (wholly vaporized)', cas: '0-73-0' },
  { haz: 'T', name: 'Uracil Mustard', cas: '66-75-1' },
  { haz: 'T', name: 'Uracil mustard', cas: '66-75-1' },
  {
    haz: 'T',
    name: 'Uranium, natural [soluble & insoluble compounds]',
    cas: '7440-61-1',
  },
  { haz: 'T', name: 'Urea, N-ethyl-N-nitroso-', cas: '759-73-9' },
  { haz: 'T', name: 'Urea, N-methyl-N-nitroso-', cas: '684-93-5' },
  { haz: 'T', name: 'Urethane', cas: '51-79-6' },
  { haz: 'T', name: 'Vanadic acid, ammonium salt', cas: '7803-55-6' },
  { haz: 'T', name: 'Vanadium and/or vanadium compounds', cas: '' },
  { haz: 'T', name: 'Vanadium pentoxide', cas: '1314-62-1' },
  { haz: 'T', name: 'VAPONA', cas: '' },
  { haz: 'T', name: 'Vinclozolin', cas: '50471-44-8' },
  { haz: 'T', name: 'Vinyl Acetate', cas: '108-05-4' },
  { haz: 'T', name: 'Vinyl Benzene', cas: '100-42-5' },
  { haz: 'T', name: 'Vinyl Bromide', cas: '593-60-2' },
  { haz: 'T', name: 'Vinyl chloride', cas: '75-01-4' },
  { haz: 'T,R', name: 'Vinyl Cyanide [1910.1045]', cas: '107-13-1' },
  { haz: 'T', name: 'Vinyl Cyclohexene Dioxide', cas: '106-87-6' },
  { haz: 'T', name: 'Vinyl Fluoride', cas: '75-02-5' },
  { haz: 'T', name: 'Vinyl Trichloride', cas: '79-00-5' },
  { haz: 'T', name: 'Vinylamine, N-methyl-N-nitroso-', cas: '4549-40-0' },
  { haz: 'T', name: 'Vinyltrichlorosilane', cas: '' },
  {
    haz: 'T',
    name: 'Warfarin, & salts, when present at concentrations greater tha0.3%',
    cas: '81-81-2',
  },
  { haz: 'T', name: 'Welding Fumes', cas: '0-49-0' },
  { haz: 'T', name: 'WEPSYN 155', cas: '' },
  { haz: 'T', name: 'Wood Dust', cas: '0-43-0' },
  { haz: 'T', name: 'Wood Dust [beech & oak]', cas: '0-42-0' },
  {
    haz: 'T',
    name: 'Wood Dust [birch, mahogany, walnut & teak]',
    cas: '0-57-0',
  },
  { haz: 'T', name: 'WP 155', cas: '' },
  { haz: 'T', name: 'X-Radiation', cas: '0-92-0' },
  { haz: 'T,F', name: 'Xylene', cas: '1330-20-7' },
  {
    haz: 'T',
    name: 'trimethoxybenzoyl)oxy]-, methyl este(3beta,16beta,17alpha,18beta,20alpha)-',
    cas: '50-55-5',
  },
  { haz: 'T', name: 'Zalcitabine', cas: '7481-89-2' },
  { haz: 'T', name: 'ZECTRAM', cas: '' },
  { haz: 'T', name: 'Zidovudine', cas: '30516-87-1' },
  { haz: 'T', name: 'Zileuton', cas: '111406-87-2' },
  { haz: 'T', name: 'Zinc and/or zinc compounds', cas: '' },
  { haz: 'T', name: 'Zinc arsenate', cas: '' },
  { haz: 'T', name: 'Zinc arsenite', cas: '' },
  { haz: 'T', name: 'Zinc Beryllium Silicate, as Be', cas: '39413-47-3' },
  { haz: 'T', name: 'Zinc Chromate Hydroxide, as Cr6+', cas: '12206-12-1' },
  { haz: 'T', name: 'Zinc Chromate Hydroxide, as Cr6+', cas: '15930-94-6' },
  { haz: 'T', name: 'Zinc Chromate Hydroxide, as Cr6+', cas: '66516-58-3' },
  { haz: 'T', name: 'Zinc Chromate, as Cr6+', cas: '1308-13-0' },
  { haz: 'T', name: 'Zinc Chromate, as Cr6+', cas: '1328-67-2' },
  { haz: 'T', name: 'Zinc Chromate, as Cr6+', cas: '13530-65-9' },
  { haz: 'T', name: 'Zinc Chromate, as Cr6+', cas: '14675-41-3' },
  { haz: 'T', name: 'Zinc Chromate, as Cr6+', cas: '37300-23-5' },
  { haz: 'T', name: 'Zinc Chromate, as Cr6+', cas: '57486-12-1' },
  { haz: 'T,R', name: 'Zinc cyanide', cas: '557-21-1' },
  { haz: 'T', name: 'Zinc ethyl', cas: '' },
  { haz: 'T', name: 'Zinc phosphide', cas: '' },
  { haz: 'T', name: 'Zinc phosphide', cas: '1314-84-7' },
  {
    haz: 'T',
    name: 'Zinc phosphide when present atconcentrations of 10% or less',
    cas: '1314-84-7',
  },
  {
    haz: 'T',
    name: 'Zinc Potassium Chromate (Hydroxide), as Cr6+',
    cas: '11103-86-9',
  },
  {
    haz: 'T',
    name: 'Zinc Potassium Chromate (Hydroxide), as Cr6+',
    cas: '12527-08-1',
  },
  {
    haz: 'T',
    name: 'Zinc Potassium Chromate (Hydroxide), as Cr6+',
    cas: '37809-34-0',
  },
  { haz: 'T', name: 'Zinc Yellow, as Cr6+', cas: '1308-13-0' },
  { haz: 'T', name: 'Zinc Yellow, as Cr6+', cas: '1328-67-2' },
  { haz: 'T', name: 'Zinc Yellow, as Cr6+', cas: '13530-65-9' },
  { haz: 'T', name: 'Zinc Yellow, as Cr6+', cas: '14675-41-3' },
  { haz: 'T', name: 'Zinc Yellow, as Cr6+', cas: '37300-23-5' },
  { haz: 'T', name: 'Zinc Yellow, as Cr6+', cas: '57486-12-1' },
  {
    haz: 'T',
    name: "Zinc, bis(dimethylcarbamodithioato- S,S')-,",
    cas: '137-30-4',
  },
  { haz: 'T', name: 'ZINOPHOS', cas: '' },
  { haz: 'T', name: 'Ziram.', cas: '137-30-4' },
  { haz: 'T', name: 'Zirconium chloride', cas: '' },
  { haz: 'T', name: 'Zirconium Tetrachloride', cas: '' },
];
