import cn from 'classnames';
import Document from './Document';
import classes from './UploadEditor.module.scss';

const Documents = ({
  className,
  onViewDocument,
  onDeleteDocument,
  documents,
  notFoundText,
  isArchived,
}) => {
  return (
    <div className={cn(classes.documentsWrapper, className)}>
      {!documents?.length && !!notFoundText && (
        <span className={classes.documentsNotFoundText}>{notFoundText}</span>
      )}
      {(documents || []).map((item) => {
        return (
          <Document
            key={item.id}
            onView={() => {
              onViewDocument(item);
            }}
            onDelete={() => {
              onDeleteDocument(item);
            }}
            data={item}
            isArchived={isArchived}
          />
        );
      })}
    </div>
  );
};

export default Documents;
