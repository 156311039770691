import isEmpty from 'lodash/isEmpty';
import Button from 'src/components/Button';
import FieldsRow from 'src/components/Form/FieldsRow';
import FormDatepicker from 'src/components/FormFields/Datepicker/FormDatepicker';
import FormGroup from 'src/components/FormFields/FormGroup';
import FormInput from 'src/components/FormFields/Input/FormInput';
import PageFooter from 'src/components/Page/PageFooter';
import CreateSubSystemView from '../View';

const CreateSubSystemForm = ({
  onSubmit,
  isViewDetails,
  details,
  control,
  formState,
  isEdit,
  submitLoading,
  onCancel,
}) => {
  return (
    <>
      {isViewDetails ? (
        <CreateSubSystemView details={details} />
      ) : (
        <form role="form" className="s-form" onSubmit={onSubmit}>
          <div className="s-form-inner">
            <FieldsRow col={1}>
              <FormGroup>
                <FormInput
                  required
                  label="Name"
                  name="sub_system_name"
                  placeholder="Enter name"
                  control={control}
                  radius="md"
                  variant="primary"
                  fontSize="sm"
                />
              </FormGroup>
            </FieldsRow>
            <FieldsRow col={2}>
              <FormGroup>
                <FormDatepicker
                  required
                  label="Last Inspection"
                  name="last_inspection"
                  placeholder="Select"
                  control={control}
                  radius="md"
                  variant="primary"
                  fontSize="sm"
                />
              </FormGroup>
              <FormGroup>
                <FormInput
                  label="Documents Count"
                  name="documents_count"
                  placeholder="Enter count"
                  control={control}
                  radius="md"
                  variant="primary"
                  fontSize="sm"
                  type="number"
                />
              </FormGroup>
            </FieldsRow>
            <FieldsRow col={1}>
              <FormGroup>
                <FormInput
                  label="Description"
                  name="description"
                  placeholder="Enter description"
                  control={control}
                  radius="md"
                  variant="primary"
                  fontSize="sm"
                  type="textarea"
                />
              </FormGroup>
            </FieldsRow>
          </div>
          <PageFooter fixed>
            <Button variant="gray" type="button" onClick={onCancel}>
              Cancel
            </Button>
            <Button
              variant="primary"
              type="submit"
              disabled={!formState.isValid || isEmpty(formState.dirtyFields)}
              loading={submitLoading}
            >
              {isEdit ? 'Save Changes' : 'Add'}
            </Button>
          </PageFooter>
        </form>
      )}
    </>
  );
};

export default CreateSubSystemForm;
