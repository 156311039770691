import { RESOURCE_DATA_KEY } from 'src/helper/constants/store';
import useResourceActions from 'src/helper/hooks/useResourceActions';
import useUploadFile from 'src/helper/hooks/useUploadFile';
import { getErrorMessageFromResponse } from 'src/utils/actions';
import { getErrorMessage, getSuccessMessage } from 'src/utils/toast';

const useFunction = ({ documentId, notify }) => {
  const { onUpdateResourceFile } = useResourceActions();
  const { onUpload } = useUploadFile();
  const reloadCb = (res, message) => {
    if (res?.isSuccess) {
      notify(...getSuccessMessage(message));
    } else {
      notify(...getErrorMessage(getErrorMessageFromResponse(res)));
    }
  };
  const onUploadFile = async (tFile) => {
    const uploadedDocumentId = await onUpload(tFile);
    return {
      isSuccess: !!uploadedDocumentId,
      data: { documentId: uploadedDocumentId },
    };
  };
  const onUploadNewVersion = async (uploadedDocumentId) => {
    if (documentId && uploadedDocumentId) {
      const res = await onUpdateResourceFile(
        RESOURCE_DATA_KEY.QUICK_ACCESS_ITEM_DOCUMENT_DETAILS,
        documentId,
        {
          document_uuid: uploadedDocumentId,
        }
      );
      return res;
    }
    return { isSuccess: false };
  };

  return {
    onUploadNewVersion,
    onUploadFile,
  };
};

export default useFunction;
