import { yupResolver } from '@hookform/resolvers/yup';
import isEmpty from 'lodash/isEmpty';
import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import Button from 'src/components/Button';
import FormGroup from 'src/components/FormFields/FormGroup';
import FormInput from 'src/components/FormFields/Input/FormInput';
import FormSelect from 'src/components/FormFields/Select/FormSelect';
import Modal from 'src/components/Modal';
import { INPUT_HIGHLIGHT_ERROR_MESSAGE } from 'src/helper/constants';
import { SECTION_LEVEL_OPTIONS } from 'src/helper/constants/quickAccess';
import { useMetadata } from 'src/helper/providers/MetadataProvider';
import classes from './AddSectionModal.module.scss';

const formSchema = yup.object().shape({
  section_name: yup.string().required(INPUT_HIGHLIGHT_ERROR_MESSAGE),
  section_level_id: yup.mixed().required(INPUT_HIGHLIGHT_ERROR_MESSAGE),
  role_id: yup.mixed().required(INPUT_HIGHLIGHT_ERROR_MESSAGE),
});

const AddSectionModal = ({ isOpen, onClose, onSubmit, editObj }) => {
  const { roleOptions } = useMetadata();
  const isEdit = !!editObj?.section?.original_id || !!editObj?.section?.id;
  const [loading, setLoading] = useState(false);
  const nameRef = useRef(null);
  const { handleSubmit, control, formState } = useForm({
    resolver: yupResolver(formSchema),
    defaultValues: {
      section_name: editObj?.section?.name || '',
      section_level_id: editObj?.category?.level || null,
      role_id: editObj?.section?.role_id
        ? String(editObj.section.role_id)
        : null,
    },
  });

  useEffect(() => {
    setLoading(false);
    setTimeout(() => {
      if (nameRef && nameRef.current) {
        nameRef.current.focus();
      }
    }, 100);
  }, []);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={isEdit ? 'Edit Section' : 'Add New Section'}
      hideClose
      centered
      wrapClassName={classes.modalWrapper}
      radius="lg"
      size="s_small"
    >
      <form
        role="form"
        className={classes.form}
        onSubmit={handleSubmit((values) => {
          setLoading(true);
          onSubmit(
            {
              ...values,
              id: editObj?.section?.original_id || editObj?.section?.id || '',
            },
            () => {
              setLoading(false);
            }
          );
        })}
      >
        <div className={classes.formInner}>
          <FormGroup>
            <FormInput
              label="Section Name"
              name="section_name"
              placeholder="Enter section name"
              control={control}
              radius="md"
              variant="primary"
              fontSize="sm"
              innerRef={nameRef}
              required
            />
          </FormGroup>
          <FormGroup>
            <FormSelect
              options={SECTION_LEVEL_OPTIONS}
              required
              label="Section Level"
              name="section_level_id"
              placeholder="Select section level"
              control={control}
              radius="md"
              variant="primary"
              fontSize="sm"
              activeShadow
            />
          </FormGroup>
          <FormGroup>
            <FormSelect
              options={roleOptions}
              required
              label="Role responsible for section"
              name="role_id"
              placeholder="Select role"
              control={control}
              radius="md"
              variant="primary"
              fontSize="sm"
              activeShadow
            />
          </FormGroup>
        </div>
        <div className={classes.formFooter}>
          <Button variant="primary" isLink type="button" onClick={onClose}>
            Cancel
          </Button>
          <Button
            variant="primary"
            type="submit"
            loading={loading}
            disabled={!formState.isValid || isEmpty(formState.dirtyFields)}
          >
            {isEdit ? 'Save Changes' : 'Create Section'}
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default AddSectionModal;
