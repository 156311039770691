import range from 'lodash/range';
import React from 'react';
import { ReactComponent as MoreSvg } from 'src/assets/icons/more.svg';
import Dropdown from 'src/components/Dropdown';
import ScoreColor from 'src/components/Score/Color';
import { getFullAddress } from 'src/utils/address';
import classes from './RegionDetails.module.scss';

export const getColumns = ({ onEdit, onArchive }) => {
  return [
    {
      key: 'name',
      label: 'Location Name',
      render: (val, obj) => {
        return (
          <span className={classes.locationColInner}>
            <span>{val}</span>
            <span>{getFullAddress(obj.address)}</span>
          </span>
        );
      },
      sortable: true,
      width: 317,
    },
    {
      key: 'score',
      label: 'Score',
      render: () => {
        return (
          <span className={classes.scoreColInner}>
            <ScoreColor color="#45B942" />
            <span>90-Optimal</span>
          </span>
        );
      },
      sortable: true,
      width: 150,
    },
    {
      key: 'issueDetails',
      label: 'Issue Details',
      render: (val) => {
        if (!val) return '-';
        return (
          <span style={{ textDecoration: 'underline', cursor: 'pointer' }}>
            {val}
          </span>
        );
      },
      width: 150,
    },
    {
      key: 'updated',
      label: 'Updated',
      render: (val) => val || '-',
      sortable: true,
      width: 150,
    },
    {
      key: 'lastInspection',
      label: 'Last Inspection',
      render: (val) => val || '-',
      sortable: true,
      width: 200,
    },
    {
      key: 'action',
      width: 56,
      maxWidth: 56,
      render: (_, obj) => {
        return (
          <Dropdown
            items={[
              {
                key: 'edit',
                label: 'Edit',
                onClick: () => {
                  if (onEdit) onEdit(obj);
                },
              },
              {
                key: 'archive',
                label: 'Archive',
                onClick: () => {
                  if (onArchive) onArchive(obj);
                },
              },
            ]}
          >
            <MoreSvg />
          </Dropdown>
        );
      },
    },
  ];
};
export const markers = [
  {
    id: 1,
    name: 'Chicago, Illinois',
    position: { lat: 41.881832, lng: -87.623177 },
  },
  {
    id: 2,
    name: 'Denver, Colorado',
    position: { lat: 39.739235, lng: -104.99025 },
  },
  {
    id: 3,
    name: 'Los Angeles, California',
    position: { lat: 34.052235, lng: -118.243683 },
  },
  {
    id: 4,
    name: 'New York, New York',
    position: { lat: 40.712776, lng: -74.005974 },
  },
];
