import PageTitle from 'src/components/Page/PageTitle';
import Tag from 'src/components/Tag';
import { getFullAddress } from 'src/utils/address';
import { getShortDayWeek } from 'src/utils/date';
import {
  getIsSameDaysGrouped,
  getOperatingHoursGrouped,
} from 'src/utils/operatingHours';
import { capitalize } from 'src/utils/string';
import classes from './LocationDetails.module.scss';
import OperatingHoursRecord from './components/OperatingHoursRecord';
import { useIndexData } from './selectorData';

const DetailsTitle = () => {
  const { locationDetails } = useIndexData();
  const regionName = locationDetails?.region_name;
  const hours = (locationDetails?.operating_hours || []).filter(
    (obj) =>
      !!obj.is_enabled &&
      (!!obj.is_24_hours || !!obj.closing_time || !!obj.opening_time)
  );
  const hoursGrouped = getOperatingHoursGrouped(hours);
  const isAllSameHour = getIsSameDaysGrouped(hours, hoursGrouped);

  return (
    <div className={classes.pageTitleWrapper}>
      <PageTitle
        subTitle={
          <span className={classes.subTitle}>
            <span>{locationDetails?.description || '-'}</span>
            <span>
              <span>Hours</span>
              {hours.length === 0 && '-'}
              {isAllSameHour ? (
                <>
                  {hoursGrouped.map((obj, i) => {
                    return (
                      <OperatingHoursRecord
                        key={i}
                        dayStr={`${getShortDayWeek(
                          capitalize(obj.days[0])
                        )} - ${getShortDayWeek(
                          capitalize(obj.days[obj.days.length - 1])
                        )}`}
                        is24={obj.is_24_hours}
                        from={obj.opening_time}
                        to={obj.closing_time}
                      />
                    );
                  })}
                </>
              ) : (
                <>
                  {hours.map((obj, i) => {
                    return (
                      <OperatingHoursRecord
                        key={i}
                        dayStr={obj.day_of_week}
                        is24={obj.is_24_hours}
                        from={obj.opening_time}
                        to={obj.closing_time}
                      />
                    );
                  })}
                </>
              )}
            </span>
            <span>
              <span>Address</span>
              <span style={{ whiteSpace: 'pre-wrap' }}>
                {getFullAddress(locationDetails?.address, true) || '-'}
              </span>
            </span>
          </span>
        }
      >
        {locationDetails.name}
        {!!regionName && (
          <Tag variant="dark_gray" outline radius="sm">
            {regionName}
          </Tag>
        )}
      </PageTitle>
    </div>
  );
};

export default DetailsTitle;
