import { apiUploadAppDocument } from 'src/api';
import { callAction } from 'src/utils/actions';

const useUploadFile = () => {
  const onUpload = async (tFile) => {
    const formData = new FormData();
    formData.append('file', tFile);
    const res = await callAction(() => {
      return apiUploadAppDocument(formData);
    });
    return res?.data?.uuid || '';
  };

  return {
    onUpload,
  };
};

export default useUploadFile;
