import cn from 'classnames';
import classes from './Badge.module.scss';

const Badge = ({ className, type, count }) => {
  const nCount = Number(count || 0);
  return (
    <span
      className={cn(
        classes.wrapper,
        classes[`type_${type}`],
        {
          [classes.min]: nCount < 100,
        },
        className
      )}
    >
      <span>{count < 100 ? count : `99+`}</span>
    </span>
  );
};

export default Badge;
