import cn from 'classnames';
import { useState } from 'react';
import Table from 'src/components/Table';
import classes from './LocationTable.module.scss';
import { getColumns } from './constants';

const LocationTable = ({ className }) => {
  const [checkedRows, setCheckedRows] = useState([]);
  const columns = getColumns({ onUnarchive: () => {} });
  const data = [{ key: 1, id: 1 }];
  return (
    <div className={cn(classes.wrapper, className)}>
      <Table
        columns={columns}
        data={data}
        checkable={{
          checkedRows,
          onChange: (cRows) => {
            // setCheckedRows(cRows);
          },
        }}
      />
    </div>
  );
};

export default LocationTable;
