import { capitalize } from 'src/utils/string';

export const actionCb = (cb, errCb) => (res, err) => {
  if (res) {
    if (cb) cb(res);
  } else {
    if (errCb) errCb(err);
  }
};

export const callAction = async (fn) => {
  try {
    const res = await fn();
    return res;
  } catch (error) {
    return { isSuccess: false, data: {} };
  }
};

const getSubErrorMessage = (obj) => {
  const field = obj?.path || '';
  const displayField = field.replace(/\//g, '');
  const message = obj?.message || '';
  return [capitalize(displayField), message].filter((v) => !!v).join(' ');
};

export const getErrorMessageFromResponse = (res) => {
  const subErrors = res?.data?.response?.data?.subErrors || [];
  const tErrorMessage = res?.data?.response?.data?.error;
  if (subErrors.length > 0) {
    return getSubErrorMessage(subErrors[0]);
  }
  return tErrorMessage;
};
