import { Controller } from 'react-hook-form';
import Datepicker from './index';

const FormDatepicker = ({ name, control, onChange, ...props }) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <Datepicker
          {...field}
          {...props}
          error={error?.message}
          onChange={(val) => {
            field.onChange(val);
            if (onChange) {
              onChange(val);
            }
          }}
        />
      )}
    />
  );
};

export default FormDatepicker;
