import cn from 'classnames';
import { useEffect, useState } from 'react';
import classes from './Loading.module.scss';

let tempPercent = 0;

const LoadingCircle = ({ className }) => {
  const [percent, setPercent] = useState(0);
  const updateText = (tPercent) => {
    if (tPercent < 99) {
      tempPercent = tPercent + 1;
      setPercent(tempPercent);
      setTimeout(() => {
        updateText(tempPercent);
      }, 30); // Speed of updating
    }
  };

  useEffect(() => {
    tempPercent = 0;
    updateText(tempPercent);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className={cn(classes.loadingCircleWrapper, className)}>
      <svg viewBox="0 0 50 50">
        <circle
          className={classes.loadingCircleBg}
          cx="25"
          cy="25"
          r="20"
        ></circle>
        <circle
          className={classes.loadingCircleProgress}
          cx="25"
          cy="25"
          r="20"
        ></circle>
      </svg>
      <div className={classes.loadingCirclePercent}>{percent}%</div>
    </div>
  );
};

export default LoadingCircle;
