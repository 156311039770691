import { LOCATION_DETAILS_TAB_KEYS } from 'src/pages/LocationDetails/constants';
import { getTabUrlAction } from 'src/utils/routes';

export const getCreateChemicalPayload = (values) => {
  const returnObj = {
    ...values,
  };
  return returnObj;
};
export const getTitle = ({
  isViewDetails,
  isEdit,
  chemicalDetails,
  locationId,
}) => {
  if (isViewDetails) return `${chemicalDetails?.chemical_name || ''}`;
  return isEdit
    ? locationId
      ? 'Edit Chemical'
      : `Edit Chemical - ${chemicalDetails?.chemical_name}`
    : 'Add New Chemical';
};
export const getSubTitle = (isViewDetails, isEdit, locationId) => {
  if (!!isEdit && !!locationId)
    return (
      <>
        An Americold refrigeration facility specializes in
        temperature-controlled storage and logistics, supporting the
        distribution of perishable goods efficiently and reliably.
      </>
    );
  if (isViewDetails) return '';
  return <>Add new chemical to the location.</>;
};
export const getBreadcrumbText = ({
  isViewDetails,
  isEdit,
  chemicalName,
  locationId,
}) => {
  if (isViewDetails) return chemicalName;
  return isEdit ? `Edit Chemical` : 'Add New Chemical';
};
export const getBreadcrumbs = ({
  locationId,
  isViewDetails,
  isEdit,
  chemicalName,
  locationName,
}) => {
  return [
    ...[
      {
        link: '/locations',
        label: 'Locations',
      },
      {
        link: getTabUrlAction(
          `/locations/${locationId}`,
          LOCATION_DETAILS_TAB_KEYS.CHEMICALS
        ),
        label: locationName,
      },
    ],
    {
      label: getBreadcrumbText({
        isViewDetails,
        isEdit,
        chemicalName,
        locationId,
      }),
    },
  ];
};
