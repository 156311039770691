import { getReducerMoreData } from 'src/utils/store';
import { defaultObj } from '../constants';
import { handleData } from '../middlewares/handleData';
import Types from '../types/location';

const initialState = {
  allLocations: {
    ...defaultObj,
  },
  locations: {
    ...defaultObj,
  },
  locationDetails: {
    ...defaultObj,
  },
  createLocation: {
    ...defaultObj,
  },
  updateLocation: {
    ...defaultObj,
  },
  deleteLocation: {
    ...defaultObj,
  },
};

const LocationReducer = (state = initialState, action) => {
  const { type, payload, meta } = action;

  switch (type) {
    case Types.SYNC_ADD_ALL_LOCATIONS: {
      return {
        ...state,
        allLocations: {
          ...state.allLocations,
          data: {
            ...state.allLocations.data,
            data: [...(state.allLocations.data?.data || []), payload],
          },
        },
      };
    }
    case Types.GET_ALL_LOCATIONS:
      return handleData(state, action, {
        request: (prevState) => ({
          ...prevState,
          allLocations: {
            ...prevState.allLocations,
            loading: true,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          allLocations: {
            loading: false,
            data: payload,
            error: '',
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          allLocations: {
            loading: false,
            data: {},
            error: payload,
          },
        }),
      });
    case Types.GET_LOCATIONS:
      return handleData(state, action, {
        request: (prevState) => ({
          ...prevState,
          locations: {
            ...prevState.locations,
            loading: true,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          locations: {
            loading: false,
            data: getReducerMoreData({
              meta,
              prevState,
              payload,
              key: 'locations',
            }),
            error: '',
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          locations: {
            loading: false,
            data: {},
            error: payload,
          },
        }),
      });
    case Types.GET_LOCATION_DETAILS:
      return handleData(state, action, {
        request: (prevState) => ({
          ...prevState,
          locationDetails: {
            ...prevState.locationDetails,
            loading: true,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          locationDetails: {
            loading: false,
            data: payload.data,
            error: '',
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          locationDetails: {
            loading: false,
            data: {},
            error: payload,
          },
        }),
      });
    case Types.CREATE_LOCATION:
      return handleData(state, action, {
        request: (prevState) => ({
          ...prevState,
          createLocation: {
            ...prevState.createLocation,
            loading: true,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          createLocation: {
            loading: false,
            data: payload,
            error: '',
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          createLocation: {
            loading: false,
            data: {},
            error: payload,
          },
        }),
      });
    case Types.UPDATE_LOCATION:
      return handleData(state, action, {
        request: (prevState) => ({
          ...prevState,
          updateLocation: {
            ...prevState.updateLocation,
            loading: true,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          updateLocation: {
            loading: false,
            data: payload,
            error: '',
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          updateLocation: {
            loading: false,
            data: {},
            error: payload,
          },
        }),
      });
    case Types.DELETE_LOCATION:
      return handleData(state, action, {
        request: (prevState) => ({
          ...prevState,
          deleteLocation: {
            ...prevState.deleteLocation,
            loading: true,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          deleteLocation: {
            loading: false,
            data: payload,
            error: '',
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          deleteLocation: {
            loading: false,
            data: {},
            error: payload,
          },
        }),
      });
    default:
      return state;
  }
};

export default LocationReducer;
