import cn from 'classnames';
import isEmpty from 'lodash/isEmpty';
import { useState } from 'react';
import ConfirmModal from 'src/components/Modal/Confirm';
import DeleteModal from 'src/components/Modal/Delete';
import PageTitle from 'src/components/Page/PageTitle';
import { QUICK_ACCESS_TYPES } from 'src/helper/constants/quickAccess';
import RichTextEditor from '../RichTextEditor';
import RightActions from '../RightActions';
import UploadEditor from '../UploadEditor';
import UploadModal from '../UploadModal';
import UploadResultOverlay from '../UploadResultOverlay';
import classes from './Content.module.scss';
import Organization from './Organization';
import RichTextInfo from './RichTextInfo';
import TagAndCategoryRemove from './TagAndCategoryRemove';

const Content = ({
  className,
  details,
  organization,
  type,
  onBack,
  editorContent,
  onEditorChange,
  onSubmitEditor,
  submitEditorLoading,
  documents,
  archivedDocuments,
  firstDocument,
  isEditEditor,
  setIsEditEditor,
  onUploadDocument,
  uploadedResult,
  onDeleteUploadedDocument,
  onViewDocument,
  onDeleteDocument,
  onDeleteCategory,
  onSwitchTab,
  documentCount,
}) => {
  const [isOpenUpload, setIsOpenUpload] = useState(false);
  const [deleteObj, setDeleteObj] = useState(null);
  const [archiveObj, setArchiveObj] = useState(null);
  const isUploadFile = type == QUICK_ACCESS_TYPES.FILE_UPLOAD;
  const isRichText = type == QUICK_ACCESS_TYPES.RICH_TEXT;
  const isEmptyRichText = isRichText && isEmpty(firstDocument);
  const isDocumentsNotFound =
    isUploadFile && !documents?.length && !archivedDocuments.length;
  return (
    <>
      <div className={classes.pageTitleWrapper}>
        <PageTitle
          className={classes.pageTitle}
          subTitle={
            isUploadFile && (
              <>
                Manage all documents and information related to{' '}
                {details?.name || ''}
              </>
            )
          }
        >
          <span>{details?.name}</span>
          {isRichText && <Organization name={organization} />}
          {isUploadFile && (
            <TagAndCategoryRemove
              onAdd={() => {
                setIsOpenUpload(true);
              }}
              onRemove={
                details?.can_be_hidden
                  ? () => {
                      setDeleteObj({});
                    }
                  : undefined
              }
              isNeedAttention={isDocumentsNotFound}
              documentCount={documentCount}
            />
          )}
        </PageTitle>
        {isRichText && (
          <div className={classes.infoWrapper}>
            <RichTextInfo document={firstDocument} />
            <RightActions
              onCancel={onBack}
              onCreate={() => {
                onSubmitEditor(firstDocument);
              }}
              submitLoading={submitEditorLoading}
              isEmptyRichText={isEmptyRichText}
              isEdit={isEditEditor}
              setIsEdit={setIsEditEditor}
            />
          </div>
        )}
      </div>
      <div className={cn(classes.wrapper, className)}>
        {isRichText && (
          <RichTextEditor
            className={classes.richTextEditor}
            editorContent={editorContent}
            onEditorChange={onEditorChange}
            isEdit={isEditEditor || isEmptyRichText}
            document={firstDocument}
          />
        )}
        {isUploadFile && (
          <UploadEditor
            onUpload={(tFile) => {
              onUploadDocument(tFile);
            }}
            documents={documents}
            archivedDocuments={archivedDocuments}
            onViewDocument={onViewDocument}
            onDeleteDocument={(obj) => {
              setArchiveObj({
                title: 'Archive Document',
                submitText: 'Archive',
                description:
                  'Are you sure you want to archive this file? It will no longer be publicly available for audits.',
                id: obj.id,
              });
            }}
            onSwitchTab={onSwitchTab}
            isNotFound={isDocumentsNotFound}
          />
        )}
      </div>
      {!!uploadedResult && (
        <UploadResultOverlay
          data={uploadedResult}
          onDelete={onDeleteUploadedDocument}
        />
      )}
      {!!isOpenUpload && (
        <UploadModal
          isOpen
          onClose={() => {
            setIsOpenUpload(false);
          }}
          onSubmit={(tFile) => {
            setIsOpenUpload(false);
            onUploadDocument(tFile);
          }}
        />
      )}
      {!!archiveObj && (
        <ConfirmModal
          isOpen
          onClose={() => {
            setArchiveObj(null);
          }}
          onSubmit={(cb) => {
            onDeleteDocument(archiveObj, cb, () => {
              setArchiveObj(null);
            });
          }}
          title={archiveObj?.title}
          submitText={archiveObj?.submitText}
          size="s_small"
        >
          {archiveObj?.description}
        </ConfirmModal>
      )}
      {!!deleteObj && (
        <DeleteModal
          title="Remove Category"
          isOpen
          onClose={() => {
            setDeleteObj(null);
          }}
          onSubmit={(_, cb) => {
            onDeleteCategory(cb, () => {
              setDeleteObj(null);
            });
          }}
          description={
            <span>Are you sure you want to remove this category?</span>
          }
          submitProps={{
            text: 'Remove Category',
          }}
          inputProps={{
            label: 'Type name of category to remove',
            placeholder: 'Category name',
          }}
          disabledFn={(tVal) => {
            const valTrim = (tVal || '').trim();
            return !valTrim || tVal !== details?.name;
          }}
        />
      )}
    </>
  );
};

export default Content;
