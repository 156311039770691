import uniqBy from 'lodash/uniqBy';
import { useContext, useEffect, useRef, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import Breadcrumb from 'src/components/Breadcrumb';
import Button from 'src/components/Button';
import Loading from 'src/components/Loading';
import PageFooter, {
  PageFooterSelectedText,
} from 'src/components/Page/PageFooter';
import PageTitle from 'src/components/Page/PageTitle';
import PageWrapper from 'src/components/Page/PageWrapper';
import ToastAlert from 'src/components/ToastAlert';
import { LOCATIONS_SCOPE_ATTR_NAME } from 'src/helper/constants';
import {
  CALLBACK_ACTION_PARAM_KEY,
  CREATE_SUCCESS_PARAM_KEY,
} from 'src/helper/constants/routes';
import { RESOURCE_DATA_KEY } from 'src/helper/constants/store';
import useOwnNavigate from 'src/helper/hooks/useOwnNavigate';
import useResourceActions from 'src/helper/hooks/useResourceActions';
import { MenuContext } from 'src/helper/providers/MenuProvider';
import GenericContent from 'src/pages/Generic/components/Content';
import GenericTable from 'src/pages/Generic/components/Table';
import { LOCATION_DETAILS_TAB_KEYS } from 'src/pages/LocationDetails/constants';
import { getErrorMessageFromResponse } from 'src/utils/actions';
import { notifyPrimary } from 'src/utils/notification';
import {
  getAddStaffUrlAction,
  getAllUrlParams,
  getRelativePathWithParams,
  getRemovedParamFromUrl,
  getTabUrlAction,
} from 'src/utils/routes';
import classes from './AddStaff.module.scss';
import { getColumns } from './constants';
import { useActions, useIndexData } from './selectorData';

const DEFAULT_SORT_KEY = 'created_at';
let cacheSearch = '';
let cachePage = 1;
let cacheSize = 10;
let cacheSortField = DEFAULT_SORT_KEY;
let cacheSortMethod = 'desc';

const AddStaff = () => {
  const location = useLocation();
  const urlParams = getAllUrlParams(location?.search);
  const { selectedLocationIds } = useContext(MenuContext);
  const selectedLocationIdsString = selectedLocationIds.join(',');
  const { onAssignResourceStaff, onGetResources } = useResourceActions();
  const { getResources, getLocationDetails } = useActions();
  const notificationAlertRef = useRef(null);
  const { onNavigate, fullPathname } = useOwnNavigate();
  const params = useParams();
  const locationId = Number(params.locationId || 0);
  const [loading, setLoading] = useState(false);
  const [locationDetailsLoading, setLocationDetailsLoading] = useState(false);
  const [staffLoading, setStaffLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [checkedRows, setCheckedRows] = useState([]);
  const [preStaffs, setPreStaffs] = useState([]);
  const checkedLength = checkedRows.length;
  const { locationDetails, usersData } = useIndexData();
  const users = usersData?.data || [];
  const locationUrl = getTabUrlAction(
    `/locations/${locationId}`,
    LOCATION_DETAILS_TAB_KEYS.STAFF_LIST
  );
  const breadcrumbs = [
    {
      link: `/locations`,
      label: 'Locations',
    },
    {
      link: locationUrl,
      label: locationDetails?.name,
    },
    {
      label: 'Add Staff',
    },
  ];
  const notify = (type, title, description) => {
    notifyPrimary({ ref: notificationAlertRef, type, title, description });
  };
  const columns = getColumns();
  const reloadData = (tSearch, tPage, tSize, tSortField, tSortMethod) => {
    setLoading(true);
    getResources(
      RESOURCE_DATA_KEY.USERS,
      {
        page: tPage,
        per_page: tSize,
        search: tSearch,
        sort_by: tSortField,
        sort_order: tSortMethod,
        filters: {
          statuses: 'active',
          exclude_location_id: locationId,
        },
        [LOCATIONS_SCOPE_ATTR_NAME]: selectedLocationIdsString,
      },
      (res) => {
        const tStaffs = res?.isSuccess ? res.data : [];
        // const actualPreStaffs = tPreStaffs || preStaffs;
        const actualPreStaffs = [];
        if (actualPreStaffs.length > 0 && tStaffs.length > 0) {
          const tCheckedRows = [];
          setPreStaffs(
            actualPreStaffs.filter((obj) => {
              if (tStaffs.findIndex((obj1) => obj1.id === obj.id) >= 0) {
                tCheckedRows.push(obj);
                return false;
              }
              return true;
            })
          );
          if (tCheckedRows.length > 0) {
            setCheckedRows([...checkedRows, ...tCheckedRows]);
          }
        }
        setLoading(false);
      }
    );
  };
  const reloadLocationDetails = (cb) => {
    setLocationDetailsLoading(true);
    getLocationDetails(locationId, (res) => {
      setLocationDetailsLoading(false);
      if (cb) cb(res);
    });
  };
  const reloadLocationStaffs = async (cb) => {
    setStaffLoading(true);
    const res = await onGetResources(RESOURCE_DATA_KEY.USERS, {
      page: 1,
      per_page: 9999,
      filters: {
        location_id: locationId,
        status: 'active',
      },
    });
    const tPreStaffs = res?.isSuccess ? res.data : [];
    setPreStaffs(tPreStaffs);
    if (cb) cb(tPreStaffs);
    setStaffLoading(false);
  };
  const handleAddStaff = async () => {
    setSubmitLoading(true);
    const assignments = checkedRows
      .filter((obj) => !!obj.role_id)
      .map((obj) => ({
        user_id: obj.id,
        role_id: obj.role_id,
      }));
    const res = await onAssignResourceStaff(
      RESOURCE_DATA_KEY.LOCATIONS,
      locationId,
      {
        assignments,
      }
    );
    setSubmitLoading(false);
    if (res?.isSuccess) {
      onNavigate(getAddStaffUrlAction(locationUrl));
    } else {
      notify('error', 'Error!', getErrorMessageFromResponse(res));
    }
  };

  useEffect(() => {
    setLoading(false);
    setCheckedRows([]);
    cacheSearch = '';
    cachePage = 1;
    cacheSize = 10;
    cacheSortField = DEFAULT_SORT_KEY;
    cacheSortMethod = 'desc';
    reloadData(
      cacheSearch,
      cachePage,
      cacheSize,
      cacheSortField,
      cacheSortMethod
    );
    reloadLocationDetails();
    if (
      !!urlParams?.[CALLBACK_ACTION_PARAM_KEY] &&
      [CREATE_SUCCESS_PARAM_KEY].includes(
        urlParams?.[CALLBACK_ACTION_PARAM_KEY]
      )
    ) {
      notify('success', 'Success!', 'Staff added successfully');
      onNavigate(
        getRemovedParamFromUrl(fullPathname, [CALLBACK_ACTION_PARAM_KEY])
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <PageWrapper className={classes.wrapper}>
        {(locationDetailsLoading || staffLoading) && <Loading isPage />}
        <Breadcrumb items={breadcrumbs} />
        <PageTitle
          subTitle={
            <span>
              Add staff from users that are already in the system. You can
              select as few or as many as needed before continuing. Selected
              Staff will receive a notification that they’ve been added to{' '}
              {locationDetails?.name}.
            </span>
          }
        >
          Add Staff
        </PageTitle>
        <GenericContent>
          <GenericTable
            unbox
            tableBox
            // onAdd={() => {
            //   onNavigate(
            //     getRelativePathWithParams('/users/create', {
            //       location: locationId,
            //     })
            //   );
            // }}
            columns={columns}
            searchPlaceholder="Search Staff"
            title="Staff List"
            data={users}
            total={usersData.meta?.total || 0}
            onPaginationChange={(tPage, tSize) => {
              cachePage = tPage;
              cacheSize = tSize;
              reloadData(
                cacheSearch,
                cachePage,
                cacheSize,
                cacheSortField,
                cacheSortMethod
              );
            }}
            onSearchChange={(val) => {
              cachePage = 1;
              cacheSearch = val;
              reloadData(
                cacheSearch,
                cachePage,
                cacheSize,
                cacheSortField,
                cacheSortMethod
              );
            }}
            onSortChange={(obj) => {
              cachePage = 1;
              cacheSortField = obj.field;
              cacheSortMethod = obj.method;
              reloadData(
                cacheSearch,
                cachePage,
                cacheSize,
                cacheSortField,
                cacheSortMethod
              );
            }}
            loading={loading && !locationDetailsLoading}
            // addBtnText="Create User"
            paginationSettings={{
              light: true,
            }}
            checkedRows={checkedRows}
            onCheckChanged={(row, allChecked, itemChecked) => {
              if (allChecked === true || allChecked === false) {
                const tUserIds = users.map((obj) => obj.id);
                setCheckedRows(
                  allChecked
                    ? uniqBy([...checkedRows, ...users], 'id')
                    : checkedRows.filter((obj) => !tUserIds.includes(obj.id))
                );
              } else {
                setCheckedRows(
                  itemChecked
                    ? [...checkedRows, row]
                    : checkedRows.filter((obj) => obj.id !== row.id)
                );
              }
            }}
          />
        </GenericContent>
        <PageFooter fixed>
          {checkedLength > 0 && (
            <PageFooterSelectedText>
              {checkedLength} Staff{checkedLength === 1 ? '' : 's'} Selected
            </PageFooterSelectedText>
          )}
          <Button
            variant="gray"
            onClick={() => {
              onNavigate(locationUrl);
            }}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            disabled={checkedLength === 0}
            onClick={handleAddStaff}
            loading={submitLoading}
          >
            Add Staff
          </Button>
        </PageFooter>
      </PageWrapper>
      <ToastAlert toastRef={notificationAlertRef} />
    </>
  );
};

export default AddStaff;
