import { GoogleMap, InfoWindow, Marker } from '@react-google-maps/api';
import cn from 'classnames';
import { useState } from 'react';
import Loading from 'src/components/Loading';
import classes from './Map.module.scss';

// const markers = [
//   {
//     id: 1,
//     name: 'Chicago, Illinois',
//     position: { lat: 41.881832, lng: -87.623177 },
//   },
//   {
//     id: 2,
//     name: 'Denver, Colorado',
//     position: { lat: 39.739235, lng: -104.99025 },
//   },
//   {
//     id: 3,
//     name: 'Los Angeles, California',
//     position: { lat: 34.052235, lng: -118.243683 },
//   },
//   {
//     id: 4,
//     name: 'New York, New York',
//     position: { lat: 40.712776, lng: -74.005974 },
//   },
// ];

const Map = ({ className, markers, zoom, loadedZoom }) => {
  const [activeMarker, setActiveMarker] = useState(null);
  const [loading, setLoading] = useState(true);

  const handleActiveMarker = (marker) => {
    if (marker === activeMarker) {
      return;
    }
    setActiveMarker(marker);
  };

  const handleOnLoad = (map) => {
    if (markers?.length) {
      const bounds = new window.google.maps.LatLngBounds();
      (markers || []).forEach(({ position }) => bounds.extend(position));
      map.fitBounds(bounds);
      setTimeout(() => {
        if (loadedZoom) {
          map.setZoom(loadedZoom);
        }
        setLoading(false);
      }, 1000);
    } else {
      setLoading(false);
    }
  };

  return (
    <div className={cn(classes.wrapper, className)}>
      {loading && <Loading isPage />}
      <GoogleMap
        onLoad={handleOnLoad}
        onClick={() => setActiveMarker(null)}
        mapContainerStyle={{ width: '100%', height: '100%' }}
        zoom={zoom || 12}
      >
        {!!markers?.length && (
          <>
            {(markers || []).map(({ id, name, position }) => (
              <Marker
                key={id}
                position={position}
                onClick={() => handleActiveMarker(id)}
              >
                {activeMarker === id && !!name ? (
                  <InfoWindow onCloseClick={() => setActiveMarker(null)}>
                    <div>{name}</div>
                  </InfoWindow>
                ) : null}
              </Marker>
            ))}
          </>
        )}
      </GoogleMap>
    </div>
  );
};

export default Map;
