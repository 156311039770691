import { DOCUMENT_STATUS_VALUES } from 'src/helper/constants/document';
import { getMomentDate } from './date';

export const getDocumentStatusByTime = (expiredDate) => {
  if (!expiredDate)
    return {
      type: DOCUMENT_STATUS_VALUES.EXPIRED,
      text: 'Expired',
    };
  const today = getMomentDate('', true, true);
  const tExpiredDate = getMomentDate(expiredDate, false, true);
  const diffDate = tExpiredDate.diff(today, 'days');
  if (diffDate < 0)
    return {
      type: DOCUMENT_STATUS_VALUES.EXPIRED,
      text: 'Expired',
    };
  if (diffDate < 30)
    return {
      type: DOCUMENT_STATUS_VALUES.WARNING,
      text: `Exp: ${diffDate} day${diffDate === 1 ? '' : 's'}`,
    };
  return {
    type: DOCUMENT_STATUS_VALUES.ACTIVE,
    text: 'Active',
  };
};
