import isEmpty from 'lodash/isEmpty';
import { Controller } from 'react-hook-form';
import Button from 'src/components/Button';
import FieldsLabel from 'src/components/Form/FieldsLabel';
import FieldsRow from 'src/components/Form/FieldsRow';
import FormCheckbox from 'src/components/FormFields/Checkbox/FormCheckbox';
import FormGroup from 'src/components/FormFields/FormGroup';
import FormInput from 'src/components/FormFields/Input/FormInput';
import ChemicalAutosuggest from 'src/components/FormFields/Select/ChemicalAutosuggest';
import FormSelect from 'src/components/FormFields/Select/FormSelect';
import { chemicals } from 'src/components/FormFields/Select/data';
import PageFooter from 'src/components/Page/PageFooter';
import { QUANTITY_UNITS } from 'src/helper/constants/quantityUnits';
import CreateChemicalView from '../View';

const CreateChemicalForm = ({
  onSubmit,
  isViewDetails,
  chemicalDetails,
  control,
  formState,
  isEdit,
  submitLoading,
  setValue,
  onCancel,
}) => {
  return (
    <>
      {isViewDetails ? (
        <CreateChemicalView chemicalDetails={chemicalDetails} />
      ) : (
        <form role="form" className="s-form" onSubmit={onSubmit}>
          <div className="s-form-inner">
            <FieldsRow col={2}>
              <FormGroup>
                <FormInput
                  required
                  label="Chemical Name"
                  name="chemical_name"
                  placeholder="Enter chemical name"
                  control={control}
                  radius="md"
                  variant="primary"
                  fontSize="sm"
                  hidden
                />
                <Controller
                  name="chemical_name" // Field name to match your form field
                  control={control}
                  render={({ field }) => (
                    <ChemicalAutosuggest
                      {...field} // Pass the value and onChange from react-hook-form
                      onChange={(value) => {
                        field.onChange(value);
                        const chemical = chemicals.find(
                          (c) => c.name === value
                        );
                        if (chemical) {
                          setValue('cas_number', chemical.cas, {
                            shouldDirty: true,
                            shouldValidate: true,
                          });
                        }
                      }}
                      required
                    />
                  )}
                />
              </FormGroup>
              <FormGroup>
                <FormInput
                  required
                  label="Cas Number"
                  name="cas_number"
                  placeholder="Enter cas number"
                  control={control}
                  radius="md"
                  variant="primary"
                  fontSize="sm"
                />
              </FormGroup>
            </FieldsRow>
            <FormGroup>
              <FormCheckbox
                name="tier_2_reportable"
                variant="primary"
                control={control}
                label="Tier 2 Reportable"
                fontSize="md"
                inline
              />
            </FormGroup>
            <FieldsLabel>Quantity Information</FieldsLabel>
            <FieldsRow col={2}>
              <FormGroup>
                <FormInput
                  required
                  label="Quantity"
                  name="quantity"
                  placeholder="0"
                  control={control}
                  radius="md"
                  variant="primary"
                  fontSize="sm"
                />
              </FormGroup>
              <FormGroup>
                <FormSelect
                  options={QUANTITY_UNITS}
                  label="Quantity unit"
                  name="quantity_unit"
                  placeholder="Select"
                  control={control}
                  radius="md"
                  variant="primary"
                  fontSize="sm"
                  required
                />
              </FormGroup>
            </FieldsRow>
          </div>
          <PageFooter fixed>
            <Button variant="gray" type="button" onClick={onCancel}>
              Cancel
            </Button>
            <Button
              variant="primary"
              type="submit"
              disabled={!formState.isValid || isEmpty(formState.dirtyFields)}
              loading={submitLoading}
            >
              {isEdit ? 'Save Changes' : 'Add'}
            </Button>
          </PageFooter>
        </form>
      )}
    </>
  );
};

export default CreateChemicalForm;
