export const isSidebarExpanded = () => {
  return (
    document.body.classList.contains('g-sidenav-pinned') &&
    document.body.classList.contains('g-sidenav-show')
  );
};
export const expandSidebar = () => {
  document.body.classList.add('g-sidenav-pinned');
  document.body.classList.add('g-sidenav-show');
  document.body.classList.remove('g-sidenav-hidden');
};
export const collapseSidebar = () => {
  document.body.classList.remove('g-sidenav-pinned');
  document.body.classList.remove('g-sidenav-show');
  document.body.classList.add('g-sidenav-hidden');
};
