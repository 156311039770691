import cn from 'classnames';
import { ReactComponent as WarningSvg } from 'src/assets/icons/warning-2.svg';
import Tag from 'src/components/Tag';
import classes from './Emergency.module.scss';

const EmergencyEmptyPlan = ({ className, children }) => {
  return (
    <div className={cn(classes.emergencyEmptyPlan, className)}>
      <div>
        <WarningSvg />
        <Tag variant="red" weight="lg">
          Action Required
        </Tag>
        {children}
      </div>
    </div>
  );
};

export default EmergencyEmptyPlan;
