import cn from 'classnames';
import { forwardRef } from 'react';
import InputMask from 'react-input-mask';
import { FormFeedback, Label, Input as ReactstrapInput } from 'reactstrap';
import { ReactComponent as WarningSvg } from 'src/assets/icons/warning-2.svg';
import RequiredMark from 'src/components/RequiredMark';
import { INPUT_HIGHLIGHT_ERROR_MESSAGE } from 'src/helper/constants';
import InputGroup from '../InputGroup';
import classes from './Input.module.scss';

const FormatInput = forwardRef(
  (
    {
      className,
      error,
      label,
      radius,
      variant,
      onChange,
      type,
      fontSize,
      required,
      activeShadow,
      maskChar,
      ...props
    },
    ref
  ) => {
    return (
      <>
        <InputGroup
          className={cn(
            'input-group-merge',
            classes.inputWrapper,
            classes.formatInputWrapper
          )}
        >
          {!!label && (
            <Label className={classes.label}>
              {label}
              {required && <RequiredMark />}
            </Label>
          )}
          <InputMask
            className={cn(
              'form-control',
              classes.wrapper,
              classes[`radius_${radius}`],
              classes[`variant_${variant}`],
              classes[`fontSize_${fontSize}`],
              {
                [classes.hasVariant]: !!variant,
                [classes.activeShadow]: activeShadow,
                [classes.formatInputError]: !!error,
              },
              className
            )}
            onChange={(e) => {
              if (onChange) onChange(e);
            }}
            maskChar={maskChar || ''}
            {...props}
          />
          {error && error !== INPUT_HIGHLIGHT_ERROR_MESSAGE && (
            <FormFeedback className={classes.error}>
              <WarningSvg />
              <span>{error}</span>
            </FormFeedback>
          )}
        </InputGroup>
      </>
    );
  }
);

export default FormatInput;
