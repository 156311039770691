import cn from 'classnames';
import { useState } from 'react';
import Upload from 'src/components/FormFields/Upload';
import Tabs from 'src/components/Tabs';
import Documents from './Documents';
import classes from './UploadEditor.module.scss';

const UploadEditor = ({
  className,
  onUpload,
  documents,
  archivedDocuments,
  onViewDocument,
  onDeleteDocument,
  onSwitchTab,
  isNotFound,
}) => {
  const [activeTab, setActiveTab] = useState('active');
  const items = [
    {
      key: 'active',
      title: 'Active',
      content: (
        <Documents
          documents={documents}
          onViewDocument={onViewDocument}
          onDeleteDocument={onDeleteDocument}
          notFoundText="No active files exist."
        />
      ),
    },
    {
      key: 'archived',
      title: 'Archived',
      content: (
        <Documents
          documents={archivedDocuments}
          onViewDocument={onViewDocument}
          onDeleteDocument={onDeleteDocument}
          notFoundText="No archived files exist."
          isArchived
        />
      ),
    },
  ];

  return (
    <>
      <div className={cn(classes.wrapper, className)}>
        {isNotFound ? (
          <div className={classes.uploadWrapper}>
            <Upload
              supportText="Supported formats: .doc, .xlsx, .pdf"
              accept=".doc,.docx,.xlsx,.pdf"
              onChange={(tFiles) => {
                const tFile = tFiles?.[0];
                if (tFile) {
                  onUpload(tFile);
                }
              }}
              isSingle={true}
            />
          </div>
        ) : (
          <Tabs
            items={items}
            activeTab={activeTab}
            onChange={(val) => {
              setActiveTab(val);
              onSwitchTab(val);
            }}
            headerFullWidth
            headerSize="sm"
          />
        )}
      </div>
    </>
  );
};

export default UploadEditor;
