import { useContext, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Breadcrumb from 'src/components/Breadcrumb';
import Button from 'src/components/Button';
import Loading from 'src/components/Loading';
import PageTitle from 'src/components/Page/PageTitle';
import PageWrapper from 'src/components/Page/PageWrapper';
import ToastAlert from 'src/components/ToastAlert';
import { LOCATIONS_SCOPE_ATTR_NAME } from 'src/helper/constants';
import { LOCATION_MENU_NAME } from 'src/helper/constants/menu';
import {
  CALLBACK_ACTION_PARAM_KEY,
  CREATE_SUCCESS_PARAM_KEY,
  EDIT_SUCCESS_PARAM_KEY,
} from 'src/helper/constants/routes';
import { RESOURCE_DATA_KEY } from 'src/helper/constants/store';
import useInfiniteScroll from 'src/helper/hooks/useInfiniteScroll';
import useOwnNavigate from 'src/helper/hooks/useOwnNavigate';
import useResourceActions from 'src/helper/hooks/useResourceActions';
import { MenuContext } from 'src/helper/providers/MenuProvider';
import { notifyPrimary } from 'src/utils/notification';
import { getAllUrlParams } from 'src/utils/routes';
import LocationsContent from './Content';
import classes from './Locations.module.scss';
import { useActions, useIndexData } from './selectorData';

let locationsPage = 1;

const Locations = () => {
  const { onArchiveResource } = useResourceActions();
  const { availableMenus, selectedLocationIds } = useContext(MenuContext);
  const selectedLocationIdsString = selectedLocationIds.join(',');
  const locationMenuObj = availableMenus.find(
    (obj) => obj.name === LOCATION_MENU_NAME
  );
  const location = useLocation();
  const urlParams = getAllUrlParams(location?.search);
  const notificationAlertRef = useRef(null);
  const { onNavigate } = useOwnNavigate();
  const [isLazyLoading, setLazyLoading] = useState(false);
  const { locations, locationsLoading } = useIndexData();
  const locationsMeta = locations.meta || null;
  const { getLocations, deleteLocation } = useActions();
  const breadcrumbs = [
    {
      label: 'Locations',
    },
  ];
  const notify = (type, title, description, settings) => {
    notifyPrimary({
      ref: notificationAlertRef,
      type,
      title,
      description,
      settings,
    });
  };
  const reloadLocations = (locationIdsString, cb) => {
    if (locationIdsString) {
      getLocations(
        {
          page: locationsPage,
          per_page: 10,
          [LOCATIONS_SCOPE_ATTR_NAME]: locationIdsString,
        },
        cb
      );
    }
  };
  const loadMore = async () => {
    setLazyLoading(true);
    locationsPage = locationsMeta.current_page + 1;
    reloadLocations(selectedLocationIdsString, () => {
      setLazyLoading(false);
    });
  };
  const handleDeleteLocation = async (tId, reason, cb) => {
    const res = await onArchiveResource(RESOURCE_DATA_KEY.LOCATIONS, tId, {
      archive_reason: reason,
    });
    if (res.isSuccess) {
      locationsPage = 1;
      reloadLocations(selectedLocationIdsString);
      if (cb) cb();
    } else {
      notify('error', 'Error!', 'Archive Location failed');
    }
  };
  useInfiniteScroll({
    elementId: 'root',
    onLoadMore: loadMore,
    threshold: 800,
    shouldLoadMore:
      !isLazyLoading &&
      locationsMeta &&
      locationsMeta.current_page < locationsMeta.last_page,
  });

  useEffect(() => {
    locationsPage = 1;
    if (
      !!urlParams?.[CALLBACK_ACTION_PARAM_KEY] &&
      [CREATE_SUCCESS_PARAM_KEY, EDIT_SUCCESS_PARAM_KEY].includes(
        urlParams[CALLBACK_ACTION_PARAM_KEY]
      )
    ) {
      notify(
        'success',
        'Success!',
        `Location ${
          urlParams[CALLBACK_ACTION_PARAM_KEY] === CREATE_SUCCESS_PARAM_KEY
            ? 'created'
            : 'updated'
        } successfully`
      );
      onNavigate('/locations');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (selectedLocationIdsString) {
      reloadLocations(selectedLocationIdsString);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLocationIdsString]);

  return (
    <>
      <PageWrapper className={classes.wrapper} gap="sm">
        <Breadcrumb items={breadcrumbs} />
        <PageTitle
          gap="sm"
          subTitle={
            <span>Select a location to view details or make adjustments</span>
          }
        >
          <span>Locations</span>
          <div className={classes.titleButtons}>
            <Button
              variant="primary"
              onClick={() => {
                onNavigate('/locations/create-location');
              }}
              type="button"
              fontSize="sm"
              weight="md"
            >
              Add New
            </Button>
          </div>
        </PageTitle>
        <div className={classes.locationsContentWrapper}>
          {locationsLoading && locationsPage === 1 && <Loading isPage />}
          <LocationsContent
            notify={notify}
            menuObj={locationMenuObj}
            onDelete={handleDeleteLocation}
          />
          {locationsLoading && locationsPage > 1 && <Loading isBottom />}
        </div>
      </PageWrapper>
      <ToastAlert toastRef={notificationAlertRef} />
    </>
  );
};

export default Locations;
