import CreateChemical from 'src/pages/Chemical/Create';

const chemicalRoutes = [
  {
    path: '/locations/:locationId/chemical/create',
    name: 'Create Chemical',
    icon: '',
    iconActive: '',
    component: CreateChemical,
    layout: 'private',
  },
];

export default chemicalRoutes;
