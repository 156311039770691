// eslint-disable-next-line no-unused-vars
import cn from 'classnames';
import React, { useEffect } from 'react';
import Breadcrumb from 'src/components/Breadcrumb';
import PageWrapper from 'src/components/Page/PageWrapper';
import useSidebarMenus from 'src/helper/hooks/useSidebarMenus';
import classes from './Dashboard.module.scss';
import Segment from './Segment';

const Dashboard = () => {
  const breadcrumbs = [
    {
      link: '/',
      label: 'Dashboard',
    },
    // {
    //   link: '/',
    //   label: 'Previous Page',
    // },
    // {
    //   label: 'Current Page',
    // },
  ];

  const { menus } = useSidebarMenus();
  // useEffect(() => {
  //   console.log('menus', menus);

  // }, [menus]);
  const foundMenu = (menus || []).find((m) => m.name == 'Repository') || {};
  const segments = (foundMenu.views || []).filter((v) => v.path == '');

  return (
    <PageWrapper className={classes.wrapper}>
      <Breadcrumb items={breadcrumbs} />
      <div>Omni Gauge Component</div>
      <hr />
      <div>
        {segments.map((s, i) => (
          <Segment segment={s} key={i}></Segment>
        ))}
      </div>
    </PageWrapper>
  );
};

export default Dashboard;
