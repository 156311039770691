import omit from 'lodash/omit';
import { LOCATION_DETAILS_TAB_KEYS } from 'src/pages/LocationDetails/constants';
import { getBEDate } from 'src/utils/date';
import { getSelectValue } from 'src/utils/form';
import { getTabUrlAction } from 'src/utils/routes';

export const getCreateEquipmentPayload = (values) => {
  const maintainById = getSelectValue(values.maintenance_by_id);
  const returnObj = {
    ...omit(values, ['maintenance_by_id']),
    sub_system_id: getSelectValue(values.sub_system_id),
    maintenance_by: Number(maintainById),
    last_maintenance: getBEDate(values.last_maintenance),
  };
  return returnObj;
};
export const getTitle = ({ isViewDetails, isEdit, details, locationId }) => {
  if (isViewDetails) return `${details?.name || ''}`;
  return isEdit
    ? locationId
      ? 'Edit Equipment'
      : `Edit Equipment - ${details?.name}`
    : 'Add New Equipment';
};
export const getSubTitle = (isViewDetails, isEdit, locationId) => {
  if (!!isEdit && !!locationId)
    return (
      <>
        An Americold refrigeration facility specializes in
        temperature-controlled storage and logistics, supporting the
        distribution of perishable goods efficiently and reliably.
      </>
    );
  if (isViewDetails) return '';
  return <>Add new equipment to the location.</>;
};
export const getBreadcrumbText = ({
  isViewDetails,
  isEdit,
  name,
  locationId,
}) => {
  if (isViewDetails) return name;
  return isEdit ? `Edit Equipment` : 'Add New Equipment';
};
export const getBreadcrumbs = ({
  locationId,
  isViewDetails,
  isEdit,
  name,
  locationName,
}) => {
  return [
    ...[
      {
        link: '/locations',
        label: 'Locations',
      },
      {
        link: getTabUrlAction(
          `/locations/${locationId}`,
          LOCATION_DETAILS_TAB_KEYS.EQUIPMENT
        ),
        label: locationName,
      },
    ],
    {
      label: getBreadcrumbText({
        isViewDetails,
        isEdit,
        name,
        locationId,
      }),
    },
  ];
};
