import {
  apiCreateRegion,
  apiDeleteRegion,
  apiGetLocations,
  apiGetRegionDetails,
  apiGetRegions,
  apiUpdateRegion,
} from 'src/api';
import Types from '../types/region';

export const syncRemoveRegions = (id) => (dispatch) => {
  dispatch({
    type: Types.SYNC_REMOVE_REGIONS,
    payload: id,
  });
};
export const syncUpdateRegionDetails = (data) => (dispatch) => {
  dispatch({
    type: Types.SYNC_UPDATE_REGION_DETAILS,
    payload: {
      ...data,
    },
  });
};
export const getRegions = (params, cb) => {
  return {
    isAsyncCall: true,
    payload: { isMore: params.page > 1 },
    type: Types.GET_REGIONS,
    asyncCall: () => {
      return apiGetRegions(params);
    },
    afterSuccessCall: (_dispatch, response) => {
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};
export const getRegionDetails = (id, cb) => {
  return {
    isAsyncCall: true,
    payload: {},
    type: Types.GET_REGION_DETAILS,
    asyncCall: () => {
      return apiGetRegionDetails(id);
    },
    afterSuccessCall: (_dispatch, response) => {
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};
export const getRegionLocations = (id, params, cb) => {
  return {
    isAsyncCall: true,
    payload: {},
    type: Types.GET_REGION_LOCATIONS,
    asyncCall: () => {
      return apiGetLocations({
        ...params,
        filters: {
          region_id: id,
          ...(params?.filters || {}),
        },
      });
    },
    afterSuccessCall: (_dispatch, response) => {
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};
export const createRegion = (data, cb) => {
  return {
    isAsyncCall: true,
    payload: {},
    type: Types.CREATE_REGION,
    asyncCall: () => {
      return apiCreateRegion(data);
    },
    afterSuccessCall: (_dispatch, response) => {
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};
export const updateRegion = (data, cb) => {
  return {
    isAsyncCall: true,
    payload: {},
    type: Types.UPDATE_REGION,
    asyncCall: () => {
      return apiUpdateRegion(data);
    },
    afterSuccessCall: (_dispatch, response) => {
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};
export const deleteRegion = (id, cb) => {
  return {
    isAsyncCall: true,
    payload: {},
    type: Types.DELETE_REGION,
    asyncCall: () => {
      return apiDeleteRegion(id);
    },
    afterSuccessCall: (_dispatch, response) => {
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};
