export const RESOURCE_DATA_KEY = {
  REGIONS: 'regions',
  COMPANIES: 'companies',
  USERS: 'users',
  ROLES: 'roles',
  SUB_SYSTEMS: 'sub-systems',
  PARTS: 'parts',
  LOCATIONS: 'locations',
  LOCATION_STAFF: 'location-staff',
  LOCATION_CHEMICALS: 'chemicals',
  LOCATION_EQUIPMENT: 'location-equipment',
  LOCATION_SUB_SYSTEM: 'location-sub-system',
  LOCATION_QUICK_ACCESS: 'location-quick-access',
  EMERGENCY_CONTACT: 'emergency-contacts',
  EMERGENCY_DOCUMENT: 'emergency-documents',
  EMERGENCY_DOCUMENT_PROCEDURES: 'emergency-documents-procedures',
  EMERGENCY_DOCUMENT_EVACUATION_PLANS: 'emergency-documents-evacuation-plans',
  QUICK_ACCESS_SECTIONS: 'quick-access-section',
  QUICK_ACCESS_ITEMS: 'quick-access-items',
  QUICK_ACCESS_HIERARCHY: 'quick-access-hierarchy',
  QUICK_ACCESS_ITEM_DETAILS: 'quick-access-item-details',
  QUICK_ACCESS_ITEM_ARCHIVED_DOCUMENTS: 'quick-access-item-archived-documents',
  QUICK_ACCESS_ITEM_REMOVE: 'quick-access-item-remove',
  QUICK_ACCESS_ITEM_DOCUMENT_COUNT: 'quick-access-item-document-counts',
  QUICK_ACCESS_ITEM_DOCUMENTS: 'quick-access-item-documents',
  QUICK_ACCESS_ITEM_DOCUMENT_DETAILS: 'quick-access-item-document-details',
  QUICK_ACCESS_ITEM_DOCUMENT_HISTORY_DETAILS:
    'quick-access-item-document-history-details',
  DOCUMENTS: 'documents',
};
export const RESOURCE_DATA_KEY_MAPPING = {
  [RESOURCE_DATA_KEY.EMERGENCY_DOCUMENT_PROCEDURES]:
    RESOURCE_DATA_KEY.EMERGENCY_DOCUMENT,
  [RESOURCE_DATA_KEY.EMERGENCY_DOCUMENT_EVACUATION_PLANS]:
    RESOURCE_DATA_KEY.EMERGENCY_DOCUMENT,
  [RESOURCE_DATA_KEY.LOCATION_STAFF]: RESOURCE_DATA_KEY.USERS,
  [RESOURCE_DATA_KEY.QUICK_ACCESS_SECTIONS]: 'quick-access/sections',
  [RESOURCE_DATA_KEY.QUICK_ACCESS_ITEMS]: 'quick-access/items',
  [RESOURCE_DATA_KEY.QUICK_ACCESS_ITEM_DETAILS]: 'quick-access/items',
  [RESOURCE_DATA_KEY.QUICK_ACCESS_HIERARCHY]: 'quick-access/hierarchy',
  [RESOURCE_DATA_KEY.LOCATION_SUB_SYSTEM]: RESOURCE_DATA_KEY.SUB_SYSTEMS,
  [RESOURCE_DATA_KEY.LOCATION_QUICK_ACCESS]: 'quick-access/entity-sections',
  [RESOURCE_DATA_KEY.QUICK_ACCESS_ITEM_DOCUMENTS]: 'quick-access/documents',
  [RESOURCE_DATA_KEY.QUICK_ACCESS_ITEM_DOCUMENT_DETAILS]:
    'quick-access/documents',
  [RESOURCE_DATA_KEY.QUICK_ACCESS_ITEM_DOCUMENT_HISTORY_DETAILS]:
    'quick-access/documents/history',
  [RESOURCE_DATA_KEY.QUICK_ACCESS_ITEM_ARCHIVED_DOCUMENTS]:
    'quick-access/documents/archived',
  [RESOURCE_DATA_KEY.LOCATION_EQUIPMENT]: 'equipments',
  [RESOURCE_DATA_KEY.QUICK_ACCESS_ITEM_DOCUMENT_COUNT]:
    'quick-access/items/document-counts',
  [RESOURCE_DATA_KEY.QUICK_ACCESS_ITEM_REMOVE]:
    'quick-access/entity-items/remove',
};
export const STORE_SYNC_ACTIONS = {
  ADD: 'add',
  UPDATE: 'update',
  DELETE: 'delete',
};
