import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation, useParams } from 'react-router-dom';
import * as yup from 'yup';
import Breadcrumb from 'src/components/Breadcrumb';
import Loading from 'src/components/Loading';
import ConfirmModal from 'src/components/Modal/Confirm';
import ToastAlert from 'src/components/ToastAlert';
import { VIEW_PARAM_KEY, isUrlAction } from 'src/helper/constants/routes';
import { RESOURCE_DATA_KEY } from 'src/helper/constants/store';
import useOwnNavigate from 'src/helper/hooks/useOwnNavigate';
import useResourceActions from 'src/helper/hooks/useResourceActions';
import GenericCreateTitle from 'src/pages/GenericCreate/components/Title';
import GenericCreateWrapper from 'src/pages/GenericCreate/components/Wrapper';
import { LOCATION_DETAILS_TAB_KEYS } from 'src/pages/LocationDetails/constants';
import { getErrorMessageFromResponse } from 'src/utils/actions';
import { notifyPrimary } from 'src/utils/notification';
import {
  getAllUrlParams,
  getCreateEditUrlAction,
  getTabUrlAction,
} from 'src/utils/routes';
import classes from './Create.module.scss';
import CreateSubSystemForm from './components/Form';
import { useActions, useIndexData } from './selectorData';
import {
  getBreadcrumbs,
  getCreateSubSystemPayload,
  getSubTitle,
  getTitle,
} from './utils';

const formSchema = yup.object().shape({
  sub_system_name: yup.string().required('Name is required!'),
  last_inspection: yup.mixed().required('Last Inspection is required!'),
});

let confirmFn = null;

const CreateSubSystem = () => {
  const { prevPath, onNavigate } = useOwnNavigate();
  const { onCreateResource, onEditResource } = useResourceActions();
  const { getResourceDetails, getLocationDetails } = useActions();
  const { subSystemDetails, locationDetails } = useIndexData();
  const location = useLocation();
  const urlParams = getAllUrlParams(location?.search);
  const params = useParams();
  const locationId = Number(params.locationId || 0);
  const locationUrl = getTabUrlAction(
    `/locations/${locationId}`,
    LOCATION_DETAILS_TAB_KEYS.SUB_SYSTEMS
  );
  const backUrl = prevPath || `${locationId ? locationUrl : '/locations'}`;
  const subSystemId = Number(urlParams.id || 0);
  const isViewDetails =
    isUrlAction(urlParams?.[VIEW_PARAM_KEY]) && !!subSystemId;
  const isEdit = !isUrlAction(urlParams?.[VIEW_PARAM_KEY]) && !!subSystemId;
  const notificationAlertRef = useRef(null);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [detailsLoading, setDetailsLoading] = useState(false);
  const [locationDetailsLoading, setLocationDetailsLoading] = useState(false);
  const [confirmObj, setConfirmObj] = useState(null);
  const breadcrumbs = getBreadcrumbs({
    locationId,
    isViewDetails,
    isEdit,
    name: subSystemDetails?.sub_system_name,
    locationName: locationDetails?.name,
  });
  const { handleSubmit, control, setValue, formState, reset } = useForm({
    resolver: yupResolver(formSchema),
    defaultValues: {
      sub_system_name: '',
      last_inspection: null,
      documents_count: '',
      description: '',
    },
  });
  const notify = (type, title, description) => {
    notifyPrimary({ ref: notificationAlertRef, type, title, description });
  };
  const reloadDetails = (cb) => {
    setDetailsLoading(true);
    getResourceDetails(
      RESOURCE_DATA_KEY.LOCATION_SUB_SYSTEM,
      subSystemId,
      (res) => {
        setDetailsLoading(false);
        if (cb) cb(res);
      }
    );
  };
  const reloadLocationDetails = (cb) => {
    setLocationDetailsLoading(true);
    getLocationDetails(locationId, (res) => {
      setLocationDetailsLoading(false);
      if (cb) cb(res);
    });
  };
  const onSubmit = async (values) => {
    if (!submitLoading) {
      setSubmitLoading(true);
      const tPayload = getCreateSubSystemPayload({
        ...values,
        location_id: locationId,
      });
      let res = null;
      if (isEdit) {
        tPayload.status = subSystemDetails?.status || 'active';
        res = await onEditResource(
          RESOURCE_DATA_KEY.LOCATION_SUB_SYSTEM,
          subSystemId,
          tPayload
        );
      } else {
        tPayload.status = 'active';
        res = await onCreateResource(
          RESOURCE_DATA_KEY.LOCATION_SUB_SYSTEM,
          tPayload
        );
      }
      setSubmitLoading(false);
      if (res.isSuccess) {
        onNavigate(getCreateEditUrlAction(backUrl, isEdit));
      } else {
        notify(
          'error',
          'Error!',
          getErrorMessageFromResponse(res) ||
            `${isEdit ? 'Edit' : 'Create'} Sub-System failed!`
        );
      }
    }
  };

  useEffect(() => {
    reloadLocationDetails();
    if (subSystemId) {
      reloadDetails((res) => {
        if (isEdit) {
          const editObj = res?.data;
          if (editObj?.id) {
            const resetObj = {
              sub_system_name: editObj.sub_system_name,
              last_inspection: editObj.last_inspection,
              description: editObj.description,
            };
            reset(resetObj);
          }
        }
      });
    }
    confirmFn = null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <GenericCreateWrapper>
        {(detailsLoading || locationDetailsLoading) && <Loading isPage />}
        <Breadcrumb items={breadcrumbs} />
        <GenericCreateTitle
          subTitle={getSubTitle(isViewDetails, isEdit, locationId)}
          className={classes.pageTitle}
        >
          <span>
            {getTitle({
              isViewDetails,
              isEdit,
              details: subSystemDetails,
              locationId,
            })}
          </span>
        </GenericCreateTitle>
        <CreateSubSystemForm
          onSubmit={handleSubmit(onSubmit)}
          isViewDetails={isViewDetails}
          details={subSystemDetails}
          control={control}
          setValue={setValue}
          formState={formState}
          isEdit={isEdit}
          submitLoading={submitLoading}
          onCancel={() => {
            if (!isEdit) {
              setConfirmObj({
                title: 'Confirm',
                description:
                  'Are you sure you want to exit without saving this new sub-system? All information that has been entered will be deleted.',
                cancelText: 'Continue Editing',
                submitText: 'Cancel Creation',
              });
              confirmFn = () => {
                onNavigate(backUrl);
              };
            } else {
              onNavigate(backUrl);
            }
          }}
        />
      </GenericCreateWrapper>
      {!!confirmObj && (
        <ConfirmModal
          isOpen
          onClose={() => {
            confirmFn = null;
            setConfirmObj(null);
          }}
          onSubmit={async (cb) => {
            if (cb) cb();
            if (confirmFn) confirmFn();
            setConfirmObj(null);
          }}
          title={confirmObj?.title}
          submitText={confirmObj?.submitText}
          cancelText={confirmObj?.cancelText}
          size="sm"
          fontSize="sm"
          className={classes.confirmModalWrapper}
          type="confirmation"
        >
          {confirmObj?.description}
        </ConfirmModal>
      )}
      <ToastAlert toastRef={notificationAlertRef} />
    </>
  );
};

export default CreateSubSystem;
