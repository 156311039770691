import cn from 'classnames';
import classes from './PageFooter.module.scss';

export const PageFooterSelectedText = ({ className, children }) => {
  return (
    <span className={cn('page-footer-selected-text', className)}>
      {children}
    </span>
  );
};

const PageFooter = ({ className, children, fixed }) => {
  return (
    <div
      className={cn(
        'page-footer-wrapper',
        classes.wrapper,
        {
          [classes.fixed]: !!fixed,
        },
        className
      )}
    >
      <div className={classes.inner}>{children}</div>
    </div>
  );
};

export default PageFooter;
