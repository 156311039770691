import cn from 'classnames';
import FileItem from './FileItem';
import classes from './Upload.module.scss';

const UploadContent = ({
  className,
  data,
  onDelete,
  onRetry,
  information,
  onCancel,
  displayUploadSize,
}) => {
  if (!data?.length) return '';
  return (
    <div className={cn(classes.contentFilesList, className)}>
      {data.map((item, i) => {
        const tId = item.file_id || '';
        return (
          <FileItem
            name={item.name}
            size={item.size}
            key={i}
            onDelete={() => {
              if (onDelete) onDelete(i, item);
            }}
            onRetry={() => {
              if (onRetry) onRetry(i, item);
            }}
            onCancel={() => {
              if (onCancel) onCancel(i, item);
            }}
            loading={!!information?.[tId]?.loading}
            isSuccess={!!information?.[tId]?.isSuccess}
            isError={!!information?.[tId]?.isError}
            displaySize={displayUploadSize}
          />
        );
      })}
    </div>
  );
};

export default UploadContent;
