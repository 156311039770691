import cn from 'classnames';
import { USER_STATUS_OPTIONS } from 'src/helper/constants/user';
import { LOCATION_DETAILS_TAB_KEYS } from 'src/pages/LocationDetails/constants';
import Emergency from '../Emergency';
import LocationDetailsTable from '../LocationDetailsTable';
import classes from './TabContent.module.scss';
import {
  getColumns1,
  getColumns2,
  getColumns3,
  getColumns4,
} from './constants';

const getFilterItems = (roleList) => {
  return {
    ROLE: {
      id: 1,
      label: 'Filter by Role',
      items: (roleList || []).map((obj) => ({
        id: obj.id,
        label: obj.role_name,
      })),
    },
    STATUS: {
      id: 3,
      label: 'Filter by Status',
      items: USER_STATUS_OPTIONS.map((obj) => ({
        id: obj.value,
        label: obj.label,
      })),
    },
  };
};

const TabContent = ({
  className,
  tabKey,
  onContact,
  onAddPlan,
  onViewPlan,
  emergencyData,
  onEmergencyPaginationChange,
  roleList,
  staffListData,
  onEmergencySort,
  chemicalListData,
  locationEquipmentData,
  locationSubSystemData,
  onFiltersChanged,
  onDelete,
  onAdd,
  onEdit,
  onRowClick,
}) => {
  const FILTER_ITEMS = getFilterItems(roleList);
  const getContent = () => {
    switch (tabKey) {
      case LOCATION_DETAILS_TAB_KEYS.SUB_SYSTEMS: {
        const columns = getColumns1({
          onDelete: onDelete(tabKey),
          onEdit: onEdit(tabKey),
        });
        const filterItems = [FILTER_ITEMS.STATUS];
        return (
          <LocationDetailsTable
            title="Sub-Systems"
            columns={columns}
            searchPlaceholder="Search Sub-Systems"
            data={locationSubSystemData}
            onAdd={onAdd(tabKey)}
            onRowClick={onRowClick(tabKey)}
            onAllFiltersChanged={onFiltersChanged(tabKey)}
            filterItems={filterItems}
          />
        );
      }
      case LOCATION_DETAILS_TAB_KEYS.EQUIPMENT: {
        const columns = getColumns2({
          onDelete: onDelete(tabKey),
          onEdit: onEdit(tabKey),
        });
        const filterItems = [FILTER_ITEMS.STATUS];
        return (
          <LocationDetailsTable
            title="Equipments"
            columns={columns}
            searchPlaceholder="Search Equipment"
            onAdd={onAdd(tabKey)}
            data={locationEquipmentData}
            onRowClick={onRowClick(tabKey)}
            onAllFiltersChanged={onFiltersChanged(tabKey)}
            filterItems={[]}
          />
        );
      }
      case LOCATION_DETAILS_TAB_KEYS.EMERGENCY:
        return (
          <Emergency
            onContact={onContact}
            onDeleteContact={onDelete(tabKey)}
            onAddPlan={onAddPlan}
            onViewPlan={onViewPlan}
            data={emergencyData}
            onPaginationChange={onEmergencyPaginationChange}
            onSortChange={onEmergencySort}
          />
        );
      case LOCATION_DETAILS_TAB_KEYS.STAFF_LIST: {
        const columns = getColumns3({
          onDelete: onDelete(tabKey),
          onEdit: onEdit(tabKey),
        });
        const filterItems = [FILTER_ITEMS.ROLE, FILTER_ITEMS.STATUS];
        return (
          <LocationDetailsTable
            title="Staff List"
            columns={columns}
            searchPlaceholder="Search Staff"
            addText="Add Staff"
            onAdd={onAdd(tabKey)}
            filterItems={filterItems}
            onAllFiltersChanged={onFiltersChanged(tabKey)}
            data={staffListData}
            onRowClick={onRowClick(tabKey)}
          />
        );
      }
      case LOCATION_DETAILS_TAB_KEYS.CHEMICALS: {
        const columns = getColumns4({
          onDelete: onDelete(tabKey),
          onEdit: onEdit(tabKey),
        });
        const filterItems = [FILTER_ITEMS.STATUS];
        return (
          <LocationDetailsTable
            title="Chemicals"
            columns={columns}
            searchPlaceholder="Search Chemicals"
            onAdd={onAdd(tabKey)}
            filterItems={filterItems}
            onAllFiltersChanged={onFiltersChanged(tabKey)}
            data={chemicalListData}
            onRowClick={onRowClick(tabKey)}
          />
        );
      }
      default:
        return '';
    }
  };
  return <div className={cn(classes.wrapper, className)}>{getContent()}</div>;
};

export default TabContent;
