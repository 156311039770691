import { useContext, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import Breadcrumb from 'src/components/Breadcrumb';
import ConfirmModal from 'src/components/Modal/Confirm';
import PageTitle from 'src/components/Page/PageTitle';
import PageWrapper from 'src/components/Page/PageWrapper';
import ToastAlert from 'src/components/ToastAlert';
import { LOCATIONS_SCOPE_ATTR_NAME } from 'src/helper/constants';
import useOwnNavigate from 'src/helper/hooks/useOwnNavigate';
import { MenuContext } from 'src/helper/providers/MenuProvider';
import GenericContent from 'src/pages/Generic/components/Content';
import GenericTable from 'src/pages/Generic/components/Table';
import { notifyPrimary } from 'src/utils/notification';
import { getViewUrlAction } from 'src/utils/routes';
import classes from './MenuItems.module.scss';
import { getColumns } from './constants';
import { onDeleteMenuItem, useActions, useIndexData } from './selectorData';

let cacheSearch = '';
let cachePage = 1;
let cacheSize = 10;
let cacheSortField = 'item_name';
let cacheSortMethod = 'asc';
let cacheMounted = 0;

const AdminMenuItems = () => {
  const { reloadMenusData, selectedLocationIds } = useContext(MenuContext);
  const selectedLocationIdsString = selectedLocationIds.join(',');
  const { getMenus } = useActions();
  const notificationAlertRef = useRef(null);
  const params = useParams();
  const { onNavigate } = useOwnNavigate();
  const [loading, setLoading] = useState(false);
  const [deleteId, setDeleteId] = useState('');
  const { menusData } = useIndexData();
  const menus = menusData?.data || [];
  const breadcrumbs = [
    {
      label: 'Menu Items',
    },
  ];
  const handleView = (record) => {
    onNavigate(getViewUrlAction(`/admin/menu-items/create?id=${record?.id}`));
  };
  const handleEdit = (record) => {
    onNavigate(`/admin/menu-items/create?id=${record?.id}`);
  };
  const handleDelete = (record) => {
    setDeleteId(record?.id);
  };
  const notify = (type, title, description) => {
    notifyPrimary({ ref: notificationAlertRef, type, title, description });
  };
  const resetState = () => {
    setLoading(false);
    setDeleteId('');
    cacheSearch = '';
    cachePage = 1;
    cacheSize = 10;
    cacheSortField = 'item_name';
    cacheSortMethod = 'asc';
    cacheMounted = 0;
  };

  const columns = getColumns({
    onView: handleView,
    onEdit: handleEdit,
    onDelete: handleDelete,
  });
  const reloadMenus = (tSearch, tPage, tSize, tSortField, tSortMethod, cb) => {
    setLoading(true);
    getMenus(
      {
        page: tPage,
        per_page: tSize,
        search: tSearch,
        sort_by: tSortField,
        sort_order: tSortMethod,
        [LOCATIONS_SCOPE_ATTR_NAME]: selectedLocationIdsString,
      },
      () => {
        setLoading(false);
        if (cb) cb();
      }
    );
  };

  useEffect(() => {
    if (selectedLocationIdsString && cacheMounted === 1) {
      reloadMenus(
        cacheSearch,
        cachePage,
        cacheSize,
        cacheSortField,
        cacheSortMethod
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLocationIdsString]);
  useEffect(() => {
    resetState();
    reloadMenus(
      cacheSearch,
      cachePage,
      cacheSize,
      cacheSortField,
      cacheSortMethod,
      () => {
        cacheMounted = 1;
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <PageWrapper className={classes.wrapper}>
        <Breadcrumb items={breadcrumbs} />
        <PageTitle
          subTitle={
            <span>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer took a galley of
              type and scrambled it to make a type specimen book.
            </span>
          }
        >
          Menu Items
        </PageTitle>
        <GenericContent>
          {/* <GenericActionButtons /> */}
          <GenericTable
            onAdd={() => {
              onNavigate('/admin/menu-items/create');
            }}
            columns={columns}
            title="List of menu items"
            data={menus}
            total={menusData.meta?.total || 0}
            onPaginationChange={(tPage, tSize) => {
              cachePage = tPage;
              cacheSize = tSize;
              reloadMenus(
                cacheSearch,
                cachePage,
                cacheSize,
                cacheSortField,
                cacheSortMethod
              );
            }}
            onSearchChange={(val) => {
              cacheSearch = val;
              cachePage = 1;
              reloadMenus(
                cacheSearch,
                cachePage,
                cacheSize,
                cacheSortField,
                cacheSortMethod
              );
            }}
            onSortChange={(obj) => {
              cacheSortField = obj.field;
              cacheSortMethod = obj.method;
              cachePage = 1;
              reloadMenus(
                cacheSearch,
                cachePage,
                cacheSize,
                cacheSortField,
                cacheSortMethod
              );
            }}
            loading={loading}
          />
        </GenericContent>
      </PageWrapper>
      <ToastAlert toastRef={notificationAlertRef} />
      {!!deleteId && (
        <ConfirmModal
          isOpen
          onClose={() => {
            setDeleteId('');
          }}
          onSubmit={async (cb) => {
            const res = await onDeleteMenuItem(deleteId);
            if (cb) cb();
            if (res.isSuccess) {
              reloadMenusData();
              setDeleteId('');
              reloadMenus(
                cacheSearch,
                cachePage,
                cacheSize,
                cacheSortField,
                cacheSortMethod
              );
            } else {
              notify('error', 'Error!', 'Delete Menu Item failed!');
            }
          }}
          title="Delete Menu Item"
          submitText="Delete"
        >
          Do you want to delete this menu item?
        </ConfirmModal>
      )}
    </>
  );
};

export default AdminMenuItems;
