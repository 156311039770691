import CreateEquipment from 'src/pages/Equipment/Create';

const chemicalRoutes = [
  {
    path: '/locations/:locationId/equipment/create',
    name: 'Create Equipment',
    icon: '',
    iconActive: '',
    component: CreateEquipment,
    layout: 'private',
  },
];

export default chemicalRoutes;
