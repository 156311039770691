import { createTypes } from './createTypes';

export default createTypes(
  'SYNC_SET_LOGIN_DATA',
  'SYNC_SET_LOGIN_MENUS',
  'SYNC_SET_LOGIN_MENUS_DATA',
  'LOGIN',
  'LOG_OUT_USER',
  'GET_CURRENT_USER'
);
