import { Controller } from 'react-hook-form';
import FormatInput from './FormatInput';
import Input from './';

const FormInput = ({ name, control, onChange, ...props }) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) =>
        props.type === 'format' ? (
          <FormatInput
            {...field}
            {...props}
            error={error?.message}
            onChange={(e) => {
              field.onChange(e.target.value);
              if (onChange) {
                onChange(e);
              }
            }}
          />
        ) : (
          <Input
            {...field}
            {...props}
            error={error?.message}
            onChange={(e) => {
              field.onChange(e.target.value);
              if (onChange) {
                onChange(e);
              }
            }}
          />
        )
      }
    />
  );
};

export default FormInput;
