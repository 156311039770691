import { useEffect, useState } from 'react';
import { RESOURCE_DATA_KEY } from 'src/helper/constants/store';
import useResourceActions from 'src/helper/hooks/useResourceActions';
import { callAction } from 'src/utils/actions';

const usePartOptions = () => {
  const { onGetResources } = useResourceActions();
  const [options, setOptions] = useState([]);
  const reloadData = async () => {
    const res = await onGetResources(RESOURCE_DATA_KEY.PARTS, {
      page: 1,
      per_page: 9999,
    });
    setOptions(
      (res?.isSuccess ? res.data : []).map((obj) => ({
        value: obj.id,
        // label: obj.sub_system_name + ' ➡ ' + obj.part_name,
        label: obj.part_name,
        subSystemId: obj.sub_system_id,
      }))
    );
  };
  useEffect(() => {
    reloadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { partOptions: options, refetch: reloadData };
};

export default usePartOptions;
