import cn from 'classnames';
import classes from './QuickAccess.module.scss';

const AccessSectionLabel = ({ className, children }) => {
  return (
    <label className={cn(classes.sectionLabel, className)}>{children}</label>
  );
};

export default AccessSectionLabel;
