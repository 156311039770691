import { useState } from 'react';
import { MENU_ACTION_KEYS } from 'src/helper/constants/menu';
import useOwnNavigate from 'src/helper/hooks/useOwnNavigate';
import ArchiveLocationModal from 'src/pages/RegionDetails/components/ArchiveLocationModal';
import EmptyState from 'src/pages/Regions/components/EmptyState';
import LocationCard from 'src/pages/Regions/components/LocationCard';
import { getIsMenuAction } from 'src/utils/menu';
import classes from './Locations.module.scss';
import { useIndexData } from './selectorData';

const LocationsContent = ({ notify, menuObj, onDelete }) => {
  const [archiveLocationObj, setArchiveLocationObj] = useState(null);
  const { onNavigate } = useOwnNavigate();
  const { locations, locationsLoading } = useIndexData();
  const locationsList = locations?.data || [];
  const isNotFound = !locations?.meta?.total;

  return (
    <>
      {isNotFound ? (
        <EmptyState
          entity="location"
          onCreate={() => {
            onNavigate('/locations/create-location');
          }}
        />
      ) : (
        <div className={classes.locations}>
          {locationsList.map((location, i) => {
            return (
              <LocationCard
                key={i}
                onDelete={
                  !getIsMenuAction(menuObj, MENU_ACTION_KEYS.DELETE)
                    ? undefined
                    : () => {
                        setArchiveLocationObj(location);
                      }
                }
                onEdit={
                  !getIsMenuAction(menuObj, MENU_ACTION_KEYS.EDIT)
                    ? undefined
                    : () => {
                        onNavigate(`/locations/edit/${location.id}`);
                      }
                }
                data={location}
                onView={() => {
                  onNavigate(`/locations/${location.id}`);
                }}
                hideActions={
                  !getIsMenuAction(menuObj, MENU_ACTION_KEYS.DELETE) &&
                  !getIsMenuAction(menuObj, MENU_ACTION_KEYS.EDIT)
                }
              />
            );
          })}
        </div>
      )}
      {archiveLocationObj && (
        <ArchiveLocationModal
          isOpen
          onClose={() => {
            setArchiveLocationObj(null);
          }}
          onSubmit={(val, cb) => {
            onDelete(archiveLocationObj.id, val, () => {
              if (cb) cb();
              setArchiveLocationObj(null);
              notify('success', 'Success!', 'Location archived successfully');
            });
          }}
        />
      )}
    </>
  );
};

export default LocationsContent;
