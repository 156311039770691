import FormSelect from 'src/components/FormFields/Select/FormSelect';
import { RELATED_PSM_ELEMENT_OPTIONS } from 'src/helper/constants/option';

const SelectElements = ({ control, watch, setValue, name }) => {
  const selectedValues = watch(name);
  return (
    <FormSelect
      options={RELATED_PSM_ELEMENT_OPTIONS}
      label="Related PSM Elements"
      name={name}
      placeholder="Select related PSM elements"
      control={control}
      radius="md"
      variant="primary"
      fontSize="sm"
      activeShadow
      isMulti
      multiLabel="Elements"
      optionWithCheckbox
      hasSelectAll
      isClearable={false}
      checkedAll={
        selectedValues?.length > 0 &&
        selectedValues?.length === RELATED_PSM_ELEMENT_OPTIONS.length
      }
      optionWithIndex
      onCheckedAllChange={(tChecked) => {
        setValue(name, tChecked ? RELATED_PSM_ELEMENT_OPTIONS : []);
      }}
      closeMenuOnSelect={false}
    />
  );
};

export default SelectElements;
