import { ReactComponent as RegionsSvg } from 'src/assets/icons/Nav/Regions.svg';
import { ReactComponent as RegionsActiveSvg } from 'src/assets/icons/Nav/Regions_Active.svg';
import CreateRegion from 'src/pages/CreateRegion';
import RegionDetails from 'src/pages/RegionDetails';
import Regions from 'src/pages/Regions';

const regionRoutes = [
  {
    path: '/regions/:id',
    name: 'Region Details',
    icon: '',
    iconActive: '',
    component: RegionDetails,
    layout: 'private',
  },
  {
    path: '/regions/edit/:id',
    name: 'Edit Region',
    icon: '',
    iconActive: '',
    component: CreateRegion,
    layout: 'private',
  },
  {
    path: '/regions/create-region',
    name: 'Create Region',
    icon: '',
    iconActive: '',
    component: CreateRegion,
    layout: 'private',
  },
  {
    path: '/regions',
    name: 'Regions',
    icon: <RegionsSvg />,
    iconActive: <RegionsActiveSvg />,
    component: Regions,
    layout: 'private',
  },
];

export default regionRoutes;
