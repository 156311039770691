import classnames from 'classnames';
import { useContext, useState } from 'react';
import {
  Col,
  Collapse,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form,
  ListGroup,
  ListGroupItem,
  Media,
  Nav,
  NavItem,
  Navbar,
  Row,
  UncontrolledDropdown,
} from 'reactstrap';
import { ReactComponent as NotificationSvg } from 'src/assets/icons/notification.svg';
import Select from 'src/components/FormFields/Select';
import { isStaging } from 'src/helper/constants';
import useDynamicMenu from 'src/helper/hooks/useDynamicMenu';
import useProfile from 'src/helper/hooks/useProfile';
import { MenuContext } from 'src/helper/providers/MenuProvider';
import classes from './Topbar.module.scss';

function Topbar({ theme, sidenavOpen, toggleSidenav, onLogout }) {
  const { selectedLocations, onUpdateSelectedLocations } =
    useContext(MenuContext);
  const { scopeFilteredMenus } = useDynamicMenu();
  const currentUser = useProfile();

  return (
    <>
      <Navbar
        className={classnames(
          'navbar-top navbar-expand border-bottom bg-info',
          classes.navbarTopWrapper
        )}
      >
        <Container fluid>
          <Collapse navbar isOpen={true}>
            {!isStaging && (
              <Form
                className={classnames(
                  'navbar-search form-inline mr-sm-3',
                  { 'navbar-search-light': theme === 'dark' },
                  { 'navbar-search-dark': theme === 'light' }
                )}
              >
                <Select
                  className={classes.selectLocationWrapper}
                  options={scopeFilteredMenus}
                  placeholder="Select Locations"
                  hideArrow
                  isMulti
                  selectedWithCheck
                  value={selectedLocations}
                  closeMenuOnSelect={false}
                  hasSelectAll
                  checkedAll={
                    selectedLocations.length > 0 &&
                    selectedLocations.length === scopeFilteredMenus.length
                  }
                  onCheckedAllChange={(tChecked) => {
                    const isMin = selectedLocations.length == 1 && !tChecked;
                    if (!isMin) {
                      onUpdateSelectedLocations(
                        tChecked ? scopeFilteredMenus : [scopeFilteredMenus[0]]
                      );
                    }
                  }}
                  multiLabel="locations selected"
                  onChange={(val) => {
                    const isMin =
                      selectedLocations.length === 1 && !val?.length;
                    if (!isMin) {
                      onUpdateSelectedLocations(
                        val?.length === 0 ? [scopeFilteredMenus[0]] : val
                      );
                    }
                  }}
                />
              </Form>
            )}
            <Nav className="align-items-center ml-md-auto" navbar>
              <NavItem className="d-xl-none">
                <div
                  className={classnames(
                    'pr-3 sidenav-toggler',
                    { active: sidenavOpen },
                    { 'sidenav-toggler-dark': theme === 'dark' }
                  )}
                  onClick={toggleSidenav}
                >
                  <div className="sidenav-toggler-inner">
                    <i className="sidenav-toggler-line" />
                    <i className="sidenav-toggler-line" />
                    <i className="sidenav-toggler-line" />
                  </div>
                </div>
              </NavItem>
              <UncontrolledDropdown nav>
                <DropdownToggle className="nav-link" color="" tag="a">
                  <NotificationSvg />
                </DropdownToggle>
                <DropdownMenu
                  className="dropdown-menu-xl py-0 overflow-hidden"
                  right
                >
                  <div className="px-3 py-3">
                    <h6 className="text-sm text-muted m-0">
                      You have <strong className="text-info">13</strong>{' '}
                      notifications.
                    </h6>
                  </div>
                  <ListGroup flush>
                    <ListGroupItem
                      className="list-group-item-action"
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                      tag="a"
                    >
                      <Row className="align-items-center">
                        <Col className="col-auto">
                          <img
                            alt="..."
                            className="avatar rounded-circle"
                            src={require('src/assets/img/theme/team-1.jpg')}
                          />
                        </Col>
                        <div className="col ml--2">
                          <div className="d-flex justify-content-between align-items-center">
                            <div>
                              <h4 className="mb-0 text-sm">John Snow</h4>
                            </div>
                            <div className="text-right text-muted">
                              <small>2 hrs ago</small>
                            </div>
                          </div>
                          <p className="text-sm mb-0">
                            Let's meet at Starbucks at 11:30. Wdyt?
                          </p>
                        </div>
                      </Row>
                    </ListGroupItem>
                  </ListGroup>
                  <DropdownItem
                    className="text-center text-info font-weight-bold py-3"
                    href="#pablo"
                    onClick={(e) => e.preventDefault()}
                  >
                    View all
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
            <Nav className="align-items-center ml-auto ml-md-0" navbar>
              <UncontrolledDropdown nav>
                <DropdownToggle className="nav-link pr-0" color="" tag="a">
                  <Media className="align-items-center">
                    <span className="avatar avatar-sm rounded-circle">
                      <img
                        alt="..."
                        src={require('src/assets/img/user-placeholder.png')}
                      />
                    </span>
                    <Media className={classes.userProfileText}>
                      <span>{currentUser.name}</span>
                      <span>{currentUser.email}</span>
                    </Media>
                  </Media>
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      if (onLogout) onLogout();
                    }}
                  >
                    <i className="ni ni-user-run" />
                    <span>Logout</span>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default Topbar;
