import cn from 'classnames';
import { forwardRef, useRef } from 'react';
import ReactQuill from 'react-quill';
import classes from './Editor.module.scss';

// Formats objects for setting up the Quill editor
const formats = [
  'header',
  'font',
  'size',
  'bold',
  'italic',
  'underline',
  'align',
  'strike',
  'script',
  'blockquote',
  'background',
  'list',
  'bullet',
  'indent',
  'link',
  'image',
  'color',
  'code-block',
];

let tempRef = null;

const CustomUndo = () => (
  <svg viewBox="0 0 18 18">
    <polygon className="ql-fill ql-stroke" points="6 10 4 12 2 10 6 10" />
    <path
      className="ql-stroke"
      d="M8.09,13.91A4.6,4.6,0,0,0,9,14,5,5,0,1,0,4,9"
    />
  </svg>
);

// Redo button icon component for Quill editor
const CustomRedo = () => (
  <svg viewBox="0 0 18 18">
    <polygon className="ql-fill ql-stroke" points="12 10 14 12 16 10 12 10" />
    <path
      className="ql-stroke"
      d="M9.91,13.91A4.6,4.6,0,0,1,9,14a5,5,0,1,1,5-5"
    />
  </svg>
);
const undoChange = () => {
  if (tempRef) {
    tempRef.getEditor().history.undo();
  }
};

// Custom Redo Button
const redoChange = () => {
  if (tempRef) {
    tempRef.getEditor().history.redo();
  }
};

const QuillToolbar = () => (
  <div className="ql-toolbar">
    <div id="toolbar">
      <span className="ql-formats">
        <select
          className="ql-header"
          defaultValue={''}
          onChange={(e) => e.persist()}
        >
          <option value="1" />
          <option value="2" />
          <option value="3" />
          <option selected />
        </select>
      </span>
      <span className="ql-formats">
        <button className="ql-bold" />
        <button className="ql-italic" />
        <button className="ql-underline" />
      </span>
      <span className="ql-formats">
        <button className="ql-link" />
      </span>
      <span className="ql-formats">
        <button className="ql-list" value="ordered" />
        <button className="ql-list" value="bullet" />
      </span>
      <span className="ql-formats">
        <button className="ql-align" value="" />
        <button className="ql-align" value="center" />
        <button className="ql-align" value="right" />
        <button className="ql-align" value="justify" />
        {/* <select className="ql-color" />
        <select className="ql-background" /> */}
      </span>
      <span className="ql-formats">
        <button className="ql-indent" value="-1" />
        <button className="ql-indent" value="+1" />
      </span>
      <span className="ql-formats">
        <button className="ql-undo">
          <CustomUndo />
        </button>
        <button className="ql-redo">
          <CustomRedo />
        </button>
      </span>
    </div>
  </div>
);

const Editor = forwardRef(({ className, ...other }, ref) => {
  const modules = {
    toolbar: {
      container: '#toolbar',
      handlers: {
        undo: undoChange,
        redo: redoChange,
      },
    },
    history: {
      delay: 500,
      maxStack: 100,
      userOnly: true,
    },
  };
  return (
    <div className={cn(classes.wrapper, className)}>
      <QuillToolbar />
      <ReactQuill
        {...other}
        ref={(tRef) => {
          tempRef = tRef;
        }}
        modules={modules}
        formats={formats}
      />
    </div>
  );
});

export default Editor;
