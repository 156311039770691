/* eslint-disable no-console */
/* eslint-disable no-prototype-builtins */
// context/todoContext.tsx
import moment from 'moment';
import * as React from 'react';
// import {
//   createEvent,
//   fetchEvents,
//   deleteEvent,
//   updateEvent
// } from "../utils/api";
import { useActions } from 'src/components/Adjenda/selectorData.js';
import { useReducerData } from 'src/store/hooks';

export const EventContext = React.createContext();

const EventProvider = ({ children }) => {
  const [events, setEvents] = React.useState([]);
  const [allSubordinates, setAllSubordinates] = React.useState([]);

  const [selectedEvent, setSelectedEvent] = React.useState({
    _id: '',
    title: '',
    description: '',
    start: null,
    end: null,
    bgColor: '',
  });
  const [open, setOpen] = React.useState(false);
  const [requestPayload, setRequestPayload] = React.useState({
    mode: 'attention',
    view: 'month',
    startDate: moment(new Date()).startOf('month').toDate(),
  });
  const [formType, setFormType] = React.useState('');

  const userData = useReducerData('auth', 'login.data', []);
  const { getTasks, startTask, createTask, deleteTask, updateTask } =
    useActions();

  // task processing functions

  const reformatItems = (events, userData, allSubordinates) => {
    return events.map(function (event) {
      var newObj = { ...event };
      if (
        event.assignedTo.indexOf(userData.id) == -1
        // && event.createdBy != userData.id
      ) {
        // not assigned to logged in user
        newObj['notAssignedToLoggedinUser'] = true;
      }
      newObj['_id'] = event._id;
      newObj['status'] = event.status;
      newObj['title'] = event.title || event.workflow.information.taskTitle;
      newObj['description'] =
        event.description || event.workflow?.information.taskDescription;
      newObj['hours'] = [1]; // event.hours;
      newObj['start'] = new Date(Date.parse(event.startDate)); // .toISOString().slice(0, 16);
      newObj['end'] = new Date(Date.parse(event.startDate)); // .toISOString().slice(0, 16);
      newObj['flags'] = getTaskFlags(newObj, allSubordinates);
      newObj['style'] = getStyle(newObj);
      newObj['prompt'] = getPrompt(newObj, userData);
      newObj['bgColor'] = getCalendarColor(newObj);
      newObj['usersInfo'] = event.usersInfo;
      return newObj;
    });
  };

  const getTaskFlags = (task, allSubordinates) => {
    // TODO: move to util to be accessed
    // by BE and FE
    if (!task.flags) task.flags = [];
    delete task.fault;
    delete task.faultSince;
    const now = new Date().setHours(0, 0, 0, 0);
    if (task.requiresSupervisorApproval) {
      // deb;
    }
    if (
      new Date(task.dueDate) < now &&
      task.status != 'Completed' &&
      task.flags.indexOf('overdue') == -1
    ) {
      task.flags.push('overdue');
      task.fault = 'overdue';
      task.faultSince = moment(task.dueDate).set('hours', 0).add(1, 'days');
    }
    if (
      new Date(task.publishDate) > now &&
      task.flags.indexOf('hidden') == -1
    ) {
      task.flags.push('hidden');
    }
    if (task.assignedTo.length == 0) {
      task.flags.push('awaiting assignment');
      if (new Date(task.startDate) < now) {
        task.flags.push('late to assign');
      }
    }
    if (
      new Date(task.startDate) < now &&
      ['Created', 'Queued'].includes(task.status) &&
      task.flags.indexOf('late to start') == -1
    ) {
      task.flags.push('late to start');
      if (!task.fault) {
        task.fault = 'late to start';
        task.faultSince = moment(task.startDate).set('hours', 0).add(1, 'days');
      }
    }
    if (
      ['In Progress', 'Started'].includes(task.status) &&
      task.flags.indexOf('in progress') == -1
    )
      task.flags.push('in progress');
    if (task.status == 'Completed' && task.flags.indexOf('completed') == -1) {
      task.flags.push('completed');
    }

    // see which level of supervisor should be informed of the task fault
    if (task.fault && task.notAssignedToLoggedinUser) {
      // console.log(allSubordinates)
      // find the first subordinate that is in the assignedTo list
      const subordinate = allSubordinates.find(
        (s) => task.assignedTo.indexOf(s.userId) > -1
      );
      if (subordinate) {
        // logic: if the task is in fault, then show it to the supervisor right away
        // then, 3 days later to his upervisor, and so on
        const showSince = task.faultSince.add(
          (subordinate.distance - 1) * 3,
          'days'
        );
        // if show since is in the past, then hide the task
        // use moment to compare dates and make hours zero
        if (showSince.set('hours', 0) > moment().set('hours', 0)) {
          task.flags.push('hidden');
        }
      }
    }
    return task.flags;
  };

  const getCalendarColor = (task) => {
    const disabled = moment(task.allowedStartDate) >= new Date();
    if (disabled) return '#aaa';
    if (task.flags.indexOf('overdue') > -1) return '#ff0000';
    if (task.flags.indexOf('late to start') > -1) return '#ff0000';
    if (task.flags.indexOf('completed') > -1) return '#55aa55';

    switch (task.status) {
      case 'Queued':
        return '#ff7f50';
      case 'Started':
      case 'In Progress':
        return '#55aa55';
      case 'Completed':
        return '#00ff00';
      default:
        return '#ff7f50';
    }
  };

  const getStyle = (task) => {
    const base = { borderRadius: '13px 0 0 13px' };
    if (task.flags.indexOf('overdue') > -1)
      return { ...base, backgroundColor: '#ff000040' };
    if (task.flags.indexOf('late to start') > -1)
      return { ...base, backgroundColor: '#ff000020' };
    if (task.flags.indexOf('completed') > -1)
      return { ...base, backgroundColor: '#00ff0040' };
    return {};
  };
  const getPrompt = (task, userData) => {
    let supevisorMessage = '';
    if (task.notAssignedToLoggedinUser) {
      // not assigned to logged in user
      supevisorMessage = (
        <div>
          {task.usersInfo
            .filter((t) => task.assignedTo.indexOf(t.id) > -1)
            .map((u, uIndex) => (
              <div key={uIndex}>{u.name} is</div>
            ))}
          {task.flags.indexOf('late to assign') > -1 && (
            <div>Late to assign</div>
          )}
        </div>
      );
    } else if (task.flags.indexOf('late to assign') > -1)
      supevisorMessage = <div>Late to assign</div>;

    // supevisorMessage = <div> {JSON.stringify(task.flags)}</div>
    const base = {
      borderRadius: '7px',
      padding: '5px',
      marginBottom: '10px',
      fontWeight: 'bold',
      textAlign: 'center',
    };
    if (task.flags.indexOf('completed') > -1)
      return (
        <div style={{ ...base, backgroundColor: '#00ff0040' }}>
          {supevisorMessage}Completed
        </div>
      );
    if (task.flags.indexOf('overdue') > -1)
      return (
        <div style={{ ...base, backgroundColor: '#ff000040' }}>
          {supevisorMessage}Overdue
        </div>
      );
    if (task.flags.indexOf('late to start') > -1)
      return (
        <div style={{ ...base, backgroundColor: '#ff000020' }}>
          {supevisorMessage}Late to start
        </div>
      );
    if (isDueInDays(task.dueDate, 0))
      return (
        <div style={{ ...base, backgroundColor: '#ff000040' }}>
          {supevisorMessage}Due Today
        </div>
      );
    if (isDueInDays(task.dueDate, 1))
      return (
        <div style={{ ...base, backgroundColor: '#ff000040' }}>
          {supevisorMessage}Due Tomorrow
        </div>
      );
    if (isDueInDays(task.dueDate, 2))
      return (
        <div style={{ ...base, backgroundColor: '#dd000040' }}>
          {supevisorMessage}Due in 2 Days
        </div>
      );
    if (isDueInDays(task.dueDate, 3))
      return (
        <div style={{ ...base, backgroundColor: '#bb000040' }}>
          {supevisorMessage}Due in 2 Days
        </div>
      );
    return undefined;
  };

  function isDueInDays(dateString, days) {
    const today = new Date();
    today.setHours(0, 0, 0, 0); // Normalize today's date to 00:00:00

    const targetDate = new Date(today);
    targetDate.setDate(targetDate.getDate() + days); // Add specified number of days

    const inputDate = new Date(dateString);
    inputDate.setHours(0, 0, 0, 0); // Ignore time part of input date

    return inputDate.getTime() === targetDate.getTime();
  }
  const fixDatesAsIso = (event) => {
    const starter = new Date(Date.parse(event.start));
    starter.setMinutes(starter.getMinutes() - starter.getTimezoneOffset());
    const ender = new Date(Date.parse(event.end));
    ender.setMinutes(ender.getMinutes() - ender.getTimezoneOffset());

    return {
      start: starter.toISOString().slice(0, 16),
      end: ender.toISOString().slice(0, 16),
    };
  };

  const fixDatesAsTimestamps = (event) => {
    const starter = new Date(Date.parse(event.start));
    starter.setMinutes(starter.getMinutes() - starter.getTimezoneOffset());
    const ender = new Date(Date.parse(event.end));
    ender.setMinutes(ender.getMinutes() - ender.getTimezoneOffset());

    return {
      start: Date.parse(starter),
      end: Date.parse(ender),
    };
  };

  // events functions

  const handleClickOpen = (event = null) => {
    try {
      setOpen(true);
      if (event === null) {
        // Create New Event
        setFormType('add');
      } else if (
        event.hasOwnProperty('start') &&
        event.hasOwnProperty('end') &&
        !event.hasOwnProperty('title') &&
        !event.hasOwnProperty('description')
      ) {
        // Timeslot Select
        setFormType('add');
        const stamps = fixDatesAsIso(event);
        setSelectedEvent(stamps);
        console.log('timeslot', stamps);
      } else {
        // Select Existing
        const stamps = fixDatesAsTimestamps(event);
        setSelectedEvent({ ...stamps, ...event });
        console.log('selectExisting', selectedEvent);
        setFormType('show');
      }
    } catch (e) {
      alert('handleClickOpen: ', e);
    }
  };

  const handleClose = () => {
    setFormType('');
    setSelectedEvent(null);
    setOpen(false);
  };

  const init = async (payload) => {
    try {
      if (!payload) {
        payload = requestPayload;
      } else {
        setRequestPayload(payload);
      }
      getTasks({ userId: userData.id, ...payload }, (res) => {
        setAllSubordinates(res.allSubordinates);
        const events = res.result || [];
        const newItems = reformatItems(events, userData, res.allSubordinates);
        console.log('init: ', newItems);
        setEvents(newItems);
      });
    } catch (e) {
      alert(e);
    }
  };

  React.useEffect(() => {
    // init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const saveEvent = async (event) => {
    const payload = { ...event };
    const adjustPayload = fixDatesAsTimestamps(payload);
    const newEvent = await createTask({ ...adjustPayload, ...payload });
    if (newEvent.isSuccess) {
      const reformatItem = reformatItems([newEvent.result.task], userData);
      setEvents([
        ...events,
        {
          ...reformatItem[0],
        },
      ]);
      handleClose();
    }
    return newEvent;
  };

  const editEvent = async (data) => {
    const res = await updateTask(data._id || selectedEvent._id, data);

    const reformatItem = fixDatesAsTimestamps(res.result.task);
    const foundEvent = events.filter((event) => {
      if (event._id === data._id) {
        const newObject = { ...reformatItem, ...data };
        newObject.flags = getTaskFlags(newObject);
        const index = events.indexOf(event);
        events.splice(index, 1);
        setEvents([...events, newObject]);
      }
    });
    if (foundEvent) {
      handleClose();
      init();
    }
    return reformatItem;
  };

  const removeEvent = async () => {
    const newEvent = await deleteTask(selectedEvent._id);
    if (newEvent.isSuccess) {
      handleClose();
      init();
    }
    return newEvent;
  };

  return (
    <EventContext.Provider
      value={{
        events,
        open,
        saveEvent,
        editEvent,
        removeEvent,
        handleClickOpen,
        handleClose,
        selectedEvent,
        setSelectedEvent,
        formType,
        setFormType,
        init,
      }}
    >
      {children}
    </EventContext.Provider>
  );
};

export default EventProvider;

export function useEventContext() {
  return React.useContext(EventContext);
}
