import { RESOURCE_DATA_KEY } from 'src/helper/constants/store';
import * as locationActions from 'src/store/actions/location';
import * as regionActions from 'src/store/actions/region';
import * as resourceActions from 'src/store/actions/resource';
import { useReducerData, useStoreActions } from 'src/store/hooks';

export const useIndexData = (isQuickAccess, documentHistoryId) => {
  const detailsData = useReducerData(
    'resource',
    `resourceDetails.data.${RESOURCE_DATA_KEY.EMERGENCY_DOCUMENT}`,
    {}
  );
  const documentDetailsData = useReducerData(
    'resource',
    `resourceDetails.data.${RESOURCE_DATA_KEY.QUICK_ACCESS_ITEM_DOCUMENT_DETAILS}`,
    {}
  );
  const documentHistoryDetailsData = useReducerData(
    'resource',
    `resourceDetails.data.${RESOURCE_DATA_KEY.QUICK_ACCESS_ITEM_DOCUMENT_HISTORY_DETAILS}`,
    {}
  );
  const quickAccessDetails = useReducerData(
    'resource',
    `resourceDetails.data.${RESOURCE_DATA_KEY.QUICK_ACCESS_ITEM_DETAILS}`,
    {}
  );
  const tDocumentDetailsData = documentHistoryId
    ? { ...documentHistoryDetailsData }
    : { ...documentDetailsData };
  return {
    locationDetails: useReducerData('location', 'locationDetails.data', {}),
    locationDetailsLoading: useReducerData(
      'location',
      'locationDetails.loading',
      false
    ),
    detailsData: isQuickAccess
      ? {
          ...tDocumentDetailsData,
          title: tDocumentDetailsData.file_name,
          valid_until: tDocumentDetailsData.expires_at,
          quickAccessDetails,
        }
      : detailsData,
  };
};

export const useActions = () => {
  return useStoreActions({
    ...locationActions,
    ...resourceActions,
  });
};
