export const SECTION_LEVELS = {
  ORGANIZATION: 'Organization',
  LOCATION: 'Location',
  SUB_SYSTEM: 'Subsystem',
  EQUIPMENT: 'Equipment',
};
export const QUICK_ACCESS_TYPES = {
  FILE_UPLOAD: 'file',
  RICH_TEXT: 'rich_text',
};
export const QUICK_ACCESS_TYPE_LABELS = {
  [QUICK_ACCESS_TYPES.FILE_UPLOAD]: 'File Upload',
  [QUICK_ACCESS_TYPES.RICH_TEXT]: 'Rich Text',
};
export const QUICK_ACCESS_SECTIONS = {
  GENERAL: 'General',
  GUIDELINES: 'Guidelines',
  CONTRACTORS: 'Contractors',
};
export const SECTION_LEVEL_OPTIONS = [
  {
    value: SECTION_LEVELS.ORGANIZATION,
    label: 'Organization',
  },
  {
    value: SECTION_LEVELS.LOCATION,
    label: 'Location',
  },
  {
    value: SECTION_LEVELS.SUB_SYSTEM,
    label: 'Subsystem',
  },
  {
    value: SECTION_LEVELS.EQUIPMENT,
    label: 'Equipment',
  },
];
export const QUICK_ACCESS_TYPE_OPTIONS = [
  {
    value: QUICK_ACCESS_TYPES.FILE_UPLOAD,
    label: QUICK_ACCESS_TYPE_LABELS[QUICK_ACCESS_TYPES.FILE_UPLOAD],
  },
  {
    value: QUICK_ACCESS_TYPES.RICH_TEXT,
    label: QUICK_ACCESS_TYPE_LABELS[QUICK_ACCESS_TYPES.RICH_TEXT],
  },
];
export const QUICK_ACCESS_SECTION_ITEMS = {
  [SECTION_LEVELS.ORGANIZATION]: Object.keys(QUICK_ACCESS_SECTIONS).map(
    (key, index) => {
      const sectionId = `${SECTION_LEVELS.ORGANIZATION}_${key}`;
      return {
        id: sectionId,
        title: QUICK_ACCESS_SECTIONS[key],
        canRemove: index === Object.keys(QUICK_ACCESS_SECTIONS).length - 1,
        items: [
          {
            id: 1,
          },
        ],
      };
    }
  ),
  [SECTION_LEVELS.LOCATION]: Object.keys(QUICK_ACCESS_SECTIONS).map(
    (key, index) => {
      const sectionId = `${SECTION_LEVELS.LOCATION}_${key}`;
      return {
        id: sectionId,
        title: QUICK_ACCESS_SECTIONS[key],
        canRemove: index === Object.keys(QUICK_ACCESS_SECTIONS).length - 1,
        items:
          index < Object.keys(QUICK_ACCESS_SECTIONS).length - 1
            ? [
                {
                  id: 1,
                },
              ]
            : [],
      };
    }
  ),
  [SECTION_LEVELS.SUB_SYSTEM]: Object.keys(QUICK_ACCESS_SECTIONS).map(
    (key, index) => {
      const sectionId = `${SECTION_LEVELS.SUB_SYSTEM}_${key}`;
      return {
        id: sectionId,
        title: QUICK_ACCESS_SECTIONS[key],
        canRemove: index === Object.keys(QUICK_ACCESS_SECTIONS).length - 1,
        items:
          index < Object.keys(QUICK_ACCESS_SECTIONS).length - 1
            ? [
                {
                  id: 1,
                },
              ]
            : [],
      };
    }
  ),
  [SECTION_LEVELS.EQUIPMENT]: Object.keys(QUICK_ACCESS_SECTIONS).map(
    (key, index) => {
      const sectionId = `${SECTION_LEVELS.EQUIPMENT}_${key}`;
      return {
        id: sectionId,
        title: QUICK_ACCESS_SECTIONS[key],
        canRemove: index === Object.keys(QUICK_ACCESS_SECTIONS).length - 1,
        items:
          index < Object.keys(QUICK_ACCESS_SECTIONS).length - 1
            ? [
                {
                  id: 1,
                },
              ]
            : [],
      };
    }
  ),
};
