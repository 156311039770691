export const ALL_OPTION = {
  value: 'all',
  label: 'All',
};
export const NONE_OPTION = {
  value: 'none',
  label: 'None',
};
export const MENU_ITEM_DEFAULT_TYPE_OPTION = {
  value: 'process',
  label: 'Process',
};
export const MENU_ITEM_TYPE_OPTIONS = [
  MENU_ITEM_DEFAULT_TYPE_OPTION,
  {
    value: 'workflow',
    label: 'Workflow',
  },
  {
    value: 'menu group',
    label: 'Menu Group',
  },
  {
    value: 'repository folder',
    label: 'Repository Folder',
  },
];
export const VIEW_DAYS_OPTIONS = [
  {
    value: 'Last 30 Days',
    label: 'Last 30 Days',
  },
  {
    value: 'Last 60 Days',
    label: 'Last 60 Days',
  },
  {
    value: 'Last 90 Days',
    label: 'Last 90 Days',
  },
];
export const NEW_OLD_OPTIONS = [
  {
    label: 'Newest - Oldest',
    value: 'desc',
  },
  {
    label: 'Oldest - Newest',
    value: 'asc',
  },
];
export const LATEST_OLD_OPTIONS = [
  {
    label: 'Latest - Oldest',
    value: 'desc',
  },
  {
    label: 'Oldest - Latest',
    value: 'asc',
  },
];
export const YES_NO_VALUES = {
  YES: 'yes',
  NO: 'no',
};
export const YES_NO_OPTIONS = [
  {
    value: YES_NO_VALUES.YES,
    label: 'Yes',
  },
  {
    value: YES_NO_VALUES.NO,
    label: 'No',
  },
];
export const RELATED_PSM_ELEMENT_OPTIONS = [
  {
    value: 'Employee Participation',
    label: 'Employee Participation',
  },
  {
    value: 'PSI',
    label: 'PSI',
  },
  {
    value: 'PHA',
    label: 'PHA',
  },
  {
    value: 'Operating Procedures',
    label: 'Operating Procedures',
  },
  {
    value: 'Training',
    label: 'Training',
  },
  {
    value: 'Contractors',
    label: 'Contractors',
  },
  {
    value: 'Pre-Safety Startup Review',
    label: 'Pre-Safety Startup Review',
  },
  {
    value: 'Hot Work Permit',
    label: 'Hot Work Permit',
  },
  {
    value: 'Management of Change',
    label: 'Management of Change',
  },
  {
    value: 'Incident Investigation',
    label: 'Incident Investigation',
  },
  {
    value: 'Emergency Planning & Response',
    label: 'Emergency Planning & Response',
  },
  {
    value: 'Compliance Audits',
    label: 'Compliance Audits',
  },
  {
    value: 'Trade Secrets',
    label: 'Trade Secrets',
  },
];
export const RELATED_PSM_ELEMENT_WITH_NUMBER_OPTIONS =
  RELATED_PSM_ELEMENT_OPTIONS.map((obj, index) => ({
    value: obj.value,
    label: `${index + 1}. ${obj.label}`,
  }));
