// import { useState } from 'react';
// import { ReactComponent as DangerSvg } from 'src/assets/icons/danger.svg';
// import Input from 'src/components/FormFields/Input';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import Button from 'src/components/Button';
import FormCheckbox from 'src/components/FormFields/Checkbox/FormCheckbox';
import FormGroup from 'src/components/FormFields/FormGroup';
import FormInput from 'src/components/FormFields/Input/FormInput';
import Modal from 'src/components/Modal';
import { INPUT_HIGHLIGHT_ERROR_MESSAGE } from 'src/helper/constants';
import { MASK_INPUT_PATTER } from 'src/helper/constants/pattern';
import { testFormatField } from 'src/utils/form';
import classes from './AddContactModal.module.scss';

const formSchema = yup.object().shape({
  type: yup.string().required(INPUT_HIGHLIGHT_ERROR_MESSAGE),
  name: yup.string().required(INPUT_HIGHLIGHT_ERROR_MESSAGE),
  phone: yup
    .string()
    .required(INPUT_HIGHLIGHT_ERROR_MESSAGE)
    .test(
      'invalid-filled-phone-number',
      'Invalid Phone Number',
      testFormatField(true)
    ),
});

const AddContactModal = ({ isOpen, onClose, onSubmit, editObj }) => {
  const isEdit = !!editObj?.id;
  const [loading, setLoading] = useState(false);
  const nameRef = useRef(null);
  const { handleSubmit, control, formState } = useForm({
    resolver: yupResolver(formSchema),
    defaultValues: {
      type: editObj?.type || '',
      name: editObj?.name || '',
      phone: editObj?.phone || '',
      website: editObj?.website || '',
      additional_details: editObj?.additional_details || '',
      favorite: !!editObj?.favourite,
    },
  });

  useEffect(() => {
    setLoading(false);
    setTimeout(() => {
      if (nameRef && nameRef.current) {
        nameRef.current.focus();
      }
    }, 100);
  }, []);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={isEdit ? 'Edit Emergency Contact' : 'Add New Emergency Contact'}
      hideClose
      centered
      wrapClassName={classes.modalWrapper}
      radius="lg"
    >
      <div className={classes.description}>
        <span>Add new emergency contact by filling the details.</span>
      </div>
      <form
        role="form"
        className={classes.form}
        onSubmit={handleSubmit((values) => {
          setLoading(true);
          onSubmit(
            {
              ...values,
              id: editObj?.id,
            },
            () => {
              setLoading(false);
            }
          );
        })}
      >
        <div className={classes.formInner}>
          <FormGroup>
            <FormInput
              label="Type"
              name="type"
              placeholder="Enter contact type"
              control={control}
              radius="md"
              variant="primary"
              fontSize="sm"
              innerRef={nameRef}
              required
            />
          </FormGroup>
          <FormGroup>
            <FormInput
              label="Name"
              name="name"
              placeholder="Enter contact name"
              control={control}
              radius="md"
              variant="primary"
              fontSize="sm"
              required
            />
          </FormGroup>
          <FormGroup>
            <FormInput
              label="Phone"
              name="phone"
              placeholder="Enter contact phone"
              control={control}
              radius="md"
              variant="primary"
              fontSize="sm"
              required
              type="format"
              mask={MASK_INPUT_PATTER.PHONE}
              maskChar="_"
            />
          </FormGroup>
          <FormGroup>
            <FormInput
              label="Website"
              name="website"
              placeholder="Enter contact website"
              control={control}
              radius="md"
              variant="primary"
              fontSize="sm"
            />
          </FormGroup>
          <FormGroup>
            <FormInput
              label="Additional Details"
              name="additional_details"
              placeholder="Enter any additional notes needed"
              control={control}
              radius="md"
              variant="primary"
              fontSize="sm"
              type="textarea"
            />
          </FormGroup>
          <FormGroup>
            <FormCheckbox
              name="favorite"
              variant="primary"
              control={control}
              label="Mark as favorite"
              fontSize="sm"
            />
          </FormGroup>
        </div>
        <div className={classes.formFooter}>
          <Button variant="primary" isLink type="button" onClick={onClose}>
            Cancel
          </Button>
          <Button
            variant="primary"
            type="submit"
            loading={loading}
            disabled={!formState.isValid || !formState.isDirty}
          >
            {isEdit ? 'Save Changes' : 'Add Contact'}
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default AddContactModal;
