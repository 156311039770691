import cn from 'classnames';
import { ReactComponent as Edit2Svg } from 'src/assets/icons/edit-2.svg';
import { ReactComponent as TrashSvg } from 'src/assets/icons/trash.svg';
import Collapse from 'src/components/Collapse';
import Icon from 'src/components/Icon';
import Tag from 'src/components/Tag';
import AddButton from '../AddButton';
import Block from '../Block';
import classes from './Section.module.scss';

const Section = ({
  className,
  title,
  collapse,
  toggle,
  onAdd,
  onEdit,
  onEditItem,
  items,
  onRemove,
  onRemoveItem,
}) => {
  const isNotFound = !items?.length;
  return (
    <div className={cn(classes.wrapper, className)}>
      <div className={classes.header}>
        <span>{title}</span>
        <div>
          <Icon
            icon={<Edit2Svg />}
            activeIcon={<Edit2Svg />}
            size={18}
            onClick={onEdit}
          />
          {!!onRemove && (
            <Icon
              icon={<TrashSvg />}
              activeIcon={<TrashSvg />}
              size={18}
              onClick={onRemove}
            />
          )}
          <Tag
            outline
            variant={collapse ? 'dark_gray' : 'gray'}
            radius="sm"
            onClick={toggle}
          >
            {collapse ? 'Expand' : 'Collapse'}
          </Tag>
        </div>
      </div>
      <Collapse
        className={cn(classes.content, {
          [classes.isNotFound]: isNotFound,
        })}
        isOpen={!collapse}
      >
        {/* this block make collapse smooth */}
        <div></div>
        <div>
          {!isNotFound && (
            <>
              {items.map((item) => {
                return (
                  <Block
                    key={item.id}
                    onEdit={() => {
                      onEditItem(item);
                    }}
                    data={item}
                    onRemove={
                      !item.is_default && !!onRemoveItem
                        ? () => {
                            onRemoveItem(item);
                          }
                        : undefined
                    }
                  />
                );
              })}
            </>
          )}
          <AddButton
            onClick={onAdd}
            center={isNotFound}
            className={classes.addBtn}
          />
        </div>
      </Collapse>
    </div>
  );
};

export default Section;
