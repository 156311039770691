import get from 'lodash/get';
import StatusTag from 'src/components/Tag/Status';
import { getFormatDate } from 'src/utils/date';

export const renderDateColumn = (val) => {
  return getFormatDate(val, 'MMM DD, YYYY') || '-';
};
export const renderNormalColumn = (val) => {
  return val || '-';
};
export const renderNormalColumnCustomAttr = (attr) => (val, obj) => {
  const tVal = get(obj, attr) || '-';
  return tVal;
};
export const renderNumberColumn = (val) => {
  return val || '0';
};

export const renderStatusColumn = (val) => {
  return <StatusTag value={val} />;
};
