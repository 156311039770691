import { RESOURCE_DATA_KEY } from 'src/helper/constants/store';
import useOwnNavigate from 'src/helper/hooks/useOwnNavigate';
import useResourceActions from 'src/helper/hooks/useResourceActions';
import useUploadFile from 'src/helper/hooks/useUploadFile';
import { getErrorMessageFromResponse } from 'src/utils/actions';
import { getErrorMessage, getSuccessMessage } from 'src/utils/toast';

const useFunction = ({
  locationId,
  quickAccessId,
  reloadDocuments,
  reloadArchivedDocuments,
  notify,
}) => {
  const { onNavigate } = useOwnNavigate();
  const {
    onAddResourceDocument,
    onUpdateResourceContent,
    onArchiveResource,
    onCreateResource,
  } = useResourceActions();
  const { onUpload } = useUploadFile();
  const reloadCb = (res, message) => {
    if (res?.isSuccess) {
      notify(...getSuccessMessage(message));
    } else {
      notify(...getErrorMessage(getErrorMessageFromResponse(res)));
    }
  };
  const onSubmitEditor = async (documentId, editorContent, cb) => {
    let res = null;
    if (documentId) {
      res = await onUpdateResourceContent(
        RESOURCE_DATA_KEY.QUICK_ACCESS_ITEM_DOCUMENTS,
        documentId,
        {
          content: editorContent,
        }
      );
    } else {
      res = await onAddResourceDocument(
        RESOURCE_DATA_KEY.QUICK_ACCESS_ITEM_DETAILS,
        quickAccessId,
        {
          entity_id: locationId,
          entity_type: 'location',
          content: editorContent,
        }
      );
    }
    reloadCb(
      res,
      documentId
        ? 'Changes have been saved successfully.'
        : 'Document has been created successfully'
    );
    if (res.isSuccess) {
      reloadDocuments(1, quickAccessId, {
        silent: true,
        cb,
      });
    } else {
      if (cb) cb();
    }
  };
  const onSubmitDocument = async (tFile) => {
    const uploadedDocumentId = await onUpload(tFile);
    if (uploadedDocumentId) {
      const res = await onAddResourceDocument(
        RESOURCE_DATA_KEY.QUICK_ACCESS_ITEM_DETAILS,
        quickAccessId,
        {
          entity_id: locationId,
          entity_type: 'location',
          document_uuids: [uploadedDocumentId],
        }
      );
      reloadCb(res, 'Document has been added successfully');
      if (res.isSuccess) {
        reloadDocuments(1, quickAccessId);
      }
      return res;
    } else {
      notify(...getErrorMessage('Uploaded document failed!'));
    }
    return { isSuccess: false };
  };
  const onArchiveDocument = async (id) => {
    const res = await onArchiveResource(
      RESOURCE_DATA_KEY.QUICK_ACCESS_ITEM_DOCUMENTS,
      id,
      {}
    );
    reloadCb(res, 'Document Archived Successfully');
    if (res.isSuccess) {
      reloadDocuments(1, quickAccessId);
      reloadArchivedDocuments(1, quickAccessId);
    }
    return res;
  };
  const onRemoveItem = async (data) => {
    const res = await onCreateResource(
      RESOURCE_DATA_KEY.QUICK_ACCESS_ITEM_REMOVE,
      {
        item_id: quickAccessId,
        entity_type: 'location',
        entity_id: locationId,
      }
    );
    reloadCb(res, 'Category Removed Successfully');
    if (res.isSuccess) {
      onNavigate(`/locations/${locationId}`);
    }
    return res;
  };

  return {
    onSubmitEditor,
    onSubmitDocument,
    onArchiveDocument,
    onRemoveItem,
  };
};

export default useFunction;
