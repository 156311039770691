import DocumentDetails from 'src/pages/DocumentDetails';
import QuickAccessDetails from 'src/pages/QuickAccess/Details';

const quickAccessRoutes = [
  {
    path: '/locations/:locationId/quick-access/:quickAccessId',
    name: 'Location Quick Access',
    icon: '',
    iconActive: '',
    component: QuickAccessDetails,
    layout: 'private',
  },
  {
    path: '/locations/:locationId/quick-access/:quickAccessId/documents/:documentId',
    name: 'Location Quick Access Document Details',
    icon: '',
    iconActive: '',
    component: DocumentDetails,
    layout: 'private',
  },
  {
    path: '/locations/:locationId/quick-access/:quickAccessId/documents/:documentId/history/:documentHistoryId',
    name: 'Location Quick Access Document Details History',
    icon: '',
    iconActive: '',
    component: DocumentDetails,
    layout: 'private',
  },
];

export default quickAccessRoutes;
