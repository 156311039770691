import cn from 'classnames';
import { forwardRef } from 'react';
import LabelTooltip from 'src/components/FormFields/LabelTooltip';
import classes from './Checkbox.module.scss';

const Checkbox = forwardRef(
  (
    {
      className,
      label,
      id,
      variant,
      fontSize,
      labelTooltip,
      optionalText,
      inline,
      ...props
    },
    ref
  ) => {
    return (
      <div
        className={cn(
          'custom-control custom-control-alternative custom-checkbox',
          classes.wrapper,
          classes[`variant_${variant}`],
          classes[`fontSize_${fontSize}`],
          {
            'inline-block': !!inline,
          },
          className
        )}
      >
        <input
          className="custom-control-input"
          type="checkbox"
          id={id}
          ref={ref}
          {...props}
        />
        <label className="custom-control-label" htmlFor={id}>
          <span>{label}</span>
          {!!optionalText && (
            <span className={classes.optionalText}>{optionalText}</span>
          )}
          {!!labelTooltip?.text && !!labelTooltip?.icon && (
            <LabelTooltip tooltip={labelTooltip} />
          )}
        </label>
      </div>
    );
  }
);

export default Checkbox;
