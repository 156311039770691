import { ReactComponent as SettingsSvg } from 'src/assets/icons/Nav/Settings1.svg';
import { ReactComponent as SettingsActiveSvg } from 'src/assets/icons/Nav/Settings1_Active.svg';
import Admin from 'src/pages/Admin';
import AdminCompanies from 'src/pages/Admin/Companies';
import AdminCompaniesCreate from 'src/pages/Admin/CompaniesCreate';
import AdminMenuItems from 'src/pages/Admin/MenuItems';
import AdminMenuItemsCreate from 'src/pages/Admin/MenuItemsCreate';
import AdminParts from 'src/pages/Admin/Parts';
import AdminPartsCreate from 'src/pages/Admin/PartsCreate';
import AdminRoles from 'src/pages/Admin/Roles';
import AdminRolesCreate from 'src/pages/Admin/RolesCreate';
import AdminSubSystems from 'src/pages/Admin/SubSystems';
import SubSystemsCreate from 'src/pages/Admin/SubSystemsCreate';

const adminRoutes = [
  {
    path: '/admin',
    name: 'Admin',
    icon: <SettingsSvg />,
    iconActive: <SettingsActiveSvg />,
    component: Admin,
    layout: 'private',
  },
  {
    path: '/admin/menu-items',
    name: 'Menu Items',
    icon: <SettingsSvg />,
    iconActive: <SettingsActiveSvg />,
    component: AdminMenuItems,
    layout: 'private',
  },
  {
    path: '/admin/menu-items/create',
    name: 'Menu Items Create',
    icon: <SettingsSvg />,
    iconActive: <SettingsActiveSvg />,
    component: AdminMenuItemsCreate,
    layout: 'private',
  },
  {
    path: '/admin/companies',
    name: 'Companies',
    icon: <SettingsSvg />,
    iconActive: <SettingsActiveSvg />,
    component: AdminCompanies,
    layout: 'private',
  },
  {
    path: '/admin/companies/create',
    name: 'Companies Create',
    icon: <SettingsSvg />,
    iconActive: <SettingsActiveSvg />,
    component: AdminCompaniesCreate,
    layout: 'private',
  },
  {
    path: '/admin/roles',
    name: 'Roles',
    icon: <SettingsSvg />,
    iconActive: <SettingsActiveSvg />,
    component: AdminRoles,
    layout: 'private',
  },
  {
    path: '/admin/roles/create',
    name: 'Roles Create',
    icon: <SettingsSvg />,
    iconActive: <SettingsActiveSvg />,
    component: AdminRolesCreate,
    layout: 'private',
  },
  {
    path: '/admin/parts',
    name: 'Parts',
    icon: <SettingsSvg />,
    iconActive: <SettingsActiveSvg />,
    component: AdminParts,
    layout: 'private',
  },
  {
    path: '/admin/parts/create',
    name: 'Parts Create',
    icon: <SettingsSvg />,
    iconActive: <SettingsActiveSvg />,
    component: AdminPartsCreate,
    layout: 'private',
  },
  {
    path: '/admin/sub-systems',
    name: 'SubSystems',
    icon: <SettingsSvg />,
    iconActive: <SettingsActiveSvg />,
    component: AdminSubSystems,
    layout: 'private',
  },
  {
    path: '/admin/sub-systems/create',
    name: 'SubSystems Create',
    icon: <SettingsSvg />,
    iconActive: <SettingsActiveSvg />,
    component: SubSystemsCreate,
    layout: 'private',
  },
];

export default adminRoutes;
