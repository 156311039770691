import { useEffect, useRef, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import Breadcrumb from 'src/components/Breadcrumb';
import Loading from 'src/components/Loading';
import ConfirmModal from 'src/components/Modal/Confirm';
import PageWrapper from 'src/components/Page/PageWrapper';
import ToastAlert from 'src/components/ToastAlert';
import { LOCATION_EMERGENCY_TYPE } from 'src/helper/constants/location';
import { RESOURCE_DATA_KEY } from 'src/helper/constants/store';
import useOwnNavigate from 'src/helper/hooks/useOwnNavigate';
import { notifyPrimary } from 'src/utils/notification';
import { getAllUrlParams, getViewUrlAction } from 'src/utils/routes';
import LocationDetailsInfo from './Info';
import classes from './LocationDetails.module.scss';
import QuickAccessSection from './QuickAccessSection';
import DetailsTab from './Tabs';
import DetailsTitle from './Title';
import AddContactModal from './components/AddContactModal';
import AddPlanModal from './components/AddPlanModal';
import {
  LOCATION_DETAILS_ADD_LIST_LINK_MAPPING,
  LOCATION_DETAILS_DELETE_OBJ_MAPPING,
  LOCATION_DETAILS_EDIT_LIST_LINK_MAPPING,
  LOCATION_DETAILS_TAB_KEYS,
  LOCATION_DETAILS_TAB_SUB_KEYS,
  LOCATION_EMERGENCY_DEFAULT_FILTERS,
  LOCATION_LIST_DEFAULT_FILTERS,
  getAddPlanMessage,
  getTabItems,
} from './constants';
import { useActions, useIndexData } from './selectorData';
import useFunction from './useFunction';
import { getListFiltersParams, showToast, updateMarker } from './utils';

let locationTabFilters = null;
let emergencyProcedureFilters = null;
let emergencyEvacuationFilters = null;
const resetFilters = () => {
  locationTabFilters = {
    [LOCATION_DETAILS_TAB_KEYS.STAFF_LIST]: {
      ...LOCATION_LIST_DEFAULT_FILTERS,
    },
    [LOCATION_DETAILS_TAB_KEYS.CHEMICALS]: {
      ...LOCATION_LIST_DEFAULT_FILTERS,
    },
    [LOCATION_DETAILS_TAB_KEYS.SUB_SYSTEMS]: {
      ...LOCATION_LIST_DEFAULT_FILTERS,
    },
    [LOCATION_DETAILS_TAB_KEYS.EQUIPMENT]: {
      ...LOCATION_LIST_DEFAULT_FILTERS,
    },
  };
  emergencyProcedureFilters = {
    ...LOCATION_EMERGENCY_DEFAULT_FILTERS,
  };
  emergencyEvacuationFilters = {
    ...LOCATION_EMERGENCY_DEFAULT_FILTERS,
  };
};

const LocationDetails = () => {
  const { onNavigate, fullPathname } = useOwnNavigate();
  const notificationAlertRef = useRef(null);
  const location = useLocation();
  const urlParams = getAllUrlParams(location?.search);
  const params = useParams();
  const locationId = Number(params.id || 0);
  const { getLocationDetails, getResources } = useActions();
  const {
    locationDetails,
    locationDetailsLoading,
    emergencyContactsData,
    emergencyProceduresData,
    emergencyEvacuationPlansData,
    locationStaffsData,
    locationChemicalsData,
    locationEquipmentData,
    locationSubSystemData,
    locationQuickAccessData,
  } = useIndexData();
  const isEmergencyWarning =
    !emergencyProceduresData?.meta?.total ||
    !emergencyEvacuationPlansData?.meta?.total;
  const preActiveTab = urlParams?.tab;
  const [activeTab, setActiveTab] = useState(
    LOCATION_DETAILS_TAB_KEYS[preActiveTab]
      ? preActiveTab
      : LOCATION_DETAILS_TAB_KEYS.SUB_SYSTEMS
  );
  const [markers, setMarkers] = useState([]);
  const [modalContactObj, setModalContactObj] = useState(null);
  const [modalPlanObj, setModalPlanObj] = useState(null);
  const [deleteObj, setDeleteObj] = useState(null);
  const [roleList, setRoleList] = useState([]);
  const [uploadInformation, setUploadInformation] = useState({});
  const [proceduresLoading, setProceduresLoading] = useState(false);
  const [evacuationLoading, setEvacuationLoading] = useState(false);
  const breadcrumbs = [
    {
      link: `/locations`,
      label: 'Locations',
    },
    {
      label: locationDetails.name,
    },
  ];
  const notify = (type, title, description) => {
    notifyPrimary({
      ref: notificationAlertRef,
      type,
      title,
      description,
      settings: {
        // autoDismiss: 1000,
      },
    });
  };
  const handleAddList = (tTab) => () => {
    const addListLink = LOCATION_DETAILS_ADD_LIST_LINK_MAPPING[tTab];
    if (addListLink) {
      onNavigate(`/locations/${locationId}${addListLink}`);
    }
  };
  const handleEditList = (tTab) => (obj) => {
    const editListLink = LOCATION_DETAILS_EDIT_LIST_LINK_MAPPING[tTab];
    if (editListLink) {
      onNavigate(`/locations/${locationId}${editListLink}?id=${obj.id}`);
    }
  };
  const handleRowClick = (tTab) => (obj) => {
    const viewListLink = LOCATION_DETAILS_EDIT_LIST_LINK_MAPPING[tTab];
    if (viewListLink) {
      onNavigate(
        getViewUrlAction(`/locations/${locationId}${viewListLink}?id=${obj.id}`)
      );
    }
  };
  const handleEditContact = (obj) => {
    setModalContactObj(obj);
  };
  const handleViewPlan = (tType, obj) => {
    onNavigate(`/locations/${locationId}/emergency/${obj.id}`);
  };
  const handleAddPlan = (tType) => {
    setUploadInformation({});
    setModalPlanObj({ type: tType });
  };
  const handleDelete = (tTab) => (obj) => {
    const getDeleteFn = LOCATION_DETAILS_DELETE_OBJ_MAPPING[tTab];
    if (getDeleteFn) {
      setDeleteObj(getDeleteFn(obj.id));
    }
  };
  const loadTabData = (tTabKey, subKey) => {
    switch (tTabKey) {
      case LOCATION_DETAILS_TAB_KEYS.EMERGENCY: {
        switch (subKey) {
          case LOCATION_DETAILS_TAB_SUB_KEYS.CONTACTS:
            getResources(RESOURCE_DATA_KEY.EMERGENCY_CONTACT, {
              page: 1,
              per_page: 9999,
              filters: {
                location_id: locationId,
              },
            });
            break;
          case LOCATION_DETAILS_TAB_SUB_KEYS.PROCEDURES: {
            if (!proceduresLoading) {
              setProceduresLoading(true);
              getResources(
                RESOURCE_DATA_KEY.EMERGENCY_DOCUMENT_PROCEDURES,
                {
                  document_type: 'procedure',
                  page: emergencyProcedureFilters?.page,
                  per_page: emergencyProcedureFilters?.size,
                  location_id: locationId,
                  sort_by: emergencyProcedureFilters?.sort?.field,
                  sort_order: emergencyProcedureFilters?.sort?.method,
                },
                () => {
                  setProceduresLoading(false);
                }
              );
            }
            break;
          }
          case LOCATION_DETAILS_TAB_SUB_KEYS.EVACUATION_PLANS: {
            if (!evacuationLoading) {
              setEvacuationLoading(true);
              getResources(
                RESOURCE_DATA_KEY.EMERGENCY_DOCUMENT_EVACUATION_PLANS,
                {
                  document_type: 'evacuation_plan',
                  page: emergencyEvacuationFilters?.page,
                  per_page: emergencyEvacuationFilters?.size,
                  location_id: locationId,
                  sort_by: emergencyEvacuationFilters?.sort?.field,
                  sort_order: emergencyEvacuationFilters?.sort?.method,
                },
                () => {
                  setEvacuationLoading(false);
                }
              );
            }
            break;
          }
          default:
            break;
        }
        break;
      }
      case LOCATION_DETAILS_TAB_KEYS.STAFF_LIST: {
        getResources(
          RESOURCE_DATA_KEY.LOCATION_STAFF,
          getListFiltersParams(
            locationTabFilters[LOCATION_DETAILS_TAB_KEYS.STAFF_LIST],
            locationId
          )
        );
        break;
      }
      case LOCATION_DETAILS_TAB_KEYS.CHEMICALS: {
        getResources(
          RESOURCE_DATA_KEY.LOCATION_CHEMICALS,
          getListFiltersParams(
            locationTabFilters[LOCATION_DETAILS_TAB_KEYS.CHEMICALS],
            locationId
          )
        );
        break;
      }
      case LOCATION_DETAILS_TAB_KEYS.EQUIPMENT: {
        getResources(
          RESOURCE_DATA_KEY.LOCATION_EQUIPMENT,
          getListFiltersParams(
            locationTabFilters[LOCATION_DETAILS_TAB_KEYS.EQUIPMENT],
            locationId
          )
        );
        break;
      }
      case LOCATION_DETAILS_TAB_KEYS.SUB_SYSTEMS: {
        getResources(
          RESOURCE_DATA_KEY.LOCATION_SUB_SYSTEM,
          getListFiltersParams(
            locationTabFilters[LOCATION_DETAILS_TAB_KEYS.SUB_SYSTEMS],
            locationId
          )
        );
        break;
      }
      default:
        break;
    }
  };
  const reloadQuickAccess = () => {
    getResources(RESOURCE_DATA_KEY.LOCATION_QUICK_ACCESS, {
      entity_type: 'location',
      entity_id: locationId,
    });
  };
  const reloadEmergencyDocuments = (tType) => {
    if (tType === LOCATION_EMERGENCY_TYPE.PROCEDURE) {
      loadTabData(
        LOCATION_DETAILS_TAB_KEYS.EMERGENCY,
        LOCATION_DETAILS_TAB_SUB_KEYS.PROCEDURES
      );
    } else {
      loadTabData(
        LOCATION_DETAILS_TAB_KEYS.EMERGENCY,
        LOCATION_DETAILS_TAB_SUB_KEYS.EVACUATION_PLANS
      );
    }
  };
  const {
    onConfirmedContact,
    onConfirmedDelete,
    onUploadPlan,
    onReloadRoles,
    onRetryPlan,
  } = useFunction(locationId, loadTabData, notify, setRoleList);
  const handleEmergencyPaginationChange = (type, tPage, tSize) => {
    if (type === LOCATION_EMERGENCY_TYPE.PROCEDURE) {
      emergencyProcedureFilters.page = tPage;
      emergencyProcedureFilters.size = tSize;
    } else {
      emergencyEvacuationFilters.page = tPage;
      emergencyEvacuationFilters.size = tSize;
    }
    reloadEmergencyDocuments(type);
  };
  const handleFiltersChanged =
    (tTab) => (tSearch, tSort, tFilterIds, tPagination) => {
      locationTabFilters = {
        ...locationTabFilters,
        [tTab]: {
          search: tSearch,
          sort: tSort,
          filters_id: [...tFilterIds],
          page: tPagination?.page,
          size: tPagination?.size,
        },
      };
      loadTabData(tTab, '');
    };
  const handleEmergencySort = (tVal, tType) => {
    if (tType === LOCATION_EMERGENCY_TYPE.PROCEDURE) {
      emergencyProcedureFilters.sort.method = tVal.value;
    } else {
      emergencyEvacuationFilters.sort.method = tVal.value;
    }
    reloadEmergencyDocuments(tType);
  };
  const tabProps = {
    staffListData: locationStaffsData,
    chemicalListData: locationChemicalsData,
    emergencyData: {
      contacts: emergencyContactsData,
      procedures: emergencyProceduresData,
      evacuationPlans: emergencyEvacuationPlansData,
    },
    roleList,
    locationEquipmentData,
    locationSubSystemData,
    onAdd: handleAddList,
    onEdit: handleEditList,
    onRowClick: handleRowClick,
    onContact: handleEditContact,
    onViewPlan: handleViewPlan,
    onAddPlan: handleAddPlan,
    onDelete: handleDelete,
    onFiltersChanged: handleFiltersChanged,
    onEmergencyPaginationChange: handleEmergencyPaginationChange,
    onEmergencySort: handleEmergencySort,
  };
  const items = getTabItems(isEmergencyWarning, tabProps);
  const handleConfirmedContact = (values, cb) => {
    onConfirmedContact(values, cb, () => {
      setModalContactObj(null);
    });
  };
  const handleConfirmedDelete = (cb) => {
    onConfirmedDelete(
      deleteObj.type,
      deleteObj.id,
      {
        user_id: deleteObj.id,
      },
      cb,
      () => {
        setDeleteObj(null);
      }
    );
  };
  const handleConfirmedPlan = (values, cb) => {
    if (cb) cb();
    onUploadPlan({ ...values, type: modalPlanObj.type }, setUploadInformation);
  };
  const handleRetryPlan = (obj) => {
    onRetryPlan(modalPlanObj.type, obj, setUploadInformation);
  };

  useEffect(() => {
    resetFilters();
    getLocationDetails(locationId, (res) => {
      updateMarker(res, setMarkers);
    });
    loadTabData(activeTab, LOCATION_DETAILS_TAB_SUB_KEYS.CONTACTS);
    reloadQuickAccess();
    reloadEmergencyDocuments(LOCATION_EMERGENCY_TYPE.PROCEDURE);
    reloadEmergencyDocuments(LOCATION_EMERGENCY_TYPE.EVACUATION_PLAN);
    onReloadRoles();
    showToast(urlParams, notify, onNavigate, fullPathname, activeTab);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <PageWrapper className={classes.wrapper}>
        {locationDetailsLoading && <Loading isPage />}
        <Breadcrumb items={breadcrumbs} />
        <DetailsTitle />
        <LocationDetailsInfo markers={markers} />
        <QuickAccessSection
          data={locationQuickAccessData.data}
          locationId={locationId}
        />
        <DetailsTab
          items={items}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          loadTabData={loadTabData}
        />
      </PageWrapper>
      {!!modalContactObj && (
        <AddContactModal
          isOpen
          onClose={() => {
            setModalContactObj(null);
          }}
          onSubmit={handleConfirmedContact}
          editObj={modalContactObj}
        />
      )}
      {!!modalPlanObj && (
        <AddPlanModal
          isOpen
          onClose={(shouldLoadData) => {
            if (shouldLoadData) {
              reloadEmergencyDocuments(modalPlanObj?.type);
            }
            setModalPlanObj(null);
          }}
          onSubmit={handleConfirmedPlan}
          obj={modalPlanObj}
          uploadInformation={uploadInformation}
          onRetry={handleRetryPlan}
          onSuccess={() => {
            reloadEmergencyDocuments(modalPlanObj?.type);
            notify(...getAddPlanMessage(true));
            setModalPlanObj(null);
          }}
        />
      )}
      {!!deleteObj && (
        <ConfirmModal
          isOpen
          onClose={() => {
            setDeleteObj(null);
          }}
          onSubmit={handleConfirmedDelete}
          title={deleteObj?.title}
          submitText={deleteObj?.submitText}
          size="sm"
        >
          {deleteObj?.description}
        </ConfirmModal>
      )}
      <ToastAlert toastRef={notificationAlertRef} />
    </>
  );
};

export default LocationDetails;
