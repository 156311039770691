export const QUANTITY_UNITS = [
  { label: 'Grams', value: 'g' },
  { label: 'Kilograms', value: 'kg' },
  { label: 'Liters', value: 'l' },
  { label: 'Milliliters', value: 'ml' },
  { label: 'Pounds', value: 'lbs' },
  { label: 'Gallons', value: 'gls' },
  { label: 'Cubic Meters', value: 'm3' },
  { label: 'Cubic Centimeters', value: 'cm3' },
  { label: 'Square Meters', value: 'm2' },
  { label: 'Square Centimeters', value: 'cm2' },
];

export const getQuantityUnitName = (abbr) => {
  const unit = QUANTITY_UNITS.find((u) => u.value === abbr);
  return unit ? unit.label : abbr;
};
