import {
  apiAddResourceDocument,
  apiArchiveResource,
  apiArchiveVersionResource,
  apiAssignResourceLocationsRoles,
  apiAssignResourceMenuItems,
  apiAssignResourceStaff,
  apiCreateResource,
  apiDeleteResource,
  apiDeleteResourceStaff,
  apiGetResourceHistory,
  apiGetResourceLocationsRoles,
  apiGetResourceMenuItems,
  apiGetResourceVersions,
  apiGetResources,
  apiUpdateResource,
  apiUpdateResourceContent,
  apiUpdateResourceFile,
  apiUploadNewVersionResource,
  apiUploadResource,
} from 'src/api';
import { callAction } from 'src/utils/actions';

const useResourceActions = () => {
  const onGetResources = async (key, params) => {
    const res = await callAction(() => {
      return apiGetResources(key, params);
    });
    return res;
  };
  const onCreateResource = async (key, payload) => {
    const res = await callAction(() => {
      return apiCreateResource(key, payload);
    });
    return res;
  };
  const onEditResource = async (key, id, payload) => {
    const res = await callAction(() => {
      return apiUpdateResource(key, id, payload);
    });
    return res;
  };
  const onDeleteResource = async (key, id, data) => {
    const res = await callAction(() => {
      return apiDeleteResource(key, id, data);
    });
    return res;
  };
  const onDeleteResourceStaff = async (key, id, data) => {
    const res = await callAction(() => {
      return apiDeleteResourceStaff(key, id, data);
    });
    return res;
  };
  const onArchiveResource = async (key, id, data, method) => {
    const res = await callAction(() => {
      return apiArchiveResource(key, id, data, method);
    });
    return res;
  };
  const onArchiveVersionResource = async (key, id, data, method) => {
    const res = await callAction(() => {
      return apiArchiveVersionResource(key, id, data, method);
    });
    return res;
  };
  const onAssignResourceMenuItem = async (key, id, data) => {
    const res = await callAction(() => {
      return apiAssignResourceMenuItems(key, id, data);
    });
    return res;
  };
  const onGetResourceMenuItem = async (key, id) => {
    const res = await callAction(() => {
      return apiGetResourceMenuItems(key, id);
    });
    return res;
  };
  const onAssignResourceLocationsRoles = async (key, id, data) => {
    const res = await callAction(() => {
      return apiAssignResourceLocationsRoles(key, id, data);
    });
    return res;
  };
  const onGetResourceLocationsRoles = async (key, id) => {
    const res = await callAction(() => {
      return apiGetResourceLocationsRoles(key, id);
    });
    return res;
  };
  const onUploadResource = async (key, data) => {
    const res = await callAction(() => {
      return apiUploadResource(key, data);
    });
    return res;
  };
  const onUploadNewVersionResource = async (key, id, data) => {
    const res = await callAction(() => {
      return apiUploadNewVersionResource(key, id, data);
    });
    return res;
  };
  const onGetResourceVersions = async (key, id, params) => {
    const res = await callAction(() => {
      return apiGetResourceVersions(key, id, params);
    });
    return res;
  };
  const onGetResourceHistory = async (key, id, params) => {
    const res = await callAction(() => {
      return apiGetResourceHistory(key, id, params);
    });
    return res;
  };
  const onAssignResourceStaff = async (key, id, data) => {
    const res = await callAction(() => {
      return apiAssignResourceStaff(key, id, data);
    });
    return res;
  };
  const onAddResourceDocument = async (key, id, data) => {
    const res = await callAction(() => {
      return apiAddResourceDocument(key, id, data);
    });
    return res;
  };
  const onUpdateResourceContent = async (key, id, data) => {
    const res = await callAction(() => {
      return apiUpdateResourceContent(key, id, data);
    });
    return res;
  };
  const onUpdateResourceFile = async (key, id, data) => {
    const res = await callAction(() => {
      return apiUpdateResourceFile(key, id, data);
    });
    return res;
  };

  return {
    onCreateResource,
    onEditResource,
    onDeleteResource,
    onArchiveResource,
    onArchiveVersionResource,
    onGetResources,
    onAssignResourceMenuItem,
    onGetResourceMenuItem,
    onAssignResourceLocationsRoles,
    onGetResourceLocationsRoles,
    onUploadResource,
    onUploadNewVersionResource,
    onGetResourceVersions,
    onGetResourceHistory,
    onAssignResourceStaff,
    onDeleteResourceStaff,
    onAddResourceDocument,
    onUpdateResourceContent,
    onUpdateResourceFile,
  };
};

export default useResourceActions;
