import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation, useParams } from 'react-router-dom';
import * as yup from 'yup';
import Breadcrumb from 'src/components/Breadcrumb';
import Loading from 'src/components/Loading';
import ConfirmModal from 'src/components/Modal/Confirm';
import ToastAlert from 'src/components/ToastAlert';
import { VIEW_PARAM_KEY, isUrlAction } from 'src/helper/constants/routes';
import { RESOURCE_DATA_KEY } from 'src/helper/constants/store';
import useOwnNavigate from 'src/helper/hooks/useOwnNavigate';
import useResourceActions from 'src/helper/hooks/useResourceActions';
import { useMetadata } from 'src/helper/providers/MetadataProvider';
import GenericCreateTitle from 'src/pages/GenericCreate/components/Title';
import GenericCreateWrapper from 'src/pages/GenericCreate/components/Wrapper';
import { LOCATION_DETAILS_TAB_KEYS } from 'src/pages/LocationDetails/constants';
import { getErrorMessageFromResponse } from 'src/utils/actions';
import { notifyPrimary } from 'src/utils/notification';
import {
  getAllUrlParams,
  getCreateEditUrlAction,
  getTabUrlAction,
} from 'src/utils/routes';
import classes from './Create.module.scss';
import CreateChemicalForm from './components/Form';
import { useActions, useIndexData } from './selectorData';
import {
  getBreadcrumbs,
  getCreateChemicalPayload,
  getSubTitle,
  getTitle,
} from './utils';

const formSchema = yup.object().shape({
  chemical_name: yup.string().required('Chemical name is required!'),
  cas_number: yup.string().required('Cas number is required!'),
  tier_2_reportable: yup.boolean().required('Tier 2 reportable is required!'),
  quantity: yup.number().required('Quantity is required!'),
  quantity_unit: yup.mixed().required('Quantity unit is required!'),
});

let confirmFn = null;

const CreateChemical = () => {
  const { prevPath, onNavigate } = useOwnNavigate();
  const { rolesLoading, chemicalsLoading } = useMetadata();
  const { onCreateResource, onEditResource } = useResourceActions();
  const { getResourceDetails } = useActions();
  const { chemicalDetails, locationDetails } = useIndexData();
  const location = useLocation();
  const urlParams = getAllUrlParams(location?.search);
  const params = useParams();
  const locationId = Number(params.locationId || 0);
  const locationUrl = getTabUrlAction(
    `/locations/${locationId}`,
    LOCATION_DETAILS_TAB_KEYS.CHEMICALS
  );
  const backUrl = prevPath || `${locationId ? locationUrl : '/locations'}`;
  const chemicalId = Number(urlParams.id || 0);
  const isViewDetails =
    isUrlAction(urlParams?.[VIEW_PARAM_KEY]) && !!chemicalId;
  const isEdit = !isUrlAction(urlParams?.[VIEW_PARAM_KEY]) && !!chemicalId;
  const notificationAlertRef = useRef(null);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [detailsLoading, setDetailsLoading] = useState(false);
  const [locationDetailsLoading, setLocationDetailsLoading] = useState(false);
  const [confirmObj, setConfirmObj] = useState(null);
  const breadcrumbs = getBreadcrumbs({
    locationId,
    isViewDetails,
    isEdit,
    chemicalName: chemicalDetails?.name || chemicalDetails?.chemical_name,
    locationName: locationDetails?.name,
  });
  const { handleSubmit, control, setValue, formState, reset } = useForm({
    resolver: yupResolver(formSchema),
    defaultValues: {
      chemical_name: '',
      cas_number: '',
      tier_2_reportable: false,
      quantity: 0,
      quantity_unit: 'gls',
    },
  });
  const notify = (type, title, description) => {
    notifyPrimary({ ref: notificationAlertRef, type, title, description });
  };
  const reloadChemicalDetails = (cb) => {
    setDetailsLoading(true);
    getResourceDetails(
      RESOURCE_DATA_KEY.LOCATION_CHEMICALS,
      chemicalId,
      (res) => {
        setDetailsLoading(false);
        if (cb) cb(res);
      }
    );
  };
  const onSubmit = async (values) => {
    setConfirmObj({
      title: 'Confirm New Chemical',
      description:
        'Confirm that you are creating this new chemical and the entered details are correct and related to the new chemical.',
    });
    confirmFn = async () => {
      if (!submitLoading) {
        setSubmitLoading(true);
        const tPayload = getCreateChemicalPayload({
          ...values,
          location_id: locationId,
        });
        if (tPayload.quantity_unit.value)
          tPayload.quantity_unit = tPayload.quantity_unit.value;
        let res = null;
        if (isEdit) {
          tPayload.status = chemicalDetails?.status || 'active';
          res = await onEditResource(
            RESOURCE_DATA_KEY.LOCATION_CHEMICALS,
            chemicalId,
            tPayload
          );
        } else {
          tPayload.status = 'active';
          res = await onCreateResource(
            RESOURCE_DATA_KEY.LOCATION_CHEMICALS,
            tPayload
          );
        }
        setSubmitLoading(false);
        if (res.isSuccess) {
          onNavigate(getCreateEditUrlAction(backUrl, isEdit));
        } else {
          notify(
            'error',
            'Error!',
            getErrorMessageFromResponse(res) ||
              `${isEdit ? 'Edit' : 'Create'} Chemical failed!`
          );
        }
      }
    };
  };

  useEffect(() => {
    if (chemicalId) {
      reloadChemicalDetails((res) => {
        if (isEdit) {
          const editObj = res?.data;
          if (editObj?.id) {
            const resetObj = {
              chemical_name: editObj.chemical_name || '',
              cas_number: editObj.cas_number || '',
              tier_2_reportable: editObj.tier_2_reportable || false,
              quantity: editObj.quantity,
              quantity_unit: editObj.quantity_unit || 0,
            };
            reset(resetObj);
          }
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chemicalId]);
  useEffect(() => {
    confirmFn = null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <GenericCreateWrapper>
        {(detailsLoading ||
          rolesLoading ||
          chemicalsLoading ||
          locationDetailsLoading) && <Loading isPage />}
        <Breadcrumb items={breadcrumbs} />
        <GenericCreateTitle
          subTitle={getSubTitle(isViewDetails, isEdit, locationId)}
          className={classes.pageTitle}
        >
          <span>
            {getTitle({ isViewDetails, isEdit, chemicalDetails, locationId })}
          </span>
        </GenericCreateTitle>
        <CreateChemicalForm
          onSubmit={handleSubmit(onSubmit)}
          isViewDetails={isViewDetails}
          chemicalDetails={chemicalDetails}
          control={control}
          setValue={setValue}
          formState={formState}
          isEdit={isEdit}
          submitLoading={submitLoading}
          chemicalId={chemicalId}
          onCancel={() => {
            if (!isEdit) {
              setConfirmObj({
                title: 'Confirm',
                description:
                  'Are you sure you want to exit without saving this new chemical? All information that has been entered will be deleted.',
                cancelText: 'Continue Editing',
                submitText: 'Cancel Creation',
              });
              confirmFn = () => {
                onNavigate(backUrl);
              };
            } else {
              onNavigate(backUrl);
            }
          }}
        />
      </GenericCreateWrapper>
      {!!confirmObj && (
        <ConfirmModal
          isOpen
          onClose={() => {
            confirmFn = null;
            setConfirmObj(null);
          }}
          onSubmit={async (cb) => {
            if (cb) cb();
            if (confirmFn) confirmFn();
            setConfirmObj(null);
          }}
          title={confirmObj?.title}
          submitText={confirmObj?.submitText}
          cancelText={confirmObj?.cancelText}
          size="sm"
          fontSize="sm"
          className={classes.confirmModalWrapper}
          type="confirmation"
        >
          {confirmObj?.description}
        </ConfirmModal>
      )}
      <ToastAlert toastRef={notificationAlertRef} />
    </>
  );
};

export default CreateChemical;
