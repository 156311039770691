import {
  RESOURCE_DATA_KEY,
  STORE_SYNC_ACTIONS,
} from 'src/helper/constants/store';
import useResourceActions from 'src/helper/hooks/useResourceActions';
import { getErrorMessageFromResponse } from 'src/utils/actions';
import { getErrorMessage, getSuccessMessage } from 'src/utils/toast';
import { useActions } from './selectorData';
import { getSectionBR, getSectionItemBR } from './utils';

const useFunction = ({ notify, reloadData, companyId }) => {
  const { syncUpdateQuickAccessSection, syncUpdateQuickAccessSectionItem } =
    useActions();
  const { onCreateResource, onEditResource, onDeleteResource } =
    useResourceActions();

  const reloadCb = (res, message, loadingCb, cb) => {
    if (res?.isSuccess) {
      if (message) {
        notify(...getSuccessMessage(message));
      }
      reloadData();
      if (cb) cb();
    } else {
      const tMessage = getErrorMessageFromResponse(res);
      notify(...getErrorMessage(tMessage));
    }
    if (loadingCb) loadingCb();
  };

  const onSubmitSection = async (values, loadingCb, cb) => {
    const br = getSectionBR(
      {
        ...values,
      },
      companyId
    );
    let res = null;
    if (values?.id) {
      res = await onEditResource(
        RESOURCE_DATA_KEY.QUICK_ACCESS_SECTIONS,
        values.id,
        br
      );
    } else {
      res = await onCreateResource(RESOURCE_DATA_KEY.QUICK_ACCESS_SECTIONS, br);
    }
    reloadCb(
      res,
      `Quick Access Section has been ${values.id ? 'updated' : 'created'}`,
      loadingCb,
      () => {
        const addedSection = res.data;
        syncUpdateQuickAccessSection({
          ...values.rawData,
          categoryId: values?.id
            ? values.rawData.category?.id
            : br.level.toLowerCase(),
          sectionId: values.rawData.section?.id,
          newSection: br,
          addedSection,
          action: values?.id
            ? STORE_SYNC_ACTIONS.UPDATE
            : STORE_SYNC_ACTIONS.ADD,
        });
        if (cb) cb();
      }
    );
  };
  const onDeleteSection = async (removeSectionObj, reason, loadingCb, cb) => {
    const res = await onDeleteResource(
      RESOURCE_DATA_KEY.QUICK_ACCESS_SECTIONS,
      removeSectionObj.section.original_id,
      {
        delete_reason: reason,
      }
    );
    reloadCb(res, `Quick Access Section has been removed`, loadingCb, () => {
      syncUpdateQuickAccessSection({
        categoryId: removeSectionObj.category.id,
        sectionId: removeSectionObj.section.id,
        action: STORE_SYNC_ACTIONS.DELETE,
      });
      if (cb) cb();
    });
  };
  const onSubmitItem = async (values, loadingCb, cb) => {
    let res = null;
    const br = getSectionItemBR(
      {
        ...values,
      },
      companyId
    );
    if (values?.id) {
      res = await onEditResource(
        RESOURCE_DATA_KEY.QUICK_ACCESS_ITEMS,
        values.id,
        br
      );
    } else {
      res = await onCreateResource(RESOURCE_DATA_KEY.QUICK_ACCESS_ITEMS, br);
    }
    reloadCb(
      res,
      `Section Item has been ${values.id ? 'updated' : 'created'}`,
      loadingCb,
      () => {
        const addedItem = res.data;
        syncUpdateQuickAccessSectionItem({
          ...values.rawData,
          categoryId: values.rawData.category.id,
          sectionId: values.rawData.section.id,
          itemId: values.rawData.item?.id,
          newItem: br,
          addedItem,
          action: values?.id
            ? STORE_SYNC_ACTIONS.UPDATE
            : STORE_SYNC_ACTIONS.ADD,
        });
        if (cb) cb();
      }
    );
  };
  const onDeleteItem = async (deleteItemObj, loadingCb, cb) => {
    const res = await onDeleteResource(
      RESOURCE_DATA_KEY.QUICK_ACCESS_ITEMS,
      deleteItemObj.item.original_id
    );
    reloadCb(res, 'Section Item has been removed', loadingCb, () => {
      syncUpdateQuickAccessSectionItem({
        categoryId: deleteItemObj.category.id,
        sectionId: deleteItemObj.section.id,
        itemId: deleteItemObj.item.id,
        action: STORE_SYNC_ACTIONS.DELETE,
      });
      if (cb) cb();
    });
  };

  return {
    onSubmitSection,
    onDeleteSection,
    onSubmitItem,
    onDeleteItem,
  };
};

export default useFunction;
