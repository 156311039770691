import { LOCATION_DETAILS_TAB_KEYS } from 'src/pages/LocationDetails/constants';
import { getBEDate } from 'src/utils/date';
import { getTabUrlAction } from 'src/utils/routes';

export const getCreateSubSystemPayload = (values) => {
  const returnObj = {
    ...values,
    last_inspection: getBEDate(values.last_inspection),
  };
  return returnObj;
};
export const getTitle = ({ isViewDetails, isEdit, details, locationId }) => {
  if (isViewDetails) return `${details?.name || ''}`;
  return isEdit
    ? locationId
      ? 'Edit Sub-System'
      : `Edit Sub-System - ${details?.name}`
    : 'Add New Sub-System';
};
export const getSubTitle = (isViewDetails, isEdit, locationId) => {
  if (!!isEdit && !!locationId)
    return (
      <>
        An Americold refrigeration facility specializes in
        temperature-controlled storage and logistics, supporting the
        distribution of perishable goods efficiently and reliably.
      </>
    );
  if (isViewDetails) return '';
  return <>Add new sub-system to the location.</>;
};
export const getBreadcrumbText = ({
  isViewDetails,
  isEdit,
  name,
  locationId,
}) => {
  if (isViewDetails) return name;
  return isEdit ? `Edit Sub-System` : 'Add New Sub-System';
};
export const getBreadcrumbs = ({
  locationId,
  isViewDetails,
  isEdit,
  name,
  locationName,
}) => {
  return [
    ...[
      {
        link: '/locations',
        label: 'Locations',
      },
      {
        link: getTabUrlAction(
          `/locations/${locationId}`,
          LOCATION_DETAILS_TAB_KEYS.SUB_SYSTEMS
        ),
        label: locationName,
      },
    ],
    {
      label: getBreadcrumbText({
        isViewDetails,
        isEdit,
        name,
        locationId,
      }),
    },
  ];
};
