import cn from 'classnames';
import { useState } from 'react';
import Button from 'src/components/Button';
import SearchInput from 'src/components/FormFields/Input/SearchInput';
import Tag from 'src/components/Tag';
import { applySearch } from 'src/utils/search';
import classes from './Header.module.scss';

const Header = ({ className, expanded, toggle, onAdd, onSearchChange }) => {
  const [search, setSearch] = useState('');
  return (
    <div className={cn(classes.wrapper, className)}>
      <span className={classes.title}>Categories</span>
      <div className={classes.actions}>
        <SearchInput
          size="medium"
          placeholder="Search"
          iconPosition="right"
          value={search}
          onChange={(e) => {
            setSearch(e.target.value);
            applySearch(e.target.value, (val) => {
              onSearchChange(val);
            });
          }}
          light
        />
        <Button variant="primary" size="medium" onClick={onAdd}>
          Add New Section
        </Button>
        <Tag
          size="sm"
          outline
          variant={expanded ? 'gray' : 'dark_gray'}
          radius="sm"
          onClick={toggle}
        >
          {expanded ? 'Collapse' : 'Expand'} All
        </Tag>
      </div>
    </div>
  );
};

export default Header;
