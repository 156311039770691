import cn from 'classnames';
import { useState } from 'react';
import Button from 'src/components/Button';
import classes from './RightActions.module.scss';

const RightActions = ({
  className,
  onCancel,
  onCreate,
  submitLoading,
  isEmptyRichText,
  isEdit,
  setIsEdit,
}) => {
  return (
    <div className={cn(classes.wrapper, className)}>
      {!isEmptyRichText && !isEdit ? (
        <>
          <Button
            variant="primary"
            onClick={() => {
              setIsEdit(true);
            }}
          >
            Edit Content
          </Button>
        </>
      ) : (
        <>
          <Button
            variant="primary"
            isLink
            onClick={
              isEdit
                ? () => {
                    setIsEdit(false);
                  }
                : onCancel
            }
          >
            Cancel
          </Button>
          <Button variant="primary" onClick={onCreate} loading={submitLoading}>
            {!isEmptyRichText ? 'Save Changes' : 'Create Document'}
          </Button>
        </>
      )}
    </div>
  );
};

export default RightActions;
