import { useEffect, useState } from 'react';
import { RESOURCE_DATA_KEY } from 'src/helper/constants/store';
import useResourceActions from 'src/helper/hooks/useResourceActions';

const useRegionOptions = () => {
  const { onGetResources } = useResourceActions();
  const [options, setOptions] = useState([]);
  const reloadData = async () => {
    const res = await onGetResources(RESOURCE_DATA_KEY.REGIONS, {
      page: 1,
      per_page: 9999,
    });
    setOptions(
      (res?.isSuccess ? res.data : []).map((obj) => ({
        value: obj.id,
        label: obj.name,
      }))
    );
  };
  useEffect(() => {
    reloadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { regionOptions: options, refetch: reloadData };
};

export default useRegionOptions;
