export const getDefaultActiveCollapseMenu = (path) => {
  const tPath = path || '';
  if (tPath.startsWith('/admin/')) return 'Admin';
  return '';
};
export function findChildLevelByName(data, targetName, currentLevel = 0) {
  for (const item of data) {
    // Check if the current item's name matches the target name
    if (item.name === targetName) {
      return currentLevel + 1;
    }
    // Recursively search in the child menus
    if (item.views && item.views.length > 0) {
      const level = findChildLevelByName(
        item.views,
        targetName,
        currentLevel + 1
      );
      if (level !== null) {
        return level;
      }
    }
  }
  return null; // Return null if not found
}
export const getCollapseStates = (tRoutes) => {
  let initialState = {};
  tRoutes.map((prop, key) => {
    if (prop.collapse) {
      initialState = {
        [prop.state]: getCollapseInitialState(prop.views),
        ...getCollapseStates(prop.views),
        ...initialState,
      };
    }
    return null;
  });
  return initialState;
};
export const getCollapseInitialState = (tRoutes) => {
  for (let i = 0; i < tRoutes.length; i++) {
    if (tRoutes[i].collapse && getCollapseInitialState(tRoutes[i].views)) {
      return true;
    } else if (
      location.pathname.indexOf(tRoutes[i].path) !== -1 &&
      !!tRoutes[i].path
    ) {
      return true;
    }
  }
  return false;
};
