export const getSelectValue = (obj) => {
  if (!obj) return '';
  return typeof obj.value === 'string' || typeof obj.value === 'number'
    ? obj.value
    : typeof obj === 'string' || typeof obj === 'number'
    ? obj
    : '';
};
export const getSelectValues = (arr) => {
  return (arr || []).map((obj) => getSelectValue(obj));
};

export const isValidFormatField = (value) => {
  return !(value || '').includes('_');
};
const trimFormatValue = (val) => {
  if (!val) return '';
  return val
    .replace(/\//g, '')
    .replace(/-/g, '')
    .replace(/_/g, '')
    .replace(/:/g, '')
    .replace(/\)/g, '')
    .replace(/\(/g, '');
};
export const testFormatField = (isRequired) => (value) => {
  const isPassedPattern = !!trimFormatValue(value) && isValidFormatField(value);
  if (isRequired) {
    return isPassedPattern;
  }
  return !trimFormatValue(value) || isPassedPattern;
};
