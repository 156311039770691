import cn from 'classnames';
import isEmpty from 'lodash/isEmpty';
import { useState } from 'react';
import Button from 'src/components/Button';
import Input from 'src/components/FormFields/Input';
import Modal from 'src/components/Modal';
import classes from './Modal.module.scss';

const DeleteModal = ({
  isOpen,
  onClose,
  onSubmit,
  className,
  description,
  inputProps,
  submitProps,
  title,
  disabledFn,
}) => {
  const [loading, setLoading] = useState(false);
  const [val, setVal] = useState('');

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={title}
      hideClose
      centered
      className={cn(classes.deleteModalWrapper, className)}
      size="s_small"
    >
      {!!description && (
        <div className={classes.deleteDescription}>{description}</div>
      )}
      {!isEmpty(inputProps) && (
        <div>
          <Input
            placeholder={inputProps.placeholder}
            value={val}
            onChange={(e) => {
              setVal(e.target.value);
            }}
            type="textarea"
            label={inputProps.label}
            radius="md"
            variant="primary"
            maxLength={255}
          />
        </div>
      )}
      <div className={classes.deleteModalFooter}>
        <Button
          variant="primary"
          type="button"
          fontSize="sm"
          onClick={onClose}
          isLink
        >
          Cancel
        </Button>
        <Button
          variant="red"
          type="button"
          fontSize="sm"
          loading={loading}
          onClick={() => {
            setLoading(true);
            onSubmit(val, () => {
              setLoading(false);
            });
          }}
          disabled={disabledFn ? disabledFn(val) : !(val || '').trim()}
        >
          {submitProps?.text || 'Delete'}
        </Button>
      </div>
    </Modal>
  );
};

export default DeleteModal;
