import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation, useParams } from 'react-router-dom';
import * as yup from 'yup';
import Breadcrumb from 'src/components/Breadcrumb';
import Loading from 'src/components/Loading';
import ConfirmModal from 'src/components/Modal/Confirm';
import ToastAlert from 'src/components/ToastAlert';
import { VIEW_PARAM_KEY, isUrlAction } from 'src/helper/constants/routes';
import { RESOURCE_DATA_KEY } from 'src/helper/constants/store';
import useOwnNavigate from 'src/helper/hooks/useOwnNavigate';
import useResourceActions from 'src/helper/hooks/useResourceActions';
import GenericCreateTitle from 'src/pages/GenericCreate/components/Title';
import GenericCreateWrapper from 'src/pages/GenericCreate/components/Wrapper';
import { LOCATION_DETAILS_TAB_KEYS } from 'src/pages/LocationDetails/constants';
import { getErrorMessageFromResponse } from 'src/utils/actions';
import { notifyPrimary } from 'src/utils/notification';
import {
  getAllUrlParams,
  getCreateEditUrlAction,
  getTabUrlAction,
} from 'src/utils/routes';
import classes from './Create.module.scss';
import CreateEquipmentForm from './components/Form';
import { useActions, useIndexData } from './selectorData';
import {
  getBreadcrumbs,
  getCreateEquipmentPayload,
  getSubTitle,
  getTitle,
} from './utils';

const formSchema = yup.object().shape({
  name: yup.string().required('Name is required!'),
  sub_system_id: yup.mixed().required('Sub-system is required!'),
  last_maintenance: yup.string().required('Last Maintenance is required!'),
  maintenance_by_id: yup.mixed().required('Maintenance By is required!'),
});

let confirmFn = null;

const CreateEquipment = () => {
  const { prevPath, onNavigate } = useOwnNavigate();
  const { onCreateResource, onEditResource } = useResourceActions();
  const { getResourceDetails, getLocationDetails } = useActions();
  const { equipmentDetails, locationDetails } = useIndexData();
  const location = useLocation();
  const urlParams = getAllUrlParams(location?.search);
  const params = useParams();
  const locationId = Number(params.locationId || 0);
  const locationUrl = getTabUrlAction(
    `/locations/${locationId}`,
    LOCATION_DETAILS_TAB_KEYS.EQUIPMENT
  );
  const backUrl = prevPath || `${locationId ? locationUrl : '/locations'}`;
  const equipmentId = Number(urlParams.id || 0);
  const isViewDetails =
    isUrlAction(urlParams?.[VIEW_PARAM_KEY]) && !!equipmentId;
  const isEdit = !isUrlAction(urlParams?.[VIEW_PARAM_KEY]) && !!equipmentId;
  const notificationAlertRef = useRef(null);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [detailsLoading, setDetailsLoading] = useState(false);
  const [locationDetailsLoading, setLocationDetailsLoading] = useState(false);
  const [confirmObj, setConfirmObj] = useState(null);
  const breadcrumbs = getBreadcrumbs({
    locationId,
    isViewDetails,
    isEdit,
    name: equipmentDetails?.name,
    locationName: locationDetails?.name,
  });
  const { handleSubmit, control, setValue, formState, reset } = useForm({
    resolver: yupResolver(formSchema),
    defaultValues: {
      name: '',
      sub_system_id: null,
      description: '',
      last_maintenance: '',
      maintenance_by_id: null,
    },
  });
  const notify = (type, title, description) => {
    notifyPrimary({ ref: notificationAlertRef, type, title, description });
  };
  const reloadDetails = (cb) => {
    setDetailsLoading(true);
    getResourceDetails(
      RESOURCE_DATA_KEY.LOCATION_EQUIPMENT,
      equipmentId,
      (res) => {
        setDetailsLoading(false);
        if (cb) cb(res);
      }
    );
  };
  const reloadLocationDetails = (cb) => {
    setLocationDetailsLoading(true);
    getLocationDetails(locationId, (res) => {
      setLocationDetailsLoading(false);
      if (cb) cb(res);
    });
  };
  const onSubmit = async (values) => {
    if (!submitLoading) {
      setSubmitLoading(true);
      const tPayload = getCreateEquipmentPayload({
        ...values,
        location_id: locationId,
      });
      let res = null;
      if (isEdit) {
        tPayload.status = equipmentDetails?.status || 'active';
        res = await onEditResource(
          RESOURCE_DATA_KEY.LOCATION_EQUIPMENT,
          equipmentId,
          tPayload
        );
      } else {
        tPayload.status = 'active';
        res = await onCreateResource(
          RESOURCE_DATA_KEY.LOCATION_EQUIPMENT,
          tPayload
        );
      }
      setSubmitLoading(false);
      if (res.isSuccess) {
        onNavigate(getCreateEditUrlAction(backUrl, isEdit));
      } else {
        notify(
          'error',
          'Error!',
          getErrorMessageFromResponse(res) ||
            `${isEdit ? 'Edit' : 'Create'} Equipment failed!`
        );
      }
    }
  };

  useEffect(() => {
    reloadLocationDetails();
    if (equipmentId) {
      reloadDetails((res) => {
        if (isEdit) {
          const editObj = res?.data;
          if (editObj?.id) {
            const maintenanceBy =
              editObj.maintenance_by?.id || editObj.maintenance_by;
            const resetObj = {
              name: editObj.name,
              description: editObj.description || '',
              sub_system_id: editObj.sub_system_id || null,
              maintenance_by_id: maintenanceBy ? String(maintenanceBy) : null,
              last_maintenance: editObj.last_maintenance || '',
            };
            reset(resetObj);
          }
        }
      });
    }
    confirmFn = null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <GenericCreateWrapper>
        {(detailsLoading || locationDetailsLoading) && <Loading isPage />}
        <Breadcrumb items={breadcrumbs} />
        <GenericCreateTitle
          subTitle={getSubTitle(isViewDetails, isEdit, locationId)}
          className={classes.pageTitle}
        >
          <span>
            {getTitle({
              isViewDetails,
              isEdit,
              details: equipmentDetails,
              locationId,
            })}
          </span>
        </GenericCreateTitle>
        <CreateEquipmentForm
          onSubmit={handleSubmit(onSubmit)}
          isViewDetails={isViewDetails}
          details={equipmentDetails}
          control={control}
          setValue={setValue}
          formState={formState}
          isEdit={isEdit}
          submitLoading={submitLoading}
          onCancel={() => {
            if (!isEdit) {
              setConfirmObj({
                title: 'Confirm',
                description:
                  'Are you sure you want to exit without saving this new equipment? All information that has been entered will be deleted.',
                cancelText: 'Continue Editing',
                submitText: 'Cancel Creation',
              });
              confirmFn = () => {
                onNavigate(backUrl);
              };
            } else {
              onNavigate(backUrl);
            }
          }}
        />
      </GenericCreateWrapper>
      {!!confirmObj && (
        <ConfirmModal
          isOpen
          onClose={() => {
            confirmFn = null;
            setConfirmObj(null);
          }}
          onSubmit={async (cb) => {
            if (cb) cb();
            if (confirmFn) confirmFn();
            setConfirmObj(null);
          }}
          title={confirmObj?.title}
          submitText={confirmObj?.submitText}
          cancelText={confirmObj?.cancelText}
          size="sm"
          fontSize="sm"
          className={classes.confirmModalWrapper}
          type="confirmation"
        >
          {confirmObj?.description}
        </ConfirmModal>
      )}
      <ToastAlert toastRef={notificationAlertRef} />
    </>
  );
};

export default CreateEquipment;
