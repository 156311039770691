import { yupResolver } from '@hookform/resolvers/yup';
import { useContext, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import Breadcrumb from 'src/components/Breadcrumb';
import Button from 'src/components/Button';
import FormGroup from 'src/components/FormFields/FormGroup';
import FormInput from 'src/components/FormFields/Input/FormInput';
import MenuItemActions from 'src/components/FormFields/MenuItemActions';
import SelectLocation from 'src/components/FormFields/Select/Location';
import Loading from 'src/components/Loading';
import ToastAlert from 'src/components/ToastAlert';
import { VIEW_PARAM_KEY, isUrlAction } from 'src/helper/constants/routes';
import { RESOURCE_DATA_KEY } from 'src/helper/constants/store';
import useOptions from 'src/helper/hooks/useOptions';
import useOwnNavigate from 'src/helper/hooks/useOwnNavigate';
import useResourceActions from 'src/helper/hooks/useResourceActions';
import { MenuContext } from 'src/helper/providers/MenuProvider';
import Field from 'src/pages/GenericCreate/components/Field';
import GenericCreateFooter from 'src/pages/GenericCreate/components/Footer';
import GenericCreateTitle from 'src/pages/GenericCreate/components/Title';
import GenericCreateWrapper from 'src/pages/GenericCreate/components/Wrapper';
import { getMenuActionsArr } from 'src/utils/menu';
import { notifyPrimary } from 'src/utils/notification';
import { getAllUrlParams } from 'src/utils/routes';
import { formSchema } from './constants';
import { useActions, useIndexData } from './selectorData';
import {
  getAssignedMenuItemsPayload,
  getBreadcrumbText,
  getCreateRolePayload,
  getSubTitle,
  getTitle,
} from './utils';

const RolesCreate = () => {
  const { selectedLocations, getSingleLocationOption } =
    useContext(MenuContext);
  const {
    onCreateResource,
    onEditResource,
    onAssignResourceMenuItem,
    onGetResourceMenuItem,
  } = useResourceActions();
  const { menuOptions } = useOptions();
  const { getResourceDetails, getAllMenus } = useActions();
  const { roleDetails } = useIndexData();
  const location = useLocation();
  const urlParams = getAllUrlParams(location?.search);
  const { onNavigate } = useOwnNavigate();
  const roleId = Number(urlParams.id || 0);
  const isViewDetails = isUrlAction(urlParams?.[VIEW_PARAM_KEY]) && !!roleId;
  const isEdit = !isUrlAction(urlParams?.[VIEW_PARAM_KEY]) && !!roleId;
  const notificationAlertRef = useRef(null);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [detailsLoading, setDetailsLoading] = useState(false);
  const [assignedMenuItemsLoading, setAssignedMenuItemsLoading] =
    useState(false);
  const breadcrumbs = [
    {
      link: '/admin/roles',
      label: 'Roles',
    },
    {
      label: getBreadcrumbText({ isViewDetails, isEdit }),
    },
  ];
  const { handleSubmit, control, setValue, formState, reset } = useForm({
    resolver: yupResolver(formSchema),
    defaultValues: {
      role_name: '',
      description: '',
      location_ids: selectedLocations,
      menu_items: [],
    },
  });
  const notify = (type, title, description) => {
    notifyPrimary({ ref: notificationAlertRef, type, title, description });
  };
  const reloadRoleDetails = (cb) => {
    setDetailsLoading(true);
    getResourceDetails(RESOURCE_DATA_KEY.ROLES, roleId, (res) => {
      setDetailsLoading(false);
      if (cb) cb(res);
    });
  };
  const reloadAssignedMenuItems = async (cb) => {
    setAssignedMenuItemsLoading(true);
    const res = await onGetResourceMenuItem(RESOURCE_DATA_KEY.ROLES, roleId);
    if (cb) cb(res);
    setAssignedMenuItemsLoading(false);
  };
  const assignedMenuItems = async (tRoleId, tMenuItems, cb) => {
    const tPayload = getAssignedMenuItemsPayload(tMenuItems);
    const res = await onAssignResourceMenuItem(
      RESOURCE_DATA_KEY.ROLES,
      tRoleId,
      tPayload
    );
    setSubmitLoading(false);
    if (res.isSuccess) {
      if (cb) cb();
    } else {
      notify('error', 'Error!', `Assign Menu Items failed!`);
    }
  };
  const onSubmit = async ({ menu_items, ...values }) => {
    if (!submitLoading) {
      setSubmitLoading(true);
      const tPayload = getCreateRolePayload(values);
      let res = null;
      if (roleId) {
        tPayload.status = roleDetails?.status;
        res = await onEditResource(RESOURCE_DATA_KEY.ROLES, roleId, tPayload);
      } else {
        tPayload.status = 'active';
        res = await onCreateResource(RESOURCE_DATA_KEY.ROLES, tPayload);
      }
      if (res.isSuccess) {
        assignedMenuItems(res.data?.id, menu_items, () => {
          onNavigate('/admin/roles');
        });
      } else {
        setSubmitLoading(false);
        notify('error', 'Error!', `${roleId ? 'Edit' : 'Create'} Role failed!`);
      }
    }
  };
  useEffect(() => {
    if (!isViewDetails) {
      getAllMenus({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (roleId) {
      reloadRoleDetails((res) => {
        if (isEdit) {
          const editObj = res?.data;
          if (editObj?.id) {
            const resetObj = {
              role_name: editObj.role_name,
              description: editObj.description,
              location_ids: !editObj.location_ids?.length
                ? selectedLocations
                : editObj.location_ids
                    .map((id) => {
                      return getSingleLocationOption(id);
                    })
                    .filter((v) => !!v),
            };
            reloadAssignedMenuItems((res1) => {
              resetObj.menu_items = (res1?.data || []).map((obj) => {
                return {
                  id: obj.id,
                  actions: getMenuActionsArr(obj),
                };
              });
              reset(resetObj);
            });
          }
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roleId]);

  return (
    <>
      <GenericCreateWrapper>
        {(detailsLoading || assignedMenuItemsLoading) && <Loading isPage />}
        <Breadcrumb items={breadcrumbs} />
        <GenericCreateTitle subTitle={getSubTitle({ isEdit, roleDetails })}>
          <span>{getTitle({ isViewDetails, isEdit, roleDetails })}</span>
        </GenericCreateTitle>
        <form role="form" className="s-form" onSubmit={handleSubmit(onSubmit)}>
          <div className="s-form-inner">
            {isViewDetails ? (
              <>
                <Field label="Name" value={roleDetails?.role_name || '-'} />
                <Field
                  label="Location"
                  value={
                    <>
                      {(roleDetails?.location_ids || [])
                        .map((tId) => getSingleLocationOption(tId))
                        .filter((obj) => !!obj?.value)
                        .map((obj) => {
                          return <span key={obj.value}>{obj.label}</span>;
                        })}
                    </>
                  }
                />
                <Field
                  label="Description"
                  value={roleDetails?.description || '-'}
                />
              </>
            ) : (
              <>
                <FormGroup>
                  <FormInput
                    required
                    label="Name"
                    name="role_name"
                    placeholder="Enter name"
                    control={control}
                    radius="md"
                    variant="primary"
                    fontSize="sm"
                  />
                </FormGroup>
                <FormGroup>
                  <SelectLocation
                    name="location_ids"
                    onChange={(val) => {
                      setValue('location_ids', val, {
                        shouldDirty: true,
                        shouldValidate: true,
                      });
                    }}
                    control={control}
                    label="Locations"
                    required
                  />
                </FormGroup>
                <FormGroup>
                  <FormInput
                    label="Description"
                    name="description"
                    placeholder="Enter description"
                    control={control}
                    radius="md"
                    variant="primary"
                    fontSize="sm"
                    type="textarea"
                  />
                </FormGroup>
              </>
            )}
          </div>
          {!isViewDetails && (
            <FormGroup>
              <MenuItemActions
                name="menu_items"
                onChange={(val) => {
                  setValue('menu_items', val, {
                    shouldDirty: true,
                  });
                }}
                control={control}
                required
                menus={menuOptions.map((obj) => obj.rawObj)}
              />
            </FormGroup>
          )}
          {!isViewDetails && (
            <GenericCreateFooter>
              <Button
                variant="primary"
                isLink
                type="button"
                onClick={() => {
                  onNavigate('/admin/roles');
                }}
              >
                Cancel
              </Button>
              <Button
                variant="primary"
                type="submit"
                disabled={!formState.isValid || !formState.isDirty}
                loading={submitLoading}
              >
                {isEdit ? 'Save Changes' : 'Create'}
              </Button>
            </GenericCreateFooter>
          )}
        </form>
      </GenericCreateWrapper>
      <ToastAlert toastRef={notificationAlertRef} />
    </>
  );
};

export default RolesCreate;
