import AddStaff from 'src/pages/Staff/Add';
import CreateStaff from 'src/pages/Staff/Create';

const staffRoutes = [
  {
    path: '/locations/:locationId/staff/create',
    name: 'Create Staff',
    icon: '',
    iconActive: '',
    component: CreateStaff,
    layout: 'private',
  },
  {
    path: '/locations/:locationId/add-staff',
    name: 'Location Add Staff',
    icon: '',
    iconActive: '',
    component: AddStaff,
    layout: 'private',
  },
];

export default staffRoutes;
