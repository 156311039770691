export const INPUT_HIGHLIGHT_ERROR_MESSAGE = 'INPUT_HIGHLIGHT_ERROR_MESSAGE';
export const SELECT_CREATE_OPTION = 'SELECT_CREATE_OPTION';
export const DELETE_REGION_HAS_LOCATION_PREFIX = 'has_location_-';
export const FORM_BUILDER_DEFAULT_OPTIONS = [
  {
    text: 'Placeholder option 1',
    value: '1',
  },
  {
    text: 'Placeholder option 2',
    value: '2',
  },
  {
    text: 'Placeholder option 3',
    value: '3',
  },
];
export const LOCATIONS_SCOPE_ATTR_NAME = 'x-locations-scope';
export const isStaging = process.env.REACT_APP_NODE_ENV === 'staging';
export const DEFAULT_LOCATION = {
  lat: 43.61351,
  lng: -116.203791,
};
