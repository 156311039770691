import cn from 'classnames';
import isArray from 'lodash/isArray';
import { forwardRef } from 'react';
import Select from 'react-select';
import Creatable from 'react-select/creatable';
import { FormFeedback, Label } from 'reactstrap';
import { ReactComponent as WarningSvg } from 'src/assets/icons/warning-2.svg';
import LabelTooltip from 'src/components/FormFields/LabelTooltip';
import RequiredMark from 'src/components/RequiredMark';
import { INPUT_HIGHLIGHT_ERROR_MESSAGE } from 'src/helper/constants';
import classes from './Select.module.scss';
import {
  CheckboxOption,
  LocationGroupHeading,
  MenuList,
  MultiValueContainer,
  MultiValueContainerLocationGroup,
  OptionSelect,
  OptionSelectedWithCheck,
  SingleValue,
  customStyles,
} from './components';

const ReSelect = forwardRef((props, ref) => {
  const {
    isCreate,
    isValueHasIcon,
    selectedWithCheck,
    label,
    className,
    value,
    options,
    error,
    required,
    locationGroup,
    size,
    labelTooltip,
    optionWithCheckbox,
    ...other
  } = props;
  let tValue = value;
  const isValidValue = other.isMulti
    ? !value || (isArray(value) && value.every((obj) => !!obj?.value))
    : !value || !!value?.value;
  if (!isValidValue) {
    tValue = options.find((obj) => obj.value === value);
  }
  let selectComponents = {
    MultiValueContainer,
    MenuList,
    Option: OptionSelect,
  };
  if (isValueHasIcon) {
    selectComponents = {
      ...selectComponents,
      SingleValue,
    };
  }
  if (selectedWithCheck) {
    selectComponents = {
      ...selectComponents,
      Option: OptionSelectedWithCheck,
    };
  }
  if (locationGroup) {
    selectComponents = {
      ...selectComponents,
      Option: CheckboxOption,
      GroupHeading: LocationGroupHeading,
      MultiValueContainer: MultiValueContainerLocationGroup,
    };
  }
  if (optionWithCheckbox) {
    selectComponents = {
      ...selectComponents,
      Option: CheckboxOption,
    };
  }
  return (
    <div
      className={cn(
        classes.wrapper,
        {
          [classes.isError]: !!error,
        },
        className
      )}
      key={other.key}
    >
      {!!label && (
        <Label className={classes.label}>
          {label}
          {required && <RequiredMark />}
          {!!labelTooltip?.text && !!labelTooltip?.icon && (
            <LabelTooltip tooltip={labelTooltip} />
          )}
        </Label>
      )}
      {isCreate ? (
        <Creatable
          ref={ref}
          hideSelectedOptions={false}
          menuPosition="fixed"
          className={classes.wrapper}
          styles={customStyles(props)}
          components={selectComponents}
          value={tValue}
          options={options}
          menuPortalTarget={document.body}
          {...other}
        />
      ) : (
        <Select
          ref={ref}
          hideSelectedOptions={false}
          // closeMenuOnSelect={false}
          menuPosition="fixed"
          className={classes.wrapper}
          styles={customStyles(props)}
          components={selectComponents}
          value={tValue}
          options={options}
          menuPortalTarget={document.body}
          {...other}
        />
      )}
      {error && error !== INPUT_HIGHLIGHT_ERROR_MESSAGE && (
        <FormFeedback className={classes.error}>
          <WarningSvg />
          <span>{error}</span>
        </FormFeedback>
      )}
    </div>
  );
});

export default ReSelect;
