import { useEffect, useRef, useState } from 'react';
import Breadcrumb from 'src/components/Breadcrumb';
import ConfirmModal from 'src/components/Modal/Confirm';
import DeleteModal from 'src/components/Modal/Delete';
import PageWrapper from 'src/components/Page/PageWrapper';
import ToastAlert, { useNotify } from 'src/components/ToastAlert';
import { RESOURCE_DATA_KEY } from 'src/helper/constants/store';
import useResourceActions from 'src/helper/hooks/useResourceActions';
import { useMenu } from 'src/helper/providers/MenuProvider';
import classes from './QuickAccess.module.scss';
import Title from './Title';
import AddItemModal from './components/AddItemModal';
import AddSectionModal from './components/AddSectionModal';
import Category from './components/Category';
import Header from './components/Header';
import { breadcrumbs } from './constants';
import { useActions, useIndexData } from './selectorData';
import useFunction from './useFunction';
import useQuickAccessCollapse from './useQuickAccessCollapse';
import { searchCategories } from './utils';

const QuickAccess = () => {
  const { onGetResources } = useResourceActions();
  const { companyId } = useMenu();
  const { getResources } = useActions();
  const [search, setSearch] = useState('');
  const { quickAccessAllData } = useIndexData();
  const allCategories = searchCategories(quickAccessAllData.data || [], search);
  const notificationAlertRef = useRef(null);
  const {
    isCollapseAll,
    toggleAll,
    collapseItems,
    toggleCategory,
    toggleSection,
    categoriesCollapsed,
  } = useQuickAccessCollapse(allCategories);
  const [addSectionObj, setAddSectionObj] = useState(null);
  const [addItemObj, setAddItemObj] = useState(null);
  const [removeSectionObj, setRemoveSectionObj] = useState(null);
  const [loading, setLoading] = useState(null);
  const [sectionCanNotRemove, setSectionCanNotRemove] = useState('');
  const [deleteItemObj, setDeleteItemObj] = useState(null);
  const notify = useNotify(notificationAlertRef);
  const reloadSectionData = async (categoryName) => {
    setLoading(true);
    const res = await onGetResources(RESOURCE_DATA_KEY.QUICK_ACCESS_SECTIONS, {
      page: 1,
      per_page: 99999,
      filters: {
        level: categoryName,
        company_id: companyId,
      },
    });
    setLoading(false);
  };
  const reloadHierarchy = () => {
    const params = { company_id: companyId };
    getResources(RESOURCE_DATA_KEY.QUICK_ACCESS_HIERARCHY, params, () => {
      setLoading(false);
    });
  };
  const { onSubmitSection, onDeleteSection, onSubmitItem, onDeleteItem } =
    useFunction({
      notify,
      reloadData: reloadHierarchy,
      companyId,
    });
  const handleSubmitSection = (values, cb) => {
    onSubmitSection(
      {
        ...values,
        rawData: addSectionObj,
      },
      cb,
      () => {
        setAddSectionObj(null);
      }
    );
  };
  const handleSubmitItem = (values, cb) => {
    onSubmitItem(
      {
        ...values,
        rawData: addItemObj,
      },
      cb,
      () => {
        setAddItemObj(null);
      }
    );
  };
  const handleRemoveSection = (val, cb) => {
    onDeleteSection(removeSectionObj, val, cb, () => {
      setRemoveSectionObj(null);
    });
  };

  useEffect(() => {
    if (companyId) {
      reloadHierarchy();
      // reloadSectionData(SECTION_LEVELS.ORGANIZATION);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyId]);

  return (
    <>
      <PageWrapper className={classes.wrapper}>
        <Breadcrumb items={breadcrumbs} />
        <Title />
        <div className={classes.content}>
          <Header
            onAdd={() => {
              setAddSectionObj({});
            }}
            expanded={!isCollapseAll}
            toggle={toggleAll}
            onSearchChange={(val) => {
              setSearch(val);
            }}
          />
          {allCategories.map((category) => {
            return (
              <Category
                key={category.id}
                collapse={collapseItems}
                categoryCollapsed={categoriesCollapsed[category.id]}
                toggleCategory={() => {
                  toggleCategory(category);
                }}
                toggleSection={(sectionId, val) => {
                  toggleSection(sectionId, val);
                }}
                sections={category.sections}
                title={category.level}
                onAddItem={(section) => {
                  setAddItemObj({
                    category,
                    section,
                  });
                }}
                onEditItem={(section, item) => {
                  setAddItemObj({
                    category,
                    section,
                    item,
                  });
                }}
                onEditSection={(section) => {
                  setAddSectionObj({
                    category,
                    section,
                  });
                }}
                onRemoveSection={(section) => {
                  if (!section.items?.length) {
                    setRemoveSectionObj({
                      category,
                      section,
                    });
                  } else {
                    setSectionCanNotRemove(section.name);
                  }
                }}
                onRemoveItem={(section, item) => {
                  setDeleteItemObj({
                    category,
                    section,
                    item,
                  });
                }}
              />
            );
          })}
        </div>
      </PageWrapper>
      {!!sectionCanNotRemove && (
        <ConfirmModal
          isOpen
          type="confirmation"
          onClose={() => {
            setSectionCanNotRemove('');
          }}
          onSubmit={(cb) => {
            if (cb) cb();
            setSectionCanNotRemove('');
          }}
          title="Remove Section"
          submitText="Close"
          hideCancel
          size="s_small"
        >
          Sorry! You cannot remove the <b>{sectionCanNotRemove}</b> section.
          This section has associated items, documents and files. To remove this
          section, please move the documents/files to another section and item
          card.
        </ConfirmModal>
      )}
      {!!removeSectionObj && (
        <DeleteModal
          title="Remove Section"
          isOpen
          onClose={() => {
            setRemoveSectionObj(null);
          }}
          onSubmit={handleRemoveSection}
          description={
            <span>
              You are about to remove <b>{removeSectionObj?.section?.name}</b>{' '}
              section.
              <br />
              <br />
              To continue, please enter a reason for removing this category in
              the field below.
            </span>
          }
          submitProps={{
            text: 'Remove Section',
          }}
          inputProps={{
            label: 'Reason for Removal',
            placeholder: 'This is the reason for removing this section. ',
          }}
        />
      )}
      {!!addSectionObj && (
        <AddSectionModal
          isOpen
          onClose={() => {
            setAddSectionObj(null);
          }}
          onSubmit={handleSubmitSection}
          editObj={addSectionObj}
        />
      )}
      {!!addItemObj && (
        <AddItemModal
          isOpen
          onClose={() => {
            setAddItemObj(null);
          }}
          onSubmit={handleSubmitItem}
          editObj={addItemObj}
        />
      )}
      {!!deleteItemObj && (
        <ConfirmModal
          size="s_small"
          isOpen
          onClose={() => {
            setDeleteItemObj(null);
          }}
          onSubmit={(cb) => {
            onDeleteItem(deleteItemObj, cb, () => {
              setDeleteItemObj(null);
            });
          }}
          title="Delete Section Item"
          submitText="Delete"
        >
          Do you want to delete this section item?
        </ConfirmModal>
      )}
      <ToastAlert toastRef={notificationAlertRef} />
    </>
  );
};

export default QuickAccess;
