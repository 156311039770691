import Breadcrumb from 'src/components/Breadcrumb';
import PageTitle from 'src/components/Page/PageTitle';
import PageWrapper from 'src/components/Page/PageWrapper';
import GenericContent from 'src/pages/Generic/components/Content';

const Approvals = () => {
  const breadcrumbs = [
    {
      label: 'Approvals',
    },
  ];

  return (
    <>
      <PageWrapper>
        <Breadcrumb items={breadcrumbs} />
        <PageTitle>Approvals</PageTitle>
        <GenericContent></GenericContent>
      </PageWrapper>
    </>
  );
};

export default Approvals;
