import range from 'lodash/range';
import React from 'react';
import { ReactComponent as MoreSvg } from 'src/assets/icons/more.svg';
import Dropdown from 'src/components/Dropdown';
import StatusTag from 'src/components/Tag/Status';
import { getQuantityUnitName } from 'src/helper/constants/quantityUnits';
import {
  renderDateColumn,
  renderNormalColumn,
  renderNormalColumnCustomAttr,
  renderNumberColumn,
  renderStatusColumn,
} from 'src/utils/table';

export const getColumns1 = ({ onEdit, onDelete }) => {
  return [
    {
      key: 'sub_system_name',
      label: 'Name',
      render: renderNormalColumn,
      sortable: true,
      width: 317,
    },
    {
      key: 'status',
      label: 'Status',
      render: renderStatusColumn,
      sortable: true,
      width: 120,
    },
    {
      key: 'last_inspection',
      label: 'Last Inspection',
      render: renderDateColumn,
      sortable: true,
      width: 200,
    },
    {
      key: 'updated_at',
      label: 'Updated',
      render: renderDateColumn,
      sortable: true,
      width: 200,
    },
    {
      key: 'updated_by',
      label: 'Updated By',
      render: renderNormalColumnCustomAttr('updated_by.name'),
      sortable: true,
      width: 180,
    },
    {
      key: 'documents',
      label: 'Documents',
      render: (val) => '4 Documents',
      sortable: true,
      width: 180,
    },
    {
      key: 'action',
      width: 56,
      maxWidth: 56,
      render: (_, obj) => {
        return (
          <Dropdown
            items={[
              {
                key: 'edit',
                label: 'Edit Sub-System',
                onClick: () => {
                  onEdit(obj);
                },
              },
              {
                key: 'remove',
                label: 'Remove Sub-System',
                onClick: () => {
                  onDelete(obj);
                },
              },
            ]}
          >
            <MoreSvg />
          </Dropdown>
        );
      },
    },
  ];
};

export const getColumns2 = ({ onEdit, onDelete }) => {
  return [
    {
      key: 'name',
      label: 'Name',
      render: renderNormalColumn,
      sortable: true,
      width: 317,
    },
    {
      key: 'status',
      label: 'Status',
      render: renderStatusColumn,
      sortable: true,
      width: 120,
    },
    {
      key: 'sub_system_name',
      label: 'Sub-System',
      render: renderNormalColumn,
      sortable: true,
      width: 180,
    },
    {
      key: 'last_maintenance',
      label: 'Last Maint.',
      render: renderDateColumn,
      sortable: true,
      width: 180,
    },
    {
      key: 'maintenance_by_name',
      label: 'Maint. by',
      render: renderNormalColumn,
      sortable: true,
      width: 180,
    },
    {
      key: 'action',
      width: 56,
      maxWidth: 56,
      render: (_, obj) => {
        return (
          <Dropdown
            items={[
              {
                key: 'edit',
                label: 'Edit Equipment',
                onClick: () => {
                  onEdit(obj);
                },
              },
              {
                key: 'remove',
                label: 'Remove Equipment',
                onClick: () => {
                  onDelete(obj);
                },
              },
            ]}
          >
            <MoreSvg />
          </Dropdown>
        );
      },
    },
  ];
};

export const getColumns3 = ({ onDelete, onEdit }) => {
  return [
    {
      key: 'name',
      label: 'Name',
      render: renderNormalColumn,
      sortable: true,
      width: 317,
    },
    {
      key: 'status',
      label: 'Status',
      render: (val) => <StatusTag value={val} />,
      sortable: true,
      width: 150,
    },
    {
      key: 'role_name',
      label: 'Role',
      render: renderNormalColumn,
      width: 150,
      sortable: true,
    },
    {
      key: 'phone',
      label: 'Phone',
      render: renderNormalColumn,
      width: 150,
    },
    {
      key: 'email',
      label: 'Email',
      render: renderNormalColumn,
      width: 250,
    },
    {
      key: 'action',
      width: 56,
      maxWidth: 56,
      render: (_, obj) => {
        return (
          <Dropdown
            items={[
              {
                key: 'edit',
                label: 'Edit Staff',
                onClick: () => {
                  onEdit(obj);
                },
              },
              {
                key: 'remove',
                label: 'Remove Staff',
                onClick: () => {
                  onDelete(obj);
                },
              },
            ]}
          >
            <MoreSvg />
          </Dropdown>
        );
      },
    },
  ];
};

export const getColumns4 = ({ onDelete, onEdit }) => {
  return [
    {
      key: 'chemical_name',
      label: 'Name',
      render: renderNormalColumn,
      sortable: true,
      width: 317,
    },
    {
      key: 'cas_number',
      label: 'CAS Number',
      render: renderNormalColumn,
      width: 150,
    },
    {
      key: 'tier_2_reportable',
      label: 'Tier 2 reportable',
      render: (val) => <StatusTag value={val ? 'yes' : 'no'} />,
      sortable: true,
      width: 230,
    },
    {
      key: 'updated_at',
      label: 'Last updated',
      render: renderDateColumn,
      sortable: true,
      width: 200,
    },
    {
      key: 'quantity',
      label: 'Quantity',
      render: (val, row) => {
        return (
          renderNumberColumn(val) + ' ' + getQuantityUnitName(row.quantity_unit)
        );
      },
      sortable: true,
      width: 150,
    },
    {
      key: 'action',
      width: 56,
      maxWidth: 56,
      render: (_, obj) => {
        return (
          <Dropdown
            items={[
              {
                key: 'edit',
                label: 'Edit Chemical',
                onClick: () => {
                  onEdit(obj);
                },
              },
              {
                key: 'remove',
                label: 'Remove Chemical',
                onClick: () => {
                  onDelete(obj);
                },
              },
            ]}
          >
            <MoreSvg />
          </Dropdown>
        );
      },
    },
  ];
};
export const DEFAULT_LIST_DATA = {
  data: range(10).map((i) => ({
    key: i + 1,
    id: i + 1,
  })),
  meta: {
    current_page: 1,
    per_page: 10,
    total: 50,
  },
};
