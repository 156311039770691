import cn from 'classnames';
import React from 'react';
import classes from './FieldsRow.module.scss';

const FieldsRow = ({ className, children, col = 1, gap = 16 }) => {
  return (
    <div
      className={cn(classes.wrapper, className)}
      style={{ gap, gridTemplateColumns: `repeat(${col}, 1fr)` }}
    >
      {children}
    </div>
  );
};

export default FieldsRow;
