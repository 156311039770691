// import TableActionDropdown from 'src/components/Table/ActionDropdown';
import StatusTag from 'src/components/Tag/Status';
import { renderNormalColumn } from 'src/utils/table';

export const getColumns = () => {
  return [
    {
      key: 'name',
      label: 'Name',
      render: renderNormalColumn,
      sortable: true,
      width: 250,
    },
    {
      key: 'status',
      label: 'Status',
      render: (val) => <StatusTag value={val} />,
      sortable: true,
      width: 120,
    },
    {
      key: 'role_name',
      label: 'Role',
      render: renderNormalColumn,
      sortable: true,
      width: 200,
    },
    {
      key: 'email',
      label: 'Email',
      render: renderNormalColumn,
      sortable: true,
      width: 200,
    },
    // {
    //   key: 'action',
    //   width: 56,
    //   maxWidth: 56,
    //   render: (_, obj) => {
    //     return <TableActionDropdown options={[]} />;
    //   },
    // },
  ];
};
