import cn from 'classnames';
import React from 'react';
import classes from './PageTitle.module.scss';

const PageTitle = ({ className, children, subTitle, gap, subTitleStyle }) => {
  return (
    <div className={cn(classes.wrapper, classes[`gap_${gap}`], className)}>
      <div className={classes.title}>{children}</div>
      {!!subTitle && (
        <div
          className={cn(
            classes.subTitle,
            classes[`size_${subTitleStyle?.size}`]
          )}
          style={{
            maxWidth: subTitleStyle?.width
              ? `${subTitleStyle.width}px`
              : undefined,
          }}
        >
          {subTitle}
        </div>
      )}
    </div>
  );
};

export default PageTitle;
