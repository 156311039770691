import PageTitle from 'src/components/Page/PageTitle';

const Title = () => {
  return (
    <PageTitle
      subTitle={
        <>
          Quick Access allows you to organize and customize your process safety
          categories for faster navigation and improved documentation. You can
          add new category tabs to the application levels and sections by
          clicking ‘Add New’ or the + sign.
        </>
      }
      subTitleStyle={{
        width: 920,
        size: 'large',
      }}
    >
      Quick Access
    </PageTitle>
  );
};

export default Title;
