import Avatar from 'src/components/Avatar';
import StatusTag from 'src/components/Tag/Status';
import { InfoItem } from 'src/pages/DocumentDetails/components/Info';
import { getFormatDate } from 'src/utils/date';
import classes from './Content.module.scss';

const RichTextInfo = ({ document }) => {
  return (
    <div className={classes.info}>
      <InfoItem
        label="Uploaded By"
        value={<Avatar name={document?.created_by_name} />}
        gap={8}
      />
      <InfoItem
        label="Created Date"
        value={getFormatDate(document?.created_at, 'MMM DD, YYYY') || '-'}
        gap={8}
      />
      <InfoItem
        label="Expiration Date"
        value={getFormatDate(document?.expires_at, 'MMM DD, YYYY') || '-'}
        gap={8}
      />
      <InfoItem
        label="Status"
        gap={8}
        value={<StatusTag value={document?.status} size="sm1" weight="lg" />}
      />
    </div>
  );
};

export default RichTextInfo;
