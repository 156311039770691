import { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Breadcrumb from 'src/components/Breadcrumb';
import Checkbox from 'src/components/FormFields/Checkbox';
import Loading from 'src/components/Loading';
import PageTitle from 'src/components/Page/PageTitle';
import PageWrapper from 'src/components/Page/PageWrapper';
import ToastAlert from 'src/components/ToastAlert';
import { MenuContext } from 'src/helper/providers/MenuProvider';
import TaskDialog from 'src/pages/Admin/Tasks/TaskDialog';
import GenericActionButtons from 'src/pages/Generic/components/ActionButtons';
import GenericContent from 'src/pages/Generic/components/Content';
import GenericTable from 'src/pages/Generic/components/Table';
import { useReducerData } from 'src/store/hooks';
import workflow from 'src/store/types/workflow';
import { getMenusByItemType } from 'src/utils/menu';
import classes from './Repository.module.scss';
import { getColumns } from './constants';
import { useActions } from './selectorData';

const Repository = () => {
  const { getDocuments } = useActions();
  const notificationAlertRef = useRef(null);
  const params = useParams();
  const navigate = useNavigate();
  const [checkedRows, setCheckedRows] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [groups, setGroups] = useState([]);
  const { availableMenus, selectedLocationIds } = useContext(MenuContext);
  const [showTaskDialog, setShowTaskDialog] = useState(false);

  const allGroups = !params['*'];
  const folder = allGroups
    ? 'All Folders'
    : params['*'].split('/')[params['*'].split('/').length - 1];
  const menusData = useReducerData('auth', 'login.data.menus_data', []);

  const loading = false;
  const breadcrumbs = [
    {
      label: 'Repository',
    },
  ];
  const handleView = (obj) => {
    navigate('/repository/view', {
      state: { documentData: obj },
    });
  };
  const handleEdit = (obj) => {
    navigate('/repository/edit', {
      state: { documentData: obj },
    });
  };
  const handleTask = (obj) => {
    const docUrl =
      process.env.REACT_APP_API_BASE_URL +
      '/api/repository/document/' +
      obj._id +
      '/' +
      obj.slug;
    setShowTaskDialog({
      title: 'Create Task for Document: ' + obj.name,
      workflowId: obj.workflow,
      task: {
        sourceDocument: { ...obj, docUrl },
        description: 'Document: ' + docUrl,
      },
      // start: new Date(),
      // end: new Date(),
    });
  };
  const columns = getColumns({
    onView: handleView,
    onEdit: handleEdit,
    onTask: handleTask,
    navigate,
    selectedLocationIds,
    allGroups,
  }).filter((column) => !column.hidden);

  const data = documents;

  useEffect(() => {
    const fetchData = async () => {
      const res = await getDocuments({
        page: 1,
        selectedLocationIds,
        folder: allGroups ? '' : folder == 'All Folders' ? '' : folder,
      });
      setGroups(getMenusByItemType(menusData, 'repository folder'));
      setDocuments(res.result);
    };

    // Call the async function
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLocationIds, folder]);

  return (
    <>
      <PageWrapper className={classes.wrapper}>
        {loading && <Loading isPage />}
        <Breadcrumb items={breadcrumbs} />
        <PageTitle subTitle={<span>List of repository documents</span>}>
          Repository
        </PageTitle>
        <GenericContent>
          {/* <GenericActionButtons /> */}
          {/* {JSON.stringify(selectedLocationIds)} */}
          <table>
            <tr>
              {/* {allGroups && (
                <td className={classes.groups}>
                  <div
                    className={classes.group}
                    style={{ backgroundColor: '#ddd' }}
                  >
                    <span className={classes.checkbox}>
                      <Checkbox checked />
                    </span>{' '}
                    All Groups
                  </div>
                  {allGroups &&
                    groups.map((g, i) => (
                      <div className={classes.group} key={i}>
                        <span className={classes.checkbox}>
                          <Checkbox />
                        </span>
                        {g.name}
                      </div>
                    ))}
                </td>
              )} */}
              <td>
                <GenericTable
                  onAdd={() => {
                    navigate('/repository/create', {
                      state: { folder },
                    });
                  }}
                  columns={columns}
                  data={data || []}
                  title={folder}
                  hideCheckboxes
                />
              </td>
            </tr>
          </table>
        </GenericContent>
      </PageWrapper>
      <ToastAlert toastRef={notificationAlertRef} />
      {!!showTaskDialog && (
        <TaskDialog
          {...showTaskDialog}
          onClose={() => setShowTaskDialog(false)}
          formType={'add'}
        />
      )}
    </>
  );
};

export default Repository;
