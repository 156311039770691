import { combineReducers } from 'redux';
import AuthReducer from './auth';
import ExecutionReducer from './execution';
import LocationReducer from './location';
import MenuReducer from './menu';
import RegionReducer from './region';
import ResourceReducer from './resource';
import SettingReducer from './setting';
import SharedReducer from './shared';
import TasksReducer from './tasks';
import WorkflowReducer from './workflow';

const reducers = {
  auth: AuthReducer,
  setting: SettingReducer,
  location: LocationReducer,
  region: RegionReducer,
  workflow: WorkflowReducer,
  execution: ExecutionReducer,
  menu: MenuReducer,
  tasks: TasksReducer,
  shared: SharedReducer,
  resource: ResourceReducer,
};

const combinedReducer = combineReducers(reducers);

export default combinedReducer;
