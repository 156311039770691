import cn from 'classnames';
import { ReactComponent as Edit2Svg } from 'src/assets/icons/edit-2.svg';
import { ReactComponent as TrashSvg } from 'src/assets/icons/trash.svg';
import Icon from 'src/components/Icon';
import { QUICK_ACCESS_TYPE_LABELS } from 'src/helper/constants/quickAccess';
import classes from './Block.module.scss';

const Block = ({ className, onEdit, data, onRemove }) => {
  return (
    <div className={cn(classes.wrapper, className)}>
      <div className={classes.header}>
        <span className={classes.title}>{data?.name}</span>
        <div>
          <Icon
            icon={<Edit2Svg />}
            activeIcon={<Edit2Svg />}
            size={18}
            onClick={onEdit}
          />
          {!!onRemove && (
            <Icon
              icon={<TrashSvg />}
              activeIcon={<TrashSvg />}
              size={18}
              onClick={onRemove}
            />
          )}
        </div>
      </div>
      <div className={classes.content}>
        <div>
          <span>Item Type</span>
          <span>
            {QUICK_ACCESS_TYPE_LABELS[data?.type] || data?.type || ''}
          </span>
        </div>
        <div>
          <span>Description</span>
          <span className={classes.descriptionField}>
            {data?.description || '-'}
          </span>
        </div>
      </div>
    </div>
  );
};

export default Block;
