export const getUpdatedSettings = (data = [], key, value) => {
  return data.map((obj) => {
    if (obj.key === key) {
      return {
        key,
        value,
      };
    }
    return obj;
  });
};
export const getReducerMoreData = ({ meta, prevState, payload, key }) => {
  return meta?.payload?.isMore
    ? {
        ...prevState[key].data,
        ...payload,
        data: [...prevState[key].data.data, ...payload.data],
      }
    : payload;
};
