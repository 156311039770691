import { ReactComponent as WarningSvg } from 'src/assets/icons/warning-2.svg';
import Icon from 'src/components/Icon';
import {
  ADD_STAFF_SUCCESS_PARAM_KEY,
  ARCHIVE_SUCCESS_PARAM_KEY,
  CALLBACK_ACTION_PARAM_KEY,
  CREATE_SUCCESS_PARAM_KEY,
  EDIT_SUCCESS_PARAM_KEY,
  tabParams,
} from 'src/helper/constants/routes';
import { RESOURCE_DATA_KEY } from 'src/helper/constants/store';
import classes from './LocationDetails.module.scss';
import TabContent from './components/TabContent';

export const LOCATION_DETAILS_TAB_KEYS = {
  SUB_SYSTEMS: 'SUB_SYSTEMS',
  EQUIPMENT: 'EQUIPMENT',
  EMERGENCY: 'EMERGENCY',
  STAFF_LIST: 'STAFF_LIST',
  CHEMICALS: 'CHEMICALS',
};
export const LOCATION_DETAILS_TAB_SUB_KEYS = {
  CONTACTS: 'CONTACTS',
  PROCEDURES: 'PROCEDURES',
  EVACUATION_PLANS: 'EVACUATION_PLANS',
};
export const LOCATION_DETAILS_ENTITY_MESSAGE = {
  [RESOURCE_DATA_KEY.EMERGENCY_CONTACT]: 'Staff',
  [RESOURCE_DATA_KEY.LOCATION_CHEMICALS]: 'Chemical',
  [RESOURCE_DATA_KEY.LOCATION_STAFF]: 'Contact',
  [RESOURCE_DATA_KEY.LOCATION_SUB_SYSTEM]: 'Sub-System',
  [RESOURCE_DATA_KEY.LOCATION_EQUIPMENT]: 'Equipment',
};
export const LOCATION_DETAILS_TAB_ENTITY_MESSAGE = {
  [LOCATION_DETAILS_TAB_KEYS.SUB_SYSTEMS]: 'Sub-System',
  [LOCATION_DETAILS_TAB_KEYS.EQUIPMENT]: 'Equipment',
  [LOCATION_DETAILS_TAB_KEYS.CHEMICALS]: 'Chemicals',
  [LOCATION_DETAILS_TAB_KEYS.STAFF_LIST]: 'Staff',
};
export const LOCATION_DETAILS_TABS = [
  {
    key: LOCATION_DETAILS_TAB_KEYS.SUB_SYSTEMS,
    title: 'Sub-Systems',
  },
  {
    key: LOCATION_DETAILS_TAB_KEYS.EQUIPMENT,
    title: 'Equipment',
  },
  {
    key: LOCATION_DETAILS_TAB_KEYS.EMERGENCY,
    title: 'Emergency',
  },
  {
    key: LOCATION_DETAILS_TAB_KEYS.STAFF_LIST,
    title: 'Staff List',
  },
  {
    key: LOCATION_DETAILS_TAB_KEYS.CHEMICALS,
    title: 'Chemicals',
  },
];
export const getContactActionMessage = (isEdit, isSuccess) => {
  return [
    isSuccess ? 'success' : 'error',
    isSuccess ? 'Success!' : 'Error!',
    isSuccess
      ? `Contact ${isEdit ? 'updated' : 'added'} successfully`
      : `${isEdit ? 'Update' : 'Add'} contact failed`,
  ];
};
export const getDeleteActionMessage = (type, isSuccess) => {
  return [
    isSuccess ? 'success' : 'error',
    isSuccess ? 'Success!' : 'Error!',
    isSuccess
      ? `${LOCATION_DETAILS_ENTITY_MESSAGE[type]} removed successfully`
      : `Remove ${LOCATION_DETAILS_ENTITY_MESSAGE[type]} failed`,
  ];
};
export const getAddPlanMessage = (isSuccess) => {
  return [
    isSuccess ? 'success' : 'error',
    isSuccess ? 'Success!' : 'Error!',
    isSuccess ? 'Documents added successfully' : 'Add documents failed',
  ];
};
export const getArchiveDocumentMessage = () => {
  return ['success', 'Success!', 'Document archived successfully'];
};
export const getLocationDetailsToastMessage = (actionParam, activeTab) => {
  let message = 'Staff added successfully';
  if (actionParam !== ADD_STAFF_SUCCESS_PARAM_KEY) {
    message = `${LOCATION_DETAILS_TAB_ENTITY_MESSAGE[activeTab]} ${
      actionParam === CREATE_SUCCESS_PARAM_KEY ? 'created' : 'edited'
    } successfully`;
  }
  return ['success', 'Success!', message];
};
export const getDeleteContactObj = (id) => {
  return {
    title: 'Remove Emergency Contact',
    description:
      'Are you sure you want to remove this emergency contact? This action cannot be undone.',
    submitText: 'Remove Contact',
    id,
    type: RESOURCE_DATA_KEY.EMERGENCY_CONTACT,
  };
};
export const getDeleteStaffObj = (id) => {
  return {
    title: 'Remove Staff from Location',
    description:
      'Please confirm that you want to remove this Staff member from this location. They can be added back at anytime.',
    submitText: 'Remove Staff',
    id,
    type: RESOURCE_DATA_KEY.LOCATIONS,
  };
};
export const getDeleteChemicalObj = (id) => {
  return {
    title: 'Remove Chemical from Location',
    description:
      'Please confirm that you want to remove this Chemical member from this location. It can be added back at anytime.',
    submitText: 'Remove Chemical',
    id,
    type: RESOURCE_DATA_KEY.LOCATION_CHEMICALS,
  };
};
export const getDeleteSubSystemObj = (id) => {
  return {
    title: 'Remove Sub-System from Location',
    description:
      'Please confirm that you want to remove this sub-system member from this location. It can be added back at anytime.',
    submitText: 'Remove Sub-System',
    id,
    type: RESOURCE_DATA_KEY.LOCATION_SUB_SYSTEM,
  };
};
export const getDeleteEquipmentObj = (id) => {
  return {
    title: 'Remove Equipment from Location',
    description:
      'Please confirm that you want to remove this equipment member from this location. It can be added back at anytime.',
    submitText: 'Remove Equipment',
    id,
    type: RESOURCE_DATA_KEY.LOCATION_EQUIPMENT,
  };
};
export const getTabItems = (isEmergencyWarning, tabProps) => {
  return LOCATION_DETAILS_TABS.map((obj) => {
    return {
      key: obj.key,
      title: (
        <>
          {obj.title}
          {obj.key === LOCATION_DETAILS_TAB_KEYS.EMERGENCY &&
            isEmergencyWarning && (
              <Icon
                icon={<WarningSvg />}
                activeIcon={<WarningSvg />}
                size={20}
                className={classes.warningIcon}
              />
            )}
        </>
      ),
      content: <TabContent tabKey={obj.key} {...tabProps} />,
    };
  });
};
export const LOCATION_LIST_DEFAULT_FILTERS = {
  search: '',
  sort: {
    field: '',
    method: '',
  },
  filters_id: [],
  page: 1,
  size: 10,
};
export const LOCATION_EMERGENCY_DEFAULT_FILTERS = {
  page: 1,
  size: 4,
  sort: {
    field: 'created_at',
    method: 'desc',
  },
};
const texts = [
  'NH3 Charging',
  'SRV Replacement',
  'Ammonia Sensor Calibration',
  'Line Break and Link to Permits',
  'Underfloor Heat',
  'Ice Management',
];
export const LOCATION_QUICK_ACCESS_ITEMS = [
  {
    id: 1,
    label: 'Task Procedures',
    count: 6,
    countType: 'info',
    items: [
      {
        id: 11,
        // label: 'Task Procedures',
        items: texts.map((text, i) => ({
          id: `11${i}`,
          label: text,
          type: 'info',
          url: '/regions',
        })),
      },
    ],
  },
  {
    id: 2,
    label: 'Process Safety',
    count: 6,
    countType: 'warning',
    items: [
      {
        id: 22,
        items: texts.map((text, i) => ({
          id: `22${i}`,
          label: text,
          type: 'warning',
          url: '/regions',
        })),
      },
    ],
  },
  {
    id: 3,
    label: 'Trainings',
    count: 6,
    countType: 'error',
    items: [
      {
        id: 33,
        items: texts.map((text, i) => ({
          id: `33${i}`,
          label: text,
          type: 'error',
          url: '/regions',
        })),
      },
    ],
  },
];
export const LOCATION_DETAILS_DELETE_OBJ_MAPPING = {
  [LOCATION_DETAILS_TAB_KEYS.CONTACTS]: getDeleteContactObj,
  [LOCATION_DETAILS_TAB_KEYS.STAFF_LIST]: getDeleteStaffObj,
  [LOCATION_DETAILS_TAB_KEYS.CHEMICALS]: getDeleteChemicalObj,
  [LOCATION_DETAILS_TAB_KEYS.SUB_SYSTEMS]: getDeleteSubSystemObj,
  [LOCATION_DETAILS_TAB_KEYS.EQUIPMENT]: getDeleteEquipmentObj,
};
export const LOCATION_DETAILS_ADD_LIST_LINK_MAPPING = {
  [LOCATION_DETAILS_TAB_KEYS.EQUIPMENT]: '/equipment/create',
  [LOCATION_DETAILS_TAB_KEYS.STAFF_LIST]: '/add-staff',
  [LOCATION_DETAILS_TAB_KEYS.CHEMICALS]: '/chemical/create',
  [LOCATION_DETAILS_TAB_KEYS.SUB_SYSTEMS]: '/sub-system/create',
};
export const LOCATION_DETAILS_EDIT_LIST_LINK_MAPPING = {
  [LOCATION_DETAILS_TAB_KEYS.EQUIPMENT]: '/equipment/create',
  [LOCATION_DETAILS_TAB_KEYS.STAFF_LIST]: '/staff/create',
  [LOCATION_DETAILS_TAB_KEYS.CHEMICALS]: '/chemical/create',
  [LOCATION_DETAILS_TAB_KEYS.SUB_SYSTEMS]: '/sub-system/create',
};
