import cn from 'classnames';
import Tabs from 'src/components/Tabs';
import Tag from '../Tag';
import classes from './QuickAccess.module.scss';

// Structure: Tab => Section => Item
// Tab has id, label, count, countType, items (sections)
// Section has id, label, items (items)
// Item has id, label, type
// Type is 'info', 'warning', 'error'

const QuickAccess = ({
  className,
  title,
  warningText,
  errorText,
  showIncomplete,
  toggleShowIncomplete,
  items,
  activeTab,
  onTabChange,
}) => {
  return (
    <div className={cn(classes.wrapper, className)}>
      <div className={classes.title}>
        <span className={classes.text}>{title}</span>
        {!!warningText && (
          <Tag variant="dark_warning" weight="lg">
            {warningText}
          </Tag>
        )}
        {!!errorText && (
          <Tag variant="error" weight="lg">
            {errorText}
          </Tag>
        )}
      </div>
      {!!items?.length && (
        <Tabs
          items={items}
          className={classes.tabs}
          activeTab={activeTab}
          onChange={(val) => {
            onTabChange(val);
          }}
          headerFullWidth
          headerSize="sm"
          headerExtra={
            <span className={classes.tabExtraInner}>
              <Tag
                outline
                variant={showIncomplete ? 'dark_gray' : 'gray'}
                radius="sm"
                weight={showIncomplete ? 'lg' : ''}
                onClick={toggleShowIncomplete}
              >
                Show Incomplete
              </Tag>
            </span>
          }
        />
      )}
    </div>
  );
};

export default QuickAccess;
