// eslint-disable-next-line no-unused-vars
import cn from 'classnames';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Breadcrumb from 'src/components/Breadcrumb';
import Button from 'src/components/Button';
import PageWrapper from 'src/components/Page/PageWrapper';
import Tabs from 'src/components/Tabs';
import useSidebarMenus from 'src/helper/hooks/useSidebarMenus';
import classes from './Dashboard.module.scss';

const Segment = ({ segment }) => {
  const [activeTab, setActiveTab] = useState(segment?.views?.[0].name);
  const navigate = useNavigate();

  const items = (segment?.views || []).map((v) => ({
    key: v.name,
    title: v.name,
    content: (
      <>
        {v.views.map((vv, i) => (
          <div className={classes.previewBox} key={i}>
            {vv.name}
            <hr />
            <Button
              className={'float-right'}
              variant="gray"
              type="button"
              fontSize="sm"
              weight="md"
              onClick={() => {
                navigate(vv.path.replace('executions', 'repository'));
              }}
            >
              Documents
            </Button>{' '}
            <Button
              className={'float-right'}
              variant="gray"
              type="button"
              fontSize="sm"
              weight="md"
              onClick={() => {
                navigate(vv.path.replace('executions', 'repository-audits'));
              }}
            >
              Audits
            </Button>{' '}
            <Button
              className={'float-right'}
              variant="gray"
              type="button"
              fontSize="sm"
              weight="md"
              onClick={() => {
                navigate(vv.path.replace('executions', 'workflows/edit'));
              }}
            >
              Manage
            </Button>
          </div>
        ))}
      </>
    ),
  }));

  return (
    <div>
      <h2>{segment?.name}</h2>
      <Tabs
        items={items}
        activeTab={activeTab}
        onChange={(val) => {
          setActiveTab(val);
        }}
        headerFullWidth
        headerSize="sm"
      ></Tabs>
      {/* {segment.views.map(v=><div>{v.name}</div>)} */}
      {/* {JSON.stringify(Object.keys(segment))} */}
    </div>
  );
};

export default Segment;
