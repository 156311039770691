import omit from 'lodash/omit';
import { DEFAULT_LOCATION } from 'src/helper/constants';
import {
  ADD_STAFF_SUCCESS_PARAM_KEY,
  ARCHIVE_SUCCESS_PARAM_KEY,
  CALLBACK_ACTION_PARAM_KEY,
  CREATE_SUCCESS_PARAM_KEY,
  EDIT_SUCCESS_PARAM_KEY,
  tabParams,
} from 'src/helper/constants/routes';
import { getFullAddress } from 'src/utils/address';
import { getRemovedParamFromUrl } from 'src/utils/routes';
import { getRealWebsiteUrl } from 'src/utils/website';
import {
  getArchiveDocumentMessage,
  getLocationDetailsToastMessage,
} from './constants';

export const getNewContactBR = (values) => {
  return {
    ...omit(values, ['favorite', 'id']),
    favourite: !!values.favorite,
    website: getRealWebsiteUrl(values.website) || null,
  };
};
export const getNewPlanBR = (values, tFile) => {
  const tData = new FormData();
  tData.append('document_type', values.type);
  tData.append('location_id', values.locationId);
  tData.append('file', tFile);
  return tData;
};
export const getListFiltersParams = (values, locationId, filterNamMapping) => {
  const returnObj = {
    search: values?.search || '',
    page: values?.page || 1,
    per_page: values?.size || 10,
    filters: {
      location_id: locationId,
    },
  };
  const filteredRoles = (values?.filters_id || []).filter(
    (v) => !!v && typeof v === 'number'
  );
  const filteredStatuses = (values?.filters_id || []).filter(
    (v) => !!v && typeof v === 'string'
  );
  if (filteredRoles?.length > 0) {
    returnObj.filters.role_ids = filteredRoles;
  }
  if (filteredStatuses?.length > 0) {
    returnObj.filters[filterNamMapping?.[`statuses`] || 'statuses'] =
      filteredStatuses;
  }
  if (!!values?.sort?.field && !!values?.sort?.method) {
    returnObj.sort_by = values.sort.field;
    returnObj.sort_order = values.sort.method;
  }
  return returnObj;
};
export const showToast = (
  urlParams,
  notify,
  onNavigate,
  fullPathname,
  activeTab
) => {
  const actionParam = urlParams?.[CALLBACK_ACTION_PARAM_KEY];
  if (
    !!actionParam &&
    [
      ARCHIVE_SUCCESS_PARAM_KEY,
      ADD_STAFF_SUCCESS_PARAM_KEY,
      CREATE_SUCCESS_PARAM_KEY,
      EDIT_SUCCESS_PARAM_KEY,
    ].includes(actionParam)
  ) {
    if (actionParam === ARCHIVE_SUCCESS_PARAM_KEY) {
      notify(...getArchiveDocumentMessage());
    } else {
      notify(...getLocationDetailsToastMessage(actionParam, activeTab));
    }
    onNavigate(
      getRemovedParamFromUrl(fullPathname, [CALLBACK_ACTION_PARAM_KEY])
    );
  }
};
export const updateMarker = (res, setMarkers) => {
  const tMarket = {
    id: 2,
    name: '',
  };
  if (res) {
    const tAddress = getFullAddress(res?.data?.address);
    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ address: tAddress }, (results, status) => {
      if (status === 'OK' && results[0]) {
        const location = results[0].geometry.location;
        tMarket.name = tAddress;
        tMarket.position = { lat: location.lat(), lng: location.lng() };
      } else {
        tMarket.position = { ...DEFAULT_LOCATION };
        console.error('Geocode error:', status);
      }
      setMarkers([tMarket]);
    });
  } else {
    tMarket.position = { ...DEFAULT_LOCATION };
    setMarkers([tMarket]);
  }
};
