import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import * as yup from 'yup';
import Breadcrumb from 'src/components/Breadcrumb';
import Button from 'src/components/Button';
import FormGroup from 'src/components/FormFields/FormGroup';
import FormInput from 'src/components/FormFields/Input/FormInput';
import Loading from 'src/components/Loading';
import ToastAlert from 'src/components/ToastAlert';
import { VIEW_PARAM_KEY, isUrlAction } from 'src/helper/constants/routes';
import { RESOURCE_DATA_KEY } from 'src/helper/constants/store';
import useOwnNavigate from 'src/helper/hooks/useOwnNavigate';
import useResourceActions from 'src/helper/hooks/useResourceActions';
import Field from 'src/pages/GenericCreate/components/Field';
import GenericCreateFooter from 'src/pages/GenericCreate/components/Footer';
import GenericCreateTitle from 'src/pages/GenericCreate/components/Title';
import GenericCreateWrapper from 'src/pages/GenericCreate/components/Wrapper';
import { notifyPrimary } from 'src/utils/notification';
import { getAllUrlParams } from 'src/utils/routes';
import { useActions, useIndexData } from './selectorData';
import { getCreateCompanyPayload } from './utils';

const formSchema = yup.object().shape({
  name: yup.string().required('Name is required!'),
  email: yup
    .string()
    .required('Email is required!')
    .email('Email is not valid!'),
});

const CompaniesCreate = () => {
  const { onCreateResource, onEditResource } = useResourceActions();
  const { getResourceDetails } = useActions();
  const { companyDetails } = useIndexData();
  const location = useLocation();
  const urlParams = getAllUrlParams(location?.search);
  const { onNavigate } = useOwnNavigate();
  const companyId = Number(urlParams.id || 0);
  const isEdit = !!companyId;
  const isView = isUrlAction(urlParams?.[VIEW_PARAM_KEY]) && isEdit;
  const notificationAlertRef = useRef(null);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [detailsLoading, setDetailsLoading] = useState(false);
  const getTitle = () => {
    if (isView) return `View Details - ${companyDetails?.name}`;
    return isEdit ? `Edit Company - ${companyDetails?.name}` : 'Create Company';
  };
  const getSubTitle = () => {
    return (
      <>
        Lorem Ipsum is simply dummy text of the printing and typesetting
        industry. Lorem Ipsum
        <br />
        has been the industry's standard dummy text ever since the 1500s, when
        an unknown printer
        <br />
        took a galley of type and scrambled it to make a type specimen book.
      </>
    );
  };
  const getBreadcrumbText = () => {
    if (isView) return 'Company View Details';
    return isEdit ? 'Company Edit' : 'Company Create';
  };
  const breadcrumbs = [
    {
      link: '/admin/companies',
      label: 'Companies',
    },
    {
      label: getBreadcrumbText(),
    },
  ];
  const { handleSubmit, control, watch, setValue, formState, reset } = useForm({
    resolver: yupResolver(formSchema),
    defaultValues: {
      name: '',
      phone_number: '',
      address: '',
      email: '',
      website: '',
      logo: '',
    },
  });
  const notify = (type, title, description) => {
    notifyPrimary({ ref: notificationAlertRef, type, title, description });
  };
  const reloadCompanyDetails = (cb) => {
    setDetailsLoading(true);
    getResourceDetails(RESOURCE_DATA_KEY.COMPANIES, companyId, (res) => {
      setDetailsLoading(false);
      if (cb) cb(res);
    });
  };
  const onSubmit = async (values) => {
    if (!submitLoading) {
      setSubmitLoading(true);
      const tPayload = getCreateCompanyPayload(values);
      let res = null;
      if (companyId) {
        res = await onEditResource(
          RESOURCE_DATA_KEY.COMPANIES,
          companyId,
          tPayload
        );
      } else {
        res = await onCreateResource(RESOURCE_DATA_KEY.COMPANIES, tPayload);
      }
      setSubmitLoading(false);
      if (res.isSuccess) {
        onNavigate('/admin/companies');
      } else {
        notify(
          'error',
          'Error',
          `${companyId ? 'Edit' : 'Create'} Company failed!`
        );
      }
    }
  };

  useEffect(() => {
    if (companyId) {
      reloadCompanyDetails((res) => {
        if (!isView && !!isEdit) {
          const editObj = res?.data;
          if (editObj?.id) {
            reset({
              name: editObj.name,
              phone_number: editObj.phone_number || '',
              address: editObj.address || '',
              email: editObj.email || '',
              website: editObj.website || '',
              logo: editObj.logo || '',
            });
          }
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyId]);

  return (
    <>
      <GenericCreateWrapper>
        {detailsLoading && <Loading isPage />}
        <Breadcrumb items={breadcrumbs} />
        <GenericCreateTitle subTitle={getSubTitle()}>
          <span>{getTitle()}</span>
        </GenericCreateTitle>
        <form role="form" className="s-form" onSubmit={handleSubmit(onSubmit)}>
          <div className="s-form-inner">
            {isView ? (
              <>
                <Field label="Name" value={companyDetails?.name || '-'} />
                <Field label="Email" value={companyDetails?.email || '-'} />
                <Field
                  label="Phone Number"
                  value={companyDetails?.phone_number || '-'}
                />
                <Field label="Address" value={companyDetails?.address || '-'} />
                <Field label="Website" value={companyDetails?.website || '-'} />
                <Field label="Logo" value={companyDetails?.logo || '-'} />
              </>
            ) : (
              <>
                <FormGroup>
                  <FormInput
                    required
                    label="Name"
                    name="name"
                    placeholder="Enter name"
                    control={control}
                    radius="md"
                    variant="primary"
                    fontSize="sm"
                  />
                </FormGroup>
                <FormGroup>
                  <FormInput
                    label="Email"
                    name="email"
                    placeholder="Enter email"
                    control={control}
                    radius="md"
                    variant="primary"
                    fontSize="sm"
                  />
                </FormGroup>
                <FormGroup>
                  <FormInput
                    label="Phone Number"
                    name="phone_number"
                    placeholder="Enter phone number"
                    control={control}
                    radius="md"
                    variant="primary"
                    fontSize="sm"
                  />
                </FormGroup>
                <FormGroup>
                  <FormInput
                    label="Address"
                    name="address"
                    placeholder="Enter address"
                    control={control}
                    radius="md"
                    variant="primary"
                    fontSize="sm"
                  />
                </FormGroup>
                <FormGroup>
                  <FormInput
                    label="Website"
                    name="website"
                    placeholder="Enter website"
                    control={control}
                    radius="md"
                    variant="primary"
                    fontSize="sm"
                  />
                </FormGroup>
                <FormGroup>
                  <FormInput
                    label="Logo"
                    name="logo"
                    placeholder="Enter logo"
                    control={control}
                    radius="md"
                    variant="primary"
                    fontSize="sm"
                  />
                </FormGroup>
              </>
            )}
          </div>
          {!isView && (
            <GenericCreateFooter>
              <Button
                variant="primary"
                isLink
                type="button"
                onClick={() => {
                  onNavigate('/admin/companies');
                }}
              >
                Cancel
              </Button>
              <Button
                variant="primary"
                type="submit"
                disabled={!formState.isValid || !formState.isDirty}
                loading={submitLoading}
              >
                {isEdit ? 'Save Changes' : 'Create'}
              </Button>
            </GenericCreateFooter>
          )}
        </form>
      </GenericCreateWrapper>
      <ToastAlert toastRef={notificationAlertRef} />
    </>
  );
};

export default CompaniesCreate;
