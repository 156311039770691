import CreateLocationSubSystem from 'src/pages/SubSystem/Create';

const subSystemRoutes = [
  {
    path: '/locations/:locationId/sub-system/create',
    name: 'Create Location Sub-System',
    icon: '',
    iconActive: '',
    component: CreateLocationSubSystem,
    layout: 'private',
  },
];

export default subSystemRoutes;
