import * as yup from 'yup';
import { INPUT_HIGHLIGHT_ERROR_MESSAGE } from 'src/helper/constants';

export const DEFAULT_EDIT_OBJ = {
  name: 'Americold Logistics Nampa',
  description: `An Americold refrigeration facility specializes in temperature-controlled storage and logistics, supporting the distribution of perishable goods efficiently and reliably.`,
  overview: `An Americold refrigeration facility is a state-of-the-art hub designed for temperature-controlled storage and logistics, serving industries that rely on efficient handling of perishable goods, such as food, pharmaceuticals, and other temperature-sensitive products. These facilities are strategically located near transportation networks—such as highways, railways, and ports—to streamline supply chain operations and reduce transit times. Their infrastructure is tailored to maintain precise temperature ranges, from freezing to chilled, ensuring product quality and compliance with safety standards.`,
};

export const formSchema = yup.object().shape({
  name: yup.string().required(INPUT_HIGHLIGHT_ERROR_MESSAGE),
  description: yup.string().required(INPUT_HIGHLIGHT_ERROR_MESSAGE),
  system_overview: yup.string().required(INPUT_HIGHLIGHT_ERROR_MESSAGE),
  address: yup.string().required(INPUT_HIGHLIGHT_ERROR_MESSAGE),
  region_id: yup.mixed().required(INPUT_HIGHLIGHT_ERROR_MESSAGE),
});
