import cn from 'classnames';
import findIndex from 'lodash/findIndex';
import get from 'lodash/get';
import uniqBy from 'lodash/uniqBy';
import { useEffect, useRef } from 'react';
import { components } from 'react-select';
import { ReactComponent as ArrowUpSvg } from 'src/assets/icons/arrow-up1.svg';
import { ReactComponent as TickCircleSvg } from 'src/assets/icons/tick-circle.svg';
import Checkbox from 'src/components/FormFields/Checkbox';
import Icon from 'src/components/Icon';
import { SELECT_CREATE_OPTION } from 'src/helper/constants';
import classes from './Select.module.scss';

const HEADING_LABEL_MAPPING = {
  Location: {
    single: 'location',
    plural: 'locations',
  },
};

export const MultiValueContainer = (props) => {
  const selectedValue = get(props, 'data.value', '');
  const selectedLabel = get(props, 'data.label', '');
  const selectedValues = get(props, 'selectProps.value') || [];
  const multiLabel = get(props, 'selectProps.multiLabel', '');
  const isFirst = findIndex(selectedValues, { value: selectedValue }) === 0;
  const valuesLength = selectedValues.length;
  if (valuesLength === 1) {
    return <span className={classes.multipleValuesLabel}>{selectedLabel}</span>;
  }
  return (
    <span
      className={cn({
        [classes.hide]: !isFirst,
      })}
    >{`${valuesLength} ${multiLabel}`}</span>
  );
};
export const MultiValueContainerLocationGroup = (props) => {
  const selectedValue = get(props, 'data.value', '');
  const selectedValues = get(props, 'selectProps.value') || [];
  const multiLabel = get(props, 'selectProps.multiLabel', '');
  const isFirst = findIndex(selectedValues, { value: selectedValue }) === 0;
  const valuesLength = selectedValues.length;
  return (
    <span
      className={cn({
        [classes.hide]: !isFirst,
      })}
    >{`${valuesLength} ${multiLabel}`}</span>
  );
};
export const SingleValue = ({ children, ...props }) => {
  const { data } = props;
  const { obj = {} } = data;
  const { icon } = obj;
  return (
    <components.SingleValue {...props}>
      {children} {icon || ''}
    </components.SingleValue>
  );
};
export const MenuList = (props) => {
  const { selectProps } = props || {};
  const hasSelectAll = selectProps?.hasSelectAll;
  const menuListRef = useRef(null);
  useEffect(() => {
    if (menuListRef.current) {
      menuListRef.current.querySelector('div').onscroll = () => {
        // TODO: handle onScroll
      };
    }
  }, [menuListRef]);

  return (
    <div className={classes.menuWithCheckbox} ref={menuListRef}>
      <components.MenuList {...props}>
        {hasSelectAll && (
          <div
            className={classes.menuSelectAllWrapper}
            onClick={(e) => {
              if (selectProps?.onCheckedAllChange) {
                selectProps.onCheckedAllChange(!selectProps?.checkedAll);
              }
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            <Checkbox
              variant="primary"
              id="select-select-all-checkbox"
              className={classes.checkbox}
              label={selectProps?.checkedAll ? 'Deselect All' : 'Select All'}
              checked={!!selectProps?.checkedAll}
              onChange={() => {}}
            />
          </div>
        )}
        {props.children}
      </components.MenuList>
    </div>
  );
};
export const OptionSelectedWithCheck = (props) => {
  const { label } = props;
  return (
    <components.Option {...props}>
      {props.isSelected ? <TickCircleSvg /> : ''}
      <span>{label}</span>
    </components.Option>
  );
};
export const CheckboxOption = (props) => {
  const { label } = props;
  const tOption = props.data;
  const tOptions = props.options || [];
  const tOnChange = props?.selectProps?.onChange;
  const optionWithIndex = props?.selectProps?.optionWithIndex;
  const tValues = props?.selectProps?.value || [];
  const tValue = tOption.value;
  const tChecked = tValues.findIndex((obj) => obj.value === tValue) >= 0;
  const tOptionIndex = tOptions.findIndex((obj) => obj.value === tOption.value);
  return (
    <components.Option {...props}>
      <div
        onClick={(e) => {
          if (tOnChange)
            tOnChange(
              !tChecked
                ? uniqBy([...tValues, tOption], 'value')
                : tValues.filter((obj) => tValue !== obj.value)
            );
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        <Checkbox
          variant="primary"
          id={`select-option-${props.innerProps?.id}`}
          className={classes.checkbox}
          label={
            optionWithIndex && typeof label === 'string'
              ? `${tOptionIndex + 1}. ${label}`
              : label
          }
          checked={props.isSelected}
          onChange={() => {}}
          fontSize="md"
        />
      </div>
    </components.Option>
  );
};
export const OptionSelect = (props) => {
  const { label } = props;
  return <components.Option {...props}>{label}</components.Option>;
};
export const LocationGroupHeading = (props) => {
  const multiLabel = props?.selectProps?.multiLabel || '';
  const labelObj = HEADING_LABEL_MAPPING[multiLabel] || {
    single: multiLabel,
    plural: multiLabel,
  };
  const tOptions = props?.data?.options || [];
  const tOnChange = props?.selectProps?.onChange;
  const tValue = props?.selectProps?.value || [];
  const tChecked =
    tValue?.length > 0 &&
    tOptions.every(
      (obj) => tValue.findIndex((obj1) => obj1.value === obj.value) >= 0
    );
  return (
    <components.GroupHeading {...props}>
      <div
        onClick={(e) => {
          if (tOnChange)
            tOnChange(
              !tChecked
                ? uniqBy([...tValue, ...tOptions], 'value')
                : tValue.filter(
                    (obj) =>
                      tOptions.findIndex((obj1) => obj1.value === obj.value) < 0
                  )
            );
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        <Checkbox
          variant="primary"
          id={`select-option-${props.id}`}
          label={
            <>
              <span>{props.children}</span>
              <span>
                ({tOptions.length}{' '}
                {tOptions.length === 1 ? labelObj.single : labelObj.plural})
              </span>
            </>
          }
          checked={tChecked}
          onChange={() => {}}
          fontSize="md"
          className={cn(classes.checkbox, classes.groupHeadingCheckbox)}
        />
      </div>
      <Icon
        icon={<ArrowUpSvg />}
        activeIcon={<ArrowUpSvg />}
        size={20}
        onClick={(e) => {
          const tEl = document.getElementById(props.id);
          if (tEl?.nextElementSibling) {
            if (tEl.nextElementSibling.classList.contains('hide')) {
              tEl.nextElementSibling.classList.remove('hide');
              tEl.classList.remove('react-select-group-heading-down');
            } else {
              tEl.nextElementSibling.classList.add('hide');
              tEl.classList.add('react-select-group-heading-down');
            }
          }
          e.preventDefault();
          e.stopPropagation();
        }}
      />
    </components.GroupHeading>
  );
};
export const customStyles = (props) => {
  const {
    hideArrow,
    isPagination,
    error,
    activeShadow,
    locationGroup,
    size,
    selectorWidth,
  } = props;
  return {
    control: (bases, state) => {
      return {
        ...bases,
        borderColor: error
          ? '#fb6340'
          : state.isFocused
          ? '#000000cc'
          : '#0003',
        boxShadow:
          state.isFocused && activeShadow
            ? '0px 0px 0px 2px #09624233'
            : 'none !important',
        minHeight: size === 'sm' ? '36px' : '44px',
        fontSize: '14px',
        lineHeight: '20px',
        color: '#09090B',
        fontWeight: 400,
        background:
          state.isDisabled && isPagination ? 'transparent' : bases.background,
        '&:hover': {
          borderColor: error
            ? '#fb6340'
            : state.isFocused
            ? '#000000cc'
            : '#0003',
        },
        border: isPagination ? '0' : undefined,
      };
    },
    option: (provided, state) => {
      return {
        ...provided,
        cursor: state.isDisabled ? 'default' : 'pointer',
        background: locationGroup
          ? 'transparent !important'
          : state.isFocused || state.isSelected
          ? '#E6EFE8'
          : undefined,
        fontWeight: state?.data?.value === SELECT_CREATE_OPTION ? 400 : 500,
        color: 'black',
        display: 'flex',
        alignItems: 'center',
        gap: '8px',
        padding: locationGroup ? '8px 32px 10px 32px' : '12px 16px',
        fontSize: '14px',
        '&:hover': {
          background: '#E6EFE8',
        },
      };
    },
    menu: (provided) => {
      return {
        ...provided,
      };
    },
    groupHeading: (provided) => {
      return {
        ...provided,
        padding: locationGroup ? '8px 12px 10px 12px' : undefined,
        marginBottom: locationGroup ? '0px' : undefined,
        display: locationGroup ? 'flex' : undefined,
        alignItems: locationGroup ? 'center' : undefined,
        justifyContent: locationGroup ? 'space-between' : undefined,
        textTransform: 'capitalize',
      };
    },
    menuList: (provided) => {
      return {
        ...provided,
        padding: locationGroup ? '4px 0' : 0,
        '& > div': {
          padding: locationGroup ? '0px' : undefined,
        },
      };
    },
    singleValue: (provided) => {
      return {
        ...provided,
        '& > svg': {
          marginLeft: '6px',
          position: 'relative',
          top: '-2px',
        },
      };
    },
    noOptionsMessage: (bases) => {
      return {
        ...bases,
        '&:before': {
          display: 'none !important',
        },
        '&:after': {
          display: 'none !important',
        },
      };
    },
    clearIndicator: (bases) => {
      return {
        ...bases,
        cursor: 'pointer',
      };
    },
    dropdownIndicator: (bases) => {
      return {
        ...bases,
        display: hideArrow ? 'none' : undefined,
        padding: size === 'sm' ? '6px 8px' : isPagination ? '0' : '8px',
      };
    },
    container: (bases) => {
      return {
        ...bases,
        width: selectorWidth ? `${selectorWidth}px` : undefined,
      };
    },
    indicatorSeparator: () => ({ display: 'none' }),
    menuPortal: (base) => ({ ...base, zIndex: 1060 }),
  };
};
