import { Button } from '@material-ui/core';
import cn from 'classnames';
import isArray from 'lodash/isArray';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ReactComponent as ArrowRight1Svg } from 'src/assets/icons/arrow-right1.svg';
import {
  useActions,
  useActions as useTaskActions,
} from 'src/components/Adjenda/selectorData';
import { useEventContext } from 'src/components/Calendar/contexts/EventContext';
import Tooltip from 'src/components/Tooltip';
import useProfile from 'src/helper/hooks/useProfile';
import { useReducerData } from 'src/store/hooks';
import classes from './Adjenda.module.scss';

const Adjenda = () => {
  // const currentUser = useProfile();
  const userData = useReducerData('auth', 'login.data', []);
  const { startTask } = useTaskActions();
  const navigate = useNavigate();
  const [mode, setMode] = useState('attention');
  const [tasks, setTasks] = useState([]);

  const { events, handleClickOpen } = useEventContext();

  const viewModes = [
    { label: 'Attention required', value: 'attention' },
    { label: 'All mine', value: 'mine' },
    { label: 'All', value: 'all' },
  ];
  const modeTooltip = (
    <div
      style={{ display: 'grid', gridTemplateColumns: '180px 1fr', gap: '1rem' }}
    >
      <b>Attention required:</b> will list all items that need your attention{' '}
      <br />
      <b>All mine:</b> will list all items you are part of <br />
      <b>All:</b> will list all items your role can see <br />
    </div>
  );
  useEffect(() => {
    var _tasks;
    switch (mode) {
      default:
      case 'attention': {
        _tasks = events.filter(
          (task) =>
            (task.flags.indexOf('overdue') > -1 ||
              (moment(task.allowedStartDate) <= new Date() &&
                task.status != 'Completed')) &&
            task.flags.indexOf('hidden') == -1
        );
        break;
      }
      case 'all': {
        _tasks = events;
        break;
      }
      case 'mine': {
        _tasks = events.filter(
          (task) =>
            task.assignedTo.indexOf(userData.id) > -1 ||
            task.createdBy == userData.id
        );
        break;
      }
    }
    _tasks.sort((a, b) => {
      // by start date
      if (a.startDate < b.startDate) return -1;
      if (a.startDate > b.startDate) return 1;
      return 0;
    });
    setTasks(_tasks);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode, events]);

  const startTaskClicked = (task) => {
    startTask({ ...task, startingUserId: userData.id }, (res) => {
      const tExecution = res.result || [];
      navigate(`/workflows/execute/${task.workflow?.slug}/${tExecution._id}`);
    });
  };

  // useEffect(() => {
  //   loadTasks();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [userData.id]);

  return (
    <div className={cn(classes.wrapper)}>
      {/* {JSON.stringify(userData)}xx{userData.id} */}
      <select id="mode" onChange={(e) => setMode(e.target.value)}>
        {viewModes.map((event) => (
          <option key={event.value} value={event.value}>
            {event.label}
          </option>
        ))}
      </select>{' '}
      <Tooltip placement="right" tooltipText={modeTooltip}>
        ℹ️
      </Tooltip>
      <ul>
        {tasks
          .map((task, i) => {
            return (
              <li key={i}>
                {/* {JSON.stringify(task.status)} */}
                <table width="100%" cellPadding={10}>
                  <tr>
                    <td width="200px" style={task.style}>
                      {task.prompt}
                      Start: {moment(task.startDate).format('MM/DD/yyyy')}
                      <br />
                      Status:{' '}
                      {task.status == 'Created'
                        ? task.assignedTo.length > 0
                          ? 'Ready'
                          : 'Awaiting Assignment'
                        : task.status || 'Queued'}
                      {/* {JSON.stringify(task.flags)} */}
                    </td>
                    <td>
                      <b>{task.title || task.workflow.information.taskTitle}</b>
                      <br />
                      Can start at:{' '}
                      {moment(task.allowedStartDate).format('MM/DD/yyyy')}
                      <br />
                      Due date: {moment(task.dueDate).format('MM/DD/yyyy')}
                      <br />
                      {task.description ||
                        task.workflow?.information.taskDescription}
                      {!task.executionId &&
                        !!task.workflow &&
                        (task.assignedTo.length > 0 ? (
                          <Button
                            className="btn-neutral"
                            disabled={
                              moment(task.allowedStartDate) > new Date()
                            }
                            onClick={() => {
                              startTaskClicked(task);
                            }}
                          >
                            START
                          </Button>
                        ) : (
                          <Button
                            className="btn-neutral"
                            onClick={() => {
                              handleClickOpen(task);
                            }}
                          >
                            ASSIGN
                          </Button>
                        ))}
                      {task.executionId && (
                        <Button
                          className="btn-neutral"
                          disabled={moment(task.allowedStartDate) > new Date()}
                          onClick={() => {
                            navigate(
                              `/workflows/execute/${task.workflow.slug}/${task.executionId}`
                            );
                          }}
                        >
                          {task.status == 'Completed' ? 'SHOW' : 'RESUME'}
                        </Button>
                      )}
                      {!task.executionId && !task.workflow && (
                        <Button
                          className="btn-neutral"
                          disabled={moment(task.allowedStartDate) > new Date()}
                          onClick={() => {
                            handleClickOpen(task);
                          }}
                        >
                          OPEN
                        </Button>
                      )}
                      {/* {JSON.stringify(task)} */}
                    </td>
                  </tr>
                </table>
              </li>
            );
          })
          .filter((e) => e)}
      </ul>
    </div>
  );
};

export default Adjenda;
