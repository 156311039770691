import { ReactComponent as OmniFlowsSvg } from 'src/assets/icons/Nav/OmniFlows.svg';
import { ReactComponent as OmniFlowsActiveSvg } from 'src/assets/icons/Nav/OmniFlows_Active.svg';
import OmniFlows from 'src/pages/OmniFlows';
import OmniForms from 'src/pages/OmniFlows/OmniForms';
import QuickAccess from 'src/pages/OmniFlows/QuickAccess';

const omniFlowsRoutes = [
  {
    path: '/omni-flows',
    name: 'Omni Flows',
    component: OmniFlows,
    layout: 'private',
    icon: <OmniFlowsSvg />,
    iconActive: <OmniFlowsActiveSvg />,
  },
  {
    path: '/omni-flows/quick-access',
    name: 'Quick Access',
    icon: '',
    iconActive: '',
    component: QuickAccess,
    layout: 'private',
  },
  {
    path: '/omni-flows/omni-forms',
    name: 'Omni Forms',
    icon: '',
    iconActive: '',
    component: OmniForms,
    layout: 'private',
  },
];

export default omniFlowsRoutes;
