import { useState } from 'react';
import { ReactComponent as OmniSvg } from 'src/assets/icons/omni.svg';
import Card from 'src/components/Card';
import Select from 'src/components/FormFields/Select';
import Map from 'src/components/Map';
import Score from 'src/components/Score';
import ScoreChart from 'src/components/Score/Chart';
import { VIEW_DAYS_OPTIONS } from 'src/helper/constants/option';
import classes from './LocationDetails.module.scss';

const LocationDetailsInfo = ({ markers }) => {
  const [val, setVal] = useState(VIEW_DAYS_OPTIONS[0]);
  return (
    <>
      <div className={classes.overviewWrapper}>
        <Card
          title="Omni Score"
          titleIcon={<OmniSvg />}
          headerRight={
            <Select
              value={val}
              options={VIEW_DAYS_OPTIONS}
              onChange={setVal}
              size="sm"
            />
          }
          contentAlign="vertical"
          variant="primary"
        >
          <Score
            score={90}
            width={280}
            activeColor={'#45B942'}
            issuesColor={'#C1FFC0'}
            color="#EEEEEE"
            title=" "
            className={classes.score}
            issues={5}
          />
          <div className={classes.scoreRight}>
            <ScoreChart
              scores={[
                100, 100, 100, 60, 60, 60, 30, 30, 100, 100, 100, 100, 100, 100,
                100, 100, 100, 100, 100, 100, 60, 60, 60, 30, 30, 100, 100, 100,
                60, 60,
              ]}
            />
            {/* <div>
              <div>
                No Issues in the last <b>4</b> days
              </div>
              <div>
                <span>
                  <b>Nov 17</b> - Ammonia Leak
                </span>
                <span>Details</span>
              </div>
              <div>
                <span>
                  <b>Nov 6</b> - Ammonia Leak
                </span>
                <span>Details</span>
              </div>
              <div>
                <span>
                  <b>Nov 5</b> - Ammonia Leak
                </span>
                <span>Details</span>
              </div>
              <div>
                <span>
                  <b>Nov 4</b> - Ammonia Leak
                </span>
                <span>Details</span>
              </div>
            </div> */}
          </div>
        </Card>
        <Card
          // title="Location"
          // titleIcon={<LocationSvg width={24} height={24} />}
          className={classes.cardMap}
        >
          {!!markers?.length && (
            <Map className={classes.map} markers={markers} loadedZoom={15} />
          )}
        </Card>
      </div>
    </>
  );
};

export default LocationDetailsInfo;
