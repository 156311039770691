// nodejs library that concatenates classes
import classnames from 'classnames';
// nodejs library to set properties for components
import React, { useContext } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Link, NavLink as NavLinkRRD, useLocation } from 'react-router-dom';
import {
  Collapse,
  Nav,
  NavItem,
  NavLink,
  Navbar,
  NavbarBrand,
} from 'reactstrap';
import { ReactComponent as ArrowRightSvg } from 'src/assets/icons/arrow-right.svg';
import Logo from 'src/components/Logo';
import useOwnNavigate from 'src/helper/hooks/useOwnNavigate';
import useCurrentPath from 'src/helper/hooks/userCurrentPath';
import { decodeToCleanUrl } from 'src/utils/routes';
import classes from './Sidebar.module.scss';
import {
  findChildLevelByName,
  getCollapseStates,
  getDefaultActiveCollapseMenu,
} from './utils';

const ACTIVE_ROUTER_MAPPING = {
  '/regions/create-region': '/regions',
  '/regions/edit/:id': '/regions',
  '/regions/:id': '/regions',
  '/locations/create-location': '/locations',
  '/locations/edit/:id': '/locations',
  '/locations/:id': '/locations',
  '/admin/menu-items/create': '/admin/menu-items',
  '/admin/companies/create': '/admin/companies',
  '/admin/roles/create': '/admin/roles',
  '/users/create': '/users',
  '/admin/parts/create': '/admin/parts',
  '/admin/sub-systems/create': '/admin/sub-systems',
  '/omni-flows/omni-forms': '/omni-flows',
  '/omni-flows/quick-access': '/omni-flows',
};

const isMenuCollapsed = () => {
  return document.body.classList.contains('g-sidenav-hidden');
};

function Sidebar({ toggleSidenav, sidenavOpen, routes, logo, rtlActive }) {
  const { onNavigate } = useOwnNavigate();
  const currentPath = useCurrentPath();
  const defaultActiveCollapseMenu = getDefaultActiveCollapseMenu(currentPath);
  const defaultState = {};
  if (defaultActiveCollapseMenu) {
    defaultState[defaultActiveCollapseMenu] = true;
  }
  const [state, setState] = React.useState(defaultState);
  const location = useLocation();
  const pathname = location.pathname;
  const activeRoute = (routeName, tRouteObj) => {
    if (routeName === '/404-not-found') return false;
    if (currentPath === '/' && routeName === '/dashboard') return true;
    return (
      currentPath === routeName ||
      decodeToCleanUrl(pathname) === decodeToCleanUrl(routeName) ||
      ACTIVE_ROUTER_MAPPING[currentPath] === routeName ||
      (tRouteObj?.views || []).some((obj) => obj.path === currentPath) ||
      (tRouteObj?.views || []).some(
        (obj) => obj.path === ACTIVE_ROUTER_MAPPING[currentPath]
      ) ||
      (currentPath === '/repository/*' && routeName === '/repository')
    );
  };
  const closeSidenav = () => {};
  const createLinks = (tRoutes, isSub) => {
    return tRoutes.map((tRoute, key) => {
      const tPath = tRoute.path || (tRoute.collapse ? '' : '/404-not-found');
      const level = findChildLevelByName(routes, tRoute.name) - 1;
      const navLinkStyles = {
        paddingLeft: `${16 - level * (level > 1 ? 2 : 6)}px`,
        marginLeft: `${16 + level * (level > 1 ? 24 : 38)}px`,
      };
      if (tRoute.collapse) {
        var st = {};
        st[tRoute['state']] = !state[tRoute.state];
        if (!tRoute.views?.length) return '';
        return (
          <NavItem key={key}>
            <NavLink
              href={'#'}
              data-toggle="collapse"
              aria-expanded={state[tRoute.state]}
              className={classnames(
                classes.navLink,
                classes.navLinkParent,
                'nav-link-parent',
                {
                  [classes.navLinkParentActive]: activeRoute(tPath, tRoute),
                  'nav-link-parent-active': activeRoute(tPath, tRoute),
                  [classes.isSub]: isSub,
                }
              )}
              onClick={(e) => {
                e.preventDefault();
                if (!tPath && !isMenuCollapsed()) {
                  setState({
                    ...state,
                    ...st,
                  });
                } else if (tPath) {
                  onNavigate(tPath);
                }
              }}
              style={navLinkStyles}
            >
              {tRoute.icon && !isSub ? (
                <>
                  <span className={'nav-icon'}>{tRoute.icon}</span>
                  <span className={'nav-icon-active'}>{tRoute.iconActive}</span>
                  <span className="nav-link-text">{tRoute.name}</span>
                </>
              ) : (
                <span className="sidenav-normal">{tRoute.name}</span>
              )}
              <span
                className={classes.collapseArrow}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setState({
                    ...state,
                    ...st,
                  });
                }}
              ></span>
            </NavLink>
            <Collapse isOpen={state[tRoute.state]}>
              <Nav className="nav-sm flex-column">
                {createLinks(tRoute.views, true)}
              </Nav>
            </Collapse>
          </NavItem>
        );
      }
      return (
        <React.Fragment key={key}>
          {!!tRoute.labelTop && (
            <div
              className={classnames(classes.navLabelTop, 'nav-label-top', {
                [classes.borderTop]: tRoute.borderTop,
              })}
            >
              <span>{tRoute.labelTop}</span>
            </div>
          )}
          <NavItem
            className={classnames(classes.navItem, {
              [classes.navItemActive]: activeRoute(tPath, tRoute),
              [classes.borderTop]: tRoute.borderTop && !tRoute.labelTop,
            })}
          >
            <NavLink
              to={tPath}
              style={navLinkStyles}
              onClick={closeSidenav}
              tag={NavLinkRRD}
              className={classnames(classes.navLink, {
                'no-active': tPath === '/404-not-found',
                [classes.navLinkActive]: activeRoute(tPath, tRoute),
                [classes.isSub]: isSub,
              })}
            >
              {tRoute.icon && !isSub ? (
                <>
                  <span className={'nav-icon'}>{tRoute.icon}</span>
                  <span className={'nav-icon-active'}>{tRoute.iconActive}</span>
                  <span className="nav-link-text">{tRoute.name}</span>
                </>
              ) : (
                <span className="sidenav-normal">{tRoute.name}</span>
              )}
            </NavLink>
          </NavItem>
        </React.Fragment>
      );
    });
  };
  const scrollBarInner = (
    <div className="scrollbar-inner">
      <div className="sidenav-header d-flex align-items-center">
        <NavbarBrand
          className={classes.brandWrapper}
          tag={Link}
          to="/dashboard"
        >
          <span className="navbar-brand-text">
            <Logo />
          </span>
          <span className="navbar-brand-text-mobile">
            <Logo isMini />
          </span>
        </NavbarBrand>
      </div>
      <div className={classnames('navbar-inner', classes.navbarInner)}>
        <Collapse navbar isOpen={true}>
          <Nav navbar>{createLinks(routes)}</Nav>
        </Collapse>
      </div>
    </div>
  );

  React.useEffect(() => {
    setState(getCollapseStates(routes));
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div
        className={classnames('sidenav-toggler', {
          active: sidenavOpen,
        })}
        onClick={toggleSidenav}
      >
        <ArrowRightSvg />
      </div>
      <Navbar
        className={classnames(
          'sidenav navbar-vertical navbar-expand-xs navbar-light bg-white ' +
            (rtlActive ? '' : 'fixed-left'),
          classes.sidebar
        )}
      >
        {/* {navigator.platform.indexOf('Win') > -1 ? (
        <PerfectScrollbar>{scrollBarInner}</PerfectScrollbar>
      ) : (
        scrollBarInner
      )} */}
        {scrollBarInner}
      </Navbar>
    </>
  );
}

export default Sidebar;
