import {
  QUICK_ACCESS_SECTION_ITEMS,
  SECTION_LEVEL_OPTIONS,
} from 'src/helper/constants/quickAccess';

export const QUICK_ACCESS_CATEGORIES = SECTION_LEVEL_OPTIONS.map(
  (obj, index) => {
    return {
      id: obj.value,
      title: obj.label,
      sections: QUICK_ACCESS_SECTION_ITEMS[obj.value],
    };
  }
);
export const breadcrumbs = [
  {
    label: 'Quick Access',
  },
];
