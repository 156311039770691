import cn from 'classnames';
import { useContext, useEffect, useRef, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import Breadcrumb from 'src/components/Breadcrumb';
import Button from 'src/components/Button';
import Dropdown from 'src/components/Dropdown';
import Loading from 'src/components/Loading';
import Modal from 'src/components/Modal';
import PageTitle from 'src/components/Page/PageTitle';
import PageWrapper from 'src/components/Page/PageWrapper';
import Table from 'src/components/Table';
import { MenuContext } from 'src/helper/providers/MenuProvider';
import {
  useActions,
  useIndexData,
} from 'src/pages/Admin/ExecuteWorkflow/selectorData';
import classes from './Tasks.module.scss';
import Task from './index';

const TaskDialog = (props) => {
  const { workflowId, onClose, title } = props;

  return (
    <Modal
      isOpen={true}
      onClose={onClose}
      title={title}
      // hideClose
      centered
      className={classes.confirmModalWrapper}
    >
      <div className={classes.confirmModalContent}>
        <div>
          <Task props={{ ...props }} />
        </div>
      </div>
    </Modal>
  );
};

export default TaskDialog;
