import cn from 'classnames';
import { ReactComponent as MoreSvg } from 'src/assets/icons/more.svg';
import Dropdown from 'src/components/Dropdown';
import classes from './Card.module.scss';
import CardFooter from './Footer';

const Card = ({
  className,
  title,
  titleIcon,
  dropdowns,
  subHeading,
  children,
  footer,
  contentClassName,
  headerRight,
  contentAlign,
  variant,
}) => {
  const hideHeader = !title && !titleIcon && !dropdowns?.length && !headerRight;
  return (
    <div
      className={cn(
        classes.wrapper,
        classes[`content_align_${contentAlign}`],
        classes[`variant_${variant}`],
        className
      )}
    >
      {!hideHeader && (
        <div className={classes.header}>
          <span>
            {titleIcon}
            <span>{title}</span>
          </span>
          {headerRight}
          {dropdowns?.length > 0 && (
            <Dropdown items={dropdowns}>
              <MoreSvg />
            </Dropdown>
          )}
        </div>
      )}
      {!!subHeading && (
        <div className={classes.subHeading}>
          <span>{subHeading}</span>
        </div>
      )}
      <div className={cn(classes.content, contentClassName)}>{children}</div>
      {footer && <CardFooter {...footer} />}
    </div>
  );
};

export default Card;
