import Request from './request';

export const apiUploadAppDocument = async (data) => {
  return Request.call({
    url: '/documents/upload',
    method: 'POST',
    data,
    multipart: true,
  });
};
export const apiGetAppDocument = async (id) => {
  return Request.call({
    url: `/documents/${id}`,
    method: 'GET',
  });
};
export const apiDeleteAppDocument = async (id) => {
  return Request.call({
    url: `/documents/${id}`,
    method: 'DELETE',
    data: {},
  });
};
export const apiDownloadAppDocument = async (id) => {
  return Request.call({
    url: `/documents/${id}/download`,
    method: 'GET',
  });
};
