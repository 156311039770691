import cn from 'classnames';
import { ReactComponent as ArchiveSvg } from 'src/assets/icons/archive.svg';
import { ReactComponent as ArrowLeftSvg } from 'src/assets/icons/arrow-left.svg';
import { ReactComponent as DocumentPreviousSvg } from 'src/assets/icons/document-previous.svg';
import { ReactComponent as ExportSvg } from 'src/assets/icons/export.svg';
import Icon from 'src/components/Icon';
import Tooltip from 'src/components/Tooltip';
import { DOCUMENT_STATUS_VALUES } from 'src/helper/constants/document';
import classes from './Info.module.scss';

export const InfoItem = ({ className, label, value, gap }) => {
  return (
    <div
      className={cn(classes.item, className)}
      style={{ gap: gap || gap === 0 ? `${gap}px` : undefined }}
    >
      <span>{label}</span>
      <span>{value}</span>
    </div>
  );
};
export const InfoRightActions = ({
  onUpload,
  onViewHistory,
  onArchive,
  type,
  onBackVersion,
  isArchivedNotHistory,
}) => {
  return (
    <div
      className={cn(classes.actions, {
        [classes.isSingle]: !!onBackVersion,
      })}
    >
      {onBackVersion ? (
        <>
          <span className={classes.iconWrapper}>
            <Icon
              icon={<ArrowLeftSvg />}
              activeIcon={<ArrowLeftSvg />}
              size={20}
              onClick={onBackVersion}
            />
          </span>
        </>
      ) : (
        <>
          {!isArchivedNotHistory && (
            <Tooltip
              placement="bottom"
              tooltipText="Upload New Version"
              dark
              hasArrow
            >
              <span className={classes.iconWrapper} onClick={onUpload}>
                <Icon
                  icon={<ExportSvg />}
                  activeIcon={<ExportSvg />}
                  size={20}
                />
              </span>
            </Tooltip>
          )}

          <Tooltip
            placement="bottom"
            tooltipText="View Document History"
            dark
            hasArrow
          >
            <span className={classes.iconWrapper} onClick={onViewHistory}>
              <Icon
                icon={<DocumentPreviousSvg />}
                activeIcon={<DocumentPreviousSvg />}
                size={20}
              />
            </span>
          </Tooltip>
          {type === DOCUMENT_STATUS_VALUES.EXPIRED && !isArchivedNotHistory && (
            <Tooltip
              placement="bottom"
              tooltipText="Archive Document"
              dark
              hasArrow
            >
              <span className={classes.iconWrapper} onClick={onArchive}>
                <Icon
                  icon={<ArchiveSvg />}
                  activeIcon={<ArchiveSvg />}
                  size={20}
                />
              </span>
            </Tooltip>
          )}
        </>
      )}
    </div>
  );
};

const Info = ({
  className,
  onArchive,
  onViewHistory,
  onUpload,
  items,
  statusType,
  onBackVersion,
  hideAction,
  isArchivedNotHistory,
}) => {
  return (
    <div className={cn(classes.wrapper, className)}>
      {(items || []).map((item, index) => {
        return <InfoItem label={item.label} value={item.value} key={index} />;
      })}
      {!hideAction && (
        <InfoRightActions
          onUpload={onUpload}
          onViewHistory={onViewHistory}
          onArchive={onArchive}
          type={statusType}
          onBackVersion={onBackVersion}
          isArchivedNotHistory={isArchivedNotHistory}
        />
      )}
    </div>
  );
};

export default Info;
