import { RESOURCE_DATA_KEY } from 'src/helper/constants/store';
import * as locationActions from 'src/store/actions/location';
import * as resourceActions from 'src/store/actions/resource';
import { useReducerData, useStoreActions } from 'src/store/hooks';

export const useIndexData = () => {
  return {
    equipmentDetails: useReducerData(
      'resource',
      `resourceDetails.data.${RESOURCE_DATA_KEY.LOCATION_EQUIPMENT}`,
      {}
    ),
    locationDetails: useReducerData('location', 'locationDetails.data', {}),
  };
};

export const useActions = () => {
  return useStoreActions({
    ...resourceActions,
    ...locationActions,
  });
};
