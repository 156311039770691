import moment from 'moment';

export const getMomentDate = (val, isToday, isResetDay) => {
  if (!val && !isToday) return '';
  if (!val && !!isToday) {
    if (isResetDay) {
      return moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
    }
    return moment();
  }
  if (isResetDay) {
    return moment(val).set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
  }
  return moment(val);
};
export const getFormatDate = (val, fmStr) => {
  if (!val || !fmStr) return '';
  return getMomentDate(val).format(fmStr);
};
export const getBEDate = (val) => {
  if (!val) return '';
  return getMomentDate(val).format('YYYY-MM-DD');
};
export const convertTime = (val) => {
  if (!val) return '';
  const valArr = val.split(' ');
  const time = valArr[0] || '';
  const time1Arr = time.split(':');
  const hour = Number(time1Arr[0] || 0);
  const minute = Number(time1Arr[1] || 0);
  const timeSuffix = (valArr[1] || '').toLowerCase();
  const addTime = timeSuffix === 'pm' ? 12 : 0;
  const momentTime = getMomentDate('', true, true)
    .add(hour + addTime, 'hours')
    .add(minute, 'minutes');
  return momentTime.isValid() ? momentTime.format('HH:mm') : '';
};
export const getTimeWithSuffix = (val) => {
  if (!val) return '';
  const momentTime = moment(`${moment().format('YYYY/MM/DD')} ${val}`);
  return momentTime.format('h:mm A');
};
export const getShortDayWeek = (val) => {
  if (!val) return '';
  return val.slice(0, 3);
};
