import { Background } from '@xyflow/react';
import moment from 'moment';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import * as yup from 'yup';
import Breadcrumb from 'src/components/Breadcrumb';
import Button from 'src/components/Button';
import Loading from 'src/components/Loading';
import PageWrapper from 'src/components/Page/PageWrapper';
import Tabs from 'src/components/Tabs';
import ToastAlert from 'src/components/ToastAlert';
import { INPUT_HIGHLIGHT_ERROR_MESSAGE } from 'src/helper/constants';
import useOwnNavigate from 'src/helper/hooks/useOwnNavigate';
import Flow from 'src/pages/Admin/Workflow/components/Flow';
import FlowRun from 'src/pages/Admin/Workflow/components/FlowRun/FlowRun';
import classes from './CreateWorkflow.module.scss';
import InformationForm from './components/InformationForm';
import SchedulesForm from './components/SchedulesForm';
import { WORKFLOW_TAB_KEYS } from './constants';
import { useActions } from './selectorData';

const CreateWorkflow = () => {
  const params = useParams();
  const workflowId = String(params.id || '');
  const notificationAlertRef = useRef(null);
  const { getWorkflowsDetails, createWorkflow, deleteWorkflow } = useActions();
  const [detailsLoading, setDetailsLoading] = useState(false);
  const [activeTab, setActiveTab] = useState(WORKFLOW_TAB_KEYS.INFORMATION);
  const [workflows, setWorkflows] = useState([]);
  const [roles, setRoles] = useState([]);
  const [workflow, setWorkflow] = useState({
    nodes: [],
    edges: [],
    information: {},
    locations: [],
  });
  const workflowRef = useRef(workflow);
  const { onNavigate } = useOwnNavigate();
  const location = useLocation();
  const [versionId, setVersionId] = useState(location.hash.substring(1));

  useEffect(() => {
    const vid = location.hash.substring(1);
    // Set the hash from location if it changes
    setVersionId(vid);
  }, [location.hash]); // Will trigger on hash changes

  useEffect(() => {
    workflowRef.current = workflow;
  }, [workflow]);

  const informWorkflowChanged = useCallback((nodes, edges) => {
    const currentWorkflow = workflowRef.current;
    var newWorkflow = { ...currentWorkflow, nodes, edges };
    setWorkflow(newWorkflow);
  }, []);

  const informSchedulesChanged = useCallback((schedules) => {
    const currentWorkflow = workflowRef.current;
    var newWorkflow = { ...currentWorkflow, schedules };
    setWorkflow(newWorkflow);
  }, []);

  const workflowInfoChanged = useCallback(
    (key, value) => {
      const currentWorkflow = workflowRef.current;
      if (key == 'locations') {
        let newWorkflow = { ...currentWorkflow };
        newWorkflow.locations = value;
        workflowRef.current = newWorkflow;
        setWorkflow({
          ...newWorkflow,
        });
      } else if (
        JSON.stringify({ ...currentWorkflow.information, [key]: value }) !==
        JSON.stringify({ ...currentWorkflow.information })
      ) {
        let newWorkflow = {
          ...currentWorkflow,
          information: { ...currentWorkflow.information, [key]: value },
        };
        workflowRef.current = newWorkflow;
        setWorkflow({
          ...newWorkflow,
        });
      }
    },
    [workflowRef]
  );

  const breadcrumbs = [
    {
      label: 'Workflows',
    },
    {
      label: 'Edit WorkFlow - ' + workflowId,
    },
  ];
  const INFORMATION_TAB = {
    key: WORKFLOW_TAB_KEYS.INFORMATION,
    title: 'Information',
    content: (
      <>
        <InformationForm
          workflowInfoChanged={workflowInfoChanged}
          workflow={workflow}
        />
      </>
    ),
  };
  const [SCHEDULE_TAB, setSCHEDULE_TAB] = useState({
    key: WORKFLOW_TAB_KEYS.SCHEDULE,
    title: 'Schedule',
    content: (
      <>
        <SchedulesForm
          workflow={workflow}
          roles={roles}
          informSchedulesChanged={informSchedulesChanged}
          workflowInfoChanged={workflowInfoChanged}
        />
      </>
    ),
  });

  const [SCHEMA_TAB, setSCHEMA_TAB] = useState({
    key: WORKFLOW_TAB_KEYS.SCHEMA,
    title: 'Schema',
    content: (
      <>
        <Flow
          informWorkflowChanged={informWorkflowChanged}
          workflow={workflow}
        />
      </>
    ),
  });

  const PREVIEW_TAB = {
    key: WORKFLOW_TAB_KEYS.PREVIEW,
    title: 'Preview',
    content: (
      <>
        <FlowRun nodes={workflow.nodes} edges={workflow.edges} />
      </>
    ),
  };
  const VERSIONS_TAB = {
    key: WORKFLOW_TAB_KEYS.VERSIONS,
    title: 'Versions',
    content: (
      <>
        <span>Versions list</span>
        {/* {JSON.stringify(workflows[0])} */}
        <table border="1" cellPadding={4}>
          <tr style={{ background: '#aaa' }}>
            <th>Updated By</th>
            <th>Status</th>
            <th>Updated At</th>
            <th>Locations</th>
            <th>Actions</th>
          </tr>
          {workflows.map((w) => (
            <tr
              onClick={() => {}}
              style={{ background: w._id === workflow._id ? '#ccc' : '' }}
              key={w._id}
            >
              <td>{w.userName}</td>
              <td>{w.status}</td>
              <td>{moment(w.updatedAt).format('MM/DD/yyyy')}</td>
              <td>
                {w.information.form?.locations.map((l, index) => (
                  <div key={index}>{l.label}</div>
                ))}
              </td>
              <td>
                {w._id !== workflow._id && (
                  <Button
                    style={{ float: 'right', marginRight: '10px' }}
                    className="my-2"
                    color="default"
                    onClick={() => {
                      onNavigate(`#${w._id}`, '', '', { replace: true });
                      setActiveTab(WORKFLOW_TAB_KEYS.INFORMATION);
                    }}
                  >
                    Open
                  </Button>
                )}
                {w.status !== 'live' && w._id !== workflow._id && (
                  <Button
                    className="my-2"
                    color="default"
                    style={{ float: 'right', marginRight: '10px' }}
                    onClick={() => deleteWorkflowDraft(w._id)}
                  >
                    Delete
                  </Button>
                )}
              </td>
            </tr>
          ))}
        </table>
      </>
    ),
  };
  // const APPROVAL_TAB = {
  //   key: WORKFLOW_TAB_KEYS.APPROVAL,
  //   title: 'Approval',
  //   content: (
  //     <>
  //       <span>Approval state</span>
  //     </>
  //   ),
  // };
  const items = [
    INFORMATION_TAB,
    SCHEDULE_TAB,
    SCHEMA_TAB,
    PREVIEW_TAB,
    VERSIONS_TAB,
    // APPROVAL_TAB,
  ];

  useEffect(() => {
    loadWorkflows();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [versionId]);

  const loadWorkflows = () => {
    if (workflowId) {
      setDetailsLoading(true);
      getWorkflowsDetails(workflowId, (res) => {
        const tWorkflows = res.result || [];
        const roles = res.roles || [];
        setWorkflows(tWorkflows);
        setRoles(roles);
        const tWorkflow =
          tWorkflows.length > 0
            ? versionId
              ? tWorkflows.find((w) => w._id === versionId)
              : tWorkflows[0]
            : {};
        if (tWorkflow._id) {
          // tWorkflow.locations = [1,2,3]
          setWorkflow(tWorkflow);
          setSCHEMA_TAB({
            key: WORKFLOW_TAB_KEYS.SCHEMA,
            title: 'Schema',
            content: (
              <>
                <Flow
                  informWorkflowChanged={informWorkflowChanged}
                  workflow={tWorkflow}
                />
              </>
            ),
          });
          setSCHEDULE_TAB({
            key: WORKFLOW_TAB_KEYS.SCHEDULE,
            title: 'Schedule',
            content: (
              <>
                <SchedulesForm
                  workflow={tWorkflow}
                  informSchedulesChanged={informSchedulesChanged}
                  workflowInfoChanged={workflowInfoChanged}
                  roles={roles}
                />
              </>
            ),
          });
        }
        setDetailsLoading(false);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const saveWorkflow = useCallback(
    (status) => {
      if (workflowId) {
        setDetailsLoading(true);
        createWorkflow({ ...workflow, slug: workflowId, status }, (res) => {
          // eslint-disable-next-line no-console
          console.log(res);
          loadWorkflows();
          setDetailsLoading(false);
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [workflow]
  );

  const deleteWorkflowDraft = useCallback(
    (id) => {
      if (workflowId) {
        setDetailsLoading(true);
        deleteWorkflow(id, (res) => {
          loadWorkflows();
          setDetailsLoading(false);
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [workflow]
  );

  return (
    <>
      <PageWrapper className={classes.wrapper}>
        {detailsLoading && <Loading isPage />}
        <Breadcrumb items={breadcrumbs} />
        <Tabs
          items={items}
          activeTab={activeTab}
          onChange={(val) => {
            setActiveTab(val);
          }}
          headerFullWidth
          headerSize="sm"
        />
        {(activeTab == WORKFLOW_TAB_KEYS.INFORMATION ||
          activeTab == WORKFLOW_TAB_KEYS.SCHEMA ||
          activeTab == WORKFLOW_TAB_KEYS.SCHEDULE) && (
          <div className={classes.formFooter}>
            <Button variant="primary" isLink type="button" onClick={() => {}}>
              Cancel
            </Button>
            <Button
              variant="primary"
              type="submit"
              onClick={() => saveWorkflow('draft')}
            >
              Save Draft
            </Button>
            {workflow.status != 'live' && (
              <Button
                variant="primary"
                type="submit"
                onClick={() => saveWorkflow('approval requested')}
              >
                Request Approval
              </Button>
            )}
            {workflow.status != 'live' && (
              <Button
                variant="primary"
                type="submit"
                onClick={() => saveWorkflow('live')}
              >
                Publish
              </Button>
            )}
          </div>
        )}
      </PageWrapper>
      <ToastAlert toastRef={notificationAlertRef} />
    </>
  );
};

export default CreateWorkflow;
