import { useState } from 'react';
import Badge from 'src/components/Badge';
import QuickAccess from 'src/components/QuickAccess';
import AccessItem from 'src/components/QuickAccess/AccessItem';
import AccessSectionLabel from 'src/components/QuickAccess/AccessSectionLabel';
import AccessTabLabel from 'src/components/QuickAccess/AccessTabLabel';
import AccessTabList from 'src/components/QuickAccess/AccessTabList';
import {
  getIsQuickAccessItemWarning,
  getQuickAccessItemsWarningCount,
} from 'src/utils/quickAccess';

const QuickAccessSection = ({ data, locationId }) => {
  const tData = data || [];
  const hide = false;
  const [accessTabKey, setAccessTabKey] = useState(1);
  const [showIncomplete, setShowIncomplete] = useState(false);
  const getLocationQuickAccessUrl = (id) =>
    `/locations/${locationId}/quick-access/${id}`;
  const totalItemsCount = tData.reduce(
    (sum, obj) => sum + (obj.items?.length || 0),
    0
  );
  const totalWarningItemsCount = tData.reduce(
    (sum, obj) => sum + getQuickAccessItemsWarningCount(obj.items),
    0
  );
  const filteredItems = [
    ...tData.map((obj, index) => {
      const warningCount = getQuickAccessItemsWarningCount(obj.items);
      return {
        id: index + 1,
        label: obj.name,
        count: warningCount > 0 ? warningCount : obj.items?.length || 0,
        countType: warningCount > 0 ? 'warning' : 'info',
        items: [
          {
            id: String(`${obj.id}_1`),
            label: '',
            items: (obj.items || []).map((item) => {
              return {
                id: String(item.id),
                label: item.name,
                type: getIsQuickAccessItemWarning(item) ? 'warning' : 'info',
                url: getLocationQuickAccessUrl(item.id),
              };
            }),
          },
        ],
      };
    }),
    {
      id: 'all',
      label: 'All',
      count:
        totalWarningItemsCount > 0 ? totalWarningItemsCount : totalItemsCount,
      countType: totalWarningItemsCount > 0 ? 'warning' : 'info',
      items: tData.map((obj, index) => {
        return {
          id: `all_${obj.id}_${index}`,
          label: obj.name,
          items: (obj.items || []).map((item) => {
            return {
              id: String(item.id),
              label: item.name,
              type: getIsQuickAccessItemWarning(item) ? 'warning' : 'info',
              url: getLocationQuickAccessUrl(item.id),
            };
          }),
        };
      }),
    },
  ];
  const quickAccessItems = filteredItems.map((obj) => {
    return {
      key: obj.id,
      title: (
        <AccessTabLabel>
          <span>{obj.label}</span>
          <Badge type={obj.countType} count={obj.count} />
        </AccessTabLabel>
      ),
      content: (
        <>
          {(obj.items || []).map((item) => {
            return (
              <AccessTabList key={item.id}>
                {item.label && (
                  <AccessSectionLabel>{item.label}</AccessSectionLabel>
                )}
                {!!item.items?.length && (
                  <AccessTabList.Inner>
                    {item.items.map((iItem) => {
                      return <AccessItem key={iItem.id} data={iItem} />;
                    })}
                  </AccessTabList.Inner>
                )}
              </AccessTabList>
            );
          })}
        </>
      ),
    };
  });

  if (hide) return '';
  return (
    <QuickAccess
      title="Quick Access"
      warningText={
        totalWarningItemsCount > 0 &&
        `${totalWarningItemsCount} Item${
          totalWarningItemsCount === 1 ? '' : 's'
        } Need Attention`
      }
      // errorText="1 Error"
      items={quickAccessItems}
      activeTab={accessTabKey}
      onTabChange={setAccessTabKey}
      showIncomplete={showIncomplete}
      toggleShowIncomplete={() => {
        setShowIncomplete(!showIncomplete);
      }}
    />
  );
};

export default QuickAccessSection;
