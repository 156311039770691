import cn from 'classnames';
import React, { useRef, useState } from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import classes from './Tooltip.module.scss';

const Tooltip = (props) => {
  const tooltipRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const {
    children,
    tooltipText,
    className,
    dark,
    light,
    hasArrow,
    flex,
    fontSize,
    overlayClassName,
    placement,
    id,
    ...other
  } = props;
  const isRightTop = placement === 'right-top';
  const tooltipPlacement = isRightTop ? 'right' : placement;

  return (
    <>
      <span
        ref={tooltipRef}
        className={cn(
          classes.wrapper,
          {
            'inline-flex': flex,
          },
          className
        )}
        id={id}
      >
        {children}
      </span>
      <UncontrolledTooltip
        isOpen={isOpen}
        toggle={(e) => {
          setIsOpen(!isOpen);
          setTimeout(() => {
            // override position of tooltip right top
            if (isRightTop) {
              const overlayEl = document.querySelectorAll(
                '.tooltip-overlay-real-placement-right-top'
              )?.[0];
              const el = document.getElementById(id);
              if (overlayEl) {
                const yPosition = el
                  ? el.getBoundingClientRect().top + window.scrollY
                  : e.clientY;
                const transformValues = getComputedStyle(overlayEl).transform;
                const matrix = new DOMMatrix(transformValues);
                overlayEl.style.transform = `translate3d(${matrix.m41}px, ${
                  yPosition - 30
                }px, ${matrix.m43}px)`;
              }
            }
          }, 100);
        }}
        // delay={{
        //   show: 0,
        //   hide: 1000000000,
        // }}
        placement={tooltipPlacement}
        delay={0}
        {...other}
        target={tooltipRef}
        placementPrefix={`${overlayClassName} ${`tooltip-overlay-real-placement-${placement}`} ${`tooltip-overlay-font-size_${fontSize}`} ${`tooltip-overlay-${tooltipPlacement}`} ${
          dark ? 'tooltip-overlay-dark' : light ? 'tooltip-overlay-light' : ''
        } ${hasArrow && 'tooltip-has-arrow'} bs-tooltip`}
      >
        {tooltipText}
      </UncontrolledTooltip>
    </>
  );
};

export default Tooltip;
