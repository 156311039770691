import { useLocation, useNavigate } from 'react-router-dom';
import { getRelativePathWithParams } from 'src/utils/routes';

const useOwnNavigate = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const onNavigate = (path, params, otherState, otherOptions) => {
    navigate(path === -1 ? path : getRelativePathWithParams(path, params), {
      state: {
        fromPath: location.pathname,
        fromSearch: location.search,
        ...(otherState || {}),
      },
      ...(otherOptions || {}),
    });
  };

  const prevPath = [location.state?.fromPath, location.state?.fromSearch]
    .filter((v) => !!v)
    .join('');
  const fullPathname = [location.pathname, location.search]
    .filter((v) => !!v)
    .join('');

  return { onNavigate, prevPath, fullPathname };
};

export default useOwnNavigate;
