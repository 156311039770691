import cn from 'classnames';
import { ReactComponent as AddSvg } from 'src/assets/icons/add.svg';
import Button from 'src/components/Button';
import classes from './AddButton.module.scss';

const AddButton = ({ className, onClick }) => {
  return (
    <div className={cn(classes.wrapper, className)}>
      <Button
        variant="default"
        ghost
        className={classes.btn}
        rounded="lg"
        onClick={onClick}
      >
        <AddSvg />
      </Button>
    </div>
  );
};

export default AddButton;
