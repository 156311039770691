import { WEEK_DAY, WEEK_DAYS } from 'src/helper/constants/date';
import { getFullAddress } from 'src/utils/address';
import { convertTime, getTimeWithSuffix } from 'src/utils/date';
import { getSelectValue } from 'src/utils/form';

export const getLocationPayload = (values) => {
  const operating_hours = {};
  WEEK_DAYS.forEach((day) => {
    const tDay = day.toLowerCase();
    const openTime = values[`${day}_from`] || '';
    const closeTime = values[`${day}_to`] || '';
    const is24Hours = !!values[`${day}_24`];
    operating_hours[tDay] = {
      is_enabled: !!values[`${day}_day`],
      is_24_hours: is24Hours,
      opening_time: is24Hours ? null : convertTime(openTime) || null,
      closing_time: is24Hours ? null : convertTime(closeTime) || null,
    };
  });
  return {
    name: values?.name || '',
    description: values?.description || '',
    system_overview: values?.system_overview || '',
    region_id: getSelectValue(values?.region_id),
    address: values.addressObj || {},
    operating_hours,
  };
};
export const setInitialValues = (
  tLocation,
  { reset, setLocationAddress, setCurrentLocation }
) => {
  const tFullAddress = getFullAddress(tLocation.address);
  const tOperationHours = tLocation.operating_hours || [];
  const editObj = {
    name: tLocation.name,
    address: tFullAddress,
    addressObj: tLocation.address,
    region_id: tLocation.region_id,
    description: tLocation.description,
    system_overview: tLocation.system_overview,
  };
  WEEK_DAYS.forEach((day) => {
    const dayLowerCase = day.toLowerCase();
    const tFoundObj = tOperationHours.find(
      (obj) => obj.day_of_week === dayLowerCase
    );
    editObj[`${day}_day`] = !!tFoundObj?.is_enabled;
    editObj[`${day}_24`] = !!tFoundObj?.is_24_hours;
    editObj[`${day}_from`] = getTimeWithSuffix(tFoundObj?.opening_time);
    editObj[`${day}_to`] = getTimeWithSuffix(tFoundObj?.closing_time);
  });
  reset(editObj);
  setLocationAddress(tFullAddress);
  const geocoder = new window.google.maps.Geocoder();
  geocoder.geocode({ address: tFullAddress }, (results, status) => {
    if (status === 'OK' && results[0]) {
      const location = results[0].geometry.location;
      setCurrentLocation({
        lat: location.lat(),
        lng: location.lng(),
      });
    } else {
      console.error('Geocode error:', status);
    }
  });
};
export const getInitialValues = (urlParams) => {
  const defaultValues = {
    name: '',
    description: '',
    system_overview: '',
    address: '',
    region_id: urlParams?.['pre-region-id']
      ? Number(urlParams['pre-region-id'])
      : null,
    addressObj: {},
  };
  WEEK_DAYS.map((day) => {
    defaultValues[`${day}_day`] =
      day !== WEEK_DAY.SUNDAY && day !== WEEK_DAY.SATURDAY;
  });

  return defaultValues;
};
