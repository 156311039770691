/* eslint-disable no-restricted-imports */
import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  TextField,
} from '@material-ui/core';
import React from 'react';
import '../../index.scss';
import TaskDialog from 'src/pages/Admin/Tasks/TaskDialog';
import { useEventContext } from '../../contexts/EventContext';

export default function UpdateEventForm() {
  const { handleClose, selectedEvent, editEvent, formType } = useEventContext();

  return (
    <TaskDialog
      task={selectedEvent}
      onClose={handleClose}
      formType={formType}
    />
  );
}
