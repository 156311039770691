import React from 'react';
import { ReactComponent as CloseCircleSvg } from 'src/assets/icons/close-circle.svg';
import { ReactComponent as TickCircle1Svg } from 'src/assets/icons/tick-circle1.svg';
import classes from './notification.module.scss';

export const notifyPrimary = ({ ref, type, title, description, settings }) => {
  const isError = type === 'error';
  let options = {
    place: 'bc',
    message: (
      <div className="alert-text">
        <div className="alert-text-inner">
          {isError ? (
            <CloseCircleSvg className={classes.notificationErrorIcon} />
          ) : (
            <TickCircle1Svg />
          )}
          <span className="alert-title" data-notify="title">
            {title}
          </span>
          <span data-notify="message">{description}</span>
        </div>
      </div>
    ),
    type: type,
    autoDismiss: 5,
    ...(settings || {}),
  };
  ref.current.notificationAlert(options);
};
