import cn from 'classnames';
import React, { useState } from 'react';
import { ReactComponent as Danger1Svg } from 'src/assets/icons/danger1.svg';
import { ReactComponent as InfoCircleSvg } from 'src/assets/icons/info-circle.svg';
import { ReactComponent as TickCircleSvg } from 'src/assets/icons/tick-circle.svg';
import Icon from 'src/components/Icon';
import useOwnNavigate from 'src/helper/hooks/useOwnNavigate';
import classes from './QuickAccess.module.scss';

const ICON_MAPPING = {
  info: <TickCircleSvg />,
  warning: <InfoCircleSvg />,
  error: <Danger1Svg />,
};

const AccessItem = ({ className, data, onClick }) => {
  const { onNavigate } = useOwnNavigate();
  const type = data?.type || '';
  // const [clicked, setClicked] = useState(false);
  const icon = ICON_MAPPING[type];
  return (
    <div
      className={cn(
        classes.accessItem,
        {
          [classes.active]: false,
        },
        classes[`type_${type}`],
        className
      )}
      onClick={() => {
        if (onClick) onClick();
        if (data?.url) {
          onNavigate(data.url);
        }
      }}
    >
      <span>{data?.label}</span>
      {!!icon && <Icon icon={icon} activeIcon={icon} size={20} />}
    </div>
  );
};

export default AccessItem;
