import findIndex from 'lodash/findIndex';
import { YES_NO_VALUES } from 'src/helper/constants/option';
import { getSelectValue, getSelectValues } from 'src/utils/form';

export const getIsCollapseAll = (items, categoriesCollapsed) => {
  // const allSections = items.flatMap((category) => category.sections || []);
  let collapseAll = !!items.length;
  items.forEach((category) => {
    if (!categoriesCollapsed[category.id]) {
      collapseAll = false;
    }
  });
  return collapseAll;
};
export const getAllCollapse = (items, collapseAll) => {
  const newSectionsCollapsed = {};
  const newCategoriesCollapsed = {};
  items.forEach((category) => {
    newCategoriesCollapsed[category.id] = !collapseAll;
    category.sections.forEach((section) => {
      newSectionsCollapsed[section.id] = !collapseAll;
    });
  });
  return { categories: newCategoriesCollapsed, sections: newSectionsCollapsed };
};
export const getSectionBR = (values, company_id) => {
  return {
    company_id,
    name: values.section_name,
    level: getSelectValue(values.section_level_id),
    role_id: Number(getSelectValue(values.role_id)),
  };
};
export const getSectionItemBR = (values) => {
  return {
    section_id: values.section_id,
    name: values.item_name,
    description: values.item_description || '',
    type: getSelectValue(values.item_type_id),
    related_psm_elements: getSelectValues(values.related_psm_elements_id || []),
    role_id: Number(getSelectValue(values.role_id) || 0) || null,
    requires_approval:
      getSelectValue(values.require_manager_approval) === YES_NO_VALUES.YES
        ? 1
        : 0,
    can_be_hidden: values.can_hidden ? 1 : 0,
  };
};
const isIncludeSearch = (item, search) => {
  return (item?.name || '').toLowerCase().includes(search);
};
export const searchCategories = (categories, search) => {
  const searchLowerCase = (search || '').toLowerCase();
  const searchedCategories = (categories || []).filter((category) => {
    return (
      !searchLowerCase ||
      (category.sections || []).some((section) => {
        return (
          !searchLowerCase ||
          isIncludeSearch(section, searchLowerCase) ||
          (section.items || []).some((item) =>
            isIncludeSearch(item, searchLowerCase)
          )
        );
      })
    );
  });
  return searchedCategories.map((category) => {
    return {
      ...category,
      sections: (category.sections || [])
        .filter((section) => {
          return (
            !searchLowerCase ||
            isIncludeSearch(section, searchLowerCase) ||
            (section.items || []).some((item) =>
              isIncludeSearch(item, searchLowerCase)
            )
          );
        })
        .map((section) => {
          return {
            ...section,
            items: (section.items || []).filter((item) =>
              isIncludeSearch(item, searchLowerCase)
            ),
          };
        }),
    };
  });
};
