import { useState } from 'react';
import { DELETE_REGION_HAS_LOCATION_PREFIX } from 'src/helper/constants';
import { MENU_ACTION_KEYS } from 'src/helper/constants/menu';
import useOwnNavigate from 'src/helper/hooks/useOwnNavigate';
import Alert from 'src/pages/Regions/components/Alert';
import RegionCard from 'src/pages/Regions/components/Card';
import DeleteModal from 'src/pages/Regions/components/DeleteModal';
import EmptyState from 'src/pages/Regions/components/EmptyState';
import TurnOffModal from 'src/pages/Regions/components/TurnOffModal';
import { getIsMenuAction } from 'src/utils/menu';
import classes from './Regions.module.scss';
import { useActions, useIndexData } from './selectorData';

const RegionsContent = ({ isEnableRegions, onDelete, menuObj }) => {
  const { onNavigate } = useOwnNavigate();
  const [isTurnOff, setIsTurnOff] = useState(false);
  const [deleteId, setDeleteId] = useState('');
  const { updateSettings, syncUpdateSettings } = useActions();
  const { regions, regionsLoading } = useIndexData();
  const regionsList = regions?.data || [];
  const hasRegions = regions?.meta?.total > 0;

  return (
    <>
      {!hasRegions && !regionsLoading ? (
        <EmptyState
          entity="region"
          onCreate={() => {
            onNavigate('/regions/create-region');
          }}
        />
      ) : (
        <div className={classes.regions}>
          {regionsList.map((region, i) => {
            return (
              <RegionCard
                key={i}
                onDelete={
                  !getIsMenuAction(menuObj, MENU_ACTION_KEYS.DELETE)
                    ? undefined
                    : () => {
                        const locationCount = Number(
                          region.locations_count || 0
                        );
                        setDeleteId(
                          locationCount > 0
                            ? `${DELETE_REGION_HAS_LOCATION_PREFIX}${locationCount}_-${region.name}_-${region.id}`
                            : region.id
                        );
                      }
                }
                onEdit={
                  !getIsMenuAction(menuObj, MENU_ACTION_KEYS.EDIT)
                    ? undefined
                    : () => {
                        onNavigate(`/regions/edit/${region.id}`);
                      }
                }
                data={region}
                onView={() => {
                  onNavigate(`/regions/${region.id}`);
                }}
              />
            );
          })}
        </div>
      )}
      {isEnableRegions && !hasRegions && (
        <Alert
          onClick={() => {
            setIsTurnOff(true);
            updateSettings({
              settings: {
                enable_regions: false,
              },
            });
          }}
          className={classes.alert}
        />
      )}
      {isTurnOff && (
        <TurnOffModal
          isOpen
          onClose={() => {
            setIsTurnOff(false);
            syncUpdateSettings('enable_regions', false);
          }}
        />
      )}
      {!!deleteId && (
        <DeleteModal
          isOpen
          onClose={() => {
            setDeleteId('');
          }}
          title="Delete Region"
          onEdit={(tId) => {
            onNavigate(`/regions/edit/${tId}`);
          }}
          onSubmit={(cb) => {
            onDelete(
              deleteId,
              () => {
                if (cb) cb();
              },
              () => {
                setDeleteId('');
              }
            );
          }}
          deleteId={deleteId}
        />
      )}
    </>
  );
};

export default RegionsContent;
