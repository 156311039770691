import cloneDeep from 'lodash/cloneDeep';
import {
  RESOURCE_DATA_KEY,
  RESOURCE_DATA_KEY_MAPPING,
  STORE_SYNC_ACTIONS,
} from 'src/helper/constants/store';

export const getApiResourceKey = (key) => {
  return RESOURCE_DATA_KEY_MAPPING[key] || key;
};
export const buildResourceData = (data, key) => {
  // build quick access categories, sections, items id
  if (key === RESOURCE_DATA_KEY.QUICK_ACCESS_HIERARCHY && data?.data?.length) {
    return {
      ...data,
      data: data.data.map((obj) => {
        obj.id = obj.level.toLowerCase();
        obj.original_id = obj.level.toLowerCase();
        if (obj.sections?.length) {
          obj.sections = obj.sections.map((section) => {
            section.original_id = section.id;
            section.id = `${obj.id}_${section.id}`;
            if (section.items?.length) {
              section.items = section.items.map((item) => {
                item.original_id = item.id;
                item.id = `${section.id}_${item.id}`;
                return item;
              });
            }
            return section;
          });
        }
        return obj;
      }),
    };
  }
  return data;
};
export const getQuickAccessHierarchyUpdatedSections = (data, payloadData) => {
  const syncAction = payloadData?.action;
  const oldData = cloneDeep(
    data[RESOURCE_DATA_KEY.QUICK_ACCESS_HIERARCHY].data
  );
  return {
    ...data,
    [RESOURCE_DATA_KEY.QUICK_ACCESS_HIERARCHY]: {
      ...data[RESOURCE_DATA_KEY.QUICK_ACCESS_HIERARCHY],
      data: oldData.map((category) => {
        if (category.id === payloadData?.categoryId) {
          switch (syncAction) {
            case STORE_SYNC_ACTIONS.ADD:
              category.sections = [
                ...category.sections,
                {
                  ...payloadData.addedSection,
                  original_id: payloadData.addedSection.id,
                  id: `${payloadData.categoryId}_${payloadData.addedSection.id}`,
                  items: [],
                },
              ];
              break;
            case STORE_SYNC_ACTIONS.UPDATE: {
              const isChangedLevel =
                payloadData.newSection.level !== payloadData.category.level;
              if (isChangedLevel) {
                category.sections = category.sections.filter(
                  (section) => section.id !== payloadData?.sectionId
                );
              } else {
                category.sections = category.sections.map((section) => {
                  if (section.id === payloadData?.sectionId) {
                    return {
                      ...section,
                      name: payloadData.newSection.name,
                      role_id: payloadData.newSection.role_id,
                    };
                  }
                  return section;
                });
              }
              break;
            }
            case STORE_SYNC_ACTIONS.DELETE:
              category.sections = category.sections.filter(
                (section) => section.id !== payloadData?.sectionId
              );
              break;
            default:
              break;
          }
        }
        return category;
      }),
    },
  };
};
export const getQuickAccessHierarchyUpdatedSectionItems = (
  data,
  payloadData
) => {
  const syncAction = payloadData?.action;
  const oldData = cloneDeep(
    data[RESOURCE_DATA_KEY.QUICK_ACCESS_HIERARCHY].data
  );
  return {
    ...data,
    [RESOURCE_DATA_KEY.QUICK_ACCESS_HIERARCHY]: {
      ...data[RESOURCE_DATA_KEY.QUICK_ACCESS_HIERARCHY],
      data: oldData.map((category) => {
        if (category.id === payloadData?.categoryId) {
          category.sections = category.sections.map((section) => {
            if (section.id === payloadData?.sectionId) {
              switch (syncAction) {
                case STORE_SYNC_ACTIONS.ADD:
                  section.items = [
                    ...section.items,
                    {
                      ...payloadData.addedItem,
                      original_id: payloadData.addedItem.id,
                      id: `${payloadData.sectionId}_${payloadData.addedItem.id}`,
                    },
                  ];
                  break;
                case STORE_SYNC_ACTIONS.UPDATE:
                  section.items = section.items.map((item) => {
                    if (item.id === payloadData?.itemId) {
                      return {
                        ...item,
                        ...payloadData.newItem,
                      };
                    }
                    return item;
                  });
                  break;
                case STORE_SYNC_ACTIONS.DELETE:
                  section.items = section.items.filter(
                    (item) => item.id !== payloadData?.itemId
                  );
                  break;
                default:
                  break;
              }
            }
            return section;
          });
        }
        return category;
      }),
    },
  };
};
