export const STATUS_TAGS = {
  active: {
    label: 'Active',
    color: 'green',
  },
  inactive: {
    label: 'Inactive',
    color: 'red',
  },
  yes: {
    label: 'Yes',
    color: 'green',
  },
  no: {
    label: 'No',
    color: 'grey',
  },
};
