import pick from 'lodash/pick';
import { useEffect, useRef, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import Breadcrumb from 'src/components/Breadcrumb';
import Button from 'src/components/Button';
import Card from 'src/components/Card';
import DoughnutChart from 'src/components/Chart/Doughnut';
import Filter from 'src/components/Filter';
import SearchInput from 'src/components/FormFields/Input/SearchInput';
import Loading from 'src/components/Loading';
import Map from 'src/components/Map';
import PageTitle from 'src/components/Page/PageTitle';
import PageWrapper from 'src/components/Page/PageWrapper';
import Score from 'src/components/Score';
import ScoreChart from 'src/components/Score/Chart';
import ScoreReport from 'src/components/Score/Report';
import Table from 'src/components/Table';
import ToastAlert from 'src/components/ToastAlert';
import {
  CALLBACK_ACTION_PARAM_KEY,
  CREATE_SUCCESS_PARAM_KEY,
} from 'src/helper/constants/routes';
import useOwnNavigate from 'src/helper/hooks/useOwnNavigate';
import CreateLocationModal from 'src/pages/CreateRegion/components/CreateLocationModal';
import { getLocationPayload } from 'src/pages/CreateRegion/utils';
import { notifyPrimary } from 'src/utils/notification';
import { getAllUrlParams } from 'src/utils/routes';
import classes from './RegionDetails.module.scss';
import ArchiveLocationModal from './components/ArchiveLocationModal';
import { getColumns, markers } from './constants';
import { useActions, useIndexData } from './selectorData';

const RegionDetails = () => {
  const notificationAlertRef = useRef(null);
  const params = useParams();
  const { onNavigate } = useOwnNavigate();
  const regionId = Number(params.id || 0);
  const location = useLocation();
  const urlParams = getAllUrlParams(location?.search);
  const {
    getRegionDetails,
    updateRegion,
    createLocation,
    syncUpdateRegionDetails,
    getRegionLocations,
    deleteLocation,
  } = useActions();
  const { regionDetails, regionDetailsLoading, regionLocations } =
    useIndexData();
  const [archiveLocationObj, setArchiveLocationObj] = useState(null);
  const [isAddLocation, setIsAddLocation] = useState(false);
  const [createLocationLoading, setCreateLocationLoading] = useState(false);
  const [search, setSearch] = useState('');
  const [checkedRows, setCheckedRows] = useState([]);
  const currentLocations = [...regionLocations];
  const currentLocationIds = currentLocations.map((obj) => obj.id);
  const filteredLocations = currentLocations.filter((obj) =>
    obj.name.toLowerCase().includes(search.toLowerCase())
  );
  const breadcrumbs = [
    {
      link: '/regions',
      label: 'Regions',
    },
    {
      label: regionDetails.name,
    },
  ];
  const handleEditLocation = (obj) => {
    onNavigate(`/locations/edit/${obj.id}`);
  };
  const handleArchiveLocation = (obj) => {
    setArchiveLocationObj(obj);
  };
  const columns = getColumns({
    onEdit: handleEditLocation,
    onArchive: handleArchiveLocation,
  });
  const notify = (type, title, description) => {
    notifyPrimary({
      ref: notificationAlertRef,
      type,
      title,
      description,
      settings: {
        place: 'bc',
      },
    });
  };
  const reloadRegionLocations = () => {
    getRegionLocations(regionId, {
      page: 1,
      per_page: 99999,
    });
  };
  const handleUploadRegion = (ids, cb) => {
    updateRegion(
      {
        ...pick(regionDetails, ['id', 'name', 'description']),
        location_ids: ids,
      },
      cb
    );
  };
  const handleCreateLocation = (values) => {
    if (!createLocationLoading) {
      setCreateLocationLoading(true);
      const tPayload = getLocationPayload(values, regionId);
      createLocation(tPayload, (res) => {
        if (res) {
          reloadRegionLocations();
          setCreateLocationLoading(false);
          setIsAddLocation(false);
          notify('success', 'Success!', 'Location created successfully');
        } else {
          notify('error', 'Error!', 'Location creating failed');
        }
      });
    }
  };

  useEffect(() => {
    getRegionDetails(regionId);
    reloadRegionLocations();
    if (
      !!urlParams?.[CALLBACK_ACTION_PARAM_KEY] &&
      urlParams[CALLBACK_ACTION_PARAM_KEY] === CREATE_SUCCESS_PARAM_KEY
    ) {
      notify('success', 'Success!', 'Location added successfully');
      onNavigate(`/regions/${regionId}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <PageWrapper className={classes.wrapper}>
        {regionDetailsLoading && <Loading isPage />}
        <Breadcrumb items={breadcrumbs} />
        <div className={classes.pageTitleWrapper}>
          <PageTitle>{regionDetails.name}</PageTitle>
        </div>
        <div className={classes.overviewWrapper}>
          <Card title="Overview" subHeading={regionDetails.description}>
            <Map className={classes.map} markers={markers} />
          </Card>
          <Card
            title="Omni Score"
            contentAlign="vertical"
            dropdowns={
              [
                // {
                //   key: 'edit',
                //   label: 'Action',
                //   onClick: () => {},
                // },
              ]
            }
          >
            <Score
              score={95}
              width={226}
              center
              activeColor={'#45B942'}
              issuesColor={'#C1FFC0'}
              color="#EEEEEE"
              title=" "
              className={classes.score}
              issues={2}
            />
            <ScoreChart
              title="Last 30 Days"
              scores={[
                100, 100, 100, 60, 60, 60, 30, 30, 100, 100, 100, 100, 100, 100,
                100, 100, 100, 100, 100, 100, 60, 60, 60, 30, 30, 100, 100, 100,
                60, 60,
              ]}
            />
            <ScoreReport
              title="Report Card"
              description="View a detailed breakdown of your region"
              className={classes.scoreReport}
            />
          </Card>
          <Card
            title="Current Issues"
            contentAlign="vertical"
            dropdowns={
              [
                // {
                //   key: 'edit',
                //   label: 'Action',
                //   onClick: () => {},
                // },
              ]
            }
          >
            <DoughnutChart className={classes.chart} />
          </Card>
        </div>
        <div className={classes.locations}>
          <div className={classes.locationHeader}>
            <span>Locations List</span>
            <div>
              <SearchInput
                size="medium"
                placeholder="Search Locations"
                iconPosition="right"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
              <Filter />
              <Button
                variant="primary"
                onClick={() => {
                  setIsAddLocation(true);
                  onNavigate(
                    `/locations/create-location?pre-region-id=${regionId}`
                  );
                }}
                fontSize="sm"
                size="medium"
              >
                Add New
              </Button>
            </div>
          </div>
          <Table
            columns={columns}
            data={filteredLocations}
            checkable={{
              checkedRows,
              onChange: (cRows) => {
                // setCheckedRows(cRows);
              },
            }}
          />
        </div>
      </PageWrapper>
      <ToastAlert toastRef={notificationAlertRef} />
      {archiveLocationObj && (
        <ArchiveLocationModal
          isOpen
          onClose={() => {
            setArchiveLocationObj(null);
          }}
          onSubmit={(val, cb) => {
            deleteLocation(archiveLocationObj.id, (res) => {
              if (cb) cb();
              if (res) {
                reloadRegionLocations();
                setArchiveLocationObj(null);
                notify('success', 'Success!', 'Location has been archived');
              } else {
                notify('error', 'Error!', 'Location archiving failed');
              }
            });
          }}
        />
      )}
    </>
  );
};

export default RegionDetails;
