import debounce from 'lodash/debounce';
import React, { useCallback, useEffect } from 'react';

const useInfiniteScroll = ({
  elementId,
  threshold = 250,
  debounceTime = 300,
  onLoadMore,
  shouldLoadMore = true,
}) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleScroll = useCallback(
    debounce(async () => {
      const element = document.getElementById(elementId);
      if (
        element.offsetHeight + element.scrollTop <
        element.scrollHeight - threshold
      ) {
        return;
      }
      // call load more
      if (onLoadMore && shouldLoadMore) onLoadMore();
    }, debounceTime),
    [elementId, debounceTime, onLoadMore, shouldLoadMore]
  );

  // observe scroll
  useEffect(() => {
    const element = document.getElementById(elementId);
    if (element) {
      element.addEventListener('scroll', handleScroll);
      return () => element.removeEventListener('scroll', handleScroll);
    }
  }, [handleScroll, elementId]);
};

export default useInfiniteScroll;
