import cn from 'classnames';
import { ReactComponent as CloseSquareSvg } from 'src/assets/icons/close-square.svg';
import Icon from 'src/components/Icon';
import { TAG_STATUS_COLOR_MAPPING } from 'src/helper/constants/tag';
import classes from './Tag.module.scss';

const Tag = ({
  className,
  children,
  variant,
  weight,
  onClose,
  outline,
  radius,
  onClick,
  size,
  ghost,
}) => {
  return (
    <span
      className={cn(
        classes.wrapper,
        classes[`variant_${TAG_STATUS_COLOR_MAPPING[variant] || variant}`],
        classes[`weight_${weight}`],
        classes[`radius_${radius}`],
        classes[`size_${size}`],
        {
          [classes.closeable]: !!onClose,
          [classes.outline]: !!outline,
          [classes.ghost]: !!ghost,
          pointer: !!onClick,
        },
        className
      )}
      onClick={onClick}
    >
      <span>{children}</span>
      {!!onClose && (
        <Icon
          icon={<CloseSquareSvg />}
          activeIcon={<CloseSquareSvg />}
          onClick={onClose}
          size={18}
        />
      )}
    </span>
  );
};

export default Tag;
