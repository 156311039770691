import React, { useState } from 'react';
import Autosuggest from 'react-autosuggest';
import classes from './ChemicalAutosuggest.module.scss';
import { chemicals, hazardMap } from './data';

// Suggestion filtering function
const getSuggestions = (value) => {
  const inputValue = (value || '').trim().toLowerCase();
  const inputLength = inputValue.length;
  var starts = chemicals.filter(
    (chem) => chem.name.toLowerCase().slice(0, inputLength) === inputValue
  );
  var contains = chemicals.filter((chem) =>
    chem.name.toLowerCase().includes(inputValue)
  );
  var cass = chemicals.filter((chem) =>
    chem.cas.toLowerCase().includes(inputValue)
  );
  var res = [...starts, ...contains, ...cass];
  // make unique
  res = res.filter((v, i, a) => a.findIndex((t) => t.name === v.name) === i);
  return res;
};
const getSuggestionValue = (suggestion) => suggestion.name; // Value shown in input
const getHazardDescription = (input) => {
  // Split input by commas, trim spaces, filter out invalid codes, and remove duplicates
  const hazards = [
    ...new Set(
      input
        .split(',')
        .map((h) => h.trim())
        .filter((h) => hazardMap[h])
    ),
  ];

  // Convert hazard codes to descriptions
  if (hazards.length === 0) {
    return 'No known hazards specified.';
  }
  if (hazards.length === 1) {
    return 'Hazard: ' + hazards.map((h) => hazardMap[h]).join(', ');
  }

  return 'Hazards: ' + hazards.map((h) => hazardMap[h]).join(', ');
};
const renderSuggestion = (suggestion) => (
  <div>
    <strong>{suggestion.name}</strong>
    <div>
      CAS: <code className={classes.cas}>{suggestion.cas}</code>
    </div>
    <div>{getHazardDescription(suggestion.haz)}</div>
  </div>
);

const ChemicalAutosuggest = ({
  value,
  onChange,
  onBlur,
  inputProps,
  ...rest
}) => {
  const [suggestions, setSuggestions] = useState([]);

  const onSuggestionsFetchRequested = ({ value }) => {
    setSuggestions(getSuggestions(value));
  };

  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  return (
    <div className={classes.autosuggestContainer}>
      <Autosuggest
        {...rest}
        suggestions={suggestions}
        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
        onSuggestionsClearRequested={onSuggestionsClearRequested}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        inputProps={{
          ...inputProps, // Spread the inputProps from the Controller
          value, // Controlled input value
          onChange: (e, { newValue }) => onChange(newValue), // Update form value on input change
          onBlur, // onBlur to mark the field as touched
          className: classes.autosuggestInput, // Apply class to input element
        }}
        theme={{
          container: classes.autosuggestContainer,
          suggestionsList: classes.suggestionsList,
          suggestion: classes.suggestionItem,
          suggestionHighlighted: classes.suggestionHighlighted,
        }}
      />
    </div>
  );
};

export default ChemicalAutosuggest;
