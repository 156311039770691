import cn from 'classnames';
import DOMPurify from 'dompurify';
import Editor from 'src/components/FormFields/Editor';
import classes from './RichTextEditor.module.scss';

const RichTextEditor = ({
  className,
  editorContent,
  onEditorChange,
  isEdit,
  document,
}) => {
  const safeHTML = document?.content
    ? DOMPurify.sanitize(document.content)
    : '';
  return (
    <div className={cn(classes.wrapper, className)}>
      {!isEdit && (
        <div className={classes.viewWrapper}>
          <div
            className={classes.view}
            dangerouslySetInnerHTML={{ __html: safeHTML }}
          />
        </div>
      )}
      <Editor
        value={editorContent}
        onChange={onEditorChange}
        className={cn(classes.editor, {
          hide: !isEdit,
        })}
      />
    </div>
  );
};

export default RichTextEditor;
