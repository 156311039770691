import cn from 'classnames';
import uniqBy from 'lodash/uniqBy';
import { useState } from 'react';
import { ReactComponent as UserAddSvg } from 'src/assets/icons/user-add.svg';
import Button from 'src/components/Button';
import Filter from 'src/components/Filter';
import SearchInput from 'src/components/FormFields/Input/SearchInput';
import Pagination from 'src/components/Pagination';
import Table from 'src/components/Table';
import { applySearch } from 'src/utils/search';
import classes from './LocationDetailsTable.module.scss';

const LocationDetailsTable = ({
  className,
  columns,
  title,
  searchPlaceholder,
  addText,
  onAdd,
  filterItems,
  onAllFiltersChanged,
  data,
  onRowClick,
  loading,
}) => {
  const listData = (data?.data || []).map((obj) => ({
    ...obj,
    check_id: `${title}_${obj.id}`,
  }));
  const listMeta = data?.meta || {};
  const total = listMeta?.total || 0;
  const page = listMeta.current_page || 1;
  const size = listMeta.per_page || 10;
  const [search, setSearch] = useState('');
  const [checkedRows, setCheckedRows] = useState([]);
  const [sort, setSort] = useState({
    field: '',
    method: '',
  });
  const [filtersChecked, setFiltersChecked] = useState([]);
  const handleAllFiltersChanged = (...params) => {
    if (onAllFiltersChanged) onAllFiltersChanged(...params);
  };
  const handleFiltersChanged = (checkedIds) => {
    setFiltersChecked(checkedIds);
    handleAllFiltersChanged(search, sort, checkedIds, { page, size });
  };
  const noDataEmptyFilter =
    total === 0 && page === 1 && !search && !sort?.field;
  const noDataHasFilter = total === 0 && (!!search || !!sort?.field);

  return (
    <div className={cn(classes.wrapper, className)}>
      <div className={classes.header}>
        <span>{title}</span>
        <div>
          <SearchInput
            size="medium"
            placeholder={searchPlaceholder}
            iconPosition="right"
            value={search}
            onChange={(e) => {
              setSearch(e.target.value);
              applySearch(e.target.value, (val) => {
                handleAllFiltersChanged(val, sort, filtersChecked, {
                  page,
                  size,
                });
              });
            }}
            light
          />
          <Filter
            items={filterItems}
            onChange={handleFiltersChanged}
            selectedItemIds={filtersChecked}
          />
          <Button
            variant="primary"
            onClick={onAdd}
            fontSize="sm"
            size="medium"
            weight="md"
          >
            {addText || 'Add New'}
          </Button>
        </div>
      </div>
      <div className={classes.tableWrapper}>
        <Table
          columns={columns}
          checkEntity={title}
          data={listData}
          checkable={{
            checkedRows,
            onChange: (row, allChecked, itemChecked) => {
              if (allChecked === true || allChecked === false) {
                const tIds = listData.map((obj) => obj.check_id);
                setCheckedRows(
                  allChecked
                    ? uniqBy([...checkedRows, ...listData], 'check_id')
                    : checkedRows.filter((obj) => !tIds.includes(obj.check_id))
                );
              } else {
                setCheckedRows(
                  itemChecked
                    ? [...checkedRows, row]
                    : checkedRows.filter((obj) => obj.check_id !== row.check_id)
                );
              }
            },
          }}
          sort={sort}
          onSortChange={(tField, tMethod) => {
            const newSort = {
              field: tField,
              method: tMethod,
            };
            setSort(newSort);
            handleAllFiltersChanged(search, newSort, filtersChecked, {
              page,
              size,
            });
          }}
          onRowClick={onRowClick}
          empty={
            (noDataHasFilter || noDataEmptyFilter) &&
            !loading && (
              <>
                <UserAddSvg />
                <span>
                  <b>
                    It looks like no {title}{' '}
                    {noDataHasFilter
                      ? 'has been matched to this filter yet'
                      : 'has been added to this location yet'}
                    .
                  </b>
                  {title == 'Staff' && (
                    <>
                      <br />
                      Adding staff helps make sure everyone is up to date on
                      <br />
                      their training and is aware of correct PSI.
                      <br />
                      <br />
                      Click Add Staff to get started.
                    </>
                  )}
                </span>
              </>
            )
          }
        />
        {!(noDataHasFilter || noDataEmptyFilter) && (
          <Pagination
            light
            data={{ size, page, total: total }}
            onChange={(tPage, tSize) => {
              if (tPage !== page || tSize !== size) {
                handleAllFiltersChanged(search, sort, filtersChecked, {
                  page: tPage,
                  size: tSize,
                });
              }
            }}
            disabled={false}
          />
        )}
      </div>
    </div>
  );
};

export default LocationDetailsTable;
