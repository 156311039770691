import { getApiResourceKey } from 'src/utils/resource';
import Request from './request';

export const apiGetResources = async (key, params) => {
  return Request.call({
    url: `/${getApiResourceKey(key)}`,
    method: 'GET',
    params,
  });
};
export const apiGetResource = async (key, id, params) => {
  return Request.call({
    url: `/${getApiResourceKey(key)}/${id}`,
    method: 'GET',
    params,
  });
};
export const apiCreateResource = async (key, data) => {
  return Request.call({
    url: `/${getApiResourceKey(key)}`,
    method: 'POST',
    data,
  });
};
export const apiUpdateResource = async (key, id, data) => {
  return Request.call({
    url: `/${getApiResourceKey(key)}/${id}`,
    method: 'PUT',
    data,
  });
};
export const apiDeleteResource = async (key, id, data = {}) => {
  return Request.call({
    url: `/${getApiResourceKey(key)}/${id}`,
    method: 'DELETE',
    data,
  });
};
export const apiArchiveResource = async (key, id, data, method) => {
  return Request.call({
    url: `/${getApiResourceKey(key)}/${id}/archive`,
    method: method || 'POST',
    data,
  });
};
export const apiArchiveVersionResource = async (key, id, data, method) => {
  return Request.call({
    url: `/${getApiResourceKey(key)}/${id}/version_archive`,
    method: method || 'PUT',
    data,
  });
};
export const apiAssignResourceMenuItems = async (key, id, data) => {
  return Request.call({
    url: `/${getApiResourceKey(key)}/${id}/assign-menu-items`,
    method: 'POST',
    data,
  });
};
export const apiGetResourceMenuItems = async (key, id) => {
  return Request.call({
    url: `/${getApiResourceKey(key)}/${id}/menu-items`,
    method: 'GET',
  });
};
export const apiAssignResourceLocationsRoles = async (key, id, data) => {
  return Request.call({
    url: `/${getApiResourceKey(key)}/${id}/assign-locations-roles`,
    method: 'POST',
    data,
  });
};
export const apiGetResourceLocationsRoles = async (key, id) => {
  return Request.call({
    url: `/${getApiResourceKey(key)}/${id}/locations-roles`,
    method: 'GET',
  });
};
export const apiUploadResource = async (key, data) => {
  return Request.call({
    url: `/${getApiResourceKey(key)}/upload`,
    method: 'POST',
    data,
    multipart: true,
  });
};
export const apiUploadNewVersionResource = async (key, id, data) => {
  return Request.call({
    url: `/${getApiResourceKey(key)}/${id}/upload-new-version`,
    method: 'POST',
    data,
    multipart: true,
  });
};
export const apiGetResourceVersions = async (key, id, params = {}) => {
  return Request.call({
    url: `/${getApiResourceKey(key)}/${id}/versions`,
    method: 'GET',
    params,
  });
};
export const apiGetResourceHistory = async (key, id, params = {}) => {
  return Request.call({
    url: `/${getApiResourceKey(key)}/${id}/history`,
    method: 'GET',
    params,
  });
};
export const apiAssignResourceStaff = async (key, id, data) => {
  return Request.call({
    url: `/${getApiResourceKey(key)}/${id}/staff`,
    method: 'POST',
    data,
  });
};
export const apiDeleteResourceStaff = async (key, id, data) => {
  return Request.call({
    url: `/${getApiResourceKey(key)}/${id}/staff`,
    method: 'DELETE',
    data,
  });
};
export const apiAddResourceDocument = async (key, id, data) => {
  return Request.call({
    url: `/${getApiResourceKey(key)}/${id}/documents`,
    method: 'POST',
    data,
  });
};
export const apiUpdateResourceContent = async (key, id, data) => {
  return Request.call({
    url: `/${getApiResourceKey(key)}/${id}/content`,
    method: 'PUT',
    data,
  });
};
export const apiUpdateResourceFile = async (key, id, data) => {
  return Request.call({
    url: `/${getApiResourceKey(key)}/${id}/file`,
    method: 'PUT',
    data,
  });
};
