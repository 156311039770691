import cn from 'classnames';
import FileItem from 'src/components/FormFields/Upload/FileItem';
import classes from './UploadResultOverlay.module.scss';

const UploadResultOverlay = ({ className, data, onDelete }) => {
  return (
    <div className={cn(classes.wrapper, className)}>
      <FileItem
        name={data?.name}
        size={data?.size}
        onDelete={onDelete}
        onRetry={() => {}}
        onCancel={() => {}}
        loading={data?.loading}
        isSuccess={false}
        isError={false}
        displaySize={!data?.loading}
        className={classes.resultItem}
      />
    </div>
  );
};

export default UploadResultOverlay;
