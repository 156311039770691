import orderBy from 'lodash/orderBy';
import uniq from 'lodash/uniq';

export const getRealLocationId = (value) => {
  return Number((value || '').split('_')[2]);
};
export const getRealLocationName = (value) => {
  return (value || '').split(' > ')[2];
};
export const getAllMenusFromLocations = (locations) => {
  const menus = [];
  (locations || []).forEach((location) => {
    if (location.menus) {
      location.menus.forEach((menu) => {
        const foundIndex = menus.findIndex((obj) => obj.id === menu.id);
        if (foundIndex >= 0) {
          menus[foundIndex].actions = uniq([
            ...menus[foundIndex].actions,
            ...menu.actions,
          ]);
        } else {
          menus.push(menu);
        }
      });
    }
  });
  return menus;
};
export const getSelectedLocationIds = (selectedLocations) => {
  const ids = (selectedLocations || [])
    .map((obj) => {
      const valueArr = obj.value.split('_');
      return Number(valueArr[valueArr.length - 1]);
    })
    .filter((v) => !!v);
  return orderBy(ids, [], ['asc']);
};
export const getLocationOptionFromId = (locationId, options) => {
  return options.find(
    (obj) => String(getRealLocationId(obj.value)) === String(locationId)
  );
};
export const getCompanyIdFromSelectedLocations = (selectedLocations) => {
  const id = selectedLocations?.[0]?.value || '';
  return Number(id.split('_')[0]);
};
