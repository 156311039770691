import FieldsRow from 'src/components/Form/FieldsRow';
import StatusTag from 'src/components/Tag/Status';
import { getQuantityUnitName } from 'src/helper/constants/quantityUnits';
import Field from 'src/pages/GenericCreate/components/Field';
import { getFormatDate } from 'src/utils/date';

const CreateChemicalView = ({ chemicalDetails }) => {
  return (
    <>
      <FieldsRow col={2}>
        <Field label="Name" value={chemicalDetails?.chemical_name} bold />
        <Field label="Cas Number" value={chemicalDetails?.cas_number} bold />
      </FieldsRow>
      <FieldsRow col={2}>
        <Field
          label="Tier 2 reportable"
          bold
          value={
            <span>
              <StatusTag
                value={chemicalDetails.tier_2_reportable ? 'yes' : 'no'}
              />
            </span>
          }
        />
        <Field
          label="Status"
          bold
          value={
            <span>
              <StatusTag value={chemicalDetails?.status} />
            </span>
          }
        />
      </FieldsRow>
      <FieldsRow col={2}>
        <Field
          label="Quantity"
          value={
            chemicalDetails?.quantity +
            ' ' +
            getQuantityUnitName(chemicalDetails.quantity_unit)
          }
          bold
        />
      </FieldsRow>
      <FieldsRow col={2}>
        <Field
          label="Last updated"
          value={getFormatDate(chemicalDetails?.updated_at, 'MMM DD, YYYY')}
          bold
        />
      </FieldsRow>
    </>
  );
};

export default CreateChemicalView;
