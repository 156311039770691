import cn from 'classnames';
import classes from './QuickAccess.module.scss';

const AccessTabList = ({ className, children }) => {
  return <div className={cn(classes.tabList, className)}>{children}</div>;
};
const AccessTabListInner = ({ className, children }) => {
  return <div className={cn(classes.tabListInner, className)}>{children}</div>;
};
AccessTabList.Inner = AccessTabListInner;

export default AccessTabList;
